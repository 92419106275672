import React, { RefObject } from "react"
import InventoryService from "../../../services/inventory/InventoryService"
import { GridAction, GridColumn, GridColumnTypes } from "../../Grid/Grid.models"
import DynamicGrid from "../../shared/DynamicGrid/DynamicGrid"
import { Col, Form, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import { DashCircle, ClockHistory, CheckLg, X, ArrowClockwise } from "react-bootstrap-icons"
import { NotificationManager } from 'react-notifications';
import ITakeoutInventoryState, { ITakeoutInventoryFilters } from "./TakeoutInventory.models"
import ShowCodeModal from "../InventoryInformations/ShowCodeModal"
import { ORDER_INFORMATION } from "../../../constants/ConstantsPermissions"
import './TakeoutInventory.scss'

const sourceKeys: { title: string, value: string }[] = [
    { title: 'ORDERS', value: 'ORDERS' },
    { title: 'WEB_SERVICE', value: 'WEB_SERVICE' },
    { title: 'MANUAL', value: 'MANUAL' },
]

export default class TakeoutInventory extends React.PureComponent<{}, ITakeoutInventoryState> {
    inventoryService: InventoryService
    grid: RefObject<DynamicGrid<ITakeoutInventoryFilters>>;

    constructor(props: any) {
        super(props);
        this.grid = React.createRef()
        this.inventoryService = new InventoryService()
        this.state = {
            rowSelected: undefined,
            showErrorModal: false,
        }
    }

    renderTooltip = (text: string, body: any) => {
        return (
            <OverlayTrigger placement="top"
                overlay={<Tooltip id="tooltip">
                    {text}
                </Tooltip>}
            >
                {body}
            </OverlayTrigger>
        )
    }

    showCodeHandler = (row: any) => {
        this.setState({ rowSelected: row })
    }

    renderSupply = (inventoryShopId: string, isCanceled: boolean) =>
        isCanceled ? this.renderTooltip("Has been canceled", <DashCircle size="20px" className="text-danger" />) :
            inventoryShopId != null ? this.renderTooltip("Has been supplied", <CheckLg size="20px" className="text-success" />) :
                this.renderTooltip("Pending", <ClockHistory size="20px" className="text-muted" />)


    renderWebhookDelivered = (isWebhookDelivered: boolean, webhookDeliveredLastError: string, id: string,inventoryShopId: string) => {
        if (isWebhookDelivered)
            return <div className="inventory-area">
                <CheckLg size="20px" className="text-success delivered-icon" />
                {this.renderTooltip("Resend Webhook", <ArrowClockwise size="20px" className="text-warning resend-icon" onClick={() => this.resendWebhook(id)} />)}
            </div>
        else if (!isWebhookDelivered && webhookDeliveredLastError)
            return this.renderTooltip("Pending", <X size="20px" onClick={() => this.setState({ rowSelected: { webhookDeliveredLastError } })} className="text-danger" />)
        else if(inventoryShopId != null)
            return this.renderTooltip("Send", <ClockHistory size="20px" onClick={() => this.resendWebhook(id)} className="text-warning" />)
        return ""
    }


    resendWebhook = (id: string) =>
        window.confirm('Are you sure to resend webhook?') &&
        this.inventoryService.resendWebhook(id).then(() => NotificationManager.success("The webhook resend successfully"))

    render(): React.ReactNode {
        let gridActions: GridAction[] = [
            {
                caption: 'Show‌', checkIsDisabled: (row) => row.inventoryShopId == null, permission: ORDER_INFORMATION, variant: 'success', skipSpinner: true, onClick: (row) => {
                    this.showCodeHandler(row)
                }
            }
        ];

        let gridColumns: GridColumn[] = [
            { title: 'Product', key: 'productTitle' },
            { title: 'Source Key', key: 'sourceKey' },
            { title: 'Source Document', key: 'sourceDocument' },
            { title: 'Delivery Key', key: 'sourceDeliveryKey' },
            { title: 'Supply', key: 'supply' },
            { title: 'Webhook Delivered', key: 'isWebhookDelivered' },
            { title: 'Create At', key: 'createDate', type: GridColumnTypes.Date },
        ]
        return (<>
            <DynamicGrid<ITakeoutInventoryFilters> ref={this.grid}
                columns={gridColumns}
                actions={gridActions}
                onFetch={this.inventoryService.getTakeoutInventories}
                filtersForm={this.filtersView}
                refactorRow={(row) => {
                    return { ...row, supply: this.renderSupply(row.inventoryShopId, row.isCanceled), isWebhookDelivered: this.renderWebhookDelivered(row.isWebhookDelivered, row.webhookDeliveredLastError, row.id,row.inventoryShopId) }
                }}
            />
            {this.state.showErrorModal && this.renderWebhookDeliveryModal()}
            {this.state.rowSelected &&
                <ShowCodeModal
                    closeModal={() => this.setState({ rowSelected: undefined })}
                    inventoryShopId={this.state.rowSelected?.inventoryShopId as string} />}
        </>)
    }

    filtersView = (filters: ITakeoutInventoryFilters, onChange: (newFilters: ITakeoutInventoryFilters) => void): JSX.Element => {
        return <>
            <Row className="mt-2">
                <Col md="12" sm="12">
                    <Form.Group>
                        <Form.Label>Source Key</Form.Label>
                        <Form.Select aria-label=">Select source key" value={filters.sourceKey}
                            onChange={(e) => onChange({ ...filters, sourceKey: e.target.value })}>
                            <option value={undefined} key={'all'}>All</option>
                            {
                                sourceKeys.map((item) => <option value={item.value} key={item.value}>{item.title}</option>)
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md="12" sm="12" className="mt-2">
                    <Form.Label>Delivery Key</Form.Label>
                    <Form.Control aria-label="Enter Wallet Address"
                        value={filters.sourceDeliveryKey} onChange={(e) => onChange({ ...filters, sourceDeliveryKey: e.target.value })} />
                </Col>
            </Row>
        </>
    }

    renderWebhookDeliveryModal = () => {
        return (
            (this.state.rowSelected && this.state.showErrorModal) && <Modal
                size={'lg'}
                show={true}
                onHide={() => this.setState({ rowSelected: undefined })}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Webhook Delivery Last Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-danger mb-3'>
                        {this.state.rowSelected.webhookDeliveredLastError}
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}