import React from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import { MegaphoneFill } from 'react-bootstrap-icons';
import Note from '../../shared/Note/Note';
import ValidAmount from '../../utilities/Amount/ValidAmount';
import OffersService from '../../../services/orders/OffersService';
import authenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext";
import { SELLER, WEB_SERVICE } from '../../../constants/ConstantsPolicies';
import { CustomButton } from '../../shared/button/ButtonComponent';
import { CircularProgress } from '@mui/material';


interface Props {
    productId: string,
    onCreated: () => void
}

interface IStates {
    maxPrice: string;
    quantity: number;
    loading: boolean;
    addToInventory: boolean;
}

export default class CreateNewOffer extends React.Component<Props, IStates> {
    offersService: OffersService;
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            maxPrice: '0',
            quantity: 1,
            addToInventory: true
        }
        this.offersService = new OffersService();
    }
    componentDidMount(): void {

    }
    onSave = () => {
        this.offersService.create({
            quantity: this.state.quantity,
            price: Number(this.state.maxPrice),
            productId: this.props.productId,
            addToInventory: this.state.addToInventory
        })
            .then((response) => {
                NotificationManager.success('Your offer created')
                this.props.onCreated();
            })
    }
    render(): React.ReactNode {
        return <div>
            <Row className="mt-2">
                <Col md="3" sm="12" className="d-flex align-items-center"><span className='text-pale-grey-main font-sm font-medium'> Max Price:</span></Col>
                <Col md="6" sm="12">
                    <Form.Control
                        type="text"
                        inputMode='decimal' value={this.state.maxPrice}
                        onChange={(e) => ValidAmount(e.target.value) && this.setState({ maxPrice: e.target.value })} />
                </Col>

            </Row>
            <br />
            <Note>
                Your price is confidential and will be used as the maximum order price
            </Note>
            <hr />
            <Row className="mt-2">
                <Col md="3" sm="12" className="d-flex align-items-center"><span className='text-pale-grey-main font-sm font-medium'>Quantity:</span></Col>
                <Col md="6" sm="12">
                    <Form.Control type="number"
                        value={this.state.quantity}
                        onChange={(e) => this.setState({ quantity: parseInt(e.target.value) })} />
                </Col>
            </Row>
            <br />
            <Note>
                The maximum number of your order (this number will be supply gradually by the sellers)
            </Note>

            <hr />

            {
                (this.context.policies?.includes(SELLER) || this.context.policies?.includes(WEB_SERVICE)) &&
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                    <Form.Check name="active" id={'v'} className="mt-4"
                        checked={this.state.addToInventory}
                        onChange={() => this.setState({ addToInventory: !this.state.addToInventory })}
                        type="checkbox" label={'Add offer to inventory (after supply)'}
                    />
                </Form.Group>
            }
            {this.state.loading ? <CustomButton variant='contained' disabled><CircularProgress size={20} /></CustomButton> :
                <CustomButton variant='contained' onClick={this.onSave}>Create Offer</CustomButton>}
        </div>
    }
}