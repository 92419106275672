import React, { RefObject } from "react";
import { Button, Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import AdminArticleCategoryService from "../../../../services/admin/articles/AdminArticleCategoryService";
import AdminArticleService from "../../../../services/admin/articles/AdminArticlesService";
import { GridAction, GridColumn } from "../../../Grid/Grid.models";
import DynamicGrid from "../../../shared/DynamicGrid/DynamicGrid";
import { IFiltersArticles, IState } from "./AdminArticleList.models";
import AdminArticleDetailsModal from "./Details/AdminArticleDetailsModal";
import { CheckLg, XLg } from "react-bootstrap-icons";
import { CustomButton } from "../../../shared/button/ButtonComponent";

export default class AdminArticleList extends React.Component<{}, IState> {
    dynamicGrid: RefObject<DynamicGrid<IFiltersArticles>>;
    adminArticleService: AdminArticleService
    adminArticleCategoryService: AdminArticleCategoryService
    constructor(props: any) {
        super(props);
        this.state = { title: '', articleCategoryId: '', categoryTitleValues: [], showDetailsModal: false };
        this.dynamicGrid = React.createRef()
        this.adminArticleService = new AdminArticleService()
        this.adminArticleCategoryService = new AdminArticleCategoryService()
    }

    componentDidMount(): void {
        this.fetchData();
    }

    fetchData = () => {
        this.adminArticleCategoryService.getTitleValues().then(response => this.setState({ categoryTitleValues: response }));
    }

    renderArticleCategory = (articleCategoryId: string) => {
        return this.state.categoryTitleValues.filter(articleCategoty => articleCategoty.value == articleCategoryId)[0].title
    }

    reload = () => this.dynamicGrid.current.fetch()

    requestToRemoveRow = (id: string) => {
        if (window.confirm('Are you sure? This Article will be deleted permanently')) {
            this.adminArticleService.delete(id).then(() => {
                NotificationManager.success("the article deleted successfully")
                this.dynamicGrid.current.fetch();
            }).finally(() => this.dynamicGrid.current.resetSpinner())
        } else this.dynamicGrid.current.resetSpinner()
    }

    departmentStatusDisplay = (status: boolean) => {
        return <span>{status ? <CheckLg size="20px" className="text-success" /> : <XLg size="20px" className="text-muted" />}</span>
    }

    filtersView = (filters: IFiltersArticles, onChange: (newFilters: IFiltersArticles) => void): JSX.Element => {
        return <>
            <Form.Group>
                <label>Title:</label>
                <Form.Control name="title" id={'title'} placeholder="Enter Title"
                    value={filters.title}
                    onChange={(e) => onChange({ ...filters, title: e.target.value })}
                />
            </Form.Group>
        </>
    }

    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: 'Title', key: 'title' },
            { title: 'Category', key: 'categoryId' },
            { title: 'Published', key: 'published' },
        ]

        var gridActions: GridAction[] = [
            { caption: 'Edit', variant: 'outline-warning', skipSpinner: true, generateLink: (row) => `/admin/create-article?articleId=${row.id}` },
            { caption: 'Details', variant: 'outline-info', onClick: (row) => this.setState({ showDetailsModal: true, rowSelectedId: row.id }), skipSpinner: true },
            { caption: 'Delete', variant: 'outline-danger', onClick: (row) => this.requestToRemoveRow(row.id) },
        ]

        return <>
            <NavLink to={'/admin/create-article'}><CustomButton variant="contained" className="mt-3 mb-4">Add</CustomButton></NavLink>
            <DynamicGrid<IFiltersArticles> ref={this.dynamicGrid}
                columns={gridColumns}
                onFetch={this.adminArticleService.getPaged}
                defaultFilters={{ published: this.state.published, title: this.state.title }}
                filtersForm={this.filtersView}
                refactorRow={(row) => {
                    return { ...row, published: this.departmentStatusDisplay(row.published), categoryId: row.categoryId && this.renderArticleCategory(row.categoryId) }
                }}
                actions={gridActions}
            />
            {this.state.showDetailsModal && <AdminArticleDetailsModal articleId={this.state.rowSelectedId} onClose={() => this.setState({ showDetailsModal: false })} />}
        </>
    }
}
