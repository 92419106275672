import React from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import InventoryProductOverviewService from "../../../../../services/inventory/InventoryProductOverviewService";
import CardView from "../../../../shared/Card/CardView";
import FormatMoney from "../../../../utilities/FormatMoney";
import { InventoryDeliveryDelayEnum } from "../../../Enums/InventoryDeliveryDelayEnum";
import InputAmountView from "../../InputGroupView/InputGroupView";
import IState, { IProps } from "./GeneralConfig.models";
import ValidAmount from "../../../../utilities/Amount/ValidAmount";

export default class GeneralConfig extends React.PureComponent<IProps, IState> {
    inventoryProductOverviewService: InventoryProductOverviewService
    constructor(props: any) {
        super(props)
        this.inventoryProductOverviewService = new InventoryProductOverviewService()
        this.state = {
            isLoading: false, customCount: null, updateConfig: {
                haveMinimumBasePrice: false, minimumBasePrice: 0, delivery: { enableParticipateOffers: false },
                minimumAlert: {},

            }
        }
    }

    componentDidMount() {
        if (this.props.deliveryConfig !== undefined)
            this.setState({
                updateConfig: {
                    delivery: this.props.deliveryConfig,
                    haveMinimumBasePrice: this.props.pricingConfig.minimumBasePrice !== null ? true : false,
                    minimumAlert: {
                        customCount: String(this.props.inventoryConfig.minimumAlertCustomCount),
                        isActive: this.props.inventoryConfig.isActiveMinimumAlert,
                        isAutoCalculatedCount: this.props.inventoryConfig.isActiveMinimumCalculated
                    },
                    minimumBasePrice: Number(this.props.pricingConfig.minimumBasePrice),
                }
            })
    }

    isAutoCalculatedCountOnChange = () => {
        this.setState(prevState => { return { updateConfig: { ...prevState.updateConfig, minimumAlert: { ...prevState.updateConfig.minimumAlert, isAutoCalculatedCount: !this.state.updateConfig.minimumAlert.isAutoCalculatedCount } } } }, () => {
            this.getCustomCount()
        })
    }

    getCustomCount = () => {
        if (!this.state.updateConfig.minimumAlert.isAutoCalculatedCount) {
            this.inventoryProductOverviewService.customCount(this.props.productId).then((response) => {
                this.setState({ customCount: response })
            })
        } else this.setState({ customCount: null })
    }

    render(): React.ReactNode {
        const { updateConfig } = this.state
        const { isActivePro } = this.props

        return <CardView card={{}}>
            <Row>
                <div className="font-bold">Offers</div>
                <span className="text-pale-grey-main font-medium pt-xs">
                    Do you want to participate in the supply of offers in addition to selling your inventory if this product is not available (if you are not in stock, you will still be visible to buyers)?
                </span>
                <div className="pl-md pt-sm">
                    <Form.Check name="deliveryType" inline checked={updateConfig.delivery.enableParticipateOffers}
                        onChange={(e) => this.setState(prevState => { return { updateConfig: { ...prevState.updateConfig, delivery: { ...prevState.updateConfig.delivery, enableParticipateOffers: true } } } })}
                        type="radio" value={"Yes"} label="Yes" />

                    <Form.Check name="deliveryType" inline checked={!updateConfig.delivery.enableParticipateOffers}
                        onChange={(e) => this.setState(prevState => { return { updateConfig: { ...prevState.updateConfig, delivery: { ...prevState.updateConfig.delivery, enableParticipateOffers: false } } } })}
                        type="radio" value={"No"} label="No" />
                </div>
            </Row>
            {
                this.state.updateConfig.delivery.enableParticipateOffers &&
                <div className="font-medium text-pale-grey-main pt-md pb-md">
                    You hereby declare that you can make this product available by the maximum time specified.
                </div>
            }
            {
                updateConfig.delivery.enableParticipateOffers &&
                <Row className="pb-md">
                    <Col md="3" sm="12">
                        <Form.Group>
                            <label className="form-label">From:</label>
                            <Form.Control type="text" value={updateConfig.delivery.delayFromNumber}
                                onChange={(e) => this.setState(prevState => { return { updateConfig: { ...prevState.updateConfig, delivery: { ...prevState.updateConfig.delivery, delayFromNumber: Number(e.target.value) } } } })} />
                        </Form.Group>
                    </Col>
                    <Col md="3" sm="12" className="d-flex-inline">
                        <Form.Group>
                            <label className="form-label">To: </label>
                            <Form.Control type="text" value={updateConfig.delivery.delayToNumber}
                                onChange={(e) => this.setState(prevState => { return { updateConfig: { ...prevState.updateConfig, delivery: { ...prevState.updateConfig.delivery, delayToNumber: Number(e.target.value) } } } })} />
                        </Form.Group>
                    </Col>
                    <Col md="3" sm="12">
                        <div style={{ paddingTop: '40px' }}>
                            {Object.keys(InventoryDeliveryDelayEnum).map(key => typeof InventoryDeliveryDelayEnum[key as any] !== 'number' && (
                                <Form.Check inline name={'delay-' + key} onChange={(e) => this.setState(prevState => { return { updateConfig: { ...prevState.updateConfig, delivery: { ...prevState.updateConfig.delivery, delayType: Number(e.target.value) } } } })}
                                    id={'delay-' + key} type="radio" value={key} label={InventoryDeliveryDelayEnum[key as any]} checked={updateConfig.delivery.delayType === Number(key)} />
                            ))}                        </div>
                    </Col>
                </Row>
            }
            <div className="pt-lg">
                {!isActivePro ? this.renderBasicView('Price') : this.renderProView()}
            </div>
        </CardView>
    }

    renderBasicView(title: string) {
        const { pricingConfig } = this.props
        return <>
            <div className="font-bold">Price</div>
            <Row Row className="pb-xs pt-md" >
                <Col md="3" sm="12">
                    <Form.Group>
                        <Form.Label>{title} : </Form.Label>
                        <InputAmountView>
                            <Form.Control type="text" inputMode="decimal" step="0.01" value={pricingConfig.baseCustomPrice} onChange={(e) => ValidAmount(e.target.value) && this.props.updatePrice(e.target.value)} />
                        </InputAmountView>
                    </Form.Group>
                </Col>
            </Row >
        </>
    }

    renderProView() {
        const { updateConfig, customCount } = this.state

        return <>
            <Row className="mb-5 mt-5">
                <h5 className="text-muted">Inventory Setting</h5><hr />
                <Col md="6" sm="12">
                    <Form.Check id="minimumAlert.isActive" checked={updateConfig.minimumAlert.isActive} onChange={() => this.setState(prevState => { return { updateConfig: { ...prevState.updateConfig, minimumAlert: { ...prevState.updateConfig.minimumAlert, isActive: !updateConfig.minimumAlert.isActive } } } })}
                        type="checkbox" label="When inventory reaches below the set limit, send notification via email and SMS for us." />
                </Col>
                <Col md="6"></Col>
                <Col md="6" sm="12">
                    <Form.Check id="minimumAlert.isAutoCalculatedCount" onChange={() => this.isAutoCalculatedCountOnChange()} checked={updateConfig.minimumAlert.isAutoCalculatedCount}
                        type="checkbox" label="The minimum inventory count is calculated automatically" />
                </Col>
                <Col md="3" sm="12">
                    {!updateConfig.minimumAlert.isAutoCalculatedCount ?
                        <Form.Group>
                            <Form.Label>
                                Custom minimum inventory count set to custom number
                            </Form.Label>
                            <Form.Control value={updateConfig.minimumAlert.customCount} type="text" inputMode="decimal" step="0.01"
                                onChange={(e) => ValidAmount(e.target.value) && this.setState(prevState => { return { updateConfig: { ...prevState.updateConfig, minimumAlert: { ...prevState.updateConfig.minimumAlert, customCount: e.target.value } } } })} />
                        </Form.Group> :
                        <span>Your minimum inventory count: {customCount}</span>
                    }
                </Col>
            </Row>
            <Row className="mb-5">
                <h5 className="text-muted">Base Pricing</h5><hr />
                <Col md="6" sm="12">
                    <div className="d-flex">
                        <b style={{ paddingRight: '10px' }}>Base Price:</b>
                        <Form.Check type="checkbox" id="isAutoBasePrice" label="Auto Base Price(Auto)" checked={this.props.pricingConfig.isAutoBasePrice}
                            onChange={() => this.props.updateIsAutoBasePrice()}
                        />
                        {this.props.pricingConfig.isAutoBasePrice && <span style={{ paddingLeft: '10px' }} className="text-muted">Now: {FormatMoney(this.props.inventoryConfig.averagePrice as number)}</span>}
                    </div>
                </Col>
                <Col md="6"></Col>
                {!this.props.pricingConfig.isAutoBasePrice ?
                    this.renderBasicView('Custom Price') :
                    <Col md="3" sm="12">

                        <Form.Check type="checkbox" id="haveMinimumBasePrice" label="Add Minimum Price" checked={updateConfig.haveMinimumBasePrice}
                            onChange={(e) => this.setState(prevState => { return { updateConfig: { ...prevState.updateConfig, haveMinimumBasePrice: !updateConfig.haveMinimumBasePrice } } })} />
                        {updateConfig.haveMinimumBasePrice &&
                            <Form.Group>
                                <Form.Label>Minimum base price:</Form.Label>
                                <InputAmountView maxHeight="36px">
                                    <Form.Control value={this.props.pricingConfig.minimumBasePrice} type="text" inputMode="decimal" step="0.01"
                                        onChange={(e) => ValidAmount(e.target.value) && this.props.updateMinimumBasePrice(e.target.value)} />
                                </InputAmountView>
                            </Form.Group>
                        }
                        <span>Price: {FormatMoney(this.props.basePrice)}</span>
                    </Col>
                }
            </Row>
        </>
    }
}