import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import FormatMoney from "../../../../../utilities/FormatMoney";
import IState, { IProps } from "./StaticPlan.models";
import ValidAmount from "../../../../../utilities/Amount/ValidAmount";

export default class StaticPlan extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = { plans: [] }
    }

    render(): React.ReactNode {
        const { sharedPercent, finalPrice, countFrom, countTo, profitPercent, price } = this.props

        return <>
            {countFrom !== undefined &&
                <Row className="mt-2">
                    <Col md="3" sm="12" className="d-flex align-items-center"><span className="text-pale-grey-main font-sm">Count from:</span></Col>
                    <Col md="6" sm="12">
                        <Form.Control type="number" value={countFrom} disabled onChange={(e) => this.props.changeCountFrom && this.props.changeCountFrom(parseInt(e.target.value))} />
                    </Col>
                </Row>
            }
            {
                countTo !== undefined && <Row className="mt-2">
                    <Col md="3" sm="12" className="d-flex align-items-center"><span className="text-pale-grey-main font-sm">Count to:</span></Col>
                    <Col md="6" sm="12">
                        <Form.Control type="number" value={countTo} onChange={(e) => this.props.changeCountTo && this.props.changeCountTo(parseInt(e.target.value))} />
                    </Col>
                </Row>
            }
            {
                profitPercent !== undefined && <Row className="mt-2">
                    <Col md="3" sm="12" className="d-flex align-items-center"><span className="text-pale-grey-main font-sm">Profit Percent:</span></Col>
                    <Col md="6" sm="12">
                        <Form.Control type="text" inputMode="decimal" step="any" pattern="[0-9]*[.,]?[0-9]*" value={profitPercent} onChange={(e) => ValidAmount(e.target.value) && this.props.changeProfitPercent && this.props.changeProfitPercent(e.target.value)} />
                    </Col>
                </Row>
            }
            {
                price !== undefined &&
                <Row className="mt-2">
                    <Col md="3" sm="12" className="d-flex align-items-center"><span className="text-pale-grey-main font-sm">Price:</span></Col>
                    <Col md="6" sm="12"><span className="font-bold">{FormatMoney(price)}</span></Col>
                </Row>
            }
            <Row className="mt-2">
                <Col md="3" sm="12" className="d-flex align-items-center"><span className="text-pale-grey-main font-sm">Shared Percent:</span></Col>
                <Col md="6" sm="12"><span className="font-bold">{sharedPercent} %</span></Col>
            </Row>
            <Row className="mt-2">
                <Col md="3" sm="12" className="d-flex align-items-center">
                    <span className="text-pale-grey-main font-sm">Final Price:</span>
                </Col>
                <Col md="6" sm="12">
                    <span className="text-success"><span className="font-bold">{finalPrice && FormatMoney(finalPrice)}</span></span>
                </Col>
            </Row>
        </>
    }
}