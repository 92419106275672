import React, { RefObject, FormEvent } from "react"
import { Button, Col, Form, Row, Spinner } from "react-bootstrap"
import IState, { IProps } from "./AdminProductCategoryManage.models"
import Uploader from "../../../shared/Uploader/Uploader"
import AdminProductCategoriesService from "../../../../services/admin/categories/AdminCategoriesService"
import { NotificationManager } from 'react-notifications'
import { CustomButton } from "../../../shared/button/ButtonComponent"
import SpinnerComponent from "../../../shared/spinner/SpinnerComponent"
import { CircularProgress } from "@mui/material"

export default class AdminProductCategoryManage extends React.Component<IProps, IState> {
    uploader: RefObject<Uploader>
    adminProductCategoriesService: AdminProductCategoriesService

    constructor(props: any) {
        super(props)
        this.state = { loading: false, pendingRequest: false, category: { title: '', orderId: 0, imageName: '', imageSrc: '', isActive: true, parentId: null }, productCategoryTitleValue: [] }

        this.adminProductCategoriesService = new AdminProductCategoriesService()
        this.uploader = React.createRef()
    }

    componentDidMount(): void {
        this.getCategory()
    }

    getCategory = () => {
        this.adminProductCategoriesService.getCategory()
            .then(res => this.setState({ productCategoryTitleValue: res }, () => this.getDetails()))
    }

    getDetails = () => {
        if (!this.props.categoryId) return
        this.setState({ loading: true })

        this.adminProductCategoriesService.getById(this.props.categoryId).then(response => {
            if (response) this.setState({ category: response })
        }).finally(() => this.setState({ loading: false }))
    }

    submit = () => {
        let { categoryId } = this.props;
        let { category } = this.state;

        this.setState({ pendingRequest: true })

        let body = {
            title: category.title,
            parentId: category.parentId,
            orderId: category.orderId,
            isActive: category.isActive,
            imageName: category.imageName,
        }

        if (categoryId) this.adminProductCategoriesService.update(categoryId, body).finally(() => {
            this.setState({ pendingRequest: false });
            NotificationManager.success("Product category updated successfully")
        })
        else this.adminProductCategoriesService.add(body).finally(() => {
            this.setState({ pendingRequest: false });
            NotificationManager.success("Product category created successfully")
        })
    }

    render(): React.ReactNode {
        let { loading, pendingRequest, category } = this.state

        return <>
            {
                !loading ?
                    <>
                        <Form className="mt-5">
                            <Row>
                                <Col md="4" sm="12">
                                    <div className="font-bold font-md">{this.props.categoryId ? 'Edit Product Category' : 'Add Product Category'}</div>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col md="3" sm="12">
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Title:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={category.title}
                                            onChange={(e) => this.setState({ category: { ...category, title: e.target.value } })}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md="3" sm="12">
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                        <Form.Label>Parent:</Form.Label>
                                        <Form.Select aria-label="Select Parent" id="parentId" value={category.parentId}
                                            onChange={(e) => this.setState({ category: { ...category, parentId: e.target.value } })}>
                                            <option value={''}>Select Parent</option>
                                            {
                                                this.state.productCategoryTitleValue.map((productCategory) => <option value={productCategory.value} key={productCategory.value}>{productCategory.title}</option>)
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md="3" sm="12">
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                        <Form.Label>Order:</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={category.orderId}
                                            onChange={(e) => this.setState({ category: { ...category, orderId: Number(e.target.value) } })}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-2 mb-3">
                                <Col md="3" sm="12">
                                    <Uploader ref={this.uploader} folderName='products' onUpload={(path) => this.setState({ category: { ...category, imageName: path } })} imageAddress={category.imageSrc} />
                                </Col>
                                <Col md="3" sm="12">
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                        <Form.Check name="active" id={'v'}
                                            checked={category.isActive}
                                            onChange={(e) => this.setState({ category: { ...category, isActive: !category.isActive } })}
                                            type="checkbox" label={'Active'}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {pendingRequest ? <CustomButton variant='contained' disabled><CircularProgress size={20} color='inherit' /></CustomButton> :
                                <CustomButton variant="contained" onClick={this.submit}>Save</CustomButton>}
                        </Form>
                    </>
                    : <SpinnerComponent />
            }
        </>
    }
}