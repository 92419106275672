import axios, { AxiosResponse } from "axios"
import { mapToDataOnly } from "../common/HttpUtility"
import { API_URL } from "../../constants/Constants";

interface IUploaderDto {
    folderName: string;
    contentType: string;
    fullPath: string;
}

export default class CommonService {
    upload(file: FormData, folderName: string): Promise<IUploaderDto> {
        return mapToDataOnly<IUploaderDto>(axios.post(`${API_URL}/FileManagement/${folderName}`, file, {
            headers: {
                'content-type': 'multipart/form-data',
            }
        }));
    }
    
    export(service: any, fileName: string): Promise<void | AxiosResponse<any, any>> {
        return service.then(({ data }) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
    }
}