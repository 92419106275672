import { Route } from "react-router-dom";
import CreateShopOnly from "../../components/shops/CreateShopOnly/CreateShopOnly";
import CreateShopRoute from "../shops/CreateShopRoute";
import MyShopsRoute from "../shops/MyShopsRoute";
import ManagementRoute from "../shops/ManagementRoute";
import MainLayout from "../../layouts/main-layout/MainLayout";

export function ShopsRoutes() {
    return (
        <>
            <Route element={<MainLayout showLogo/>}>
                <Route path="/management" element={<ManagementRoute />} />
                <Route path="/create-shop" element={<CreateShopRoute />} />
                <Route path="/new-shops" element={<MyShopsRoute />} />
            </Route>
            <Route element={<MainLayout showLogo/>}>
                <Route path="/create-shop-only" element={<CreateShopOnly />} />
            </Route>
        </>
    )
}