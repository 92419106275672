import React, { RefObject } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { NotificationManager } from 'react-notifications';
import AdminArticleCategoryService from "../../../../../services/admin/articles/AdminArticleCategoryService";
import Uploader from "../../../../shared/Uploader/Uploader";
import { IProps, IState } from "./AdminCreateArticleCategory.models";
import { CustomButton } from "../../../../shared/button/ButtonComponent";
import SpinnerComponent from "../../../../shared/spinner/SpinnerComponent";

export default class AdminCreateArticleCategory extends React.Component<IProps, IState> {
    uploader: RefObject<Uploader>
    adminArticleCategoryService: AdminArticleCategoryService
    constructor(props: any) {
        super(props);
        this.state = { loading: false, pendingRequest: false, categoryTitleValues: [], articleCategory: { imageName: '', isActive: true, title: '', orderId: 0, parentId: null, imageSrc: '' } }
        this.adminArticleCategoryService = new AdminArticleCategoryService()
    }

    componentDidMount(): void {
        this.getCategory()
    }

    getCategory = () => {
        this.adminArticleCategoryService.getTitleValues().then(response => {
            this.setState({ categoryTitleValues: response, articleCategory: { ...this.state.articleCategory } })
        }).finally(() => {
            if (this.props.articleCategoryId !== null) this.getDetails()
        })
    }

    getDetails = () => {
        this.setState({ loading: true })
        this.adminArticleCategoryService.getById(this.props.articleCategoryId).then((response) => {
            this.setState({ articleCategory: response })
        }).finally(() => this.setState({ loading: false }))
    }

    requestToEdit = (body: any) => {
        this.adminArticleCategoryService.update(this.props.articleCategoryId, body).then(() => {
            NotificationManager.success("Article category updated successfully")
        }).finally(() => this.setState({ pendingRequest: false }))
    }

    requestToCreate = (body: any) => {
        this.adminArticleCategoryService.add(body).then(() => {
            NotificationManager.success("The article category added successfully")
        }).finally(() => this.setState({ pendingRequest: false }))
    }

    submit = () => {
        this.setState({ pendingRequest: true })

        let { articleCategory } = this.state

        let body = {
            title: articleCategory.title,
            parentId: articleCategory.parentId,
            imageName: articleCategory.imageName,
            isActive: articleCategory.isActive,
            orderId: articleCategory.orderId
        }

        if (this.props.articleCategoryId)
            this.requestToEdit(body)
        else this.requestToCreate(body)
    }

    render(): React.ReactNode {
        let { pendingRequest, articleCategory, loading, categoryTitleValues } = this.state
        let { articleCategoryId } = this.props

        return <div>
            {
                !loading ?
                    <>
                        <Form className="mt-3">
                            <Row className="mb-4">
                                <Col md="4" sm="12">
                                    <div className="font-bold font-md">{articleCategoryId ? 'Edit Article Category' : 'Add Article Category'}</div>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col className="mb-3" md="4" sm="12">
                                    <Form.Label>Title:</Form.Label>
                                    <Form.Control placeholder="Enter Title"
                                        value={articleCategory.title}
                                        onChange={e => this.setState({ articleCategory: { ...articleCategory, title: e.target.value } })} />
                                </Col>
                                <Col className="mb-3" md="3" sm="12">
                                    <Form.Group controlId="exampleForm.ControlInput5">
                                        <Form.Label>Parent:</Form.Label>
                                        <Form.Select aria-label="Select source currency" id="currencyId"
                                            value={articleCategory.parentId}
                                            onChange={e => this.setState({ articleCategory: { ...articleCategory, parentId: e.target.value } })}>
                                            <option value={''} selected>Select Parent</option>
                                            {categoryTitleValues.map((item) => <option value={item.value} key={item.value}>{item.title}</option>)}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md="3" sm="12">
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                        <Form.Label>Order:</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={articleCategory.orderId}
                                            onChange={(e) => this.setState({ articleCategory: { ...articleCategory, orderId: Number(e.target.value) } })}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col md="4" sm="12">
                                    {(!this.props.articleCategoryId || !this.state.loading) &&
                                        <Uploader ref={this.uploader} folderName='products'
                                            onUpload={(path) => this.setState({ articleCategory: { ...articleCategory, imageName: path } })}
                                            imageAddress={articleCategory.imageSrc} />
                                    }
                                </Col>
                                <Col className="mb-3" md="3" sm="12">
                                    <Form.Group controlId="exampleForm.ControlInput4">
                                        <Form.Check name="active" id={'v'} className="mt-4"
                                            checked={articleCategory.isActive}
                                            onChange={(e) => this.setState({ articleCategory: { ...articleCategory, isActive: !articleCategory.isActive } })}
                                            type="checkbox" label={'Active'}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {pendingRequest ? <CustomButton variant='contained' disabled><Spinner size="sm" animation='border' /></CustomButton> :
                                <CustomButton variant='contained' onClick={this.submit}>Save</CustomButton>}
                        </Form>
                    </>
                    : <SpinnerComponent />
            }
        </div>
    }
}