import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const CustomButton = styled(Button)({
    textTransform: 'none',
    fontSize: 14,
    padding: '10px 14px',
    boxsShadow: '0px 2px 4px 0px #5A5A5A33',
    borderRadius: '8px',
    lineHeight: 1.5,
    fontFamily: [
        'SFPro-Regular'
    ].join(','),
    '@media (max-width: 900px)': {
        fontSize: 13,
        padding: '10px',
    },
});
