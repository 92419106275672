import React, { RefObject } from "react";
import './BuyOffers.scss'
import OffersService, { OfferRowDto } from "../../../../services/orders/OffersService";
import { NotificationManager } from 'react-notifications';
import inventoryShopBasePlanStatusDisplayBadge from "../../../inventory/Enums/InventoryShopBasePlanStatusEnum";
import DynamicGrid from "../../../shared/DynamicGrid/DynamicGrid";
import { GridAction, GridColumn } from "../../../Grid/Grid.models";

interface IProps {
    onClickSell: (productId: string) => void, categoryId: string
}

export default class BuyOffers extends React.Component<IProps, { offers: OfferRowDto[] }> {
    dynamicGrid: RefObject<DynamicGrid<any>>;
    offersService: OffersService;
    constructor(props: any) {
        super(props);
        this.dynamicGrid = React.createRef()
        this.offersService = new OffersService();
        this.state = { offers: [] }
    }

    componentDidMount(): void {
    }



    componentDidUpdate(prevProps: IProps) {
        if ((prevProps.categoryId !== this.props.categoryId)) {
            this.dynamicGrid.current.applyFilters(() => {
                this.dynamicGrid.current.fetch();
            });
        }
    }
    cancelOffer = (id: string) => {
        if (window.confirm('Are you sure your offer will be cancelled?'))
            this.offersService.cancel(id).then((response) => {
                NotificationManager.success('Your offer canceled')
            });
    }
    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: 'Product', key: 'productTitle', tooltip: 'Product Title' },
            { title: 'Your Available', key: 'availableInventory', tooltip: 'You have some available inventory, ready to sell on this offer!' },
            { title: 'Quantity', key: 'quantity', tooltip: 'Offer Quantity' },
            { title: 'Acceptable', key: 'acceptable', tooltip: 'Your Price is less than offer price (Acceptable count)' },
            { title: 'In-Comming Count', key: 'inCommingCount', tooltip: 'Your have some in comming order from your sources' },
        ];

        var gridActions: GridAction[] = [{ caption: 'Sell', variant: 'danger', onClick: (row) => this.props.onClickSell(row.productId), skipSpinner: true }]

        return <div className="BuyOffers">

            <div className="font-bold font-lg">Buy Offers</div>
            <span className="text-charcoal">This is a list of offers ready for buyers, you can sell to them!</span>

            <DynamicGrid<{}>
                skipUpdateFilters
                hideFilterBar
                ref={this.dynamicGrid}
                columns={gridColumns}
                actions={gridActions}
                defaultPagesize={20}
                onFetch={this.offersService.getBuyOffersPagination}
                defaultFilters={{ categoryId: this.props.categoryId }}
                refactorRow={(row) => {
                    return {
                        ...row,
                        productTitle: <>{inventoryShopBasePlanStatusDisplayBadge(row.orderingPlanStatus)} {row.productTitle}</>,
                        acceptable: row.quantity - row.outOfPriceRangeCount - row.sellerBlockCount - row.buyerBlockCount
                    }
                }} />
        </div>
    }
}