import React from "react";
import { Outlet } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import IdentityService from "../../services/identity/IdentityService";
import ShopService from "../../services/shops/ShopService";
import AuthenticationContext from "../../contexts/AuthenticationContext/AuthenticationContext";
import { ADMIN, CONTENT_WRITER, MEDIATOR, SUPERADMIN, SUPPORT } from "../../constants/ConstantsPermissions";
import FullScreenLoading from "../../components/shared/full-screen-loading/FullScreenLoading";
import LayoutContext from "../../contexts/LayoutContext/LayoutContext";
import AdminHeader from "./header/AdminHeader";
import { Subject } from "rxjs";
import DeviceResponsiveHelper from "../../components/utilities/responsive-helper/DeviceResponsiveHelper";
import BottomNavigationComponent from "../main-layout/mobile/bottom-navigation/BottomNavigation";
import { Box, Container } from "@mui/material";

export default class AdminLayout extends React.Component<{ hideSidebar?: boolean, hideMenu?: boolean, showBottomNavigation?: boolean }, {
    SidebarClosed: boolean, isMobile: boolean, progressPercent: number
}> {
    identityService: IdentityService;
    shopService: ShopService
    static contextType = AuthenticationContext;
    context!: React.ContextType<typeof AuthenticationContext>;

    constructor(props: any) {
        super(props);
        this.identityService = new IdentityService();
        this.shopService = new ShopService();
        this.state = { SidebarClosed: false, isMobile: false, progressPercent: 30 };
    }

    componentDidMount() {
        this.identityService.getUserInformation().then((response) => {
            this.context.twoFactorAvtive = response.data.hasTwoFactor
            this.context.privileges = response.data.privileges
            this.context.roles = response.data.roles
            this.context.user = { displayName: response.data.displayName, userName: response.data.userName }
            this.setState({ progressPercent: 50 });
        })
            .catch(() => {
                this.setState({ progressPercent: 100 });
            })
            .finally(() => {
                if (this.context.roles.map(item => [ADMIN, SUPERADMIN, MEDIATOR, CONTENT_WRITER, SUPPORT].includes(item)).length == 0)
                    window.location.pathname = '/dashboard'
                this.forceUpdate()

                this.setState({ progressPercent: 100 })
            })


        let isMobile = window.innerWidth > 960 ? false : true
        this.setState({ SidebarClosed: isMobile, isMobile })
    }


    render(): React.ReactNode {
        if (!this.context.isAuthentication())
            return <Navigate replace to="/sign-in" />;

        if (this.state.progressPercent < 100)
            return <FullScreenLoading progressPercent={this.state.progressPercent} />

        let hideMenu = this.props.hideMenu ?? false;

        return (<LayoutContext.Provider value={{ onAddToCartSubject: new Subject(), loadCart: () => { }, onReviewRequestChangedStatus: () => { }, reloadSideBar: () => { } }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                <AdminHeader onToggleSidebar={() => this.setState({ SidebarClosed: !this.state.SidebarClosed })} hideMenu={hideMenu} isMobile={this.state.isMobile} />
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        pb: '15vh'
                    }}
                >
                    <Container className="pt-xl">
                        <Outlet></Outlet>
                    </Container>
                </Box>
                {(this.props.showBottomNavigation) && <DeviceResponsiveHelper mobile={<BottomNavigationComponent />} desktop={<></>} />}
            </Box>
        </LayoutContext.Provider>);
    }
}