import React, { RefObject } from "react"
import AdminWalletsServices, { IFiltersGetWallets } from "../../../../services/admin/financials/AdminWalletsServices"
import DynamicGrid from "../../../shared/DynamicGrid/DynamicGrid"
import IState, { GetWallteQueryString } from "./AdminWalletsList.models"
import { GridAction, GridColumn, GridColumnTypes } from "../../../Grid/Grid.models"
import AdminWalletTransferFund from "./Transfer/AdminWalletTransferFund"
import { Col, Form, Row } from "react-bootstrap"
import AddCreditModal from "../AdminCredits/Add/AddCredit"
import LockWalletModal from "./LockWallet/LockWalletModal"
import { LockFill } from "react-bootstrap-icons"

export default class AdminWalletsList extends React.Component<{ onlyNegativeWallet?: boolean, ownerId?: string }, IState> {
  dynamicGrid: RefObject<DynamicGrid<GetWallteQueryString>>
  walletsServices: AdminWalletsServices

  constructor(props: any) {
    super(props)
    this.dynamicGrid = React.createRef()
    this.state = { transferWalletId: undefined, holdMoney: false }
    this.walletsServices = new AdminWalletsServices()
  }

  render(): React.ReactNode {
    let gridColumns: GridColumn[] = [
      { title: "Owner", key: "ownerTitle" },
      { title: "Currency", key: "currencyTitle" },
      { title: "Balance", key: "balance", type: GridColumnTypes.Amount },
      { title: "Pending", key: "pending", type: GridColumnTypes.Amount },
      { title: "Credit", key: "credit" },
      { title: "Is Lock", key: "isLock" },
    ]

    let gridActions: GridAction[] = [
      {
        caption: "Charge",
        variant: "outline-success",
        generateLink: (row) => `/admin/walletCharge/?id=${row.id}`
      },
      {
        caption: "Transfer",
        variant: "outline-primary",
        onClick: (row) => {
          this.dynamicGrid.current.resetSpinner()
          this.setState({ transferWalletId: row.id })
        }
      },
      {
        caption: "Transactions",
        variant: "outline-info",
        generateLink: (row) => `/admin/walletTransactions/?id=${row.id}`
      },
      { caption: 'Hold Money', variant: 'outline-danger', onClick: (row) => this.setState({ creditWalletId: row.id, holdMoney: true }), skipSpinner: true },
      { caption: 'Credit', variant: 'outline-success', onClick: (row) => this.setState({ creditWalletId: row.id, holdMoney: false }), skipSpinner: true },
      {
        caption: "Handle Lock", variant: "outline-info", skipSpinner: true, onClick: (row) => { this.setState({ rowWallet: row }) }
      },
    ]

    return (
      <>
        <DynamicGrid<IFiltersGetWallets>
          ref={this.dynamicGrid}
          columns={gridColumns}
          actions={gridActions}
          filtersForm={this.filtersView}
          onFetch={this.walletsServices.getPaged}
          size="small"
          defaultFilters={{ onlyNegativeWallet: this.props.onlyNegativeWallet, ownerId: this.props.ownerId}}
          refactorRow={(row) => {
            return { ...row, isLock: row.isLock && <LockFill color="red" /> }
          }}
        />
        {this.state.rowWallet && <LockWalletModal isLock={this.state.rowWallet.isLock} walletId={this.state.rowWallet.id} reload={this.reload} onClose={() => this.setState({ rowWallet: undefined })} />}
        {this.state.transferWalletId !== undefined &&
          <AdminWalletTransferFund walletId={this.state.transferWalletId} onClose={() => this.setState({ transferWalletId: undefined })} />}
        {this.state.creditWalletId && <AddCreditModal walletId={this.state.creditWalletId} reload={this.dynamicGrid.current.fetch} isNegativeCredit={this.state.holdMoney} onClose={() => this.setState({ creditWalletId: undefined })} />}
      </>
    )
  }

  reload = () => this.dynamicGrid.current.fetch()

  filtersView = (filters: IFiltersGetWallets, onChange: (newFilters: IFiltersGetWallets) => void): JSX.Element => {
    return <>
      <Row className="mt-2">
        <Col md="12" sm="12">
          <Form.Check name="onlyNegativeWallet" id={'onlyNegativeWallet'}
            checked={filters.onlyNegativeWallet}
            onChange={(e) => onChange({ ...filters, onlyNegativeWallet: e.target.checked })}
            type="checkbox" label={'Only Negative Wallet'}
          />
        </Col>
      </Row>
    </>
  }
}