import React from "react";
import { IOrdersProps } from "./Orders.models";
import ShopPolicies from "../../shared/policy/ShopPolicies/ShopPolicies";
import { SELLER } from "../../../constants/ConstantsPolicies";
import RouterTabs from "../../shared/RouterTabs/RouterTabs";
import Reports from "../../helpCenter/Reports/Reports";
import SellOrders from "./sell-orders/SellOrders";
import OrderItemsReport from "../OrdersItems/OrderItemsReport";
import OrderReport from "./Report/OrderReport";
import OwnedArchiveOffers from "../offers/OwnedArchiveOffers/OwnedArchiveOffers";
import SupportTickets from "../../helpCenter/SupportTickets/SupportTickets";
import SellOrderItems from "./sell-orders/SellOrderItems/SellOrderItems";
import { Container } from "@mui/material";

export default class Orders extends React.PureComponent<IOrdersProps, { navigateTo?: string, navigateToTab?: string }> {
    constructor(props: IOrdersProps) {
        super(props);
        this.state = {};
    }

    sellTabView(tab: string) {
        const menu = <RouterTabs items={[{ title: 'Orders', route: '/orders/sell', isActive: !this.props.tab },
        { title: 'Items', route: '/orders/sell/?tab=items', isActive: this.props.tab === "items" },
        { title: 'Review Request', route: '/orders/sell/?tab=review-request', isActive: this.props.tab === "review-request" }]} />;
        var content = <SellOrders />

        switch (tab) {
            case 'items':
                content = <SellOrderItems />
                break;
            case 'review-request':
                content = <Reports />
                break;
        }

        return <>
            {menu}
            {content}
        </>
    }

    buyTabView(tab: string) {
        const menu = <RouterTabs items={[{ title: 'Orders', route: '/orders/buy', isActive: !this.props.tab },
        { title: 'Items', route: '/orders/buy/?tab=items', isActive: this.props.tab === "items" },
        { title: 'Offers', route: '/orders/buy/?tab=offers', isActive: this.props.tab === "offers" },
        { title: 'Review Request', route: '/orders/buy/?tab=reviewRequest', isActive: this.props.tab === "reviewRequest" }]} />
        var content = <OrderReport />
        switch (tab) {
            case 'items':
                content = <OrderItemsReport />
                break;
            case 'offers':
                content = <OwnedArchiveOffers />
                break;
            case 'reviewRequest':
                content = <SupportTickets globalTicketOnly={false} />
                break;
        }

        return <>
            {menu}
            {content}
        </>
    }

    render(): React.ReactNode {


        return (<Container className="pt-xl">
            <ShopPolicies policy={SELLER} access={false}>
                {this.buyTabView(this.props.tab)}
            </ShopPolicies>

            <ShopPolicies policy={SELLER}>
                <RouterTabs items={[{ title: 'Buy', route: '/orders/buy', isActive: this.props.mode === "buy" },
                { title: 'Sell', route: '/orders/sell', isActive: this.props.mode === "sell" }]} />
                {
                    this.props.mode === "sell" ? this.sellTabView(this.props.tab) : this.buyTabView(this.props.tab)
                }
            </ShopPolicies>
        </Container>)
    }
}