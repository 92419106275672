import React from "react"
import SalesStatistics from "../orders/statistics/SalesStatisticsChart/SalesStatisticsChart"
import SalesStatisticsReport from "../orders/statistics/SalesStatisticsReport/SalesStatisticsReport"
import { Container, Grid } from "@mui/material"

export default class AdminDashboard extends React.Component<{}, {}> {
    render(): React.ReactNode {
        return (<Container className="pt-xl">
            <div className="mt-4">
                <SalesStatisticsReport />
                <Grid container className="mt-4 mb-4">
                    <Grid item className="mb-5" md={12} sm={12} xs={12}>
                        <SalesStatistics title="Total Sales" />
                    </Grid>
                    <Grid item className="mb-5" md={12} sm={12} xs={12}>
                        <SalesStatistics title="Sales Profit" onlyShared colors={["#febc3b"]} />
                    </Grid>
                </Grid>
            </div>
        </Container>)
    }
}