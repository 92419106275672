import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Navigate, NavLink } from "react-router-dom";
import Basic from "../Basic/Basic";
import CashOuts from "../cashOutes/CashOutsList/CashOutsList";
import FinancialAccountsList from "../financialManagement/FinancialAccounts/FinancialAccountsList";
import Deposit from "../wallets/Deposit/Deposit";
import ExchangeList from "../wallets/Exchange/ExchangeList";
import WalletList from "../wallets/WalletList/WalletList";
import WalletTransactionReport from "../wallets/WalletTransactionReport/WalletTransactionReport";
import { WalletTransactionStatusEnum } from "../Enums/WalletTransactionStatusEnum";
import { Container } from "@mui/material";

export default class Financials extends React.Component<{ activeTab: string | null, walletId?: string, walletStatus?: WalletTransactionStatusEnum | null }, { navigateToTab?: string }> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }
    componentDidUpdate(prevProps: Readonly<{ activeTab: string | null; walletId?: string; }>, prevState: Readonly<{ navigateToTab?: string; }>, snapshot?: any): void {
        if (prevProps.activeTab !== this.props.activeTab)
            this.setState({ navigateToTab: undefined })
    }
    handleSelect = (key) => {
        this.setState({ navigateToTab: key });
    }
    render(): React.ReactNode {

        return <Container className="pt-xl">
            {this.state.navigateToTab && this.state.navigateToTab !== this.props.activeTab ? <Navigate to={"/financials/?activeTab=" + this.state.navigateToTab} /> : null}
            <Tabs activeKey={this.props.activeTab ?? "wallets"} onSelect={this.handleSelect} id="financials-tabs" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                <Tab eventKey="wallets" title="Wallets">
                    <WalletList />
                </Tab>

                <Tab eventKey="deposit" title="Deposit">
                    <Deposit />
                </Tab>

                <Tab eventKey="exchange" title="Exchange History">
                    <ExchangeList />
                </Tab>
                <Tab eventKey="withdraw" title="Withdraw">
                    <CashOuts />
                </Tab>

                <Tab eventKey="externalWallets" title="External Wallets">
                    <FinancialAccountsList />
                </Tab>

                <Tab eventKey="walletTransaction" title="Transactions History">
                    <WalletTransactionReport status={this.props.walletStatus} walletId={this.props.walletId} />
                </Tab>
                <Tab eventKey="basic" title="Basic">
                    <Basic />
                </Tab>
            </Tabs>
        </Container>
    }
}
