import { Route } from "react-router-dom";
import SecurityRoute from "../identity/SecurityRoute";
import UserPrivilegeRoute from "../identity/UserPrivilegeRoute";
import UserProfileRoute from "../identity/UserProfileRoute";
import MainLayout from "../../layouts/main-layout/MainLayout";

export function IdentityRoutes() {
    return (
        <>
            <Route element={<MainLayout showLogo/>}>
                <Route path="/user-profile" element={<UserProfileRoute />}/>
                <Route path="/security" element={<SecurityRoute />}/>
                <Route path="/user-privilege" element={<UserPrivilegeRoute />}/>
            </Route>
        </>
    )
}