import React from 'react';
import { Spinner, Row, Col, Form } from 'react-bootstrap'
import InventoryService from '../../../services/inventory/InventoryService';
import Amount from '../../utilities/Amount/Amount';
import HubridMoment from '../../utilities/HybridMoment';
import shopInventoryStatusDisplayBadge, { ShopInventoryStatusEnum } from '../Enums/ShopInventoryStatusEnum';
import IState, { IProps } from './ShowCodeModal.models'
import { NotificationManager } from 'react-notifications';
import TOTPForm from '../../identity/Security/TwoFactor/TOTPForm/TOTPForm';
import authenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext";
import ModalComponent from '../../shared/modal/Modal';
import { CustomButton } from '../../shared/button/ButtonComponent';
import { CircularProgress } from '@mui/material';
import AlertComponent from '../../shared/alert/AlertComponent';
import SpinnerComponent from '../../shared/spinner/SpinnerComponent';

export default class ShowCodeModal extends React.Component<IProps, IState> {
    inventoryService: InventoryService
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    constructor(props: IProps) {
        super(props)
        this.state = { isLoading: false, newStatus: ShopInventoryStatusEnum.Available, takeInventoryDescription: '' }
        this.inventoryService = new InventoryService()
    }

    componentDidMount() {
        this.loadData()
    }

    componentDidUpdate(prevProps: { inventoryShopId: string }) {
        if (this.props.inventoryShopId !== prevProps.inventoryShopId)
            this.loadData();
    }

    loadData = () => {
        this.inventoryService.getById(this.props.inventoryShopId).then((response) => {
            this.setState({ information: { ...response } })
        })
    }

    showSecret = () => {
        this.setState({ isLoading: true, otpSubmitFunction: undefined })
        this.inventoryService.getInformation(this.props.inventoryShopId).then((response) => {
            this.setState({ secretInformation: response.information })
        })
            .catch(() => {
                NotificationManager.error('Error in fetch secret information');
            })
            .finally(() => {
                this.setState({ isLoading: false })
            })
    }

    takeout = () => {
        this.setState({ takeoutLoading: true, otpSubmitFunction: undefined })
        this.inventoryService.createManualTakeoutInventory({ inventoryId: this.props.inventoryShopId, description: this.state.takeInventoryDescription })
            .then(() => {
                NotificationManager.success('Inventory taked successfully');
                this.loadData();
                this.showSecret();
                this.setState({ istakeInventoryMode: false })
            })
            .catch(() => {
                NotificationManager.error('Error in take inventory');
            })
            .finally(() => {
                this.setState({ takeoutLoading: false })
            })
    }

    render(): React.ReactNode {
        const { information } = this.state
        const { closeModal } = this.props

        return <>
            <ModalComponent title={'Inventory'} size="800px" onClose={closeModal}>
                <div>
                    {this.state.information ? <>
                        <div>
                            <Row>
                                <Col md="6" sm="12">
                                    <b>Title:</b> {information.title}
                                </Col>
                                <Col md="6" sm="12">
                                    <b>Buy Price:</b> <Amount value={information.buyPrice as number} />
                                </Col>
                                <Col md="6" sm="12">
                                    <b>Create At:</b> {HubridMoment(information.createAt)}
                                </Col>
                                <Col md="6" sm="12">
                                    <b>Sold At:</b> {information.soldAt && HubridMoment(information.soldAt)}
                                </Col>
                                <Col md="6" sm="12">
                                    <b>Source:</b> {information.source}
                                </Col>
                                <Col md="6" sm="12">
                                    <b>Status:</b> {shopInventoryStatusDisplayBadge(information.status)}
                                </Col>
                                <Col md="12" sm="12">
                                    <b>Information:</b> {this.state.isLoading ? <Spinner animation="grow" size='sm' /> : this.state.secretInformation ?? information.information}
                                </Col>
                            </Row>
                        </div>
                        <br />
                        {
                            this.state.information.outBySourceKey == "ORDERS" &&
                            <AlertComponent type='warning'>
                                This inventory outed by a orders, so you can't see secret information
                            </AlertComponent>
                        }
                    </> : <SpinnerComponent />}
                    {
                        this.state.istakeInventoryMode !== true && ((this.state.information?.enterByOrderItemId) && this.state.information.status !== ShopInventoryStatusEnum.Sold) && (
                            <AlertComponent type='warning' action={{
                                label: this.state.secretInformation ? 'Hide Information' : 'Show Information', onClick: () => {
                                    if (this.state.secretInformation)
                                        this.setState({ secretInformation: undefined })
                                    else if (this.context.twoFactorAvtive)
                                        this.setState({ otpSubmitFunction: 'INFORMATION' })
                                    else
                                        this.showSecret();
                                }
                            }}>
                                This inventory enter by a order and is safe for sell to other shops, but when you see secret information of this item then you are also responsible if there is a problem!<br /><br />
                                Of course, if you do not want to sell this product to other sellers, ignore this message.<br /><br />
                            </AlertComponent>
                        )
                    }
                </div>
                {
                    this.state.information && this.state.information.outBySourceKey != "ORDERS" &&
                    <ModalComponent.Footer>
                        {
                            this.state.istakeInventoryMode !== true && (!this.state.information.enterByOrderItemId || (this.state.information.status === ShopInventoryStatusEnum.Sold && this.state.information.outBySourceKey !== "ORDERS")) && (
                                this.state.secretInformation ?
                                    <CustomButton variant='contained' color='warning' onClick={() => this.setState({ secretInformation: undefined })}>Hide Information</CustomButton>
                                    :
                                    <CustomButton variant='contained' onClick={() => {
                                        if (this.context.twoFactorAvtive)
                                            this.setState({ otpSubmitFunction: 'INFORMATION' })
                                        else
                                            this.showSecret();
                                    }}>Show Information</CustomButton>
                            )
                        }
                        {
                            this.state.information.status == ShopInventoryStatusEnum.Available && !this.state.istakeInventoryMode &&
                            <CustomButton variant='contained' onClick={() => {
                                this.setState({ istakeInventoryMode: true })
                            }}>Take From Inventory</CustomButton>
                        }

                        {
                            this.state.information.status == ShopInventoryStatusEnum.Available && this.state.istakeInventoryMode === true &&
                            <>
                                <Form.Control as="textarea" rows={3} placeholder="Enter description (optional)"
                                    value={this.state.takeInventoryDescription}
                                    onChange={e => this.setState({ takeInventoryDescription: e.target.value })} />
                                <CustomButton variant='contained' onClick={() => {
                                    if (this.context.twoFactorAvtive)
                                        this.setState({ otpSubmitFunction: 'TAKEOUT' })
                                    else
                                        this.takeout();
                                }} disabled={this.state.takeoutLoading}>{this.state.takeoutLoading ? <CircularProgress color='inherit' size={20} /> : 'Take'}</CustomButton>
                                <CustomButton variant='outlined' color='error' onClick={() => {
                                    this.setState({ istakeInventoryMode: false })
                                }} disabled={this.state.takeoutLoading}>{this.state.takeoutLoading ? <Spinner animation='border' size='sm' /> : 'Cancel'}</CustomButton>
                            </>
                        }
                    </ModalComponent.Footer>
                }
            </ModalComponent>

            {this.state.otpSubmitFunction && <TOTPForm showModal={true} onSubmit={() => {
                if (this.state.otpSubmitFunction == 'INFORMATION')
                    this.showSecret();
                else
                    this.takeout();
            }} onHide={() => this.setState({ otpSubmitFunction: undefined })} />}

        </>
    }
}