import React from "react";
import { Placeholder } from "react-bootstrap";
import './Banners.scss'
import { Grid } from "@mui/material";
import MainBanner from "../../../assets/images/banners/main.png";
import SecondBanner from "../../../assets/images/banners/second.png";
import ThirdBanner from "../../../assets/images/banners/third.png";

export default class Banners extends React.Component<{}, { loaded: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = { loaded: false };
    }
    render(): React.ReactNode {
        return (<div className="banners">
            <Grid container>
                <Grid item md={9} sm={12} xs={12}>
                    <div className="banner main-banner">
                        {
                            this.state.loaded ? null : <Placeholder animation="glow" style={{ width: '50%' }}>
                                <Placeholder style={{ height: '415px', borderRadius: '24px', width: '100%' }} />
                            </Placeholder>
                        }
                        <img src={MainBanner}
                            style={{ display: this.state.loaded ? 'block' : 'none' }}
                            onLoad={() => this.setState({ loaded: true })}
                        />
                    </div>
                </Grid>
                <Grid item md={3} sm={12} xs={12}>
                    <div className="flex column">
                        <div className="banner">
                            {
                                this.state.loaded ? null : <Placeholder animation="glow" style={{ width: '50%' }}>
                                    <Placeholder style={{ height: '200px', borderRadius: '24px', width: '100%', marginTop: '24px' }} />
                                </Placeholder>
                            }
                            <img src={SecondBanner}
                                style={{ display: this.state.loaded ? 'block' : 'none' }}
                                onLoad={() => this.setState({ loaded: true })}
                            />
                        </div>
                        <div className="banner pt-md">
                            {
                                this.state.loaded ? null : <Placeholder animation="glow" style={{ width: '50%' }}>
                                    <Placeholder style={{ height: '200px', borderRadius: '24px', width: '100%' }} />
                                </Placeholder>
                            }
                            <img src={ThirdBanner}
                                style={{ display: this.state.loaded ? 'block' : 'none' }}
                                onLoad={() => this.setState({ loaded: true })}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>);
    }
}