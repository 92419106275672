import React, { RefObject } from "react"
import { NavLink } from "react-router-dom"
import IState, { IProps, ISellersFilters } from "./AdminSellersList.models"
import DynamicGrid from "../../../../shared/DynamicGrid/DynamicGrid"
import AdminSellerService from "../../../../../services/admin/shops/AdminSellerService"
import { GridAction, GridColumn, GridColumnTypes } from "../../../../Grid/Grid.models"
import shopStatusDisplayBadge, { ShopStatusEnum } from "../../../../shops/ShopStatusEnum"
import { NotificationManager } from 'react-notifications'

export default class AdminSellersList extends React.Component<IProps, IState> {
    dynamicGrid: RefObject<DynamicGrid<ISellersFilters>>
    sellerService: AdminSellerService

    constructor(props: any) {
        super(props)
        this.state = { isLoading: false }
        this.dynamicGrid = React.createRef()
        this.sellerService = new AdminSellerService()
    }

    componentDidMount = () => {
        this.dynamicGrid.current.fetch(this.dynamicGrid.current.state.pageSize)
    }

    renderOwnerView = (userid: string, username: string) => { return <NavLink to={"/admin/users?id=" + userid}>{username}</NavLink> }

    removeSeller = (id: string) => {
        if (window.confirm('Are you sure you want to revocation this seller policy?'))
            this.sellerService.delete(id).then(() => {
                NotificationManager.success('Successfully.')
                this.dynamicGrid.current?.fetch()
            }).finally(() => this.dynamicGrid.current?.resetSpinner())
    }

    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: "Shop", key: "title" },
            { title: "Owner", key: "ownerName" },
            { title: "Members", key: "memberCount" },
            { title: "Number", key: "number" },
            { title: "Created", key: "registerAt", type: GridColumnTypes.DateOnly },
        ]
        let gridActions: GridAction[] = [{
            caption: "Remove",
            variant: "outline-danger",
            onClick: (row) => this.removeSeller(row.id)
        }]

        if (this.props.status == ShopStatusEnum.Accepted)
            gridActions.push({
                caption: "Wallet",
                variant: "outline-info",
                skipSpinner: true,
                generateLink: (row) => `/admin/financials?activeTab=wallets&ownerId=${row.id}`
            })

        return (
            <>
                <DynamicGrid<ISellersFilters>
                    ref={this.dynamicGrid}
                    columns={gridColumns}
                    onFetch={this.sellerService.get}
                    hideFilterBar
                    defaultFilters={{ status: this.props.status }}
                    actions={gridActions}
                    refactorRow={(row) => {
                        return {
                            ...row, status: shopStatusDisplayBadge(row.status), ownerName: this.renderOwnerView(row.ownerUserId, row.ownerName)
                        }
                    }}
                />
            </>
        )
    }
}
