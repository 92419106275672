import { useSearchParams } from "react-router-dom"
import Financials from "../../components/financials/Financials/Financials"
import ShopAuthenticated from "../../components/shared/ShopAuthenticated/ShopAuthenticated"
import { FINANCIAL_MENU, FULL_ACCESS } from "../../constants/ConstantsPermissions"

export default function FinancialsRoute() {
    const [searchParams] = useSearchParams()
    let activeTab = searchParams.get("activeTab")
    let walletId = searchParams.get("walletId")
    let walletStatus = searchParams.get("walletStatus")

    return  <Financials activeTab={activeTab} walletStatus={walletStatus ? Number(walletStatus) : null} walletId={walletId} />

    return <ShopAuthenticated showAccessDenied permission={FULL_ACCESS}>
        <Financials activeTab={activeTab} walletStatus={walletStatus ? Number(walletStatus) : null} walletId={walletId} />
    </ShopAuthenticated>
}