import React from "react";
import { Form, } from "react-bootstrap";
import OrdersService from "../../../services/orders/OrdersService";
import CartService from "../../../services/shopping/CartService";
import './Cart.scss'
import ShopAuthenticated from "../../shared/ShopAuthenticated/ShopAuthenticated";
import { SECRET_INFORMATION } from "../../../constants/ConstantsPermissions";
import authenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext";
import { SELLER, WEB_SERVICE } from "../../../constants/ConstantsPolicies";
import WalletService from "../../../services/financials/WalletService";
import AlertComponent from "../../shared/alert/AlertComponent";
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { CustomButton } from "../../shared/button/ButtonComponent";
import { Navigate } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import CartItem from "./table/Item/CartItem";
import SpinnerComponent from "../../shared/spinner/SpinnerComponent";

export default class Cart extends React.Component<{ close?: () => void }, {
    payUSDTRedirectLink?: string, orderId?: string, walletBalance: number,
    credit: number, items: CartItemView[], initialized: boolean, loading: boolean, addToInventory: boolean, billId?: string, navigateToPayment: boolean
}> {
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    cartService: CartService;
    walletService: WalletService;
    ordersService: OrdersService;
    USDTCurrencyId: string = 'b8b8eaaa-c421-4783-b481-ae4fea6024de';

    constructor(props: any) {
        super(props);
        this.state = { items: [], walletBalance: 0, credit: 0, loading: false, initialized: false, addToInventory: false, navigateToPayment: false }
        this.cartService = new CartService();
        this.ordersService = new OrdersService();
        this.walletService = new WalletService();
    }

    componentDidMount() {
        this.setState({ addToInventory: this.context.policies?.includes(SELLER) || this.context.policies?.includes(WEB_SERVICE) })
        this.loadCart()
        this.loadWalletBalance()
    }

    addToCart = (count: number, productId: string, sellerShopId: string, unitPrice: number): Promise<any> => {
        return this.cartService.add({ count: count, productId: productId, sellerShopId: sellerShopId, unitPrice: unitPrice })
            .finally(() => {
                this.loadCart();
            })
    }

    generateChargeLink = () => {
        this.walletService.getWalletList({}, { pagesize: 10, pageskip: 1 }).then(wallets => {
            var walletId = wallets.rows.filter(wallet => wallet.currencyId == this.USDTCurrencyId)[0].id;
            // this.setState({ payUSDTRedirectLink: `/deposit/?currencyId=${this.USDTCurrencyId}` })
            this.setState({ payUSDTRedirectLink: `/payment` })
        })
    }

    loadWalletBalance = () => {
        this.walletService.getOverview().then((response) => {
            this.setState({ walletBalance: response.balance, credit: response.credit })
            if (response.balance == 0) this.generateChargeLink()
        })
    }

    loadCart = () => {
        this.cartService.get().then((cartItems) => {
            this.setState({
                items: cartItems.map((item) => {
                    return {
                        id: item.id, productId: item.productId, sellerShopId: item.sellerShopId, productInventoryAvailableCount: item.productInventoryAvailableCount,
                        count: item.count, imageSrc: '', title: item.productTitle, unitPrice: item.unitPrice
                    }
                })
            });
        }).finally(() => this.setState({ initialized: true }))
    }

    validateWalletBalance = () => {
        let { walletBalance, credit, items } = this.state
        var balance = credit + walletBalance;
        if (balance == 0) return false
        let pricePayment = items.map(item => item.unitPrice * item.count).reduce((partialSum, a) => partialSum + a, 0);
        if (balance < pricePayment) return false
        return true
    }

    navigateToPayment = () => {
        this.setState({ navigateToPayment: true }, () => this.props.close && this.props.close())
    }

    order = () => {
        if (!this.validateWalletBalance()) {
            this.navigateToPayment();
            return;
        }

        this.setState({ loading: true })
        this.ordersService.order(false).then((response) => {
            this.loadCart();
            this.props.close && this.props.close()
            NotificationManager.success('Your order created successfully');
        }).catch(() => {
            NotificationManager.warning('There was a problem registering the order!');
        }).finally(() => this.setState({ loading: false }))
    }

    delete = (id: string) => {
        this.setState({ loading: true })
        this.cartService.delete(id).then(() => {
            this.loadCart();
        }).finally(() => this.setState({ loading: false }))
    }

    deleteAll = () => {
        this.setState({ loading: true })
        let count = this.state.items.length;
        for (let item of this.state.items)
            this.cartService.delete(item.id).then(() => {
                this.loadCart();
            }).finally(() => count > 0 ? count-- : this.setState({ loading: false }))
    }

    update = (id: string, count: number) => {
        this.setState({ loading: true })
        this.cartService.update({ productBasketId: id, count }).then(() => {
            this.loadCart();
        }).finally(() => this.setState({ loading: false }))
    }

    render(): React.ReactNode {
        if (!this.state.initialized)
            return <SpinnerComponent />

        if (this.state.navigateToPayment)
            return <Navigate to={`/payment?type=purchase`} replace={true} />

        const count = this.state.items.reduce((total, item) => total + item.count, 0);
        return <div>
            <div>
                {
                    (this.state.walletBalance <= 0 && this.state.payUSDTRedirectLink) &&
                    <AlertComponent type="warning" action={{
                        label: "Click To Charge", onClick: () => this.navigateToPayment()
                    }}>You need to charge your wallet to buy.</AlertComponent>
                }
                {
                    this.state.items.length > 0 ? <>
                        <div className="bg-white radius-sm box-shadow mt-lg mb-lg">
                            <TableContainer className="simple-table">
                                <Table sx={{ minWidth: 650 }} aria-label="table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Product</TableCell>
                                            <TableCell align="center">Seller</TableCell>
                                            <TableCell align="center">Count</TableCell>
                                            <TableCell align="center">Price</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.items.map((item, key) => (
                                            <CartItem key={String(key)} inventoryCount={item.productInventoryAvailableCount} title={item.title} count={item.count} imageSrc={item.imageSrc}
                                                unitPrice={item.unitPrice} onDelete={() => this.delete(item.id)} onChangeCount={(count) => {
                                                    if (count > item.productInventoryAvailableCount && item.productInventoryAvailableCount != null) return
                                                    this.update(item.id, count)
                                                }} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        {
                            (this.state.items.length > 0 && this.context.policies?.includes(SELLER) || this.context.policies?.includes(WEB_SERVICE)) &&
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                <Form.Check name="active" id={'v'} className="mt-4"
                                    checked={this.state.addToInventory}
                                    onChange={() => this.setState({ addToInventory: !this.state.addToInventory })}
                                    type="checkbox" label={'Add items to inventory'}
                                />
                            </Form.Group>
                        }
                    </> : <p className="pa-md text-center"> Your shopping cart is empty!</p>
                }
            </div>
            <div>
                <ShopAuthenticated permission={SECRET_INFORMATION} reverse>
                    <AlertComponent type="warning">You do not have access to purchase operations</AlertComponent>
                </ShopAuthenticated>
                <ShopAuthenticated permission={SECRET_INFORMATION}>
                    {
                        this.state.items.length > 0 &&
                        <div className="flex justify-between">
                            <div className="text-black font-md flex items-center">
                                <span className="pr-sm font-medium">Total:</span>
                                <Typography sx={{ fontSize: '18px' }}><span className="font-bold">{this.state.items.length > 0
                                    && this.state.items.map(item => item.unitPrice * item.count).reduce(function (a, b) {
                                        return a + b
                                    }, 0)}$</span></Typography>
                            </div>
                            {
                                this.state.loading ? <CustomButton disabled variant="contained"><CircularProgress size="20px" /></CustomButton> :
                                    <CustomButton variant="contained" disabled={this.state.items.length === 0} onClick={this.order}> Checkout</CustomButton>
                            }
                        </div>
                    }
                </ShopAuthenticated>
            </div>
        </div>
    }
}

interface CartItemView { id: string, title: string, productInventoryAvailableCount: number, sellerShopId: string, productId: string, count: number, unitPrice: number, imageSrc: string }