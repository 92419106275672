import React from "react";
import { NavLink } from "react-router-dom";
import './MenuItem.scss';
import authenticationContext from "../../../../../contexts/AuthenticationContext/AuthenticationContext";
import { SidebarItemView } from "../CategorySidebar";

export default class MenuItem extends React.PureComponent<{
    customStyle?: string, onNavigate?: () => void, onClick: () => void,
    catalogMode: 'express' | 'trade' | 'api', title: string, icon: any, subItems: SidebarItemView[], isSelected: boolean, id: string
},
    { title: string, isOpened: boolean, subItems: SidebarItemView[], id: string }> {
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    constructor(props: any) {
        super(props);
        this.state = { title: '', subItems: [], isOpened: false, id: '' };
    }
    componentDidMount() {
        this.setState({ title: this.props.title, subItems: this.props.subItems, isOpened: false, id: this.props.id });
    }

    componentDidUpdate() {
        this.setState(prevState => { return { ...prevState, isOpened: this.props.isSelected } });
    }

    handleClickMenuItem = (id: string) => {
        this.closeAll(id);
        this.setState(prevState => {
            return {
                ...prevState,
                subItems: prevState.subItems
                    .map(item => {

                        if (item.id === id) {
                            return { ...item, isActive: !item.isActive };
                        } else {
                            return item;
                        }
                    })
            }
        });
    }
    closeAll = (exceptedId: string) => {
        this.setState(prevState => {
            return {
                subItems: prevState.subItems
                    .map(item => { return item.id === exceptedId ? item : { ...item, isActive: false } })
            }
        });

    }

    render(): React.ReactNode {
        return (
            <div className={`MenuItem  ${this.props.customStyle ? this.props.customStyle : ' '} ${this.props.isSelected ? ' active' : ''}`} onClick={this.props.onClick}>
                {
                    <NavLink to={this.state.id ? `/marketplace?mode=${this.props.catalogMode}&parentCategoryId=${this.state.id}` : `/marketplace?mode=${this.props.catalogMode}`}>
                        <span className="menu-link flex items-center" onClick={this.props.onNavigate}>
                            {this.props.icon}
                            <span className="menu-link-title">{this.props.title}</span>
                        </span>
                    </NavLink>
                }
            </div>
        );
    }
}