import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { HandThumbsUpFill, HandThumbsDownFill } from "react-bootstrap-icons";
import { NotificationManager } from 'react-notifications';
import SupportTicketsService from "../../../../../services/helpCenter/SupportTicketService";

export default class SupportTicketAddVote extends React.Component<{ supportTicketId: string, isGood: boolean | null, onHandle: () => void }, {}> {
    supportTicketsService: SupportTicketsService
    constructor(props: any) {
        super(props);
        this.state = {}
        this.supportTicketsService = new SupportTicketsService()
    }

    addVote = (isGood: boolean) => {
        this.supportTicketsService.addVote({ isGood, supportTicketId: this.props.supportTicketId }).then(() => {
            NotificationManager.success('Vote added successfully.');
            this.props.onHandle()
        })
    }

    render(): React.ReactNode {
        return <Row>
            <Col>
                This topic was helpful ?
                <Button variant="light" className="m-1" onClick={() => this.addVote(true)}><HandThumbsUpFill className={this.props.isGood ? 'text-success' : ''} /></Button>
                <Button variant="light" className="m-1" onClick={() => this.addVote(false)}><HandThumbsDownFill className={this.props.isGood === false ? 'text-danger' : ''} /></Button>
            </Col>
        </Row>
    }
}