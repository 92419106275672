import { Route } from "react-router-dom";
import BuyOrdersRoute from "../orders/BuyOrdersRoute";
import SellOrdersRoute from "../orders/SellOrdersRoute";
import MainLayout from "../../layouts/main-layout/MainLayout";
import OrderDeliveryRoute from "../orders/OrderDeliveryRoute";

export function OrdersRoutes() {
    return (
        <>
            <Route element={<MainLayout  showLogo/>}>
                <Route path="/orders/buy" element={<BuyOrdersRoute />} />
                <Route path="/orders/sell" element={<SellOrdersRoute />} />
                <Route path="/order-delivery" element={<OrderDeliveryRoute />} />
            </Route>
        </>
    )
}