import React from 'react';
import './ExpressCategoryCatalog.scss'
import { ProductCategoryTitleView } from '../../../services/catalog/ProductCatalogService';
import { Placeholder } from 'react-bootstrap';
import ExpressCategoryCatalogItem from './CateqoryCatalopItem/ExpressCateqoryCatalopItem';
import { CategoryCatalogImages } from '../Catalog/CatalogImages';
import { Grid, Typography } from '@mui/material';

interface IProps {
    mode?: 'express' | 'trade',
    categories: ProductCategoryTitleView[]
    parentId?: string
    isLoading: boolean
}

interface ICategoryItemView extends ProductCategoryTitleView {
    hasSubMenu: boolean
}

interface IState {
    selectedCategory?: {
        title: string;
        parentId?: string;
        parentTitle?: string;
        id: string;
    };
    menuItems: ICategoryItemView[]
}

export default class ExpressCategoryCatalog extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = { menuItems: [] };
    }

    componentDidMount() {
        this.setCategoriesByParent(this.props.parentId ?? null)
    }

    componentDidUpdate(prevProps: Readonly<{ categories: ProductCategoryTitleView[]; onNavigate?: () => void; fixMargin?: boolean; parentId?: string }>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.categories != this.props.categories || this.props.parentId != prevProps.parentId)
            this.setCategoriesByParent(this.props.parentId ?? null)
    }

    setCategoriesByParent = (id: string | null) => {
        let { categories } = this.props
        let mainCategories = categories.map((category) => {
            return {
                id: category.id,
                title: category.title,
                parentId: category.parentId,
                imageName:category.imageName,
                hasSubMenu: categories.filter(menuItem => menuItem.parentId === category.id).length > 0
            } as ICategoryItemView;
        });
        let menuItems = mainCategories.filter(item => item.parentId == id)
        let category = mainCategories.filter(item => item.id == id)[0]
        let parentTitle = category && mainCategories.filter(item => item.id == category.parentId)[0]?.title

        this.setState({ menuItems: menuItems, selectedCategory: id && { parentTitle, ...category } })
    }

    render(): React.ReactNode {
        let { selectedCategory } = this.state

        return <div>
            {this.props.isLoading ?
                <Grid container spacing={1.6} sx={{ px: { lg: 1, xs: 1.6, md: 1, sm: 1.6 } }}>
                    {
                        Array.from(Array(12), (e, i) => <Grid item key={i} xs={6} lg={3} md={3} pb={1}>
                            <Placeholder animation="glow">
                                <Placeholder className={"category-catalog-item"} xs={12} />
                            </Placeholder>
                        </Grid>)
                    }
                </Grid> : <>
                    <Typography mb={2} className='express-category-catalog-title'>
                        <span className='font-bold'>{selectedCategory ? selectedCategory.title : "Popular"}</span>
                    </Typography>
                    <Grid container sx={{ px: { lg: 0, xs: 0.6, md: 0, sm: 0.6 } }}>
                        {
                            this.state.menuItems.map((item) => <Grid item key={item.id} px={1} xs={6} lg={3} md={3} pb={2}>
                                <ExpressCategoryCatalogItem title={item.title}
                                    imageName={item.imageName}
                                    mode={this.props.mode}
                                    categoryId={item.id}
                                    key={item.id}
                                    hasSubMenu={item.hasSubMenu}
                                    onClick={() => this.setCategoriesByParent(item.id)}
                                />
                            </Grid>)
                        }
                    </Grid>
                </>
            }
        </div>
    }
}