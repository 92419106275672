import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { BarChartLine } from 'react-bootstrap-icons';
import ProductCatalog from '../ProductCatalog/ProductCatalog';
import AutoBuyPlan from '../AutoBuyPlan/AutoBuyPlan';
import CreateNewOffer from '../CreateNewOffer/CreateNewOffer';
import Note from '../../shared/Note/Note';
import PriceComparison from '../../inventory/InventoryPlans/InventoryProductConfig/PriceComparison';
import IState, { IProps } from './BuyProductCatalogModal.models';
import ModalComponent from '../../shared/modal/Modal';
import EmptyImg from '../../../assets/images/content/empty-img.png'

export default class BuyProductCatalogModal extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = { loading: true, pendingRequest: 0, activeTab: 'offer' }
    }

    activeSection = (key: 'express' | 'trade') => {
        switch (key) {
            default: case 'express':
                return <>
                    <div className='flex pb-md horizontal-scrolling'>
                        <img src={EmptyImg} className='pr-md' width={'100px'} />
                        <img src={EmptyImg} className='pr-md' width={'100px'} />
                        <img src={EmptyImg} className='pr-md' width={'100px'} />
                    </div>
                    <div className='font-md font-medium'>Sellers who have the product in stock are displayed in this section and delivery is immediate.</div>
                    <ProductCatalog
                        productId={this.props.productId}
                        onAddToCart={this.props.onAddToCart}
                        onClose={this.props.onClose} />
                </>
            case 'trade':
                return <>

                    <Tabs
                        activeKey={this.state.activeTab}
                        onSelect={(key) => this.setState({ activeTab: key })}
                        className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                        <Tab eventKey="offer" title="Offer">
                            <Note>
                                In cases where the product was not available by any of the sellers or the price you want is lower, you can create your offer so that we share it with all the sellers (this is an auction for your purchase!)
                            </Note>
                            <CreateNewOffer productId={this.props.productId} onCreated={this.props.onClose} />
                        </Tab>
                        <Tab eventKey="auto" title="Auto Offer">
                            <Note>
                                In this section, you can make settings so that your warehouse is filled automatically (create automatic order and  offer), it can be very useful if you use the web service.
                            </Note>
                            <AutoBuyPlan productId={this.props.productId} />
                        </Tab>
                    </Tabs>

                </>
        }
    }

    render(): React.ReactNode {
        return <ModalComponent title={this.props.title} onClose={this.props.onClose} size={'900px'}>
            {this.activeSection(this.props.mode)}
            <ModalComponent.Footer>
                {
                    this.props.mode == 'trade' && <>
                        <div className="text-pale-grey-main font-bold pt-md pb-sm">
                            <BarChartLine /> Price comparison</div>
                        <PriceComparison productId={this.props.productId} apiPlanPrice={0} oneUnitPlanPrice={0} hideOwnedPlan />
                    </>
                }
            </ModalComponent.Footer>
        </ModalComponent>
    }
}
