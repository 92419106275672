import { IProps } from './CardView.models'

export default function CardView(props: IProps) {
    return <>
        <div className="bg-white radius-sm box-shadow pa-md">
            {props.card.header && <div className='font-bold'>
                {props.card.header}
                <div>
                    {props.renderHeader}
                </div>
            </div>}
            <div>
                {props.card.body && <div>{
                    props.small ? <small className="text-muted">{props.card.body}</small> : props.card.body
                }</div>}
                {props.children && props.children}
            </div>
        </div>
    </>
}