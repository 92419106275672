export default function TruncateString({ text, maxLength }) {
    const truncate = (str, length) => {
        if (str.length > length)
            return str.slice(0, length) + '...';
        return str;
    };

    return (
        <span>{truncate(text, maxLength)}</span>
    );
}