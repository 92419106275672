import React from 'react';
import { Alert, Button, Modal, Spinner } from 'react-bootstrap'
import { LockFill, XCircle, ClockHistory } from 'react-bootstrap-icons';
import { NotificationManager } from 'react-notifications';
import { isThisTypeNode } from 'typescript';
import ShopPolicyService from '../../../../services/shops/ShopPolicyService';
import ShopService from '../../../../services/shops/ShopService';
import HubridMoment from '../../../utilities/HybridMoment';
import { PackageStatusEnum } from '../Enum/PackageStatusEnum';
import IState, { IProps } from './RequestAccessModal.models';

export default class RequestAccessModal extends React.Component<IProps, IState> {
    shopPolicyService: ShopPolicyService
    constructor(props: any) {
        super(props)
        this.shopPolicyService = new ShopPolicyService()
        this.state = { isLoading: false, status: PackageStatusEnum.Disabled }
    }

    componentDidMount() {
        this.checkShopPackage()
    }

    checkShopPackage = () => this.shopPolicyService.checkShopPackage(this.props.policyKey).then((response) => {
        this.setState({ status: response.status ?? PackageStatusEnum.Disabled, createDate: response.createDate })
    })

    requestAccess = () => {
        this.setState({ isLoading: true })
        this.shopPolicyService.sendRequest(this.props.policyKey).then(() => {
            NotificationManager.success('Send request access is successfully.')
            this.props.onSubmit()
            this.props.closeModal()
        }).finally(() => this.setState({ isLoading: false }))
    }

    renderDefaultDetail() {
        return <>
            <p style={{ color: '#11603c' }}>It looks like you don't have access to Pro, if you want to access its features you have to have a paid policy that costs $100/year.</p>
            <p>With Pro subscription, you will have access to these features:</p>
            <ul>
                <li>
                    You will have more features in adding inventory such as:
                    <ul>
                        <li> You can to select source</li>
                        <li> You can to enter the order number</li>
                        <li> You can to see the deficits</li>
                    </ul>
                </li>
                <li>Access to inventory source list</li>
                <li>Access to deficits</li>
                <li>Access to pro mode in product planning</li>
            </ul>
        </>
    }

    render(): React.ReactNode {
        return <Modal
            size={this.props.small ? 'sm' : undefined}
            show={true}
            onHide={this.props.closeModal}
        >
            <Modal.Header closeButton>
                <Modal.Title>Request Access</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className='access-denied' style={{ margin: '3vh auto' }}>
                        {this.renderBody(this.state.status)}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    }

    renderBody(status: PackageStatusEnum) {
        switch (status) {
            case PackageStatusEnum.Pending:
                return <div>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <ClockHistory className='text-muted mb-3' size="50px" />
                    </div>
                    <b>You submitted a request on {this.state.createDate && HubridMoment(this.state.createDate.toString())}</b>
                    <Alert variant="warning" className='mt-3'>Your request is pending</Alert>
                </div>
            case PackageStatusEnum.Rejected:
                return <>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <XCircle className='text-danger mb-3' size="50px" />
                    </div>
                    <Alert variant='danger' className='mt-3'><span>Your request rejected</span></Alert>
                </>
            case PackageStatusEnum.Disabled:
            default:
                return <div className='d-flex flex-column justify-content-center align-items-center'>
                    <LockFill className='text-muted mb-3' size="50px" />
                    <span className='text-muted mb-4'>{this.props.description ? this.props.description : this.renderDefaultDetail()}</span>
                    {this.state.isLoading ? <Button variant='success w-100' disabled><Spinner size='sm' animation='border' /></Button> :
                        <Button variant='success w-100' onClick={this.requestAccess}>Request Access</Button>
                    }
                </div>
        }
    }
}