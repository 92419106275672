import { Route } from "react-router-dom";
import APIRoute from "../api/APIRoute";
import MainLayout from "../../layouts/main-layout/MainLayout";

export default function ApiRoutes() {
    return (
        <>
            <Route element={<MainLayout  showLogo/>}>
                <Route path="/rest-config" element={<APIRoute />}/>
            </Route>
        </>
    )
}