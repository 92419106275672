import React from "react"
import { Button, Col, Row, Spinner } from "react-bootstrap"
import Amount from "../../../../utilities/Amount/Amount"
import Moment from 'moment';
import cashPaymentStatusDisplayBadge, { CashPaymentStatusEnum } from "../../../../financials/Enums/CashPaymentStatusEnum"
import AdminDepositsService from "../../../../../services/admin/financials/AdminDepositsService";
import { NotificationManager } from 'react-notifications';
import ModalComponent from "../../../../shared/modal/Modal";
import SpinnerComponent from "../../../../shared/spinner/SpinnerComponent";
import { CustomButton } from "../../../../shared/button/ButtonComponent";
import { CircularProgress } from "@mui/material";

interface IProps {
    details?: {
        id: string
        amount: number
        handleAt: string | null
        requestAt: string
        status: CashPaymentStatusEnum
        shopTitle: string
        shopId: string
        description: string,
        transactionNumber: string,
        financialAccountType: string,
        financialAccount: string,
        handlerUserId: string,
        handlerName: string,
    }
    onClose: () => void;
}

export default class AdminDepositDetails extends React.PureComponent<IProps, {
    pendingRequest: boolean, withdrawDetails?: string, description?: string
}> {
    adminDepositsService: AdminDepositsService

    constructor(props: any) {
        super(props)
        this.adminDepositsService = new AdminDepositsService()
        this.state = { description: "", pendingRequest: false }
    }

    handle = (status: boolean) => {
        this.setState({ pendingRequest: true })

        if (status) {
            NotificationManager.error("This payment can verfied by provider only")
        } else {
            this.adminDepositsService.reject(this.props.details.id).then(() => {
                NotificationManager.success("Payment rejected successfully")
                this.props.onClose()
                this.close()
            }).finally(() => this.setState({ pendingRequest: false }))
        }
    }

    close = () => {
        this.props.onClose();
        this.setState({ withdrawDetails: undefined })
    }

    footerView = (status: CashPaymentStatusEnum) => {
        switch (status) {
            case CashPaymentStatusEnum.Accepted: case CashPaymentStatusEnum.Rejected:
                return <CustomButton onClick={this.close} variant="contained" color="inherit">Close</CustomButton>
            case CashPaymentStatusEnum.Pending:
                return <>
                    <Row>
                        <Col md="12" sm="12" className="mt-2">
                            {
                                this.state.pendingRequest ? <CustomButton variant="contained" disabled><CircularProgress color="inherit" size={20} /></CustomButton> :
                                    <div style={{ textAlign: 'right' }}>
                                        <CustomButton className="me-2" onClick={() => this.handle(true)} variant="contained">Accept</CustomButton>
                                        <CustomButton onClick={() => this.handle(false)} color="error" variant="contained" className="mr-2">Reject</CustomButton>
                                    </div>
                            }

                        </Col>
                    </Row>
                </>
        }
    }

    render(): React.ReactNode {
        return <>
            {
                this.props.details !== undefined &&
                <ModalComponent title={"Deposits Details"} onClose={this.close} size="800px">
                    <div>
                        {this.props.details !== undefined && <> {cashPaymentStatusDisplayBadge(this.props.details.status)}</>}
                        {
                            this.props.details !== undefined ? (<>
                                <Row>
                                    <Col>
                                        <label>Shop: </label> <b>{this.props.details.shopTitle}</b>
                                    </Col>
                                    <Col>
                                        <label>Amount: </label> <b>$<Amount value={this.props.details.amount} /></b>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>Account: </label> <b>{this.props.details.financialAccountType}</b>
                                    </Col>
                                    <Col>
                                        <label>Create At: </label> <b title={(Moment(this.props.details.requestAt).utc().format('YYYY/MM/DD HH:mm')) + ' (UTC)'}>{Moment(this.props.details.requestAt).local().format('YYYY/MM/DD HH:mm')} </b>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>Transaction Number: </label> <b>{this.props.details.transactionNumber}</b>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>Description: </label> <b>{this.props.details.description}</b>
                                    </Col>
                                </Row>
                            </>) : <SpinnerComponent />
                        }
                    </div>
                    <ModalComponent.Footer>
                        <Row>
                            <Col md="12" sm="12">
                                {this.props.details !== undefined && <>
                                    {
                                        this.footerView(this.props.details.status)
                                    }
                                </>}
                            </Col>
                        </Row>
                    </ModalComponent.Footer>
                </ModalComponent>
            }
        </>
    }
}