import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { BiHide, BiShow } from "react-icons/bi";
import { MdOutlineContentCopy } from "react-icons/md";
import OrderProductStatusBadge, { OrderProductStatusEnum } from "../../Enums/OrderProductStatusEnum";
import HybridMoment from "../../../utilities/HybridMoment";
import OrderReviewRequest from "../../OrderReviewRequest/OrderReviewRequest";

interface OrderItemView {
    deliveredAt: string
    reviewRequestSupportTicketId: string
    id: string
    informations: string
    isSold: boolean
    orderType: number
    status: number
    title: string
}

interface OrderItemsState { }

interface OrderItemsProps {
    items: OrderItemView[];
    isSafeMode: boolean
    changeSafeMode: () => void
    loadTable: () => void
}

export default class OrderItemsTableView extends React.Component<OrderItemsProps, OrderItemsState> {
    render(): React.ReactNode {
        return (<TableContainer className="grid mt-lg">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">#</TableCell>
                        <TableCell align="center">Title</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center">Information</TableCell>
                        <TableCell align="center">Delivery Time</TableCell>
                        <TableCell align="center">Show</TableCell>
                        <TableCell align="center">Report</TableCell>
                        <TableCell align="center">Copy</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        this.props.items.map((item, index) =>
                            <TableRow>
                                <TableCell align="center">{index + 1}</TableCell>
                                <TableCell align="center">{item.title}</TableCell>
                                <TableCell align="center">{OrderProductStatusBadge(item.status)}</TableCell>
                                <TableCell align="center">{item.informations && this.informationNormalize(item.informations)}</TableCell>
                                <TableCell align="center">{item.deliveredAt && HybridMoment(item.deliveredAt)}</TableCell>
                                <TableCell align="center">
                                    {(this.props.isSafeMode ?
                                        <BiHide size={22} className="pointer text-grey" onClick={this.props.changeSafeMode} />
                                        : <BiShow size={22} className="pointer text-grey" onClick={this.props.changeSafeMode} />
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                    <OrderReviewRequest orderItemId={item.id} reviewRequestSupportTicketId={item.reviewRequestSupportTicketId}
                                        status={item.status as OrderProductStatusEnum}
                                        onReported={() => { this.props.loadTable() }} />
                                </TableCell>
                                <TableCell align="center">
                                    <MdOutlineContentCopy size={22} className="pointer text-grey" onClick={this.props.changeSafeMode} />
                                </TableCell>
                            </TableRow>)}
                </TableBody>
            </Table >
        </TableContainer>);
    }

    informationNormalize(information: string) {
        information = information.replace('["', '').replace('"]', '').replace('","', ' , ');
        return information.indexOf(',') >= 0 ? information.split(',').map((info) => <span>{info}<br /></span>) : information;
    }
}