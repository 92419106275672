import React, { Component, RefObject } from "react";
import { Col, Row } from "react-bootstrap";
import OrdersService, { SellerOrderDetailsDTO, SellerOrderProductDetailsRowDTO } from "../../../../../services/orders/OrdersService";
import Moment from 'moment';
import orderStatusDisplayBadge, { orderDetailsProductStatusDisplayBadge } from "../../../OrderEnums";
import Amount from "../../../../utilities/Amount/Amount";
import GridComponent from "../../../../Grid/Grid";
import { GridAction, GridColumn, GridColumnTypes, GridResult } from "../../../../Grid/Grid.models";
import OrderProductStatusBadge, { } from "../../../Enums/OrderProductStatusEnum";
import ModalComponent from "../../../../shared/modal/Modal";
import SpinnerComponent from "../../../../shared/spinner/SpinnerComponent";
import { CustomButton } from "../../../../shared/button/ButtonComponent";
import { Grid } from "@mui/material";

interface IProps {
    orderId: string,
    onClose: () => void
}

interface IState {
    details: SellerOrderDetailsDTO,
    pageNumber: number,
    productDetail: GridResult<SellerOrderProductDetailsRowDTO>,
    productDetailTitle: string
    isLoading: boolean
}

export default class SellOrderDetails extends Component<IProps, IState> {

    grid: RefObject<GridComponent>
    productGrid: RefObject<GridComponent>
    ordersService: OrdersService
    constructor(props: any) {
        super(props);
        this.ordersService = new OrdersService();
        this.grid = React.createRef();
        this.productGrid = React.createRef();
        this.state = {
            isLoading: true,
            details: { buyerId: '', buyerTitle: '', orderDate: new Date(), orderNumber: '', products: [], status: 0, totalAmount: 0, totalCashoutAmount: 0, totalRefund: 0, totalShare: 0 },
            pageNumber: 1,
            productDetail: { rows: [], totalRows: 0 },
            productDetailTitle: ''
        }
    }

    componentDidMount(): void {
        this.ordersService.getSellOrderDetails(this.props.orderId).then(response => {
            this.setState({ details: response, isLoading: false });

            let newRows = response.products.map((row) => {
                return { ...row, isComplete: orderDetailsProductStatusDisplayBadge(row.isComplete) }
            });
            this.grid.current?.load(newRows, newRows.length)
            this.grid.current?.resetSpinner();
        })
    }

    loadProductDetails = (row: any) => {
        this.ordersService.getSellOrderDetailProductInfo({ pagesize: 10, pageskip: 1, orderId: this.props.orderId, productId: row.productId }).then(response => {
            this.setState({ productDetail: response, productDetailTitle: row.title });

            let newRows = response.rows.map((row) => {
                return { ...row, statusBadge: OrderProductStatusBadge(row.status) }
            });
            this.productGrid.current?.load(newRows, response.totalRows)
            this.productGrid.current?.resetSpinner();
        })
    }


    changePage = (page: number, row?: any) => {
        this.setState({ pageNumber: page });

        if (page === 2)
            this.loadProductDetails(row);

        this.grid.current?.resetSpinner();
        this.productGrid.current?.resetSpinner();
    }

    render(): React.ReactNode {
        const { details, pageNumber } = this.state

        return this.state.isLoading ? <ModalComponent
            size="1020px"
            onClose={this.props.onClose}
        >
            <div>
                <SpinnerComponent />
            </div>
        </ModalComponent>
            :
            <ModalComponent
                size="1020px"
                onClose={this.props.onClose}
                title={`Order Details (${details.orderNumber} - ${details.buyerTitle})`}
            >
                <div>
                    {
                        (pageNumber === 1 ?
                            <>
                                {this.detailsView(details)}
                            </>
                            : <>

                            </>)
                    }
                    {details.products.length > 0 && this.gridView(pageNumber === 1)}
                    {this.productGridView(pageNumber === 2)}
                </div>
                {
                    (pageNumber === 1 ? <></>
                        : <div>
                            <CustomButton size="small" variant="text" color="warning" onClick={() => this.changePage(1, null)}>Back To Order</CustomButton>
                        </div>)
                }
            </ModalComponent>
    }

    detailsView = (details: SellerOrderDetailsDTO) => {
        const totalCashAmountAfterRefund = details.totalCashoutAmount - details.totalRefund;
        return <>
            <Grid container>
                {this.colView("Order Number", details.orderNumber)}
                {this.colView("Order Date", Moment(details.orderDate).utc().format('YYYY/MM/DD HH:mm'))}
            </Grid>
            <Grid container>
                {this.colView("Status", orderStatusDisplayBadge(details.status))}
                {this.colView("Buyer Name", details.buyerTitle)}
            </Grid>
            <Grid container>
                {this.colView("Total Amount", <Amount formatMoney={details.totalAmount > 0} value={details.totalAmount} />)}
                {this.colView("Total Share Amount", <Amount formatMoney={details.totalShare > 0} value={details.totalShare} />)}
            </Grid>
            <Grid container>
                {this.colView("Total Cashout Amount", <Amount formatMoney={details.totalCashoutAmount > 0} value={details.totalCashoutAmount} />)}
                {this.colView("Total Refund Amount", <Amount formatMoney={details.totalRefund > 0} value={details.totalRefund} />)}
            </Grid>
            <Grid container>
                {this.colView("Total Cashout Amount (After Refund)", <Amount formatMoney={totalCashAmountAfterRefund > 0} value={totalCashAmountAfterRefund} />)}
            </Grid>
        </>
    }

    colView = (title: string, value: any) => {
        return <Grid item sm={12} md={6}>
            <span className="text-pale-grey-main font-medium">{title}:</span>
            <span className="font-bold pl-xs">{value}</span>
        </Grid>
    }

    gridView = (show: boolean) => {
        let gridColumns: GridColumn[] = [
            { title: 'ProductTitle', key: 'title' },
            { title: 'Unit Price', key: 'unitPrice', type: GridColumnTypes.Amount },
            { title: 'Count', key: 'count' },
            { title: 'Amount', key: 'amount', type: GridColumnTypes.Amount },
            { title: 'Share', key: 'shareAmount', type: GridColumnTypes.Amount },
            { title: 'Cashout', key: 'cashoutAmount', type: GridColumnTypes.Amount },
            { title: 'Refunded', key: 'refundCount' },
            { title: 'Pending', key: 'pendingCount' },
            { title: 'Delivered', key: 'deliveredCount' },
            { title: 'Status', key: 'isComplete' },
        ]
        let gridActions: GridAction[] = [
            {
                caption: 'Details', variant: 'outline-info', onClick: (row) => this.changePage(2, row)
            }

        ]
        return (<div className="pt-md" style={show ? {} : { display: "none" }}>
            <span className="font-bold font-md">Products</span>
            <GridComponent ref={this.grid} columns={gridColumns} actions={gridActions}></GridComponent>
        </div>)
    }

    productGridView = (show: boolean) => {
        let gridColumns: GridColumn[] = [
            { title: 'Informations', key: 'informations' },
            { title: 'Delivery Date', key: 'deliveryAt', type: GridColumnTypes.Date },
            { title: 'Status', key: 'statusBadge' },
        ]
        let gridActions: GridAction[] = [
        ]

        return (<div style={show ? {} : { display: "none" }}>
            <h3>Product Details</h3>
            <GridComponent ref={this.productGrid} columns={gridColumns} actions={gridActions}></GridComponent>
        </div>)
    }
}