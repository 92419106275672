import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Bag, CodeSlash, GearWideConnected, QuestionCircle, Shop, Stack } from 'react-bootstrap-icons';
import { API_CONFIG_MENU, FULL_ACCESS, HELP_CENTER_MENU, ORDER_MENU } from '../../../../constants/ConstantsPermissions';
import ShopAuthenticated from '../../../../components/shared/ShopAuthenticated/ShopAuthenticated';
import { NavLink } from 'react-router-dom';
import AuthenticationContext from '../../../../contexts/AuthenticationContext/AuthenticationContext';
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import './NavMenu.scss'
import { IoIosMenu } from "react-icons/io";

export interface IMenu {
    url: string,
    icon: any,
    label: string,
    permission: string | null
    policy?: string[]
}

export default function NavMenu() {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [currentRoute, setCurrentRoute] = React.useState(window.location.pathname);

    const context = React.useContext(AuthenticationContext)

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElNav(event.currentTarget);

    const handleCloseNavMenu = () => setAnchorElNav(null);

    const renderNavItem = (item) => {
        return (
            <MenuItem onClick={handleCloseNavMenu}>
                <NavLink to={item.url}>
                    <Typography sx={{ textAlign: 'center' }}>{item.label}</Typography>
                </NavLink>
            </MenuItem>
        )
    }

    const renderNavItemWeb = (item) => {
        return (
            <ListItem sx={{ pt: 0, pb: 0, pr: 0, pl: 0 }} key={item.label}>
                {currentRoute.includes(item.url) && <div className='active-menu-line'></div>}
                <NavLink to={item.url}>
                    <ListItemButton disableTouchRipple onClick={() => { setCurrentRoute(item.url) }} sx={{ textAlign: 'center' }}>
                        <ListItemText className={currentRoute.includes(item.url) ? 'active' : ''} sx={{ color: 'pale_grey.main' }}>
                            <span className="menu-label font-medium text-nowrap">{item.label}</span>
                        </ListItemText>
                    </ListItemButton>
                </NavLink>
            </ListItem>
        )
    }

    const items: IMenu[] = [
        { icon: <Shop />, label: 'Merket Place', url: '/marketplace', permission: null },
        { icon: <Bag />, label: 'Orders', url: '/orders/buy', permission: ORDER_MENU },
        { icon: <Stack />, label: 'Inventory', url: '/inventory', permission: null },
        { icon: <GearWideConnected />, label: 'Management', url: '/management', permission: FULL_ACCESS },
        { icon: <CodeSlash />, label: 'API', url: '/rest-config', permission: API_CONFIG_MENU },
        { icon: <QuestionCircle />, label: 'Help Center', url: '/helpCenter', permission: HELP_CENTER_MENU },
    ]

    return (
        <div>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <List sx={{ display: 'flex', pt: 0, pb: 0 }}>
                    {
                        items.map((item) => {
                            var allowPolicy = false;

                            if (item.policy)
                                for (var policy of item.policy) {
                                    allowPolicy = context.policies?.includes(policy);
                                    if (allowPolicy)
                                        break;
                                }
                            return <>
                                {
                                    (
                                        item.permission !== null ?
                                            <ShopAuthenticated permission={item.permission as string}>
                                                {item.policy && item.policy.length > 0 ? (allowPolicy && renderNavItemWeb(item)) : renderNavItemWeb(item)}
                                            </ShopAuthenticated>
                                            : item.policy && item.policy.length > 0 ? (allowPolicy && renderNavItemWeb(item)) : renderNavItemWeb(item)

                                    )
                                }
                            </>
                        })
                    }
                </List>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                >
                    <IoIosMenu color='#4F4F4F' />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{ display: { xs: 'block', md: 'none' } }}
                >
                    {
                        items.map((item, index) => {
                            var allowPolicy = false;

                            if (item.policy)
                                for (var policy of item.policy) {
                                    allowPolicy = context.policies?.includes(policy);
                                    if (allowPolicy)
                                        break;
                                }
                            return <div key={index}>
                                {
                                    (
                                        item.permission !== null ?
                                            <ShopAuthenticated permission={item.permission as string}>
                                                {item.policy && item.policy.length > 0 ? (allowPolicy && renderNavItem(item)) : renderNavItem(item)}
                                            </ShopAuthenticated>
                                            : item.policy && item.policy.length > 0 ? (allowPolicy && renderNavItem(item)) : renderNavItem(item)

                                    )
                                }
                            </div>
                        })
                    }
                </Menu>
            </Box>
        </div>
    );
}