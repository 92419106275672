import React, { Component } from "react";
import MenuItem from "./MenuItem/MenuItem";
import './CategorySidebar.scss'
import { Placeholder } from "react-bootstrap";
import ProductCatalogService, { ProductCategoryTitleView } from "../../../../services/catalog/ProductCatalogService";
import LayoutContext from "../../../../contexts/LayoutContext/LayoutContext";
import { ReactComponent as HomeIcon } from '../../../../assets/icons/categories/home.svg';

export interface SidebarItemView {
    title: string;
    parentId?: string;
    id: string;
    isActive: boolean;
    isFavorite: boolean;
    subMenu: SidebarItemView[];
    icon: any;
}

export interface IState {
    menuItems: SidebarItemView[],
    newRequestProductModal: boolean,
}

let homeMenuItem: ProductCategoryTitleView = {
    id: null,
    title: 'Home',
    parentId: null,
    icon: <HomeIcon />
}

export default class CategorySidebar extends Component<{
    selectedCategoryId?: string, categories: ProductCategoryTitleView[], onNavigate?: (id: string) => void,
    fixMargin?: boolean, catalogMode: 'express' | 'trade' | 'api'
}, IState> {
    productCatalogService: ProductCatalogService
    static contextType = LayoutContext;
    context!: React.ContextType<typeof LayoutContext>;
    constructor(props: any) {
        super(props);
        this.state = { menuItems: [], newRequestProductModal: false };
        this.productCatalogService = new ProductCatalogService()
    }

    componentDidUpdate(prevProps: Readonly<{ selectedCategoryId?: string, categories: ProductCategoryTitleView[]; onNavigate?: (id: string) => void; fixMargin?: boolean; }>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.categories !== this.props.categories || prevProps.selectedCategoryId !== this.props.selectedCategoryId)
            this.menuInitial([homeMenuItem, ...this.props.categories])

    }

    componentDidMount() {
        this.menuInitial([homeMenuItem, ...this.props.categories])
    }

    menuInitial = (categories: any[]) => {
        let context = this;
        let menuItems = categories.map((category) => {
            return {
                id: category.id,
                title: category.title,
                parentId: category.parentId,
                isActive: context.props.selectedCategoryId === category.id,
                subMenu: [],
                isFavorite: false,
                icon: category.icon
            } as SidebarItemView;
        });

        let masterMenus = menuItems.filter(item => item.parentId === null)
        masterMenus.map(masterMenu => context.setSubMenu(masterMenu, menuItems));
        masterMenus.map(masterMenu => masterMenu.subMenu.map(subMenu => context.setSubMenu(subMenu, menuItems)));

        context.setState({ menuItems: masterMenus })
    }

    setSubMenu(master: SidebarItemView, allMenuItems: SidebarItemView[]) {
        master.subMenu = allMenuItems.filter(menuItem => menuItem.parentId === master.id);
    }

    handleClickMenuItem = (id: string) => {
        this.closeAll(id);
        this.setState(prevState => {
            return {
                menuItems: prevState.menuItems
                    .map(item => { return item.id === id ? { ...item, isActive: true } : item })
            }
        });
    }

    closeAll = (exceptedId: string) => {
        this.setState(prevState => {
            return {
                menuItems: prevState.menuItems
                    .map(item => { return item.id === exceptedId ? item : { ...item, isActive: false } })
            }
        });
    }

    render(): React.ReactNode {
        let menuItems = this.state.menuItems.sort(function (x, y) { return Number(x.isFavorite) - Number(y.isFavorite) })
        return <div className={(this.props.fixMargin === true ? "fix-margin" : "")}>
            {
                this.props.categories.length === 0 ? ([...Array(11)].map((e, i) => <Placeholder animation="glow" key={i}>
                    <Placeholder style={{ height: '55px', borderRadius: '16px', width: '100%', marginBottom: '1vh' }} />
                </Placeholder>)) :
                    <>
                        {
                            menuItems.map((category) => {
                                return (<MenuItem icon={category.icon}
                                    key={category.id} title={category.title} id={category.id} onNavigate={() => this.props.onNavigate(category.id)} catalogMode={this.props.catalogMode}
                                    subItems={category.subMenu} isSelected={category.isActive} onClick={() => this.handleClickMenuItem(category.id)} />);
                            })
                        }
                    </>
            }
        </div >;
    }
}