import { useSearchParams } from "react-router-dom";
import Payment from "../../components/financials/payment/Payment";

export default function PaymentRoute() {
    const [searchParams] = useSearchParams()
    let type: 'purchase' | 'walletTopUp' | null | undefined = searchParams.get("type") as 'purchase' | 'walletTopUp' | null | undefined
    let invoiceId = searchParams.get("invoiceId")

    return <Payment type={type ?? "walletTopUp"} invoiceId={invoiceId} />
}
