import React from "react"
import './MainLayout.scss'
import { Outlet } from "react-router-dom"
import Header from "./header/Header"
import LayoutContext from "../../contexts/LayoutContext/LayoutContext"
import { Subject } from "rxjs"
import axios from "axios"
import IdentityService from "../../services/identity/IdentityService"
import ShopService from "../../services/shops/ShopService"
import AuthenticationContext from "../../contexts/AuthenticationContext/AuthenticationContext"
import SuspendedMode from "../../components/shared/suspended-mode/SuspendedMode"
import '../../assets/css/table.scss'
import BottomNavigationComponent from "./mobile/bottom-navigation/BottomNavigation"
import { Box } from "@mui/material"
import DeviceResponsiveHelper from "../../components/utilities/responsive-helper/DeviceResponsiveHelper"
import FullScreenLoading from "../../components/shared/full-screen-loading/FullScreenLoading"

export default class MainLayout extends React.Component<{ showLogo?: boolean, showBottomNavigation?: boolean }, { progressPercent: number, suspendedMode: boolean }> {
    identityService: IdentityService;
    shopService: ShopService
    static contextType = AuthenticationContext;
    context!: React.ContextType<typeof AuthenticationContext>;

    constructor(props: any) {
        super(props);
        this.identityService = new IdentityService();
        this.shopService = new ShopService();
        this.state = { suspendedMode: false, progressPercent: 30 };
    }

    componentDidMount() {
        this.identityService.getUserInformation().then((response) => {
            this.context.twoFactorAvtive = response.data.hasTwoFactor
            this.context.privileges = response.data.privileges
            this.context.roles = response.data.roles
            this.context.user = { displayName: response.data.displayName, userName: response.data.userName }

            this.setState({ progressPercent: 50 })
            this.loadShops()
        }).catch(() => { this.setState({ progressPercent: 100 }) }).finally(() => { this.forceUpdate() })
    }

    loadShops() {
        this.shopService.getTitles().then((shops) => {
            if (shops.length === 0) {
                this.setState({ progressPercent: 100 });
                return;
            }

            let activeshopId = localStorage.getItem('activeshop');
            if ((activeshopId === undefined || activeshopId === null || activeshopId === ''
                || shops.filter(shop => shop.value == activeshopId).length === 0)) {
                activeshopId = shops[0].value;
                this.setShop(activeshopId);
            }
            this.setState({ progressPercent: 90 });

            this.shopService.getOverview().then((overview) => {
                if (overview.isSuspended === true)
                    this.setState({ suspendedMode: true });

                this.context.policies = overview.policies;
                this.setState({ progressPercent: 100 });
            })
        })
    }

    setShop(id: string) {
        axios.defaults.headers.common['activeshop'] = `${id}`;
        localStorage.setItem('activeshop', id);
    }

    render(): React.ReactNode {
        if (this.state.progressPercent < 100)
            return <FullScreenLoading progressPercent={this.state.progressPercent} />

        return (
            <LayoutContext.Provider value={{ onAddToCartSubject: new Subject(), loadCart: () => { }, onReviewRequestChangedStatus: () => { }, reloadSideBar: () => { } }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <Header showLogo={this.props.showLogo} />
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            overflowY: 'auto',
                            pb: '15vh'
                        }}
                    >
                        {
                            this.state.suspendedMode && this.context.roles.filter(role => role == 'ADMIN').length == 0 ? <SuspendedMode /> : <>
                                <Outlet></Outlet>
                            </>
                        }
                    </Box>
                    {(this.props.showBottomNavigation) && <DeviceResponsiveHelper mobile={<BottomNavigationComponent />} desktop={<></>} />}
                </Box>
            </LayoutContext.Provider>
        )
    }
}