import React, { FormEvent, RefObject } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { NotificationManager } from 'react-notifications';
import Uploader from "../../../../shared/Uploader/Uploader";
import { CustomButton } from "../../../../shared/button/ButtonComponent";
import { CircularProgress } from "@mui/material";

export default class SupportTicketAddReply extends React.Component<{ hideUploader?: boolean, onAddReply: (text: string, attachmentNames?: string[]) => Promise<void>, draftText: boolean, draftId?: string }, {
    text: string,
    attachmentNames?: string[], loading: boolean
}> {
    uploader: RefObject<Uploader>
    constructor(props: any) {
        super(props);
        this.state = { text: '', loading: false, attachmentNames: [] };
    }
    componentDidMount() {
        var text = localStorage.getItem('support-ticket-draft' + (this.props.draftId ?? ""));
        if (text !== undefined && text !== null && (this.props.draftText === true || (this.props.draftId !== undefined && this.props.draftId !== null)))
            this.setState({ text })
    }
    onChangeText = (text: string) => {
        if (this.props.draftText === true || (this.props.draftId !== undefined && this.props.draftId !== null))
            localStorage.setItem('support-ticket-draft' + (this.props.draftId ?? ""), text);
        this.setState({ text: text });
    }
    reply = (ignoreText: boolean) => {
        this.setState({ loading: true });

        if ((this.state.text === null || this.state.text === '')) {
            if (!ignoreText)
                NotificationManager.error('You have to write something first');
            this.setState({ loading: false })
            return;
        }

        this.props.onAddReply(this.state.text, this.state.attachmentNames).then(() => {
            NotificationManager.success('Your reply added successfully');
            if (this.props.draftText === true || (this.props.draftId !== undefined && this.props.draftId !== null))
                localStorage.removeItem('support-ticket-draft' + (this.props.draftId ?? ""));
            this.setState({ text: '', attachmentNames: [] });
        }).finally(() => {
            this.setState({ loading: false })
        });
    }

    handleUpload = (filename: string) => {
        this.setState({ attachmentNames: [...this.state.attachmentNames, filename] })
    }

    handleRemoved = (filename: string) => {
        let temp = this.state.attachmentNames
        temp.splice(temp.indexOf(filename), 1);
        this.setState({ attachmentNames: temp })
    }

    render(): React.ReactNode {
        return <>
            <label htmlFor="supportTicketReply.text"></label>
            <Row>
                <Col md="12" sm="12">
                    <textarea rows={3}
                        value={this.state.text}
                        id="supportTicketReply.text"
                        className="form-control"
                        placeholder="Type your text here..."
                        onChange={(e) => this.onChangeText(e.target.value)} />
                </Col>
                {
                    !this.props.hideUploader && <Col md="4" sm="12">
                        <div className="mt-2">
                            <Uploader
                                ref={this.uploader} multiple folderName={"tickets"}
                                onRemovd={this.handleRemoved}
                                onUpload={this.handleUpload} imageAddress={""} />
                        </div>
                    </Col>
                }
            </Row>
            <div className="flex justify-end">
                {
                    this.state.loading ? <CustomButton variant="contained" className="mt-2 float-right" disabled><CircularProgress size={20} color="inherit" /></CustomButton>
                        : <CustomButton variant="contained" className="mt-3 me-3 float-right" onClick={() => this.reply(false)}>Send</CustomButton>
                }
            </div>
        </>
    }
}