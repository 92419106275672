import { Route } from "react-router-dom";
import WebSiteLayout from "../../website/layout/DefaultLayout/DefaultLayout";
import AboutUs from "../../website/content/AboutUs/AboutUs";
import ContactUs from "../../website/content/ContactUs/ContactUs";
import PrivacyPolicy from "../../website/content/PrivacyPolicy/PrivacyPolicy";
import NotFoundRoute from "../shared/NotFoundRoute";
import IndexRoute from "../website/IndexRoute";
import RegisterConfirmed from "../../components/identity/RegisterConfirmed/RegisterConfirmed";
import ForgotPasswordRoute from "../identity/ForgotPasswordRoute";
import ResetPasswordRoute from "../identity/ResetPasswordRoute";
import IdentityLayout from "../../layouts/identity/IdentityLayout";
import SignIn from "../../components/identity/sign-in/SignIn";
import SignUp from "../../components/identity/sign-up/SignUp";
import MainLayout from "../../layouts/main-layout/MainLayout";

export default function BaseRoutes() {
    return (
        <>
            <Route element={<WebSiteLayout />}>
                <Route index element={<IndexRoute />}></Route>
                <Route path="/about-us" element={<AboutUs />}></Route>
                <Route path="/registerConfirmed" element={<RegisterConfirmed />} />
                <Route path="/reset-password" element={<ResetPasswordRoute />} />
                <Route path="/policy" element={<PrivacyPolicy />}></Route>
                <Route path="/contact-us" element={<ContactUs />}></Route>
            </Route>
            <Route element={<IdentityLayout title="Login" />}>
                <Route path="/sign-in" element={<SignIn />} />
            </Route>
            <Route element={<IdentityLayout title="Register" />}>
                <Route path="/sign-up" element={<SignUp />} />
            </Route>
            <Route element={<IdentityLayout title="Forgot Password" />}>
                <Route path="/forgot-password" element={<ForgotPasswordRoute />} />
            </Route>
            <Route element={<MainLayout showLogo/>}>
                <Route path="*" element={<NotFoundRoute />} />
            </Route>
        </>
    )
}