import React from "react"
import { Dropdown } from "react-bootstrap"
import { useNavigate, NavLink } from "react-router-dom"
import './Profile.scss'
import authenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext"
import { ReactComponent as ProfileIcon } from '../../../assets/icons/content/profile.svg'

const CustomToggle = React.forwardRef((props: any, ref) => (
    <div className="dropdown-custom" onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
    }}>{props.children}</div>
))

const CustomMenu = React.forwardRef((props: any, ref: any) => {
    return (
        <div ref={ref} aria-labelledby={props.labeledBy} style={props.style} className={props.className}>
            {props.children}
        </div>
    )
});

export interface IMenuItems {
    styles?: string, label: string, link?: string
}

export default function Profile() {
    const context = React.useContext(authenticationContext)
    let navigate = useNavigate()

    let items: IMenuItems[] = [
        { label: 'Edit Profile', link: '/user-profile' },
        { label: 'Add New Shop', link: '/new-shops' },
        { label: 'Security', link: '/security' },
    ]

    const logout = () => {
        context.logout();
        navigate("/sign-in")
    }

    return (
        <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                <div className="text-charcoal flex items-center bg-white radius-sm py-m header-box">
                    <ProfileIcon />
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="profile-dropdown" as={CustomMenu}>
                <div className="main-info font-bold">
                    <span>{context.user.displayName}</span>
                    <span>{context.user.userName}</span>
                </div>
                <div className="clear"></div>
                <div className="footer-actions">
                    {(items && items?.length > 0) &&
                        items?.map((item, index) => {
                            return <Dropdown.Item key={index} eventKey={index} className={item.styles}>
                                <span className={`warm-grey-text ${item.styles}`}>
                                    {item.link ? <NavLink to={item.link} className="" >{item.label}</NavLink> : item.label}
                                </span>
                            </Dropdown.Item>
                        })
                    }
                    <Dropdown.Item>
                        <a onClick={() => logout()}>Logout</a>
                    </Dropdown.Item>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}