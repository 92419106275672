import React, { Component } from "react";
import { Placeholder } from "react-bootstrap";
import ProductCatalogService, { ProductCategoryTitleView } from "../../../../services/catalog/ProductCatalogService";
import LayoutContext from "../../../../contexts/LayoutContext/LayoutContext";
import { ReactComponent as HomeIcon } from '../../../../assets/icons/categories/home.svg';
import { NavLink } from "react-router-dom";
import './MobileCategories.scss';
import { Box } from "@mui/material";

export interface SidebarItemView {
    title: string;
    parentId?: string;
    id: string;
    isActive: boolean;
    isFavorite: boolean;
    subMenu: SidebarItemView[];
    icon: any;
}

export interface IState {
    menuItems: SidebarItemView[],
    newRequestProductModal: boolean,
}

let homeMenuItem: ProductCategoryTitleView = {
    id: null,
    title: 'Home',
    parentId: null,
    icon: <HomeIcon />
}

export default class MobileCategories extends Component<{
    selectedCategoryId?: string, categories: ProductCategoryTitleView[], onNavigate?: (id: string) => void,
    catalogMode: 'express' | 'trade' | 'api'
}, IState> {
    productCatalogService: ProductCatalogService
    static contextType = LayoutContext;
    context!: React.ContextType<typeof LayoutContext>;
    constructor(props: any) {
        super(props);
        this.state = { menuItems: [], newRequestProductModal: false };
        this.productCatalogService = new ProductCatalogService()
    }

    componentDidUpdate(prevProps: Readonly<{ categories: ProductCategoryTitleView[]; onNavigate?: (id: string) => void; fixMargin?: boolean; }>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.categories != this.props.categories)
            this.menuInitial([homeMenuItem, ...this.props.categories])
    }

    componentDidMount() {
        this.menuInitial([homeMenuItem, ...this.props.categories])
    }

    menuInitial = (categories: any[]) => {
        let context = this;
        let menuItems = categories.map((category) => {
            return {
                id: category.id,
                title: category.title,
                parentId: category.parentId,
                isActive: context.props.selectedCategoryId == category.id,
                subMenu: [],
                isFavorite: false,
                icon: category.icon
            } as SidebarItemView;
        });

        let masterMenus = menuItems.filter(item => item.parentId === null)
        masterMenus.map(masterMenu => context.setSubMenu(masterMenu, menuItems));
        masterMenus.map(masterMenu => masterMenu.subMenu.map(subMenu => context.setSubMenu(subMenu, menuItems)));

        context.setState({ menuItems: masterMenus })
    }

    setSubMenu(master: SidebarItemView, allMenuItems: SidebarItemView[]) {
        master.subMenu = allMenuItems.filter(menuItem => menuItem.parentId === master.id);
    }

    handleClickMenuItem = (id: string) => {
        this.setState(prevState => {
            return {
                menuItems: prevState.menuItems
                    .map(item => { return item.id === id ? { ...item, isActive: true } : { ...item, isActive: false } })
            }
        });
    }

    render(): React.ReactNode {
        let menuItems = this.state.menuItems.sort(function (x, y) { return Number(x.isFavorite) - Number(y.isFavorite) })

        return <div className="mobile-categories">
            {
                <Box pl={1.6} className="flex horizontal-scrolling">
                    {
                        this.props.categories.length === 0 ?
                            ([...Array(11)].map((e, i) => <Placeholder animation="glow" key={i}>
                                <Placeholder style={{ height: '44px', borderRadius: '12px', width: '100px', marginRight: '8px' }} />
                            </Placeholder>))
                            :

                            menuItems.map((category, key) => {
                                return (<Box key={key} className={`${category.isActive && ' active '} category-item flex justify-center items-center `} onClick={() => this.handleClickMenuItem(category.id)}>
                                    <NavLink to={category.id ? `/marketplace?parentCategoryId=${category.id}` : '/marketplace'}>
                                        <span className="font-main text-grey-dark flex items-center">
                                            {category.icon}
                                            <span>{category.title}</span>
                                        </span>
                                    </NavLink>
                                </Box>);
                            })
                    }
                </Box>
            }
        </div >;
    }
}