import React from "react";
import { Placeholder } from "react-bootstrap";
import OrderProductService, { IExpressProductCatalogDto } from "../../../../services/orders/OrderProductService";
import ProductCatalogItem from "./ExpressProductCatalogItem/ExpressProductCatalogItem";
import { Grid } from "@mui/material";
import './ExpressProductCatalog.scss'

interface IProps {
    categoryId?: string
    categoryTitle?: string
    productId?: string
    search?: string
    generalProductImage: string
    onClickBuy: (productId: string, title: string) => void
    onClickSell: (productId: string) => void
}

export default class ExpressProductCatalog extends React.Component<IProps, { isLoading: boolean, items: IExpressProductCatalogDto[] }> {
    orderProductService: OrderProductService
    constructor(props: any) {
        super(props);
        this.orderProductService = new OrderProductService()
        this.state = { isLoading: false, items: [] }
    }

    componentDidMount(): void {
        this.loadCatalog();
    }


    componentDidUpdate(prevProps: IProps) {
        if ((prevProps.categoryId !== this.props.categoryId) || (prevProps.search !== this.props.search) || (prevProps.productId !== this.props.productId))
            this.loadCatalog();
    }

    loadCatalog = () => {
        this.setState({ isLoading: true });
        this.orderProductService.getExpressCatalog({
            productCategoryId: this.props.categoryId,
            productTitleSearch: this.props.search,
            productId: this.props.productId,
        }).then((response) => {
            this.setState({
                isLoading: false,
                items: response.products
            })
        });
    }

    render(): React.ReactNode {
        return <>
            <Grid container spacing={1.6} px={1.6}>
                {this.state.isLoading ?
                    Array.from(Array(12), (e, i) => <Grid item key={i} sm={6} xs={6} lg={3} md={3} pb={1}>
                        <Placeholder animation="glow">
                            <Placeholder className={"product-catalog-item"} xs={12} />
                        </Placeholder>
                    </Grid>)
                    :
                    this.state.items.map((item) => <Grid item key={item.id} sm={6} xs={6} lg={3} md={3} pb={1}>
                        <ProductCatalogItem title={item.title}
                            onClickBuy={() => this.props.onClickBuy(item.id, item.title)}
                            onClickSell={() => this.props.onClickSell(item.id)}
                            imageSrc={this.props.generalProductImage}
                            imagePath={item.imageSrc}
                            currencyId={item.currencyId}
                            productId={item.id} realPrice={item.realPrice} key={item.id} />
                    </Grid>)
                }
            </Grid>
        </>
    }
}