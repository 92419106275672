import React from "react";
import { Form, Table } from "react-bootstrap";
import { Navigate, NavLink } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import UserPrivilegeService from "../../../services/identity/UserPrivilegeService";
import IState, { IEditUserPrivilegeDTO, IProps, IUserPrivilegesRowDTO } from "./UserPrivilege.models"
import { CircularProgress, Container, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { CustomButton } from "../../shared/button/ButtonComponent";
import SpinnerComponent from "../../shared/spinner/SpinnerComponent";

export default class UserPrivilege extends React.PureComponent<IProps, IState> {
    userPrivilegeService: UserPrivilegeService
    constructor(props: IProps) {
        super(props);
        this.state = { privileges: [], loading: false, isSelectedAll: false, pendingRequest: false }
        this.userPrivilegeService = new UserPrivilegeService()
    }

    componentDidMount() {
        this.loadData()
    }

    loadData = async () => {
        this.setState({ loading: true })
        this.userPrivilegeService.getUserPrivileges(this.props.userId).then((response) => {
            this.setState({ loading: false, privileges: response, isSelectedAll: response.filter(item => !item.hasPrivilege).length > 0 ? false : true },)
        })
    }

    updatePrivileges = () => {
        this.setState({ pendingRequest: true })

        let dto: IEditUserPrivilegeDTO[] = this.state.privileges.map((privilege) => {
            return {
                isSelected: privilege.hasPrivilege,
                privilegeId: privilege.id
            } as IEditUserPrivilegeDTO
        })

        this.userPrivilegeService.updatePrivilege(dto, this.props.userId).then(() => {
            NotificationManager.success('User privilege updates successfully.')
            return <Navigate to={'/management'} />
        }).finally(() => {
            this.setState({ pendingRequest: false })
        })
    }

    toggleSelectAll = () => {
        let updatedPrivileges = this.state.privileges.map((privilege) => ({
            ...privilege,
            hasPrivilege: !this.state.isSelectedAll,
        }));

        this.setState({ isSelectedAll: !this.state.isSelectedAll, privileges: updatedPrivileges })
    }

    selectRow = (row: IUserPrivilegesRowDTO) => {
        let tmpPrivileges: IUserPrivilegesRowDTO[] = this.state.privileges
        tmpPrivileges.filter((item) => item.id === row.id).map((privilege) => {
            return privilege.hasPrivilege = !privilege.hasPrivilege
        })

        this.setState({ privileges: tmpPrivileges, isSelectedAll: tmpPrivileges.filter(item => !item.hasPrivilege).length > 0 ? false : true })
        this.forceUpdate()
    }

    render(): React.ReactNode {
        return <Container>
            <div className="font-bold font-md pt-md"> User Privilege</ div>
            {this.gridView()}
            < div className="mt-3" > {
                this.state.pendingRequest ? <CustomButton variant='contained' disabled><CircularProgress color="inherit" size={20} /></CustomButton>
                    : <CustomButton variant="contained" onClick={this.updatePrivileges}>Update Privileges</CustomButton>
            }
                <NavLink to={`/management`}>
                    <CustomButton className="m-2" variant="outlined">Back To Shop Members List</CustomButton>
                </NavLink>
            </ div>
        </Container>
    }

    gridView() {
        return (
            <div className="bg-white radius-sm box-shadow mt-md">
                <TableContainer className="simple-table font-bold">
                    <Table aria-label="table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Title</TableCell>
                                <TableCell>Key</TableCell>
                                <TableCell><Form.Check type="checkbox" checked={this.state.isSelectedAll} onChange={() => this.toggleSelectAll()} /></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.state.loading ? (<TableRow><TableCell colSpan={5} align="center"><SpinnerComponent /></TableCell></TableRow>) :
                                    this.state.privileges.map((privilege) =>
                                        <TableRow key={privilege.id}>
                                            <TableCell>{privilege.title}</TableCell>
                                            <TableCell>{privilege.key}</TableCell>
                                            <TableCell><Form.Check type="checkbox" checked={privilege.hasPrivilege} onChange={() => this.selectRow(privilege)} /></TableCell>
                                        </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }
}