import React, { RefObject } from "react";
import { Offcanvas } from "react-bootstrap";
import ProductCatalogService, { ProductCatalogDto, ProductCategoryTitleView } from "../../../services/catalog/ProductCatalogService";
import TradeProductCatalog from "./TradeProductCatalog/TradeProductCatalog";
import ExpressProductCatalog from "./ExpressProductCatalog/ExpressProductCatalog";
import './Catalog.scss'
import CatalogImages from './CatalogImages'
import Sell from "../../inventory/Sell/Sell";
import CategorySidebar from "../../dashboard/Sidebar/CategorySidebar/CategorySidebar";
import OwnedOffersBoard from "../../orders/offers/OwnedOffersBoard/OwnedOffersBoard";
import CartService from "../../../services/shopping/CartService";
import LayoutContext from "../../../contexts/LayoutContext/LayoutContext";
import BuyOffers from "../../orders/offers/BuyOffers/BuyOffers";
import ExpressCategoryCatalog from "../CategoryCatalog/ExpressCategoryCatalog";
import Banners from "../../dashboard/Banners/Banners";
import { Box, Container, Grid, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import Search from "../Search/Search";
import BuyProductCatalogModal from "../BuyProductCatalog/BuyProductCatalogModal";
import MobileBanners from "../../dashboard/mobile/banners/MobileBanners";
import MobileCategories from "../../dashboard/mobile/categories/MobileCategories";
import DeviceResponsiveHelper from "../../utilities/responsive-helper/DeviceResponsiveHelper";

interface ProductCatalogState {
    title: string,
    searchText: string,
    selectedBuyProduct?: { id: string, title: string }
    sellSelectedProductId?: string,
    loading: boolean,
    categoryLoading: boolean,
    showSidebar: boolean
    categories: ProductCategoryTitleView[]
}
interface IProps {
    mode?: 'express' | 'trade',
    categoryId?: string
    parentCategoryId?: string
    productId?: string
    search?: string
    defaultSortKey: string
}

export default class Catalog extends React.Component<IProps, ProductCatalogState> {
    productCatalogService: ProductCatalogService;
    cartService: CartService;
    static contextType = LayoutContext;
    context!: React.ContextType<typeof LayoutContext>;
    ownedOffersBoard: RefObject<OwnedOffersBoard>;
    expressCategoryCatalog: RefObject<ExpressCategoryCatalog>;
    constructor(props: any) {
        super(props);
        this.ownedOffersBoard = React.createRef();
        this.expressCategoryCatalog = React.createRef();
        this.cartService = new CartService();
        this.productCatalogService = new ProductCatalogService();

        this.state = {
            showSidebar: false,
            categoryLoading: false,
            title: '', searchText: '',
            loading: false,
            categories: []
        }
    }


    addToCart = (count: number, productId: string, sellerShopId: string, unitPrice: number): Promise<any> => {
        return this.cartService.add({ count: count, productId: productId, sellerShopId: sellerShopId, unitPrice: unitPrice })
            .finally(() => this.context.loadCart())
    }

    componentDidMount() {
        this.loadCategories();
    }

    loadCategories = () => {
        var self = this;
        self.setState({ categoryLoading: true })

        this.productCatalogService.getProductCategories().then(function (categories) {
            self.setState({ categories });
        }).finally(() => self.setState({ categoryLoading: false }));
    }

    renderTabBar = () => {
        return <Grid container className="menu-bar radius-md">
            <Grid item md={12} sm={12} xs={12} sx={{ height: '100%' }}>
                <div className="tab-bar">
                    <Grid container className="full-height">
                        <Grid item md={6} sm={12} xs={12}>
                            <NavLink to="/marketplace?mode=express"> <div className={this.props.mode === "express" ? "active font-medium li" : "font-medium li"}>Express</div></NavLink>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <NavLink to="/marketplace?mode=trade">  <div className={this.props.mode === "trade" ? "active font-medium li" : "font-medium li"}>Trade</div></NavLink>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    }

    getBestPrice(product: ProductCatalogDto) {
        return product.sellers.length > 0 ?
            Math.min(...product.sellers.map((seller) => seller.plans.length > 0 ? seller.plans[0].price : 0)) : null;
    }

    desktop = () => {
        let { categoryId, parentCategoryId } = this.props

        return <div className="ProductCatalog">
            <Offcanvas show={this.state.showSidebar} onHide={() => this.setState({ showSidebar: false })} responsive="lg">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Categories</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <CategorySidebar onNavigate={() => this.setState({ showSidebar: false })} fixMargin categories={this.state.categories} catalogMode={this.props.mode} />
                </Offcanvas.Body>
            </Offcanvas>
            <div className="catalog-body">
                <div className="categories">
                    <div className="CategorySidebar">
                        {this.renderTabBar()}
                        <CategorySidebar selectedCategoryId={parentCategoryId} categories={this.state.categories} catalogMode={this.props.mode} />
                    </div>
                </div>
                <div className="products">
                    <Container>
                        <Box mb={4}>
                            <Search productId={this.props.productId} search={this.props.search} categoryId={this.props.categoryId} catalogMode={this.props.mode} />
                        </Box>
                        <Box mb={4}><Banners /></Box>
                        {
                            <>
                                {
                                    (categoryId || this.props.search) ?
                                        <>
                                            {
                                                this.props.mode === 'express' ?
                                                    <>
                                                        <Grid item sm={12} md={12} xs={12} px={1} mb={4}>
                                                            <span className='font-lg font-bold'>{this.state.categories.filter(item => item.id == categoryId)[0]?.title} Accounts</span>
                                                        </Grid>
                                                        <ExpressProductCatalog
                                                            categoryId={this.props.categoryId} search={this.props.search} productId={this.props.productId}
                                                            onClickBuy={(productId, title) => this.setState({ selectedBuyProduct: { id: productId, title } })}
                                                            onClickSell={(productId) => this.setState({ sellSelectedProductId: productId })}
                                                            generalProductImage={CatalogImages(this.props.categoryId)} />
                                                    </>
                                                    : <>
                                                        <BuyOffers onClickSell={(productId) => this.setState({ sellSelectedProductId: productId })} categoryId={this.props.categoryId} />
                                                        <OwnedOffersBoard categoryId={this.props.categoryId} ref={this.ownedOffersBoard} />
                                                        <TradeProductCatalog catalogMode={this.props.mode}
                                                            defaultSortKey={this.props.defaultSortKey}
                                                            categoryId={this.props.categoryId} productId={this.props.productId} search={this.props.search}
                                                            onClickBuy={(productId, title) => this.setState({ selectedBuyProduct: { id: productId, title } })}
                                                            onClickSell={(productId) => this.setState({ sellSelectedProductId: productId })}
                                                        />
                                                    </>
                                            }
                                        </>
                                        : <>
                                            {
                                                this.props.mode === 'trade' && !this.props.parentCategoryId ? <>
                                                    <BuyOffers onClickSell={(productId) => this.setState({ sellSelectedProductId: productId })} categoryId={this.props.categoryId} />
                                                    <OwnedOffersBoard categoryId={this.props.categoryId} ref={this.ownedOffersBoard} />
                                                    <TradeProductCatalog catalogMode={this.props.mode}
                                                        defaultSortKey={this.props.defaultSortKey}
                                                        categoryId={this.props.categoryId} productId={this.props.productId} search={this.props.search}
                                                        onClickBuy={(productId, title) => this.setState({ selectedBuyProduct: { id: productId, title } })}
                                                        onClickSell={(productId) => this.setState({ sellSelectedProductId: productId })}
                                                    />
                                                </> :
                                                    <ExpressCategoryCatalog parentId={parentCategoryId} isLoading={this.state.categoryLoading} categories={this.state.categories} mode={this.props.mode} />
                                            }
                                        </>
                                }
                            </>

                        }
                    </Container>
                </div>
            </div>
        </div >
    }

    mobile = () => {
        let { parentCategoryId, categoryId } = this.props

        return <>
            <Box p={1.6}>
                <MobileBanners />
            </Box>
            <Box pt={1.8}><MobileCategories categories={this.state.categories} catalogMode={this.props.mode} /></Box>
            <Box pt={2}>
                {
                    this.props.mode === 'express' ?
                        <>
                            {
                                categoryId ?
                                    <>
                                        <Typography p={1.6}>
                                            <span className='font-md font-bold'>{this.state.categories.filter(item => item.id == categoryId)[0]?.title} Accounts</span>
                                        </Typography>
                                        <ExpressProductCatalog
                                            categoryId={this.props.categoryId} search={this.props.search} productId={this.props.productId}
                                            onClickBuy={(productId, title) => this.setState({ selectedBuyProduct: { id: productId, title } })}
                                            onClickSell={(productId) => this.setState({ sellSelectedProductId: productId })}
                                            generalProductImage={CatalogImages(this.props.categoryId)} />
                                    </>
                                    : <ExpressCategoryCatalog parentId={parentCategoryId} isLoading={this.state.categoryLoading} categories={this.state.categories} />
                            }
                        </> : <></>
                }
            </Box>
        </>
    }

    render(): React.ReactNode {
        return <>
            {this.state.sellSelectedProductId !== undefined && <Sell productId={this.state.sellSelectedProductId} onClose={() => this.setState({ sellSelectedProductId: undefined })} />}
            {(this.state.selectedBuyProduct !== undefined) && <BuyProductCatalogModal
                mode={this.props.mode}
                productId={this.state.selectedBuyProduct.id}
                title={this.state.selectedBuyProduct.title}
                onAddToCart={this.addToCart}
                onClose={() => {
                    this.setState({ selectedBuyProduct: undefined })
                    this.ownedOffersBoard.current.loadBoard();
                }
                } />}
            <DeviceResponsiveHelper desktop={this.desktop()} mobile={this.mobile()} />
        </>
    }
}