import React from "react"
import { Form } from "react-bootstrap"
import ShopService from "../../../services/shops/ShopService"
import './ShopsSelection.scss'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { SelectionView } from "../../../services/common/Models"
import authenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext"
import { Shop, Person } from "react-bootstrap-icons"

interface IShop {
    title: string, value: string
}

export default class ShopsSelection extends React.Component<{
    selectedShopId?: string, additionalItem?: IShop, onChanged: (shop: IShop) => void, onLoad?: (shops: IShop[]) => void;
}, { shops: IShop[] }> {
    shopService: ShopService;
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    constructor(props: any) {
        super(props);
        this.shopService = new ShopService();
        this.state = { shops: [] };
    }

    componentDidMount() {
        this.shopService.getTitles().then((response) => {
            let shops: IShop[] = response

            if (this.props.additionalItem)
                shops.push(this.props.additionalItem)

            this.setState({ shops });
            if (this.props.onLoad)
                this.props.onLoad(shops);
        });
    }

    formatResult = (item) => {
        return (
            <div className="d-flex align-items-center">
                {item.value == 'admin' ? <Person /> : <Shop />}
                <span className="ms-2">{item.title}</span>
            </div>
        )
    }

    render(): React.ReactNode {
        if (this.state.shops.length == 0)
            return null;
        else if (this.state.shops.length > 10)
            return <ReactSearchAutocomplete<SelectionView>
                items={this.state.shops}
                fuseOptions={{ keys: ["title"] }}
                resultStringKeyName="title"
                formatResult={this.formatResult}
                inputSearchString={(this.state.shops.length > 0 && this.props.selectedShopId) ? this.state.shops.filter(shop => shop.value === this.props.selectedShopId)[0].title : this.state.shops[0].title}
                showClear={false}
                showIcon={false}
                styling={{ borderRadius: "12px", border: "0px solid", boxShadow: "unset", placeholderColor: "#333333", height: '48px', fontSize: "14px", fontFamily: "SFPro-Regular", zIndex: 1000 }}
                onSelect={(item) => this.props.onChanged(item)}
                autoFocus={false}
                showItemsOnFocus={false}
                showNoResults={false}
            />
        else
            return <Form.Select className="shop-select-box" value={this.props.selectedShopId ?? this.state.shops[0].value} onChange={(e) => this.props.onChanged(this.state.shops.filter(shop => shop.value == e.target.value)[0])}>
                {
                    this.state.shops.map(shop => (
                        <option key={shop.value} value={shop.value}>
                            {shop.title}
                        </option>))
                }
            </Form.Select>
    }
}