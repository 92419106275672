import React from "react"
import { Col, Placeholder, Row } from "react-bootstrap"
import { BarChartFill } from "react-bootstrap-icons"
import CardView from "../../../../shared/Card/CardView"
import AdminOrderProductService, { SalesReport } from "../../../../../services/admin/orders/AdminOrderProductService"
import { Grid } from "@mui/material"

export default class SalesStatisticsReport extends React.Component<{}, { loading: boolean, report: SalesReport }> {
    orderProductService: AdminOrderProductService

    constructor(props: any) {
        super(props)

        this.state = { loading: false, report: { profitPrice: 0, totalPrice: 0 } }

        this.orderProductService = new AdminOrderProductService()
    }

    componentDidMount(): void {
        this.setReport()
    }

    setReport = () => {
        this.setState({ loading: true })

        this.orderProductService.getReport().then((response) => {
            this.setState({ report: response })
        }).finally(() => this.setState({ loading: false }))
    }


    boxView = (icon: any, title: string, value: number, index: number) => {
        return (
            <CardView
                key={index}
                small
                card={{ bg: 'light' }}
            >
                <div className="d-flex align-items-center">
                    <div>{icon}</div>
                    <div className="pl-md">
                        <span className="font-bold">{title}</span>
                        <div className="text-coal">{value}</div>
                    </div>
                </div>
            </CardView>
        );
    };

    render(): React.ReactNode {
        let { report } = this.state
        let items: { icon: any, title: string, value: any }[] = [
            {
                icon: <BarChartFill size={'32px'} color="#AF7AC5" />,

                title: 'Profit (today)',
                value: report.profitPrice ?? '-'
            },
            {
                icon: <BarChartFill size={'32px'} color="#2E4053" />,
                title: 'Total Sales (today)',
                value: report.totalPrice ?? '-'
            },
        ]

        return (<>
            {
                this.state.loading ?
                    <Grid container className="mb-4" spacing={3}>
                        {
                            Array.from(Array(items.length), (e, i) => <Grid item className="mb-2" md={6} sm={12}>
                                <Placeholder animation="glow">
                                    <Placeholder xs={12} style={{ height: '80px', borderRadius: '4px' }} />
                                </Placeholder>
                            </Grid>)
                        }
                    </Grid> :
                    <Grid container className="mb-4" spacing={3}>
                        {
                            items.map((item, index) => {
                                return (<Grid className="mb-2" md={6} sm={12} item>
                                    {this.boxView(item.icon, item.title, item.value, index)}
                                </Grid>
                                )
                            })
                        }
                    </Grid>
            }
        </>)
    }
}