import { Route } from "react-router-dom";
import FinancialManagementRoute from "../financials/FinancialManagement/FinancialManagementRoute";
import FinancialsRoute from "../financials/FinancialsRoute";
import WalletChargeRoute from "../financials/WalletChargeRoute";
import WalletTransactionListRoute from "../financials/WalletTransactionListRoute";
import MainLayout from "../../layouts/main-layout/MainLayout";
import PaymentRoute from "../financials/PaymentRoute";
import EmptyLayout from "../../website/layout/EmptyLayout/EmptyLayout";
import PaymentStatusRoute from "../financials/payment-status/PaymentStatusRoute";
import PaymentResultRoute from "../financials/PaymentResultRoute";
import PaymentWatingRoute from "../financials/PaymentWaitingRoute";
import BuyCreditRoute from "../financials/BuyCreditRoute";

export default function FinancialRoutes() {
    return (
        <>
            <Route element={<MainLayout showLogo/>}>
                <Route path="/financials" element={<FinancialsRoute />} />
                <Route path="/walletTransaction" element={<WalletTransactionListRoute />} />
                <Route path="/deposit" element={<WalletChargeRoute />} />
                <Route path="/financial-management" element={<FinancialManagementRoute />} />
                <Route path="/payment" element={<PaymentRoute />} />
                <Route path="/paymentResult" element={<PaymentResultRoute />} />
                <Route path="/buy-credit" element={<BuyCreditRoute />} />
                BuyCredit
            </Route>
            <Route element={<EmptyLayout />}>
                <Route path="/payment-status" element={<PaymentStatusRoute />} />
                <Route path="/payment-waiting" element={<PaymentWatingRoute />} />
            </Route>
        </>
    )
}