import React, { RefObject } from "react";
import WalletService, { IWalletsPaginationFilters } from "../../../../services/financials/WalletService";
import { GridAction, GridColumn, GridColumnTypes } from "../../../Grid/Grid.models";
import authenticationContext from "../../../../contexts/AuthenticationContext/AuthenticationContext";
import DynamicGrid from "../../../shared/DynamicGrid/DynamicGrid";
import CreateCashOut from "../../cashOutes/CreateCashOut/CreateCashOut";
import WalletTransferFund from "../WalletTransferFund/WalletTransferFund";
import { FULL_ACCESS } from "../../../../constants/ConstantsPermissions";
import { Link } from "react-router-dom";
import { WalletTransactionStatusEnum } from "../../Enums/WalletTransactionStatusEnum";
import CreateExchangeRequestModal from "../Exchange/CreateExchangeRequestModal/CreateExchangeRequestModal";
import HoldListModal from "../Hold/HoldListModal";

export default class WalletList extends React.PureComponent<{}, {
    exchangeWalletId?: string,
    withdrawWalletId?: string,
    withdrawCurrencyId?: string,
    holdWalletId?: string
    transferWalletId?: string
}> {
    walletService: WalletService;

    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    dynamicGrid: RefObject<DynamicGrid<IWalletsPaginationFilters>>
    constructor(props: any) {
        super(props);
        this.dynamicGrid = React.createRef();
        this.walletService = new WalletService();
        this.state = {}
    }

    pendingDisplay = (id: string, value: number) => {
        return <Link to={`?activeTab=walletTransaction&walletId=${id}&walletStatus=${WalletTransactionStatusEnum.Pending}`}>{value}</Link>
    }

    holdDisplay = (id: string, value: number) => {
        return <a className="link-style" onClick={() => this.setState({ holdWalletId: id })}>{value}</a>
    }

    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: 'Currency', key: 'currencyTitle' },
            { title: 'Balance', key: 'balance', type: GridColumnTypes.Amount },
            { title: 'Pending', key: 'pending' },
            { title: 'Hold', key: 'hold' },
        ];

        let gridActions: GridAction[] = [
            { caption: 'Deposit', variant: 'outline-success', generateLink: (row) => `/buy-credit?currencyId=${row.currencyId}`},
            { caption: 'Withdraw', variant: 'outline-danger', onClick: (row) => { this.setState({ withdrawWalletId: row.id, withdrawCurrencyId: row.currencyId }); this.dynamicGrid.current?.resetSpinner() } },
            { caption: 'Exchange', variant: 'outline-primary', onClick: (row) => { this.setState({ exchangeWalletId: row.id }); this.dynamicGrid.current?.resetSpinner() } }
        ];

        if (this.context.privileges.includes(FULL_ACCESS))
            gridActions.push({ caption: 'Transfer', variant: 'outline-primary', onClick: (row) => { this.setState({ transferWalletId: row.id }); this.dynamicGrid.current?.resetSpinner() } })

        gridActions.push({ caption: 'Transactions', variant: 'outline-info', generateLink: (row) => `/financials/?activeTab=walletTransaction&walletId=${row.id}` })
        return (<div>
            {this.state.holdWalletId !== undefined && <HoldListModal walletId={this.state.holdWalletId} onClose={() => this.setState({ holdWalletId: undefined })} />}
            {this.state.withdrawWalletId !== undefined && <CreateCashOut currencyId={this.state.withdrawCurrencyId} walletId={this.state.withdrawWalletId} onClose={() => this.setState({ withdrawWalletId: undefined })} />}
            {this.state.transferWalletId !== undefined && <WalletTransferFund reload={() => this.dynamicGrid.current?.fetch()} walletId={this.state.transferWalletId} onClose={() => this.setState({ transferWalletId: undefined })} />}
            {this.state.exchangeWalletId !== undefined && <CreateExchangeRequestModal onSubmit={() => {
                this.setState({ exchangeWalletId: undefined })
                this.dynamicGrid.current?.fetch();
            }}
                fromWalletId={this.state.exchangeWalletId}
                showModal={this.state.exchangeWalletId !== undefined} closeModal={() => { this.setState({ exchangeWalletId: undefined }) }} />}


            <DynamicGrid<IWalletsPaginationFilters> ref={this.dynamicGrid}
                columns={gridColumns}
                actions={gridActions}
                onFetch={this.walletService.getWalletList}
                refactorRow={(row) => {
                    return { ...row, pending: this.pendingDisplay(row.id, row.pending), hold: this.holdDisplay(row.id, row.hold) }
                }}
            />

        </div >)
    }
}