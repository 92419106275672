import React, { RefObject } from "react";
import { Badge, Button, Card, Col, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Moment from 'moment';
import SupportTicketsService from "../../../../services/helpCenter/SupportTicketService";
import supportTicketStatusDisplayBadge, { SupportTicketStatus } from "../../SupportTickets/SupportTicketStatusEnum";
import { OrderProductStatusEnum } from "../../../orders/Enums/OrderProductStatusEnum"
import TicketView from "../../Common/TicketView/TicketView";
import IReportDetailState, { IReportDetailsProps } from "./ReportDetails.models";
import { NotificationManager } from 'react-notifications';
import InventoryService from "../../../../services/inventory/InventoryService";
import { EyeFill, EyeSlash, ShieldFillCheck } from "react-bootstrap-icons";
import TOTPForm from "../../../identity/Security/TwoFactor/TOTPForm/TOTPForm";
import TagList from "../../SupportTickets/tag-list/TagList";
import LayoutContext from "../../../../contexts/LayoutContext/LayoutContext";
import { from } from "rxjs";
import OrdersService from "../../../../services/orders/OrdersService";
import ReviewRequestService from "../../../../services/orders/ReviewRequestService";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

export default class ReportDetails extends React.PureComponent<IReportDetailsProps, IReportDetailState> {
    static contextType = LayoutContext;
    context!: React.ContextType<typeof LayoutContext>;
    supportTicketsService: SupportTicketsService
    ordersService: OrdersService;
    inventoryService: InventoryService
    reviewRequestService: ReviewRequestService;
    ticketView: RefObject<TicketView>
    totpForm: RefObject<TOTPForm>
    constructor(props: IReportDetailsProps) {
        super(props)
        this.state = { showRefundModal: false, isLoading: false, withSecretInfo: false, showOtpForm: false, returnReasonCode: 'INVALID_CODE' }
        this.supportTicketsService = new SupportTicketsService()
        this.inventoryService = new InventoryService();
        this.ordersService = new OrdersService();
        this.reviewRequestService = new ReviewRequestService();
        this.ticketView = React.createRef();
        this.totpForm = React.createRef();
    }

    componentDidMount() {
        this.getDetails()
    }


    refund = () => {
        this.setState({ isLoading: true })
        this.reviewRequestService.accept(this.props.ticketId).then(() => {
            NotificationManager.success('Product has been refunded successfully')
            this.setState({ showRefundModal: false })
            this.onChangedStatus();
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }
    getDetails = () => {
        this.setState({ isLoading: true })
        this.reviewRequestService.getById(this.props.ticketId).then((response) => {
            this.setState({ ticketDetails: response })
            this.ordersService.getBuyRelativeStatistics(response.buyerId).then((statistics) => {
                this.setState({ orderRelativeStatistics: statistics })
            })
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }

    buyRelativeStatisticsView = () => {
        return <Card>
            <Card.Header>Buyer Statistics</Card.Header>
            <Card.Body>
                <Row>
                    <Col md="6" sm="6">
                        <span>Order Count (Buy Total):</span>
                        <b>
                            {
                                this.state.orderRelativeStatistics.totalRelativeOrderItemCount
                            }
                        </b>
                    </Col>
                    <Col md="6" sm="6">
                        <span>Review Request Percent (Buy Total):</span>
                        <b>
                            {
                                this.state.orderRelativeStatistics.totalReviewRequestPercent
                            }%
                        </b>
                    </Col>
                </Row>
                <Row>
                    <Col md="6" sm="6">
                        <span>Order Count (Buy from you):</span>
                        <b>
                            {
                                this.state.orderRelativeStatistics.orderItemCount
                            }
                        </b>
                    </Col>
                    <Col md="6" sm="6">
                        <span>Review Request Percent (Buy from you):</span>
                        <b>
                            {
                                this.state.orderRelativeStatistics.reviewRequestPercent
                            }%
                        </b>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    }

    informationToArray(information): string[] {
        return JSON.parse(information);
    }

    inventoryDetailsView = () => {
        const inventory = this.state.ticketDetails.inventory;
        let information = this.informationToArray(inventory.information);
        return <Card>
            <Card.Header>Inventory Information</Card.Header>
            <Card.Body>
                <Card.Title >{information.map((info, index) => <span key={index}>{info}<br /></span>)}</Card.Title>
                <Card.Text>
                    <div><label>Product:</label>  {inventory.title}</div>
                    <div><label>Source:</label>  {inventory.source} ({inventory.orderNumber})</div>
                    <div><label>Buy Price:</label>  {inventory.buyPrice}</div>
                    <div><label>Enter at:</label> <span title={(Moment(inventory.createAt).utc().format('YYYY/MM/DD HH:mm'))}>{Moment(inventory.createAt).local().format('YYYY/MM/DD HH:mm')}</span></div>
                </Card.Text>
            </Card.Body>
        </Card>
    }


    returnReasonDetailsView = () => {
        var systemMessage = <></>;
        switch (this.state.returnReasonCode) {
            case 'INVALID_CODE':
                systemMessage = <>
                    <Badge bg="danger">Invalid Code</Badge>
                    <p>
                        The buyer has registered a request that your code is invalid. This can be related to the code structure.
                    </p>
                    <br />
                    <h6>Suggested steps for you</h6>
                    <ol>
                        <li>Check the code immediately to make sure its structure is correct (the code must have the structure related to that product)</li>
                        <li>If the code structure is wrong, immediately refund the order amount in this section and refrain from sending the currect code in chat text (it is against the rules)</li>
                        <li>If the structure of the code was correct, inform the buyer about this right here</li>
                    </ol>
                </>
                break;
            case 'ALREADY_REDEEMED':
                systemMessage = <>
                    <Badge bg="danger">Already Redeemed</Badge>
                    <p>
                        The buyer has registered a request that the code you sold has already been used and wants you to refund the order money.
                    </p>
                    <br />
                    <h6>Suggested steps for you</h6>
                    <ol>
                        <li>Check the information provided by the buyer.</li>
                        <li>If possible, find the exact time of using the code and finally compare it with the time of selling it to the buyer</li>
                        <li>Let me know the source you got this code from so they can check it further</li>
                        <li>Finally, according to the summary of information, you can refund the order amount to the buyer in this section and refrain from sending the currect code in chat text (it is against the rules)</li>
                    </ol>
                </>
                break;
            case 'DIFFERENT_REGION':
                systemMessage = <>
                    <Badge bg="danger">Different Region</Badge>
                    <p>
                        The buyer has registered a request regarding the discrepancy of the desired code region and asks you to refund the order amount.
                    </p>
                    <br />
                    <h6>Suggested steps for you</h6>
                    <ol>
                        <li>Check if the region of this code is really different and you have sold it by mistake</li>
                        <li>If there is a discrepancy, immediately refund the buyer's money from this section.</li>
                        <li>You can later enter this code in the right area and sell it</li>
                    </ol>
                </>
                break;
            case 'DIFFERENT_VALUE':
                systemMessage = <>
                    <Badge bg="danger">Different Value</Badge>
                    <p>
                        The buyer has registered a request regarding the discrepancy in the value of the desired code
                    </p>
                    <br />
                    <h6>Suggested steps for you</h6>
                    <ol>
                        <li>Check the information to make sure the buyer's request is correct</li>
                        <li>If the actual value of the product is lower than the transaction, you must immediately transfer the difference to the buyer's wallet.</li>
                        <li>If the actual value of the product is higher than the transaction, you can ask the buyer to transfer the difference to your wallet. Of course, note that this is completely optional for the buyer (according to the rules).</li>
                        <li>We suggest that you check and if you have purchased this product from your source with a discrepancy in value. Keep your source informed.</li>
                    </ol>
                </>
                break;

        }

        return <Card style={{ marginTop: '10px' }}>
            <Card.Header>Review Request</Card.Header>
            <Card.Body>
                {
                    systemMessage
                }

                <br />
                <h6>
                    Keep the following points in mind at all stages of your customer support.
                </h6>
                <ul>
                    <li>No contact information should be sent between the seller and the buyer</li>
                    <li>Transactions on this platform will always be the best way, and the buyer and seller will trade safely</li>
                    <li>The buyer may decide not to buy from you again, so be careful in dealing with buyers.</li>
                    <li>Buyers on this platform are mostly large sellers and sell to end users, so your speed in reviewing issues will help them.</li>
                    <li>We protect sellers' capital and buyers' trust, if the request is prolonged, we will check it directly</li>
                    <li>Always maintain courtesy and respect in your correspondence with the buyer</li>
                </ul>
            </Card.Body>
        </Card>
    }
    onChangedStatus = () => {
        this.getDetails()
        this.context.onReviewRequestChangedStatus();
    }

    addReply = (text: string, attachmentNames?: string[]): Promise<void> => {
        return this.supportTicketsService.reply(this.props.ticketId, text, attachmentNames).then(() => {
            this.onChangedStatus()
        });
    }

    addComment = (text: string): Promise<void> => {
        return this.supportTicketsService.createComment(this.props.ticketId, text).then(() => {
            this.onChangedStatus()
        });
    }

    render(): React.ReactNode {
        if (this.state.ticketDetails === undefined)
            return null;

        const { ticketDetails, isLoading, showRefundModal } = this.state
        return <div>
            {
                isLoading ? <Spinner animation="grow" /> : (
                    <>
                        <Row>
                            <Col md="4" sm="12">

                                <h3>{ticketDetails.title} </h3>
                                {(ticketDetails.mediationRequestDate !== null && ticketDetails.mediationRequestDate !== undefined && ticketDetails.isMediationDone !== true) && <span style={{ color: "green", display: 'block' }}>
                                    This ticket is under mediation <ShieldFillCheck />
                                </span>}
                                <TagList onHandle={this.getDetails} ticketId={this.props.ticketId} datas={ticketDetails.tags} />
                                <label>{ticketDetails.productTitle} (Price: {ticketDetails.sellPrice}) </label>
                            </Col>
                            <Col md="4" sm="12">
                                <h6>Status: <b>{supportTicketStatusDisplayBadge(ticketDetails.status)}</b></h6>
                            </Col>
                            <Col md="4" sm="12" style={{ textAlign: 'end' }}>
                                {
                                    (ticketDetails.orderItemStatus === OrderProductStatusEnum.Delivered && ticketDetails.status !== SupportTicketStatus.Closed) &&
                                    <Button variant="outline-success" size="sm" onClick={() => this.setState({ showRefundModal: true })}>Accept & Refund Amount</Button>
                                }
                                <NavLink to={`/orders/sell/?tab=review-request`}><Button variant="outline-info" className="m-1" size="sm">Back To List</Button></NavLink>
                                <br />
                                {ticketDetails.orderProductId !== null && (
                                    <>
                                        {
                                            ticketDetails.orderItemStatus === OrderProductStatusEnum.Cancel && <span className="text-danger p-2">Order item already canceled</span>
                                        }

                                        {
                                            (ticketDetails.orderItemStatus === OrderProductStatusEnum.Refund || ticketDetails.orderItemStatus === OrderProductStatusEnum.DeliveryRefund) &&
                                            <span className="text-danger p-2">Order item already refunded</span>
                                        }
                                    </>
                                )}
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col md="3" sm="12">
                                {ticketDetails.inventory && this.inventoryDetailsView()}
                                {ticketDetails.inventory && this.returnReasonDetailsView()}

                                <TicketView onHandleReply={this.addComment} title="Comment" hasEnableAddReply={ticketDetails.status !== SupportTicketStatus.Closed} comments={ticketDetails.comments} />
                            </Col>
                            <Col md="9" sm="12">
                                {this.state.orderRelativeStatistics && this.buyRelativeStatisticsView()}
                                {ticketDetails.replies &&
                                    <TicketView ref={this.ticketView} shopId={localStorage.getItem('activeshop')} onHandleReply={this.addReply} draftId={this.props.ticketId} hasEnableAddReply title="Reply" comments={ticketDetails.replies} />
                                }
                            </Col>
                        </Row>
                        <Dialog
                            open={showRefundModal}
                            keepMounted
                            onClose={() => this.setState({ showRefundModal: false })}
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle>{"Are you sure refund order amount?"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                    This order will be refunded to buyer, Are you sure refund order amount (${this.state.ticketDetails.sellPrice}) to buyer?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.setState({ showRefundModal: false })} variant="outline-danger">Cancel</Button>
                                <Button onClick={() => this.refund()}>Refund</Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )
            }
        </div>
    }
}
