import React from "react"
import { Button } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import './Actions.scss'

export default class ActionsComponent extends React.PureComponent<{ direction?: string, showAlreadyMember?: boolean }, {}> {
    render(): React.ReactNode {
        return <div className={this.props.direction === 'vertical' ? 'd-grid gap-2 justify-items-center actions' : ' text-left actions'}>
            <NavLink to="/sign-up" className="w-100">
                <Button variant="danger" className={`btn-brownish-red ${this.props.direction === 'vertical' && 'w-35'}`}>Sign up for free</Button>
            </NavLink>
        </div>
    }
}   