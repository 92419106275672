import { PureComponent, ReactNode } from "react";
import SuccessPaymentImg from '../../../../assets/images/content/success-payment.png'
import ErrorPaymentImg from '../../../../assets/images/content/error-payment.png'
import WaitingPaymentImg from '../../../../assets/images/content/hourglass.svg'
import { Container, Typography } from "@mui/material";

export default class PaymentStatus extends PureComponent<{ status: 'error' | 'success' | 'waiting' }, {}> {
    title = () => {
        switch (this.props.status) {
            case 'error':
                return 'Payment Error'
            case 'success':
                return 'Payment Successful'
            case 'waiting':
                return 'Waiting a for payment ...'
        }
    }

    description = () => {
        switch (this.props.status) {
            case 'success':
                return 'Your wallet has been topped up, and your items have been purchased.'
            case 'error':
                return 'Unfortunately, there was an issue processing your payment. Please try again.'
            case 'waiting':
                return 'Please wait while we redirect you to the payment page.'
        }
    }

    img = () => {
        switch (this.props.status) {
            case 'success':
                return <img width={144} src={SuccessPaymentImg} />
            case 'waiting':
                return <img width={144} src={WaitingPaymentImg} />
            default:
                return <img width={144} src={ErrorPaymentImg} />
        }
    }

    render(): ReactNode {
        return <Container sx={{ pt: { md: 12, sm: 8, xs: 8 } }}>
            <div className="full-width px-md pt-xl pb-xl flex justify-center column items-center box-shadow radius-md bg-white">
                {this.img()}
                <Typography className="pt-md" sx={{ fontSize: '26px', color: this.props.status === 'error' ? '#F22020' : this.props.status === 'success' ? '#23B100' : '#000' }}><span className="font-bold">{this.title()}</span> </Typography>
                <Typography className="pt-md text-center"><span className="font-main font-medium">{this.description()}</span></Typography>
            </div>
        </Container>
    }
}
