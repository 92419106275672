import React from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { NotificationManager } from 'react-notifications';
import AdminCurrenciesService from "../../../../../services/admin/financials/AdminCurrenciesService";
import ModalComponent from "../../../../shared/modal/Modal";
import { CustomButton } from "../../../../shared/button/ButtonComponent";
import { CircularProgress } from "@mui/material";

export default class AdminCreateCurrencyModal extends React.Component<{ onClose: () => void, reload: () => void, currencyId?: string }, { symbol: string, title: string, pendingRequest: boolean }> {
    adminCurrenciesService: AdminCurrenciesService
    constructor(props: any) {
        super(props);
        this.state = { pendingRequest: false, symbol: '', title: '' }
        this.adminCurrenciesService = new AdminCurrenciesService()
    }

    componentDidMount(): void {
        if (this.props.currencyId)
            this.adminCurrenciesService.getById(this.props.currencyId).then((response) => {
                this.setState({ symbol: response.symbol, title: response.title })
            })
    }

    requestToEdit = (body: any) => {
        this.adminCurrenciesService.update({ ...body, id: this.props.currencyId }).then(() => {
            NotificationManager.success("the currency added successfully")
            this.props.onClose()
            this.props.reload()
        }).finally(() => this.setState({ pendingRequest: false }))
    }

    requestToCreate = (body: any) => {
        this.adminCurrenciesService.create(body).then(() => {
            NotificationManager.success("the currency added successfully")
            this.props.onClose()
            this.props.reload()
        }).finally(() => this.setState({ pendingRequest: false }))
    }

    submit = () => {
        this.setState({ pendingRequest: true })

        let { symbol, title } = this.state

        let body = {
            title,
            symbol
        }

        if (this.props.currencyId)
            this.requestToEdit(body)
        else this.requestToCreate(body)
    }

    render(): React.ReactNode {
        let { onClose } = this.props

        let { title, symbol, pendingRequest } = this.state
        let { currencyId } = this.props

        return <ModalComponent title={currencyId ? 'Edit Currency' : 'Create Currency'} size="500px" onClose={onClose}>
            <div>
                <Form>
                    <Row>
                        <Col className="mt-3" md="12" sm="12">
                            <Form.Label>Title:</Form.Label>
                            <Form.Control placeholder="Enter Title"
                                value={title}
                                onChange={e => this.setState({ title: e.target.value })} />
                        </Col>
                        <Col className="mt-3" md="12" sm="12">
                            <Form.Label>Symbol:</Form.Label>
                            <Form.Control placeholder="Enter Title"
                                value={symbol}
                                onChange={e => this.setState({ symbol: e.target.value })} />
                        </Col>
                    </Row>
                </Form>
            </div>
            <ModalComponent.Footer>
                {pendingRequest ? <CustomButton variant='contained' disabled><CircularProgress size={20} color="inherit" /></CustomButton> :
                    <CustomButton variant='contained' onClick={this.submit}>{currencyId ? 'Edit' : 'Create'}</CustomButton>}
            </ModalComponent.Footer>
        </ModalComponent>
    }
}