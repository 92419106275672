import React, { RefObject } from "react";
import { InventoryShopBasePlanStatusEnum } from "../../../../inventory/Enums/InventoryShopBasePlanStatusEnum";
import ShopPolicies from "../../../../shared/policy/ShopPolicies/ShopPolicies";
import { ReactComponent as RedirectIcon } from '../../../../../assets/icons/content/redirect-icon.svg'
import TruncateString from "../../../../utilities/truncate-string/TruncateString";

export interface ExpressProductCatalogItemView {
    title: string
    currencyId: string
    imageSrc: string | null
    imagePath: string | null
    productId: string
    realPrice: number
    sellers: any[],
    buySuggestionCount: number
    requiredCount: number
    deficitsCount: number
    status: InventoryShopBasePlanStatusEnum;
}

export interface ExpressProductCatalogItemProps {
    title: string
    currencyId: string
    imageSrc: string | null
    imagePath?: string | null
    productId: string
    realPrice: number
    onClickBuy: () => void
    onClickSell: () => void
}

export default class ExpressProductCatalogItem extends React.Component<ExpressProductCatalogItemProps, ExpressProductCatalogItemView> {
    refPolicies: RefObject<ShopPolicies>
    constructor(props: any) {
        super(props);
        this.refPolicies = React.createRef()
        this.state = {
            currencyId: '', imageSrc: null, productId: '', realPrice: 0, title: '', sellers: [], imagePath: null,
            buySuggestionCount: 0, requiredCount: 0, deficitsCount: 0, status: InventoryShopBasePlanStatusEnum.Closed
        };
    }

    render(): React.ReactNode {
        let item = this.props;

        return <div className={"product-catalog-item"}>
            <img src={item.imagePath == null ? '/images/product-none.png' : item.imagePath} className="product-image" />
            <div className="border-line"></div>
            <div className="footer flex justify-between full-width items-center pointer" onClick={this.props.onClickBuy}>
                <div className="inline-block">
                    <img src="/icons/flags/us.png" className="pr-xs flag" />
                    <span className="mobile"><TruncateString text={item.title} maxLength={12} /></span>
                    <span className="web"><TruncateString text={item.title} maxLength={24} /></span>
                </div>
                <RedirectIcon />
            </div>
        </div>
    }
}