import { dark } from "@mui/material/styles/createPalette"

const getPalette = () => ({
    palette: {
        ...({
            primary: {
                light: '#7a9cec',
                main: '#0E3FC4',
                dark: '#2c348c',
                contrastText: '#fff',
            },
            secondary: {
                light: '#98a0c1',
                main: '#5e5fa3',
                dark: '#3e3262',
                contrastText: '#fff',
            },
            charcoal: {
                light: '#a29eac',
                main: '#444444',
                dark: '#403d48',
                contrastText: '#fff',
            },
            grey: {
                dark: '#333333',
                light: '#D3D3D3',
                200: '#f5f6fb',
                300: '#cac9d0'
            },
            success: {
                main: '#17AD24',
                light: '#E3FFDE'
            },
            pale_grey: {
                dark: '#616161',
                main: "#777777",
                light: '#eeeff2',
            },
            error: {
                main: "#B90000",
                light: '#FFDDDD',
            },
            info: {
                main: '#007CDF'
            }
        }),
    },
})

declare module '@mui/material/styles' {
    interface Palette {
        charcoal: Palette['primary']
        pale_grey: Palette['primary']
    }
    interface PaletteOptions {
        charcoal?: PaletteOptions['primary']
        pale_grey?: PaletteOptions['primary']
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        charcoal: true
        pale_grey: true
    }
}

export default getPalette