let images: any = {
    "1d4745b6-2338-40b6-afdd-630944406c7e": "Itunes.jpeg",
}
let categoryImages: any = {
    "4968808a-d8b2-4360-853b-c4924607e7a5": "google-play.png",
}
export default function ProductCatalogImages(id: string) {
    return images[id] === undefined ? null : `/images/products/${images[id]}`;
}
export function CategoryCatalogImages(id: string) {
    return categoryImages[id] === undefined ? null : `/images/categories/${categoryImages[id]}`;
} 