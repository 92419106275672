import React, { RefObject } from "react";
import { Badge, Spinner } from "react-bootstrap";
import Amount from "../utilities/Amount/Amount";
import GridProps, { GridAction, GridColumnTypes, GridState } from "./Grid.models";
import './Grid.scss'
import Pagination from "./Pagination/Pagination";
import { PaginationQuery } from "./Pagination/Pagination.models";
import Moment from 'moment';
import { NavLink } from "react-router-dom";
import ShopAuthenticated from "../shared/ShopAuthenticated/ShopAuthenticated";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { CustomButton } from "../shared/button/ButtonComponent";
import SpinnerComponent from "../shared/spinner/SpinnerComponent";

export default class Grid extends React.PureComponent<GridProps, GridState> {
    pagination: RefObject<Pagination>

    constructor(props: any) {
        super(props);
        this.pagination = React.createRef();
        this.state = { columns: [], rows: [], actions: [], startIndex: 1, totalRows: 0, spinnerPoint: [], loading: true, pageNumber: 1 };
    }

    componentDidMount = () => {
        this.setState({ columns: this.props.columns, actions: this.props.actions === undefined ? [] : this.props.actions });
    }

    load = (rows: any[], totalRows: number) => {
        let records: Record<string, any>[] = rows.map((row) => {
            let columns: Record<string, any> = [];
            let index = 0;
            for (let key of Object.keys(row)) {
                columns[key] = Object.values(row)[index++];
            }
            return columns;
        });
        this.setState({ rows: records, totalRows: totalRows, loading: false, spinnerPoint: [] });
    }

    render(): React.ReactNode {
        return (<>
            <TableContainer className={"mt-md mb-lg grid" + (this.state.loading ? ' loading ' : '')}>
                <Table size={this.props.size} aria-label="table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">#</TableCell>
                            {this.state.columns.map((column) => (<TableCell key={column.key}>{column.title}</TableCell>))}
                            {this.state.actions.length == 0 ? null : (<TableCell align="center">Actions</TableCell>)}
                        </TableRow>
                    </TableHead>
                    {
                        this.state.loading ? <TableBody>
                            <TableRow className="empty-row"><TableCell colSpan={this.state.columns.length + 2}><Spinner animation="border" /></TableCell></TableRow>
                        </TableBody> :
                            (this.state.rows.length > 0 ? this.bodyView() : this.emptyBodyView())
                    }
                </Table>
            </TableContainer>
            {this.props.onChangePage !== undefined &&
                <Pagination totalRows={this.state.totalRows} pageNumber={this.state.pageNumber} onChangePageNumber={this.changePageNumber}
                    onChangePageSize={this.fetch}></Pagination>}
        </>);
    }

    resetSpinner = () => {
        this.setState({ spinnerPoint: [] });
    }

    changePageNumber = (number: number, size: number) => {
        this.setState({ pageNumber: number }, () => this.fetch(size))
    }

    fetch = (size: number) => {
        if (this.props.onChangePage === undefined)
            return;

        var pageQuery: PaginationQuery = { pageskip: this.state.pageNumber, pagesize: size }

        this.setState({ startIndex: ((pageQuery.pageskip - 1) * pageQuery.pagesize) + 1, loading: true })
        this.props.onChangePage(pageQuery);
    }

    bodyView() {
        const badges = ['warning', 'info', 'success', 'primary', 'dark']
        let i = 0;
        return (<TableBody>
            {this.state.rows.map((row, index) => (
                <TableRow key={index} className={this.props.rowClassName && this.props.rowClassName(row)}>
                    <TableCell align="center">{this.state.startIndex + index}</TableCell>
                    {this.state.columns.map((column) => {
                        switch (column.type) {
                            case GridColumnTypes.Date:
                                return <TableCell title={(Moment(row[column.key]).utc().format('YYYY/MM/DD HH:mm'))} key={column.key}>{row[column.key] != null ? Moment(row[column.key]).local().format('YYYY/MM/DD HH:mm') : ''}</TableCell>;
                            case GridColumnTypes.DateOnly:
                                return <TableCell title={(Moment(row[column.key]).utc().format('YYYY/MM/DD'))} key={column.key}>{row[column.key] != null ? Moment(row[column.key]).local().format('YYYY/MM/DD') : ''}</TableCell>;
                            case GridColumnTypes.TimeOnly:
                                return <TableCell title={(Moment(row[column.key]).utc().format('HH:mm'))} key={column.key}>{row[column.key] != null ? Moment(row[column.key]).local().format('HH:mm') : ''}</TableCell>;
                            case GridColumnTypes.Amount:
                                return <TableCell key={column.key}><Amount formatMoney={row[column.key] > 0} value={row[column.key]} /></TableCell>;
                            case GridColumnTypes.BadgeList:
                                return row[column.key] ?
                                    <TableCell key={column.key + index}>
                                        {
                                            row[column.key].map((item, index) => {
                                                i = i > 6 ? 0 : i;
                                                i++;
                                                return <Badge bg={badges[i - 1]} className="mr-1" key={index}>{item}</Badge>;
                                            })
                                        }
                                    </TableCell> :
                                    <TableCell></TableCell>;
                            default:
                                return <TableCell key={column.key}>{row[column.key]}</TableCell>;
                        }
                    })}
                    {this.state.actions.length == 0 ? null : (<TableCell align="center">
                        {this.state.actions.map((action, actionIndex) => (
                            <span key={actionIndex}>
                                {(this.state.spinnerPoint[0] === index && this.state.spinnerPoint[1] === actionIndex) ? <SpinnerComponent /> :
                                    action.permission ? <ShopAuthenticated permission={action.permission}> {this.actionView(action, index, actionIndex, row)}</ShopAuthenticated> : this.actionView(action, index, actionIndex, row)
                                }
                            </span>
                        ))}
                    </TableCell>)}
                </TableRow>))}
        </TableBody>);
    }

    emptyBodyView() {
        return (<TableBody>
            <TableRow className="empty-row">
                <TableCell colSpan={this.state.columns.length + 1}>not exist any data.</TableCell>
            </TableRow>
        </TableBody>);
    }

    actionView(action: GridAction, index: number, actionIndex: number, row: Record<string, any>) {
        if (action.render !== undefined)
            return action.render(row);
        return (action.generateLink === undefined ?
            (this.state.spinnerPoint[0] !== index) &&
            <CustomButton className="action-button" size="small" disabled={this.state.spinnerPoint.length !== 0 || (action.checkIsDisabled ? action.checkIsDisabled(row) : false)}
                variant="text" onClick={() => {
                    if (action.onClick !== undefined) {
                        if (action.skipSpinner !== true)
                            this.setState({ spinnerPoint: [index, actionIndex] })
                        action.onClick(row)
                    }
                }} > {action.caption}</CustomButton>
            : <NavLink to={action.generateLink(row)} className={(action.checkIsDisabled && action.checkIsDisabled(row)) ? 'button-disable' : ''}>
                <CustomButton disabled={action.checkIsDisabled ? action.checkIsDisabled(row) : false} className="action-button" size="small" variant={"text"}>{this.state.spinnerPoint[0] === index && this.state.spinnerPoint[1] === actionIndex ? 'loading' : action.caption}</CustomButton>
            </NavLink>)
    }
}