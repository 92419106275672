import { Route } from "react-router-dom"
import AdminFinancialsRoute from "../financials/AdminFinancialsRoute"
import AdminShopsRoute from '../shops/AdminShopsRoute';
import AdminTicketsRoute from "../support-tickets/AdminTicketsRoute"
import AdminSupportTicketDetailsRoute from "../support-tickets/AdminTicketsDetailsRoute";
import AdminWalletTransactionsRoute from "../financials/AdminWalletTransactionsRoute";
import AdminReviewRequestRoute from "../review-requests/AdminRequestsRoute";
import AdminCategoriesRoute from "../categories/AdminCategoriesRoute";
import AdminCategoryManageRoute from "../categories/AdminCategoryManageRoute";
import AdminProductsRoute from "../products/AdminProductsRoute";
import AdminProductsManageRoute from "../products/AdminProductsManageRoute";
import AdminArticlesRoute from "../articles/AdminArticlesRoute";
import AdminCreateArticleRoute from "../articles/AdminCreateArticleRoute";
import AdminCreateArticleCategoryRoute from "../articles/AdminCreateArticleCategotyRoute";
import AdminUsersRoute from "../users/AdminUsersRoute";
import AdminSettingRoute from "../setting/AdminSettingRoute";
import AdminDashboardRoute from "../dashboard/AdminDashboardRoute";
import AdminMarketRoute from "../market/AdminMarketRoute";
import AdminCreateSellerShopRoute from "../shops/AdminCreateSellerShopRoute";
import AdminLayout from "../../../layouts/admin-layout/AdminLayout";

export default function AdminRoutes() {
  return (
    <>
      <Route element={<AdminLayout />}>
        <Route path="/admin/financials" element={<AdminFinancialsRoute />} />
        <Route path="/admin/walletTransactions" element={<AdminWalletTransactionsRoute />} />

        <Route path="/admin/shops" element={<AdminShopsRoute />} />

        <Route path="/admin/categories" element={<AdminCategoriesRoute />} />
        <Route path="/admin/category-manage" element={<AdminCategoryManageRoute />} />

        <Route path="/admin/products" element={<AdminProductsRoute />} />
        <Route path='/admin/products-manage' element={<AdminProductsManageRoute />} />

        <Route path='/admin/support-ticket' element={<AdminTicketsRoute />} />
        <Route path='/admin/support-ticket-report' element={<AdminSupportTicketDetailsRoute />} />

        <Route path='/admin/review-request' element={<AdminReviewRequestRoute />} />

        <Route path='/admin/article' element={<AdminArticlesRoute />} />
        <Route path='/admin/create-article' element={<AdminCreateArticleRoute />} />
        <Route path='/admin/create-articleCategory' element={<AdminCreateArticleCategoryRoute />} />

        <Route path='/admin/users' element={<AdminUsersRoute />} />

        <Route path='/admin/setting' element={<AdminSettingRoute />} />

        <Route path='/admin/dashboard' element={<AdminDashboardRoute />} />
        <Route path='/admin/market' element={<AdminMarketRoute />} />
        <Route path='/admin/create-seller' element={<AdminCreateSellerShopRoute />} />

      </Route>
    </>
  )
}
