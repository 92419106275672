import React from "react";
import { Box, CircularProgress, Container, Grid, TextField } from "@mui/material";
import { Navigate } from "react-router-dom";
import WalletService from "../../../../services/financials/WalletService";
import CartService from "../../../../services/shopping/CartService";
import SpinnerComponent from "../../../shared/spinner/SpinnerComponent";
import { CustomButton } from "../../../shared/button/ButtonComponent";
import Amount from "../../../utilities/Amount/Amount";
import { IoIosInformationCircleOutline } from "react-icons/io";
import * as signalR from '@microsoft/signalr';
import { FINANCIAL_API_URL } from "../../../../constants/Constants";
import axios from "axios";
import { InvoiceReadyToPaymentDtos } from "../../payment/payment-waiting/PaymentWaiting";

export default class BuyCredit extends React.Component<{ currencyId: string, amount: number }, {
    amount: string,
    feePercent: number, bill: {
        baseAmount: number,
        feeAmount: number,
        payableAmount: number
    }, walletId: string, walletBalance: number, pendingRequest: boolean, redirectToPaymentWaiting: boolean, invoiceId?: string
}> {
    walletService: WalletService;
    cartService: CartService;
    USDTCurrencyId: string = 'b8b8eaaa-c421-4783-b481-ae4fea6024de';
    billHubConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${FINANCIAL_API_URL}/Hubs/Bill?access_token=${axios.defaults.headers.common['authorization'].toString().replace("Bearer ", "") || ""}&owner_id=${axios.defaults.headers.common['ownerId'].toString() || ""}`, {
        })
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();

    constructor(props: any) {
        super(props);
        this.state = {
            amount: '0',
            feePercent: 0.5, walletId: '', walletBalance: 0, pendingRequest: false, redirectToPaymentWaiting: false,
            bill: { feeAmount: 0, baseAmount: props.amount, payableAmount: 0 }
        }
        this.walletService = new WalletService()
        this.cartService = new CartService()
    }

    componentDidMount() {
        this.billCalculation(this.props.amount.toString())
        this.loadWalletBalance()
        this.setWalletId()
        this.billHubConnection.start();
        this.billHubConnection.on("InvoiceReadyToPaymentEvent", (dto: InvoiceReadyToPaymentDtos) => this.setState({ invoiceId: dto.invoiceId }))
    }

    setWalletId = () => {
        this.walletService.getWalletList({}, { pagesize: 100, pageskip: 1 }).then(response => {
            let wallet = response.rows.filter(wallet => wallet.currencyId === this.props.currencyId)[0]

            this.setState({ walletId: wallet.id });
        })
    }

    loadWalletBalance = () => {
        this.walletService.getOverview().then((response) => {
            this.setState({ walletBalance: response.balance - response.blockedBalance });
        }).finally(() => { })
    }


    billCalculation = (amountStr: string) => {
        if (amountStr.indexOf('.') > 0 && amountStr.split('.')[1].length > 2)
            return;
        this.setState({
            amount: amountStr
        });

        var amount = parseFloat(amountStr);
        if (!isNaN(amount)) {
            let { feePercent } = this.state
            let feeAmount = amount / 100 * feePercent;
            let payableAmount = amount + feeAmount

            this.setState({
                bill: {
                    baseAmount: amount,
                    feeAmount,
                    payableAmount
                }
            });
        }
    }

    submit = () => {
        this.setState({ pendingRequest: true })

        this.walletService.buyCredit(this.state.walletId, { amount: this.state.bill.payableAmount }).then(() => {
            // this.setState({ redirectToPaymentWaiting: true })
        })
        // .finally(() => this.setState({ pendingRequest: false }))
    }

    render(): React.ReactNode {
        return <Container className="pt-xl">
            <div className="payment">
                <span className="font-lg font-bold">Wallet Top-up</span>
                {this.mainView()}
            </div>
        </Container>
    }

    alreadyPaidView = () => {
        return <p>This bill already paid</p>
    }

    mainView = () => {
        let { pendingRequest } = this.state

        if (this.state.invoiceId !== undefined)
            return <Navigate replace to={`/payment?invoiceId=${this.state.invoiceId}&type=walletTopUp`} />

        if (!this.state.bill)
            return <SpinnerComponent />

        return <>
            <Grid container className="mb-lg mt-lg">
                <Grid item md={8} pr={2}>
                    <div className="payment-box bg-white radius-lg">
                        {this.enterAmountView()}
                        <div className="mt-3 body-footer flex items-center justify-end">
                            <div className="pr-md">
                                <span className="font-md font-medium">Total:</span>
                                <span className="pl-xs font-md font-bold"><Amount value={this.state.bill.payableAmount} />$</span>
                            </div>
                            {
                                pendingRequest ?
                                    <CustomButton style={{ padding: '8px 34px' }} variant='contained' disabled><CircularProgress size={20} /></CustomButton> :
                                    <CustomButton disabled={this.state.bill.payableAmount <= 0} style={{ padding: '8px 34px' }} variant="contained" onClick={this.submit}>Pay</CustomButton>
                            }
                        </div>
                    </div>
                </Grid>
                <Grid item md={4} pl={1} style={this.state.bill.payableAmount === 0 ? { opacity: 0.5 } : {}}>
                    <div className="payment-box bg-white radius-lg">
                        {this.invoiceView()}
                    </div>
                </Grid>
            </Grid>
        </>
    }


    invoiceView = () => {
        return <>
            <Box bgcolor="#FAFAFA" sx={{ boxShadow: '0px 2px 5px 0px #AAAAAA40', borderRadius: '12px', color: '#333333' }} p={2}>
                {
                    this.state.bill.feeAmount > 0 && <>
                        <div className="pb-sm pt-sm font-medium text-grey-dark flex justify-between items-center">
                            <span>Top-up Amount</span>
                            <span className="font-bold"><Amount value={this.state.bill.baseAmount} /> $</span>
                        </div>
                    </>
                }
                <div className="pb-sm pt-sm font-medium text-grey-dark flex justify-between items-center">
                    <span>Api Gift Fee <IoIosInformationCircleOutline size={16} /></span>
                    <span>+ <Amount value={this.state.bill.feeAmount} /> $</span>
                </div>
                <div className="pb-md pt-sm font-medium text-grey-dark flex justify-between items-center">
                    <span>Payable Amount</span>
                    <span className="font-bold"><Amount value={this.state.bill.payableAmount} />  $</span>
                </div>
            </Box>
        </>
    }

    enterAmountView = () => {
        return <>
            <Grid container mt={4}>
                <Grid item xs={12} sm={12} md={12} lg={12} mb={4}>
                    <div className="font-bold font-md flex items-center"><span>Enter Amount</span></div>
                    <div className="font-medium font-sm pt-sm pb-md">Specify how much do you want to top up your shop wallet by entering the desired number in the Amount field.</div>
                    <TextField
                        fullWidth
                        id="buy-credit-amount"
                        type="number"
                        className="text-feild-filled"
                        placeholder="Enter Amount"
                        value={this.state.amount}
                        onChange={(e) => this.billCalculation(e.target.value)}
                    />
                </Grid>
            </Grid>
        </>
    }
}