import React from "react";
import { Alert, Button } from "react-bootstrap";
import OrdersService from "../../../services/orders/OrdersService";
import authenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext";
import TOTPForm from "../../identity/Security/TwoFactor/TOTPForm/TOTPForm";
import ShopAuthenticated from "../../shared/ShopAuthenticated/ShopAuthenticated";
import { ORDER_INFORMATION } from "../../../constants/ConstantsPermissions";
import Note from "../../shared/Note/Note";
import OrderItemsTableView from "./table/OrderItemsTableView";
import SpinnerComponent from "../../shared/spinner/SpinnerComponent";
import DeviceResponsiveHelper from "../../utilities/responsive-helper/DeviceResponsiveHelper";
import OrderItemsListCardView from "./list/OrderItemsListCardView";

interface OrderItemView {
    deliveredAt: string
    reviewRequestSupportTicketId: string
    id: string
    informations: string
    isSold: boolean
    orderType: number
    status: number
    title: string
}

interface OrderItemsState {
    items: OrderItemView[];
    isSafeMode: boolean;
    showOtpForm: boolean;
    loading: boolean;
}

export default class OrderItems extends React.Component<{ orderId: string, disableShowCode: boolean, hideAlert?: boolean, skipSafeMode?: boolean }, OrderItemsState> {
    ordersService: OrdersService;
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    constructor(props: any) {
        super(props);
        this.state = { items: [], isSafeMode: false, showOtpForm: false, loading: false };
        this.ordersService = new OrdersService();
    }
    componentDidMount() {
        this.loadTable((this.props.skipSafeMode && !this.context.twoFactorAvtive) ? true : false);
    }
    changeSafeMode = () => {
        if (!this.state.isSafeMode && this.context.twoFactorAvtive)
            this.setState({ showOtpForm: true });
        else
            this.loadTable(!this.state.isSafeMode);
    }
    loadTable(isSafeMode: boolean) {
        this.setState({ loading: true });
        this.ordersService.getOrderItems(this.props.orderId, isSafeMode).then((grid: any) => {
            this.setState({ items: grid.rows });
            this.setState({ isSafeMode: isSafeMode })
        }).catch((error) => {
            alert('permission denied');
        }).finally(() => {
            this.setState({ loading: false });
        });
    }
    onOTPset = () => {
        this.setState({ showOtpForm: false });
        this.loadTable(true);
    }
    render(): React.ReactNode {
        return (<>
            <ShopAuthenticated permission={ORDER_INFORMATION}>
                {
                    !this.props.hideAlert && <>
                        {
                            this.props.disableShowCode ? <>
                                <Alert variant="warning">
                                    This order added to inventory automatically, so you can see full information in <b>Inventories</b> section only!.
                                </Alert>
                            </> : <>
                                <Note>
                                    Click on <b>Show Code</b>  button to see full product information
                                </Note>
                                <Button size="sm" variant="warning" onClick={this.changeSafeMode}>
                                    {this.state.isSafeMode ? 'Hide Code' : 'Show Code'}
                                </Button>
                            </>
                        }
                    </>
                }

            </ShopAuthenticated>
            {
                this.state.loading ? <SpinnerComponent />
                    : <DeviceResponsiveHelper
                        mobile={<OrderItemsListCardView changeSafeMode={this.changeSafeMode} loadTable={() => this.loadTable(this.state.isSafeMode)} isSafeMode={this.state.isSafeMode} items={this.state.items} />}
                        desktop={<OrderItemsTableView changeSafeMode={this.changeSafeMode} loadTable={() => this.loadTable(this.state.isSafeMode)} isSafeMode={this.state.isSafeMode} items={this.state.items} />} />
            }
            <TOTPForm showModal={this.state.showOtpForm} onSubmit={this.onOTPset} onHide={() => this.setState({ showOtpForm: false })} />
        </>
        );
    }
}