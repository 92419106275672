import React from "react";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import ConfigurationService from "../../services/api/ConfigurationService";
import ApiDocument from "./ApiDocument/ApiDocument";
import TokensConfigs from "./Configuration/Tokens/TokensConfigs";
import WebHooksConfigs from "./Configuration/WebHooks/WebHooksConfigs";
import { Container } from "@mui/material";
import { Link45deg } from "react-bootstrap-icons";

export default class API extends React.PureComponent<{}, { apiKey: string, secretKey: string, loading: boolean }> {
    configurationService: ConfigurationService
    constructor(props: any) {
        super(props)
        this.configurationService = new ConfigurationService()
        this.state = { apiKey: '', secretKey: '', loading: true }
    }

    componentDidMount() {
        this.getPaged()
    }

    getPaged = () => {
        this.configurationService.get().then((response) => {
            this.setState({ apiKey: response.apiKey, secretKey: response.secretKey, })
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render(): React.ReactNode {
        return <Container className="pt-xl">
            <h4>
                You can see the information required by developers from <a href="https://docs.apigift.com" target="_blank" >Developer Section<Link45deg /></a>
            </h4> <br/>
            <Tabs defaultActiveKey="tokens" id="api-tabs" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                <Tab eventKey="tokens" title="Api Keys">
                    {
                        this.state.loading ? <Spinner animation="border" /> :
                            <TokensConfigs apiKey={this.state.apiKey} reload={this.getPaged} />
                    }
                </Tab>
                <Tab eventKey="webHook" title="WebHook Configs" disabled={this.state.apiKey === null}>
                    {this.state.apiKey && <WebHooksConfigs />}
                </Tab>
            </Tabs>
        </Container>
    }
} 
