import React, { FormEvent } from 'react';
import { Alert, Button, Col, Form, InputGroup, Modal, OverlayTrigger, Row, Spinner, Tab, Tabs, Tooltip } from 'react-bootstrap'
import IState, { IProps } from './TransferFund.models'
import { ArrowRightShort, CheckCircleFill, ClipboardPlus, Heart, Star, StarFill } from 'react-bootstrap-icons';
import TOTPForm from '../identity/Security/TwoFactor/TOTPForm/TOTPForm';
import Amount from '../utilities/Amount/Amount';
import authenticationContext from '../../contexts/AuthenticationContext/AuthenticationContext';
import QrCodeGenerator from '../shared/QrCodeGenerator/QrCodeGenerator';
import { NotificationManager } from 'react-notifications';
import ShopService from '../../services/shops/ShopService';
import ValidAmount from '../utilities/Amount/ValidAmount';
import FavoriteDestinationsModal from './FavoriteDestinations/FavoriteDestinationsModal';
import ModalComponent from '../shared/modal/Modal';
import { CustomButton } from '../shared/button/ButtonComponent';
import SpinnerComponent from '../shared/spinner/SpinnerComponent';
import { CircularProgress } from '@mui/material';

export default class WalletTransferFund extends React.Component<IProps, IState> {
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    shopService: ShopService;

    constructor(props: any) {
        super(props)
        this.shopService = new ShopService();
        this.state = {
            amount: '0',
            activeTab: 'send',
            destinationAddress: '',
            description: '',
            activeOTPMode: false,
            pendingRequest: false,
            isOpenFavoriteDestinationsModal: false,
            destinationOwnerTitle: null,
            isAddedDestinationToFavorite: false
        }
    }

    submitConfirm = () => {
        if (window.confirm(`Are you sure about transferring ${this.state.amount}?`))
            this.setState({ activeOTPMode: true })
    }

    submit = () => {
        this.props.submit(
            this.state.destinationAddress,
            Number(this.state.amount),
            this.state.description,
            this.state.isAddedDestinationToFavorite
        )
    }

    copyTextToClipboard = async (text: string) => {
        if ("clipboard" in navigator) {
            NotificationManager.info("Copied.")
            return await navigator.clipboard.writeText(text)
        }
    }

    onChangeWalletAddress = async (value: string, isAddedDestinationToFavorite: boolean = false) => {
        this.setState({ destinationAddress: value, destinationOwnerTitle: null, isAddedDestinationToFavorite })

        if (value != '' && value != null) {
            this.setState({ pendingRequest: true })

            await this.shopService.getShopInfo(value)
                .then((response) => this.setState({ destinationOwnerTitle: !response.value ? null : response.title ?? `Shop  ${response.value}` }))
                .finally(() => this.setState({ pendingRequest: false }))
        }
    }

    renderSendTabView = () => {
        const availableBalance = this.props.walletBalance - this.props.walletBlockedBalance;
        return <>
            {this.state.isOpenFavoriteDestinationsModal && <FavoriteDestinationsModal onSelect={(address) => this.onChangeWalletAddress(address, true)} onClose={() => this.setState({ isOpenFavoriteDestinationsModal: false })} />}
            {
                !this.props.isLoading &&
                <>
                    <Row>
                        <Col md="12" sm="12">
                            Balance: <Amount value={this.props.walletBalance} floor /><br />
                            Available Balance: <b><Amount value={availableBalance} floor /></b>
                        </Col>
                        <Col md="12" sm="12">
                            <Form.Group className="mb-2">
                                <Form.Label>Wallet Address (API Gift address):  </Form.Label>
                                <InputGroup>
                                    <Form.Control type="text" placeholder="Enter destination address"
                                        value={this.state.destinationAddress}
                                        onChange={(e) => this.onChangeWalletAddress(e.target.value)} />
                                    <Button variant="outline-secondary" size="sm" className="copy-btn" onClick={() => this.setState({ isOpenFavoriteDestinationsModal: true })}>
                                        <StarFill />
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                            {this.state.pendingRequest ? <Spinner animation="border" size="sm" /> :
                                this.state.destinationOwnerTitle &&
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='mt-1 mb-2'> Destination Owner: <b>{this.state.destinationOwnerTitle}</b></div>
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                Add To Favorite
                                            </Tooltip>
                                        }>
                                        {this.state.isAddedDestinationToFavorite ?
                                            <StarFill className='m-1' size="20px" onClick={() => this.setState({ isAddedDestinationToFavorite: false })} style={{ color: '#ffc107' }} /> :
                                            <Star className='m-1' size="20px" onClick={() => this.setState({ isAddedDestinationToFavorite: true })} />}
                                    </OverlayTrigger>
                                </div>
                            }
                        </Col>
                        <Col md="12" sm="12">
                            <Form.Group className="mb-2">
                                <Form.Label>Amount:  </Form.Label>
                                <Form.Control
                                    type="text"
                                    inputMode='decimal'
                                    placeholder="Enter transfer amount"
                                    value={this.state.amount}
                                    onChange={(e) => ValidAmount(e.target.value) && this.setState({ amount: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md="12" sm="12">
                            <Form.Group className="mb-2">
                                <Form.Label>Description (just for you):  </Form.Label>
                                <Form.Control type="text" placeholder="Enter your description"
                                    value={this.state.description}
                                    onChange={(e) => this.setState({ description: e.target.value })} />
                            </Form.Group>
                        </Col>
                    </Row>
                    {
                        availableBalance < Number(this.state.amount) &&
                        <Alert variant='danger'>The entered amount is higher than available balance (<b><Amount value={availableBalance} floor /></b>)</Alert>
                    }
                </>
            }
        </>
    }

    renderReceiveTabView = () => {
        let { walletId, isLoading } = this.props

        return (
            <>
                <h5>Scan the following QRCode</h5>(To share your wallet address with others)
                <Row>
                    <Col md='12' sm='12'>
                        <div style={{ textAlign: "center" }} className='p-4'>
                            {walletId !== "" && !isLoading ? (
                                <QrCodeGenerator keyGenerator={walletId} />
                            ) : (
                                <Spinner animation='grow' />
                            )}
                        </div>
                    </Col>
                    <Col md='12' sm='12'>
                        <div className='pb-4 pt-4 d-flex align-items-end'>
                            <span className='me-2'>Wallet address: </span>
                            <strong> {walletId} </strong>
                            <Button
                                variant='outline-secondary'
                                size='sm'
                                className='ms-4 mb-1'
                                onClick={() => this.copyTextToClipboard(walletId)}
                            >
                                <ClipboardPlus />
                            </Button>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }

    render(): React.ReactNode {

        if (this.props.showReceipt)
            return <>
                <Modal size="lg"
                    show={true}
                    backdrop="static"
                    onHide={this.props.onClose}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Successful transfer receipt in API Gift <CheckCircleFill color='green' /></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            !this.props.isLoading &&

                            <>
                                <Row>
                                    <Col md="12" sm="12">
                                        This receipt constitutes the final transfer.<br /><br />
                                        <ArrowRightShort /> amount: {this.state.amount}<br />
                                        <ArrowRightShort /> from: {this.props.walletId}<br />
                                        <ArrowRightShort /> to: {this.state.destinationAddress}<br />
                                        <ArrowRightShort /> transaction number: {this.props.transactionId}
                                        <hr />
                                        <Alert variant='success'>The amount of <b>{this.state.amount}</b> was transferred from your wallet with address <b>{this.props.walletId} </b>
                                            to another wallet with address <b>{this.state.destinationAddress}</b>.</Alert>
                                        <br />
                                    </Col>
                                </Row>
                            </>
                        }

                    </Modal.Body>
                </Modal >
            </>
        else
            return <>

                <ModalComponent title={'Wallet Transfer Fund'} size="800px" onClose={this.props.onClose}>
                    <Form>
                        <div>
                            <Tabs
                                activeKey={this.state.activeTab}
                                onSelect={(key) => this.setState({ activeTab: key })}
                                id='wallet-transfer-tabs'
                                className='mb-3'
                                mountOnEnter={true}
                                unmountOnExit={true}
                            >
                                <Tab eventKey='send' title='Send'>
                                    {
                                        (this.state.activeOTPMode && !this.context.twoFactorAvtive) ?
                                            <Alert variant='danger'>
                                                <b>Two-factor verification is disabled</b>
                                                <br />
                                                To transfer fund, you must activate your Two-factor
                                            </Alert> :
                                            this.renderSendTabView()
                                    }
                                </Tab>
                                <Tab eventKey='receive' title='Receive'>
                                    {this.renderReceiveTabView()}
                                </Tab>
                            </Tabs>
                        </div>
                        {
                            this.state.activeTab === 'send' &&
                            <div className='flex justify-end pt-md'>
                                {this.props.isLoading ? <CustomButton variant='contained' disabled><CircularProgress color="inherit" size={20} /></CustomButton> :
                                    <CustomButton variant='contained' onClick={() => this.submitConfirm()}>Transfer</CustomButton>}
                            </div>
                        }
                    </Form>
                </ModalComponent>
                {(this.state.activeOTPMode && this.context.twoFactorAvtive) && <TOTPForm showModal={this.state.activeOTPMode} onSubmit={this.submit} onHide={() => this.setState({ activeOTPMode: false })} />}
            </>
    }
}
