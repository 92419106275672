import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ADMIN, CONTENT_WRITER, MEDIATOR, SUPERADMIN, SUPPORT } from '../../../../constants/ConstantsPermissions';
import ShopAuthenticated from '../../../../components/shared/ShopAuthenticated/ShopAuthenticated';
import { NavLink } from 'react-router-dom';
import AuthenticationContext from '../../../../contexts/AuthenticationContext/AuthenticationContext';
import { Collapse, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import './AdminNavMenu.scss';
import { IoIosMenu } from "react-icons/io";
import AdminAuthenticated from '../../../../components/shared/AdminAuthenticated/AdminAuthenticated';
import { MdExpandLess } from "react-icons/md";
import { MdExpandMore } from "react-icons/md";

export interface IMenu {
    url: string,
    icon?: any,
    label: string,
    permission: string | null
    policy?: string[]
    roles: string[]
    childs?: IMenu[]
}

export default function AdminNavMenu() {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [currentRoute, setCurrentRoute] = React.useState(window.location.pathname);
    const [openMenuIndex, setOpenMenuIndex] = React.useState<number | null>(null); // State for collapsing

    const context = React.useContext(AuthenticationContext);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElNav(event.currentTarget);

    const handleCloseNavMenu = () => setAnchorElNav(null);

    const toggleCollapse = (index: number) => {
        setOpenMenuIndex(openMenuIndex === index ? null : index); // Toggle collapse
    };

    const renderNavItem = (item: IMenu) => (
        <MenuItem onClick={handleCloseNavMenu}>
            <NavLink to={item.url}>
                <Typography sx={{ textAlign: 'center' }}>{item.label}</Typography>
            </NavLink>
        </MenuItem>
    );

    const renderNavItemWeb = (item: IMenu, index: number) => (
        <ListItem sx={{ pt: 0, pb: 0, pr: 0, pl: 0 }} key={item.label}>
            {item.childs ? (
                <>
                    <ListItemButton onClick={() => toggleCollapse(index)}>
                        <ListItemText className={currentRoute.includes(item.url) ? 'active' : ' '} sx={{ color: 'pale_grey.main' }}>
                            <span className="menu-label font-medium text-nowrap">{item.label}</span>
                        </ListItemText>
                        {openMenuIndex === index ? <MdExpandLess /> : <MdExpandMore />}
                    </ListItemButton>
                    <Collapse in={openMenuIndex === index} timeout="auto" unmountOnExit className='box-shadow'>
                        <List component="div" disablePadding>
                            {item.childs.map((child) => (
                                <ListItemButton
                                    key={child.label}
                                    sx={{ pl: 4 }}
                                    onClick={() => setCurrentRoute(child.url)}
                                    component={NavLink}
                                    to={child.url}
                                >
                                    <ListItemText>
                                        <span className="menu-label font-medium text-nowrap">{child.label}</span>
                                    </ListItemText>
                                </ListItemButton>
                            ))}
                        </List>
                    </Collapse>
                </>
            ) : (
                <NavLink to={item.url}>
                    <ListItemButton disableTouchRipple onClick={() => { setCurrentRoute(item.url); }} sx={{ textAlign: 'center' }}>
                        <ListItemText className={currentRoute.includes(item.url) ? 'active' : ''} sx={{ color: 'pale_grey.main' }}>
                            <span className="menu-label font-medium text-nowrap">{item.label}</span>
                        </ListItemText>
                    </ListItemButton>
                </NavLink>
            )}
        </ListItem>
    );

    const items: IMenu[] = [
        { label: 'Dashboard', url: 'admin/dashboard', permission: null, roles: [ADMIN, SUPERADMIN] },
        { label: 'Financials', url: 'admin/financials', permission: null, roles: [ADMIN, SUPERADMIN] },
        {
            label: 'Market', url: ' ', permission: null, roles: [ADMIN, SUPERADMIN], childs: [
                { label: 'Shops', url: 'admin/shops', permission: null, roles: [ADMIN, SUPERADMIN] },
                { label: 'Sellers', url: 'admin/market', permission: null, roles: [ADMIN, SUPERADMIN] },
                { label: 'Categories', url: 'admin/categories', permission: null, roles: [ADMIN, SUPERADMIN] },
                { label: 'Products', url: 'admin/products', permission: null, roles: [ADMIN, SUPERADMIN] },
            ]
        },
        { label: 'Users', url: 'admin/users', permission: null, roles: [ADMIN] },
        {
            label: 'Support', url: ' ', permission: null, roles: [ADMIN, SUPERADMIN, SUPPORT], childs: [
                { label: 'Help Center', url: 'admin/support-ticket', permission: null, roles: [ADMIN, SUPERADMIN, SUPPORT] },
                { label: 'Review Request', url: 'admin/review-request', permission: null, roles: [ADMIN, SUPERADMIN, MEDIATOR] },
            ]
        },
        { label: 'Article', url: 'admin/article', permission: null, roles: [ADMIN, SUPERADMIN, CONTENT_WRITER] },
        { label: 'Setting', url: 'admin/setting', permission: null, roles: [ADMIN, SUPERADMIN, CONTENT_WRITER] },
    ];

    return (
        <div className='admin-navmenu'>
            {/* Web layout */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <List sx={{ display: 'flex', pt: 0, pb: 0 }}>
                    {items.map((item, index) => {
                        let allowPolicy = false;

                        if (item.policy) {
                            for (const policy of item.policy) {
                                allowPolicy = context.policies?.includes(policy);
                                if (allowPolicy) break;
                            }
                        }

                        return (
                            <React.Fragment key={index}>
                                {item.permission !== null ? (
                                    <AdminAuthenticated roles={item.roles}>
                                        {item.policy && item.policy.length > 0
                                            ? allowPolicy && renderNavItemWeb(item, index)
                                            : renderNavItemWeb(item, index)}
                                    </AdminAuthenticated>
                                ) : item.policy && item.policy.length > 0
                                    ? allowPolicy && renderNavItemWeb(item, index)
                                    : renderNavItemWeb(item, index)}
                            </React.Fragment>
                        );
                    })}
                </List>
            </Box>

            {/* Mobile layout */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                >
                    <IoIosMenu color='#4F4F4F' />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{ display: { xs: 'block', md: 'none' } }}
                >
                    {items.map((item, index) => {
                        let allowPolicy = false;

                        if (item.policy) {
                            for (const policy of item.policy) {
                                allowPolicy = context.policies?.includes(policy);
                                if (allowPolicy) break;
                            }
                        }

                        return (
                            <React.Fragment key={index}>
                                {item.permission !== null ? (
                                    <ShopAuthenticated permission={item.permission as string}>
                                        {item.policy && item.policy.length > 0
                                            ? allowPolicy && renderNavItem(item)
                                            : renderNavItem(item)}
                                    </ShopAuthenticated>
                                ) : item.policy && item.policy.length > 0
                                    ? allowPolicy && renderNavItem(item)
                                    : renderNavItem(item)}
                            </React.Fragment>
                        );
                    })}
                </Menu>
            </Box>
        </div>
    );
}
