import React, { RefObject } from "react"
import { Card, Modal } from "react-bootstrap"
import InventoryShopCollectionService, { IDeficitsPaginationFilters } from "../../../services/inventory/InventoryShopCollectionService"
import Grid from "../../Grid/Grid"
import { GridAction, GridColumn, GridColumnTypes } from "../../Grid/Grid.models"
import { NotificationManager } from 'react-notifications'
import CreateInventory from "../../inventory/CreateInventory/CreateInventory"
import DynamicGrid from "../../shared/DynamicGrid/DynamicGrid"


export default class Deficits extends React.PureComponent<{ cardView?: boolean, productId?: string }, {
    hsaData?: boolean,
    selectedInComming?: {
        productId: string,
        orderNumber: string,
        sourceId: string
        deficitCount: number
    }
}> {
    inventoryShopCollectionService: InventoryShopCollectionService
    dynamicGrid: RefObject<DynamicGrid<IDeficitsPaginationFilters>>;
    constructor(props: any) {
        super(props)
        this.state = {}
        this.dynamicGrid = React.createRef()
        this.inventoryShopCollectionService = new InventoryShopCollectionService()
    }

    componentDidMount() {
        // this.reloadGrid()
    }

    // reloadGrid() {
    //     this.inventoryShopCollectionService.getDeficits({ productId: this.props.productId }, { pagesize: 10, pageskip: 1 }).then((response) => {
    //         this.grid.current?.load(response, response.length)
    //         this.setState({ hsaData: response.length > 0 })

    //     })
    // }

    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: 'Title', key: 'title' },
            { title: 'Source', key: 'sourceTitle' },
            { title: 'Order Number', key: 'orderNumber' },
            { title: 'Count', key: 'count' },
            { title: 'Date', key: 'date', type: GridColumnTypes.Date },
        ];

        let gridActions: GridAction[] = [
            { caption: '+', variant: 'success', skipSpinner: true, onClick: (row) => this.setState({ selectedInComming: { productId: row.productId, orderNumber: row.orderNumber, sourceId: row.sourceId, deficitCount: row.count} }) },
        ];

        return <>
            {/* {
                <Grid ref={this.grid} columns={gridColumns} actions={gridActions}></Grid>
            } */}

            <DynamicGrid<IDeficitsPaginationFilters> ref={this.dynamicGrid}
                columns={gridColumns}
                onFetch={this.inventoryShopCollectionService.getDeficits}
                defaultFilters={{ productId: this.props.productId }}
                actions={gridActions}
            />
            {this.state.selectedInComming !== undefined &&
                <Modal size="xl"
                    show={true}
                    backdrop="static"
                    onHide={() => this.setState({ selectedInComming: undefined })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Inventory - {this.state.selectedInComming.orderNumber}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CreateInventory productId={this.state.selectedInComming.productId} inComming={this.state.selectedInComming} onAdded={() => {
                            NotificationManager.success("Your inventory added successfully")
                            this.setState({ selectedInComming: undefined })
                            this.dynamicGrid.current.fetch();
                        }} />
                    </Modal.Body>
                </Modal>
            }
        </>
    }
}