import { useSearchParams } from "react-router-dom";
import Payment from "../../components/financials/payment/Payment";
import BuyCredit from "../../components/financials/wallets/BuyCredit/BuyCredit";

export default function BuyCreditRoute() {
    const [searchParams] = useSearchParams()
    let currencyId = searchParams.get("currencyId")
    let amount = Number.parseFloat((searchParams.get("amount") ?? '0'))

    return <BuyCredit currencyId={currencyId} amount={amount} />
}
