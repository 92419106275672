import React, { FormEvent } from "react";
import authenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext";
import IdentityService from "../../../services/identity/IdentityService";
import TOTPForm from "../Security/TwoFactor/TOTPForm/TOTPForm";
import { NotificationManager } from 'react-notifications';
import { Navigate, NavLink } from "react-router-dom";
import { CircularProgress, Grid, TextField } from "@mui/material";
import { CustomButton } from "../../shared/button/ButtonComponent";

export default class SignIn extends React.Component<{}, { showPassword: boolean, pendingRequest: boolean, username: string, password: string, activeOTPMode: boolean, loading: boolean, logged: boolean, hasAccessToStore: boolean }> {
    identityService: IdentityService;
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;

    constructor(props: any) {
        super(props);
        this.identityService = new IdentityService();
        this.state = { pendingRequest: false, username: null, password: null, activeOTPMode: false, loading: false, logged: false, hasAccessToStore: true, showPassword: false };

    }

    loginForm = (e: FormEvent<HTMLFormElement>, totp?: string | undefined) => {
        e.preventDefault();
        e.stopPropagation();
        this.login();
    }
    login = (otp?: string | undefined) => {
        this.setState({ pendingRequest: true });

        this.identityService.login(this.state.username, this.state.password, otp).then((response) => {

            if (response.data.accessToken === null && response.data.hasTwoFactor)
                this.openTotpForm();
            else
                this.logon(response.data.accessToken, response.data.hasAccessToStore);

        }).catch((error) => {

            if (error.message === 'Network Error') {
                return;
            }

            if (otp === undefined)
                NotificationManager.error('Invalid username or password');
            else
                NotificationManager.error('Invalid your OTP code');
        }).finally(() => {
            this.setState({ pendingRequest: false });
        });
    }
    logon = (token: string, hasAccessToStore: boolean) => {
        this.context.login(token);
        this.setState({ logged: true, hasAccessToStore });
    }
    openTotpForm = () => {
        this.context.twoFactorAvtive = true;
        this.setState({ activeOTPMode: true });
    }
    render(): React.ReactNode {
        let { username, password, pendingRequest, showPassword } = this.state

        return <div className="full-width">
            {
                this.state.logged && (this.state.hasAccessToStore ? <Navigate to='/marketplace' /> : <Navigate to='/create-shop-only' />)
            }
            <div className="font-lg font-bold text-charcoal text-center web">Login</div>
            <div className="mini-border-line web"></div>
            <form onSubmit={this.loginForm}>
                <Grid container mt={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} mb={3}>
                        <label className="pb-sm font-sm text-charcoal-dark">Email address</label>
                        <TextField
                            fullWidth
                            id="email"
                            className="text-feild-filled"
                            placeholder="Enter email address"
                            variant="outlined"
                            value={username}
                            error={username === ""}
                            onChange={(e) => this.setState({ username: e.target.value })}
                        />

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} mb={4}>
                        <div className="flex justify-between">
                            <label className="pb-sm font-sm text-charcoal-dark">Password</label>
                            <div><NavLink to={'/forgot-password'} className="pb-sm font-sm text-charcoal-dark">Forgot?</NavLink></div>
                        </div>
                        <TextField
                            fullWidth
                            id="password"
                            className="text-feild-filled"
                            placeholder="Enter password"
                            type={showPassword ? "text" : "password"}
                            value={password}
                            error={password === ""}
                            onChange={(e) => this.setState({ password: e.target.value })}
                        />
                    </Grid>
                </Grid>
                {
                    pendingRequest ?
                        <CustomButton sx={{ borderRadius: "12px" }} variant="contained" disabled fullWidth>
                            <CircularProgress color="inherit" size={20} />
                        </CustomButton> :
                        <CustomButton sx={{ borderRadius: "12px" }} variant="contained" fullWidth onClick={() => this.login()}>Login</CustomButton>
                }
                <div className="pt-md">
                    <NavLink to={'/sign-up'} className="font-sm">Not registered?</NavLink>
                </div>
                <TOTPForm login={(token, hasAccessToStore) => { this.logon(token, hasAccessToStore) }} isLoginPage signInInformation={{ password: this.state.password, userName: this.state.username }} hasRecovery showModal={this.state.activeOTPMode} onSubmit={this.login} onHide={() => this.setState({ activeOTPMode: false })} loading={this.state.loading} />
            </form>
        </div>
    }
}