import React from "react";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { BarChartLine, Grid } from "react-bootstrap-icons";
import CardView from "../../../../shared/Card/CardView";
import IState, { IProps } from "./PlanningConfig.models";
import StaticPlan from "./StaticPlan/StaticPlan";
import PriceComparison from "../PriceComparison";

export default class PlanningConfig extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = { plans: [] }
    }

    componentDidMount() {
        if (this.props.plans !== undefined) {
            this.setState({
                plans: this.props.plans,
                isMultiPlan: this.props.plans.filter(plan => plan.isActive).length > 1
            })
        }
    }

    componentDidUpdate(prevState: any, prevProps: any) {
        if ((prevProps.plans !== this.props.plans) && this.props.plans !== undefined)
            this.setState({ plans: this.props.plans })
    }

    togglesShow = (index: number) => {
        let tempPlans = this.state.plans
        if (tempPlans !== undefined) {
            tempPlans[index].isActive = !tempPlans[index].isActive
            this.updateState(tempPlans)
        }
    }

    changeCountFrom = (newValue: number, index: number) => {
        let tempPlans = this.state.plans
        if (tempPlans !== undefined) {
            tempPlans[index].countFrom = newValue;
            this.updateState(tempPlans)
        }
    }

    changeCountTo = (newValue: number, index: number) => {
        let tempPlans = this.state.plans
        if (tempPlans !== undefined) {
            tempPlans[index].countTo = newValue
            this.updateState(tempPlans)
        }
    }

    changeProfitPercent = (e: string, index: number) => {
        let tempPlans = this.state.plans
        if (tempPlans !== undefined) {
            tempPlans[index].profitPercent = e
            this.updateState(tempPlans)
        }
    }

    updateState = (tempPlans: any[]) => {
        this.setState({ plans: tempPlans })
        this.forceUpdate()
    }

    getPrice = (percent?: string) => percent && this.props.basePrice + ((this.props.basePrice / 100) * Number(percent))

    getFinalPrice = (price?: number) => price && ((price + ((price / 100) * this.props.sharedPercent)))

    render(): React.ReactNode {
        const { plans } = this.state

        const oneUnitPlanPrice = plans.length > 0 ? this.getFinalPrice(this.getPrice(plans[0].profitPercent)) : 0;
        const planPrices = plans.filter(plan => plan.isActive)
            .map(plan => this.getFinalPrice(this.getPrice(plan.profitPercent)));
        const apiPlanPrice = Math.min(...planPrices);

        return <>
        
            <CardView card={{}}>
                {this.state.isMultiPlan !== undefined && <Tabs defaultActiveKey={this.state.isMultiPlan ? 'multi' : 'single'} className="mb-3" onSelect={(key) => this.setState({ isMultiPlan: key === 'multi' ? true : false })} mountOnEnter={true} unmountOnExit={true}>
                    <Tab eventKey="single" title={<>Single</>}>
                        {this.renderSingleView()}
                    </Tab>
                    <Tab eventKey="multi" title={<>Multi <sup><Grid /></sup></>}>
                        {this.renderMultiView()}
                    </Tab>
                </Tabs>}

            </CardView>

            <div className="pt-3">
            </div>
            <div className="text-pale-grey-main font-bold pt-md pb-sm"><BarChartLine /> Price comparison</div>
            <PriceComparison productId={this.props.productId} apiPlanPrice={apiPlanPrice} oneUnitPlanPrice={oneUnitPlanPrice} />
        </>
    }

    renderSingleView() {
        const { sharedPercent, isActivePro } = this.props
        const { plans } = this.state

        return <Row className="mb-5">
            <Col md="6" sm="12">
                {(plans && plans.length > 0) &&
                    <StaticPlan finalPrice={plans[0].profitPercent && this.getFinalPrice(this.getPrice(plans[0].profitPercent))} sharedPercent={sharedPercent}
                        profitPercent={isActivePro ? plans[0].profitPercent : undefined} changeProfitPercent={(value) => this.changeProfitPercent(value, 0)} />
                }
            </Col>
        </Row>
    }

    renderMultiView() {
        const { sharedPercent } = this.props
        const { plans } = this.state


        return <Row className="mb-5">
            {(plans && plans.length > 0) &&
                <>{
                    plans.map((plan, index) => {
                        return <Col md="6" sm="12" key={index} className="p-3">
                            <Form.Check id={`plan${index + 1}`} type="checkbox" label={`Plan ${index + 1}`} checked={plan.isActive} disabled={(plans.filter(item => item.isActive === true).length > index + 1) || (index === 0 ? false : !plans[index - 1].isActive) || (plans[index + 1] !== undefined && plans[index + 1].isActive === true)} onChange={() => { this.togglesShow(index) }} />
                            {
                                plan.isActive &&
                                <StaticPlan finalPrice={plan.profitPercent && this.getFinalPrice(this.getPrice(plan.profitPercent))} profitPercent={plan.profitPercent}
                                    sharedPercent={sharedPercent} countFrom={plan.countFrom} countTo={plan.countTo}
                                    changeProfitPercent={(value) => this.changeProfitPercent(value, index)}
                                    changeCountFrom={(value) => { this.changeCountFrom(value, index); }}
                                    changeCountTo={(value) => { this.changeCountTo(value, index); this.changeCountFrom(value + 1, index + 1); }}
                                    price={this.getPrice(plan.profitPercent)} />
                            }
                        </Col>
                    })
                }</>
            }
        </Row>
    }
}