import React from "react";
import { Col, Form } from "react-bootstrap";
import { NotificationPermissionRowDto } from "../../../../services/notifications/NotificationPermissionService";
import { CustomButton } from "../../../shared/button/ButtonComponent";
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

interface INotificationPermissaionState {
    isActiveAll: boolean
    permissions: NotificationPermissionRowDto[]
}

export default class NotificationPermissionsList extends React.PureComponent<{ onSubmit: (newPermissions: NotificationPermissionRowDto[]) => void, pendingRequest: boolean, permissions: NotificationPermissionRowDto[] }, INotificationPermissaionState> {
    constructor(props: any) {
        super(props);
        this.state = { permissions: [], isActiveAll: false };
    }

    componentDidMount(): void {
        this.setState({ permissions: this.props.permissions, isActiveAll: this.props.permissions.filter(item => item.isActive).length == this.props.permissions.length })
    }

    toggleSelectAll = () => {
        let updatedPermissions = this.state.permissions.map((privilege) => ({
            ...privilege,
            isActive: !this.state.isActiveAll,
        }));

        this.setState({ isActiveAll: !this.state.isActiveAll, permissions: updatedPermissions })
    }

    selectRow = (row: NotificationPermissionRowDto) => {
        let tmpPrivileges: NotificationPermissionRowDto[] = this.state.permissions
        tmpPrivileges.filter((item) => item.id === row.id).map((privilege) => {
            return privilege.isActive = !privilege.isActive
        })

        this.setState({ permissions: tmpPrivileges, isActiveAll: tmpPrivileges.filter(item => !item.isActive).length > 0 ? false : true })
        this.forceUpdate()
    }

    render(): React.ReactNode {
        return (
            <>
                <TableContainer className="grid mt-md">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Title</TableCell>
                                <TableCell><Form.Check type="checkbox" checked={this.state.isActiveAll} onChange={() => this.toggleSelectAll()} /></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.state.permissions.map((permission) =>
                                    <TableRow key={permission.id}>
                                        <TableCell>{permission.title}</TableCell>
                                        <TableCell><Form.Check type="checkbox" checked={permission.isActive} onChange={() => this.selectRow(permission)} /></TableCell>
                                    </TableRow>)
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Col md="12" sm="12" className="mt-3">
                    {this.props.pendingRequest ? <CustomButton variant='contained' disabled><CircularProgress size={20} color="inherit" /></CustomButton> :
                        <CustomButton variant='contained' onClick={() => this.props.onSubmit(this.state.permissions)}>Update</CustomButton>}
                </Col>
            </>
        )
    }
}