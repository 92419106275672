import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import TelegramBot from "./TelegramBot/TelegramBot";
import NotificationPermissaion from "./permissions/NotificationPermissions";
import { Container } from "@mui/material";

export default class Notifications extends React.PureComponent<{}, {}> {
    render(): React.ReactNode {
        return (
            <Container className="mt-lg">
                <Tabs defaultActiveKey={'telegramBot'} id="notifications-tabs" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                    <Tab eventKey="telegramBot" title="Telegram Bot">
                        <TelegramBot />
                    </Tab>
                    <Tab eventKey="email" title="Email">
                        <NotificationPermissaion />
                    </Tab>
                </Tabs>
            </Container>
        )
    }
}