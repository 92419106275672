import { Route } from "react-router-dom";
import EditCategoryFavoriteRoute from "../categories/EditCategoryFavoriteRoute";
import MainLayout from "../../layouts/main-layout/MainLayout";

export default function CategoriesRoutes() {
    return (
        <>
            <Route element={<MainLayout showLogo/>}>
                <Route path="/edit-category-favorite" element={<EditCategoryFavoriteRoute />} />
            </Route>
        </>
    )
}