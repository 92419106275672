import React from "react";
import { GrCircleInformation } from "react-icons/gr";
import { NavLink } from "react-router-dom";
import { OrderProductStatusEnum } from "../Enums/OrderProductStatusEnum";
import OrderItemReportModal from "../OrderItemReportModal/OrderItemReportModal";
import { CustomButton } from "../../shared/button/ButtonComponent";

export default class OrderReviewRequest extends React.Component<{ orderItemId: string, reviewRequestSupportTicketId?: string, onReported?: () => void, status: OrderProductStatusEnum }, { showModal: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = { showModal: false };
    }

    onReported = () => {
        this.setState({ showModal: false });
        this.props.onReported();
    }

    openReportModal = () => {
        this.setState({ showModal: true });
    }

    render(): React.ReactNode {
        return <>
            <div className="web">
                {
                    this.props.reviewRequestSupportTicketId ?
                        <NavLink to={`/support-ticket?id=${this.props.reviewRequestSupportTicketId}`}><GrCircleInformation size={21} className="text-grey" /></NavLink>
                        : <div className="pointer" onClick={this.openReportModal}><GrCircleInformation size={21} className="text-grey" /></div>
                }
            </div>
            <div className="mobile ">
                {
                    this.props.reviewRequestSupportTicketId ?
                        <NavLink to={`/support-ticket?id=${this.props.reviewRequestSupportTicketId}`}><CustomButton fullWidth variant="outlined">Report</CustomButton></NavLink>
                        : <CustomButton fullWidth onClick={this.openReportModal} variant="outlined">Report</CustomButton>
                }
            </div>
            {
                this.state.showModal && <OrderItemReportModal productId={this.props.orderItemId} onReported={this.onReported} status={this.props.status}
                    closeModal={() => this.setState({ showModal: false })}
                    showModal={this.state.showModal} />
            }
        </>
    }
}