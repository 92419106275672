import { Route } from "react-router-dom";
import NotificationsRoute from "../notifications/NotificationsRoute";
import MainLayout from "../../layouts/main-layout/MainLayout";

export function NotificationsRoutes() {
    return (
        <>
            <Route element={<MainLayout showLogo/>}>
                <Route path="/notifications-setting" element={<NotificationsRoute />}/>
            </Route>
        </>
    )
}