import React from "react";
import { Spinner } from "react-bootstrap";
import ICartState, { ICartProps } from "./CartView.models";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import TruncateString from "../../../utilities/truncate-string/TruncateString";

export default class CartView extends React.Component<ICartProps, ICartState> {
    constructor(props: any) {
        super(props);
        this.state = { loading: false }
    }

    render(): React.ReactNode {
        return <div>
            <div className="flex justify-between items-center">
                <Typography sx={{ fontSize: '20px' }}><span className="font-bold">Cart</span></Typography>
                {/* <CustomButton variant="text" color="info">
                    <EditorIcon /> <span className="font-medium pl-xs">Change Order</span>
                </CustomButton> */}
            </div>
            {this.state.loading ? <Spinner animation="grow" /> :
                <div className="mt-lg">
                    <TableContainer className="simple-table font-main">
                        <Table sx={{ minWidth: 320 }} aria-label="table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ pl: 0 }} align="left">Product</TableCell>
                                    <TableCell align="center">Count</TableCell>
                                    <TableCell sx={{ pr: 0 }} align="right">Price</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.products.map((item, key) => (
                                    <TableRow
                                        key={key}
                                    >
                                        <TableCell sx={{ pl: 0 }} align="left"><TruncateString text={item.title} maxLength={25} /></TableCell>
                                        <TableCell align="center">{item.count}</TableCell>
                                        <TableCell sx={{ pr: 0 }} align="right">{item.unitPrice} $</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            }
        </div>
    }
}