import React from 'react';
import { useMediaQuery } from '@mui/material';

interface IProps {
  desktop: any;
  mobile: any;
  tablet?: any
}

const DeviceResponsiveHelper: React.FC<IProps> = ({ desktop, mobile, tablet }) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(min-width:601px) and (max-width:1023px)');
  const isDesktop = useMediaQuery('(min-width:1023px)');

  if (isMobile || isTablet) return (<>{mobile}</>)
  else return (<>{desktop}</>)
};

export default DeviceResponsiveHelper;