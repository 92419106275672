import React, { RefObject } from "react";
import { Button, Col, Form, Modal, Row, Spinner, } from "react-bootstrap";
import TwoFactorService from "../../../../../services/identity/TwoFactorService";
import ITwoFactorState, { ITwoFactorProps } from "./TwoFactorStepsModal.models";
import { NotificationManager } from 'react-notifications';
import QrCodeGenerator from "../../../../shared/QrCodeGenerator/QrCodeGenerator";
import ModalComponent from "../../../../shared/modal/Modal";
import SpinnerComponent from "../../../../shared/spinner/SpinnerComponent";
import { CustomButton } from "../../../../shared/button/ButtonComponent";
import { CircularProgress } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";

export default class TwoFactorStepsModal extends React.PureComponent<ITwoFactorProps, ITwoFactorState> {
    twoFactorService: TwoFactorService
    input: RefObject<HTMLInputElement>
    constructor(props: any) {
        super(props)
        this.state = { pendingRequest: false, loading: false, totp: '', key: '', recoveryCodes: [], showRecoveryCode: false }
        this.twoFactorService = new TwoFactorService()
        this.input = React.createRef()
    }

    componentDidMount() {
        this.getToken()
        this.focusToInput()
    }

    focusToInput = () => {
        this.input.current?.focus();
    }

    submit = () => {
        const { totp } = this.state
        if (totp === '') return NotificationManager.error("Please enter the token");
        if (totp.length !== 6) return NotificationManager.error("The code entered must be 6 digits, please enter correctly");

        this.twoFactorService.verify(totp).then((response) => {
            if (response.isVerified) {
                NotificationManager.success("Two-factor has been activated for your account.")
                this.setState({ recoveryCodes: response.recoveryCodes, showRecoveryCode: true, pendingRequest: true, totp: '' })
                this.props.onHandle()
            }
        })
    }


    getToken = () => {
        this.twoFactorService.getToken().then((response) => {
            this.setState({ key: response })
        })
    }

    render(): React.ReactNode {
        const { pendingRequest, showRecoveryCode } = this.state
        const { closeModal } = this.props

        return (
            <ModalComponent
                
                size="500px"
                onClose={closeModal}
                title={"Two-factor"}>
                <div> {!showRecoveryCode ? this.qrCodeScanView() : this.recoveryCodeView()}</div>
                <ModalComponent.Footer>
                    {!showRecoveryCode ?
                        pendingRequest ? <CustomButton variant='contained' disabled><CircularProgress size={20} color="inherit" /></CustomButton> :
                            <CustomButton variant='contained' onClick={this.submit}>Verify</CustomButton>
                        : <label className="text-danger">
                            These are secret codes that once you forgot your password or had not access to your phone to use authentication code, they will be required. DONT SHARE IT WITH ANYONE and write/paste somewhere safe.
                        </label>
                    }
                </ModalComponent.Footer>
            </ModalComponent >
        )
    }

    qrCodeScanView() {
        const { loading, totp, key } = this.state
        return (
            <>
                <h5>Scan the following QRCode</h5>(Recommended App: Google Authenticator)
                <Row>
                    <Col md="12" sm="12">
                        <div style={{ textAlign: 'center' }} className="p-4">
                            {key !== '' && !loading ? <QrCodeGenerator keyGenerator={key} /> :
                                <SpinnerComponent />
                            }
                        </div>
                    </Col>
                    <Col md="12" sm="12">
                        <Form.Group>
                            <Form.Label>Enter the 6 digit code from the app once the scan is complete. </Form.Label>
                            <Form.Control ref={this.input} value={totp} onChange={e => this.setState({ totp: e.target.value })} type="text" placeholder="Type code..." />
                        </Form.Group>
                    </Col>
                </Row>
            </>
        )
    }

    recoveryCodeView() {
        return (
            <div style={{ textAlign: 'center' }}>
                <h5>Recovery Codes</h5>{this.state.recoveryCodes.map((code, index) => { return (<span className="text-danger p-2"> {code} &nbsp; {(index + 1) % 2 === 0 && <br />}</span>) })}
            </div>
        )
    }
}