import React from "react";
import { Modal, OverlayTrigger, Tab, Tabs, Tooltip } from "react-bootstrap";
import OrdersService from "../../../services/orders/OrdersService";
import HubridMoment from "../../utilities/HybridMoment";
import orderStatusDisplayBadge from "../OrderEnums";
import OrderItems from "./OrderItems";
import { OrderTypeEnum } from "../Enums/OrderTypeEnum";
import { Stack } from "react-bootstrap-icons";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import ModalComponent from "../../shared/modal/Modal";

interface OrderDerailsView {
    type: OrderTypeEnum,
    orderNumber: string;
    productCount: number;
    orderAt: string;
    status: number;
    totalPrice: number;
    addToInventory: boolean;
    products: OrderDetailsItemView[];
}

interface OrderDetailsItemView {
    productCount: number;
    title: string;
    totalAmount: number;
    unitPrice: number;
}

interface OrderDerailsState {
    order: OrderDerailsView;
    show: boolean;
}

export default class OrderDetails extends React.Component<{ orderId: string, onClose: () => void, onLoaded: () => void }, OrderDerailsState> {
    ordersService: OrdersService;
    constructor(props: any) {
        super(props);
        this.state = { order: { orderNumber: '', orderAt: '', productCount: 0, status: 0, totalPrice: 0, products: [], type: OrderTypeEnum.Website, addToInventory: false }, show: false };
        this.ordersService = new OrdersService();
    }
    componentDidMount() {
        this.loadDetails();
    }
    componentDidUpdate(prevProps: { orderId: string }, prevState: OrderDerailsState) {
        if (this.props.orderId !== prevProps.orderId)
            this.loadDetails();
    }
    loadDetails = () => {
        this.ordersService.getDetails(this.props.orderId).then((details: any) => {
            this.setState({ show: true, order: { ...details } });
            this.props.onLoaded();
        });
    }
    close = () => {
        this.setState({ show: false });
        this.props.onClose();
    }
    render(): React.ReactNode {
        return (<ModalComponent
            size="800px"
            title={`Order ${this.state.order.orderNumber}`}
            onClose={this.close}
        >
            <div>
                <TableContainer className="grid mb-4">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Status</TableCell>
                                <TableCell>Total Price</TableCell>
                                <TableCell>Count</TableCell>
                                <TableCell>Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    {orderStatusDisplayBadge(this.state.order.status)}
                                    <span style={{ display: 'inline-block', marginLeft: '5px' }}>
                                        {
                                            this.state.order.addToInventory && this.state.order.status == 1 &&
                                            <OverlayTrigger
                                                key="top"
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="tooltip-top">
                                                        This order items added to your inventory
                                                    </Tooltip>
                                                }
                                            >
                                                <div style={{ color: 'green' }}>
                                                    <Stack />
                                                </div>
                                            </OverlayTrigger>
                                        }
                                    </span>
                                </TableCell>
                                <TableCell>{this.state.order.totalPrice}</TableCell>
                                <TableCell>{this.state.order.productCount}</TableCell>
                                <TableCell>{HubridMoment(this.state.order.orderAt)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Tabs
                    defaultActiveKey="overview"
                    transition={false}
                    className="mb-3"
                >
                    <Tab eventKey="overview" title="Overview">
                        {this.overview()}
                    </Tab>
                    <Tab eventKey="items" title="Items">
                        <OrderItems orderId={this.props.orderId} disableShowCode={this.state.order.addToInventory} />
                    </Tab>
                </Tabs>
            </div>
        </ModalComponent>);
    }

    overview() {
        return <TableContainer className="grid mt-lg mb-lg">
            <Table aria-label="table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">#</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Count</TableCell>
                        <TableCell>Total Price</TableCell>
                        <TableCell>Unit Price</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.state.order.products.map((product, index) =>
                        <TableRow>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell>{product.title}</TableCell>
                            <TableCell>{product.productCount}</TableCell>
                            <TableCell>{product.totalAmount}</TableCell>
                            <TableCell>{product.unitPrice}</TableCell>
                        </TableRow>)}
                    <TableRow></TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    }
}