import { AxiosError } from 'axios';
import React, { ReactElement } from 'react';
import { Button, Modal, Form, Spinner, Row, Col, Alert } from 'react-bootstrap'
import { ExclamationCircleFill } from 'react-bootstrap-icons';
import { Navigate, NavLink } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { JsxElement } from 'typescript';
import InventoryService from '../../../services/inventory/InventoryService'
import shopInventoryStatusDisplayBadge, { shopInventoryStatusDisplayCaption, ShopInventoryStatusEnum } from '../Enums/ShopInventoryStatusEnum'
import IState, { IProps } from './ChangeInventoryProductModal.models'
import ModalComponent from '../../shared/modal/Modal';
import { CustomButton } from '../../shared/button/ButtonComponent';
import { CircularProgress } from '@mui/material';
import AlertComponent from '../../shared/alert/AlertComponent';

export default class ChangeInventoryProductModal extends React.Component<IProps, IState> {
    inventoryService: InventoryService
    constructor(props: IProps) {
        super(props)
        this.state = { isLoading: false, unlockBlocked: false }
        this.inventoryService = new InventoryService()
    }

    submit = (newStatus: ShopInventoryStatusEnum) => {
        this.setState({ isLoading: true })
        this.inventoryService.changeInventoryProduct({ inventoryShopId: this.props.inventoryShopId, status: newStatus }).then(() => {
            NotificationManager.success('Your inventory updated successfully');
            this.props.onSubmit && this.props.onSubmit()
        })
            .finally(() => {
                this.setState({ isLoading: false })
            })
    }

    unlock = (duplicateInformationAccepted: boolean) => {
        this.setState({ isLoading: true })
        this.inventoryService.unlock(this.props.inventoryShopId, duplicateInformationAccepted).then(() => {
            NotificationManager.success('Your inventory unlocked successfully');
            this.props.onSubmit && this.props.onSubmit()
        })
            .catch((e: AxiosError) => {
                if (e.response.status === 400)
                    this.setState({ unlockBlocked: true })
                else
                    NotificationManager.error('Failed to unlock the inventory');

            })
            .finally(() => {
                this.setState({ isLoading: false })
            })
    }

    footerView = () => {
        const { currentStatus } = this.props
        const { isLoading } = this.state

        if (isLoading)
            return <CustomButton variant='contained' disabled><CircularProgress color='inherit' size={20} /></CustomButton>

        switch (currentStatus) {
            case ShopInventoryStatusEnum.Available:
                return <CustomButton variant='contained' color='warning' onClick={() => this.submit(ShopInventoryStatusEnum.Hold)}>Hold This Inventory</CustomButton>

            case ShopInventoryStatusEnum.Hold:
                return <CustomButton variant='contained' color='success' onClick={() => this.submit(ShopInventoryStatusEnum.Available)}>Available Now</CustomButton>

            case ShopInventoryStatusEnum.Lock:
                if (this.state.unlockBlocked)
                    return <AlertComponent type='error'>
                        <ExclamationCircleFill style={{ marginRight: '5px' }} />
                        Another inventory with the same unique information is currently available, you can't available your inventory  until Another inventory is available.
                    </AlertComponent>
                else
                    return <>
                        {
                            this.props.hideDontShowOnly !== true && <CustomButton variant='outlined' onClick={() => this.unlock(false)}>Ok, don't show in dashboard only</CustomButton>
                        }
                        <CustomButton variant='contained' color='warning' onClick={() => this.unlock(true)}>I accept the conditions</CustomButton>
                    </>

            default:
                return <AlertComponent type='warning'>
                    The current status of the product can only be changed by the system
                </AlertComponent>
        }

    }

    render(): React.ReactNode {
        const { currentStatus, closeModal } = this.props
        const statusDescriptions: { status: ShopInventoryStatusEnum, text: ReactElement }[] = [
            {
                status: ShopInventoryStatusEnum.Preparing,
                text: <p>This inventory is being prepared to enter the sales queue and may take a few minutes to process.</p>
            },
            {
                status: ShopInventoryStatusEnum.Validation,
                text: <p>
                    This inventory is being prepared to enter the sales queue and may take a few minutes to process.
                </p>
            },
            {
                status: ShopInventoryStatusEnum.Hold,
                text: <p>
                    This inventory is blocked (Hold) by you and you can release it for sale whenever you want.
                </p>
            },
            {
                status: ShopInventoryStatusEnum.Sold,
                text: <p>
                    This inventory has been sold and its amount has been credited to your account at the time of sale
                </p>
            },
            {
                status: ShopInventoryStatusEnum.Lock,
                text: <p>
                    This inventory has been locked due to duplicate information that should be unique (you or other sellers may have already entered this code). <br />
                    Of course, if you are aware of the uniqueness of the information and also the reason for its duplication, this inventory will be sold under your responsibility.<br />
                    But you must accept the following conditions:<br />
                    <ul>
                        <li>1. The money from the sale of this product will be held (Lock) in your account for 2 weeks.</li>
                        <li>2. If the buyer registers a review request, we have the right to return the money from your account to the buyer after reviewing the sales conditions.</li>
                    </ul>
                </p>
            }
            ,
            {
                status: ShopInventoryStatusEnum.Available,
                text: <p>
                    This inventory is ready for sale and is in the sales queue. When a store buys it, the inventory status change to sold.
                </p>
            }
        ];
        var currentStatusDescription = statusDescriptions.filter(statusDescription => statusDescription.status == currentStatus).length > 0 ?
            statusDescriptions.filter(statusDescription => statusDescription.status == currentStatus)[0].text : '';
        return (
            <ModalComponent size="800px"
                onClose={closeModal}
                title={'Change Status'}
            >
                <div>
                    <Row>
                        <Col>
                            Current Status: {shopInventoryStatusDisplayBadge(currentStatus)}
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            {currentStatusDescription}
                        </Col>
                    </Row>
                </div>
                <ModalComponent.Footer>
                    {this.footerView()}
                </ModalComponent.Footer>
            </ModalComponent>
        )
    }
}