import React, { RefObject } from "react"
import { Button } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons"
import DynamicGrid from "../../shared/DynamicGrid/DynamicGrid"
import AdminProductCategoriesService from "../../../services/admin/categories/AdminCategoriesService"
import IState, { ICategory } from "./AdminProductCategory.models"
import { NotificationManager } from 'react-notifications'
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { CustomButton } from "../../shared/button/ButtonComponent"

export default class AdminProductCategory extends React.Component<{}, IState> {
    dynamicGrid: RefObject<DynamicGrid<{}>>
    adminProductCategoriesService: AdminProductCategoriesService

    constructor(props: any) {
        super(props)
        this.dynamicGrid = React.createRef()
        this.state = { categories: [], loading: false }
        this.adminProductCategoriesService = new AdminProductCategoriesService()
    }

    componentDidMount() {
        this.getColumns()
    }

    getColumns = () => {
        this.setState({ loading: true })
        this.adminProductCategoriesService.getPaged().then((response) => {
            let data: ICategory[] = response.rows.filter(category => category.parentId == null).map(category => {
                return {
                    ...category,
                    isShow: false,
                    childs: response.rows.filter(child => child.parentId == category.id).map(child => {
                        return {
                            ...child,
                            isShow: false,
                            childs: response.rows.filter(item => item.parentId == child.id).map(item => {
                                return {
                                    ...item,
                                    isShow: false,
                                    childs: []
                                }
                            })
                        }
                    })
                }
            })
            this.setState({ categories: data, loading: false })
        })
    }

    showChild = (index: number, childIndex?: number) => {
        let temp = this.state.categories;

        if (childIndex != undefined)
            temp[index].childs[childIndex].isShow = !temp[index].childs[childIndex].isShow
        else
            temp[index].isShow = !temp[index].isShow

        this.setState({ categories: temp })
        this.forceUpdate()
    }

    departmentStatusDisplay = (status: boolean) => {
        return <span>{status ? 'Active' : 'Inactive'}</span>
    }

    requestToRemoveRow = (id: string) => {
        if (window.confirm('Are you sure? This category will be deleted permanently')) {
            this.adminProductCategoriesService.delete(id).then(() => {
                NotificationManager.success("The product category deleted successfully")
                this.dynamicGrid.current.fetch();
            })
        }
    }

    render(): React.ReactNode {
        return (
            <Container className="pt-xl">
                <NavLink to={'/admin/category-manage'}><CustomButton variant="contained" className="mt-2 mb-2">Add</CustomButton></NavLink>
                <TableContainer className="grid mt-md">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Is Active</TableCell>
                                <TableCell>Parent Title</TableCell>
                                <TableCell>Order</TableCell>
                                <TableCell>Childs</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.categories.map((category, index) => {
                                return (
                                    <>
                                        <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{category.title}</TableCell>
                                            <TableCell>{this.departmentStatusDisplay(category.isActive)}</TableCell>
                                            <TableCell>{category.parentTitle}</TableCell>
                                            <TableCell>{category.orderId}</TableCell>
                                            <TableCell>{category.childs.length}</TableCell>
                                            <TableCell className="text-center" style={{ verticalAlign: 'middle' }}>
                                                <NavLink to={`/admin/category-manage?categoryId=${category.id}`} className="mr-1"><CustomButton variant="outlined" color="warning">Edit</CustomButton></NavLink>
                                                <CustomButton className="mr-1" color="error" variant="outlined" onClick={() => this.requestToRemoveRow(category.id)}>Delete</CustomButton>
                                                {category.childs.length > 0 && (category.isShow ? <CaretUpFill onClick={() => this.showChild(index)} /> : <CaretDownFill onClick={() => this.showChild(index)} />)}
                                            </TableCell>
                                        </TableRow>
                                        {category.childs.map((child, childIndex) => {
                                            if (category.isShow)
                                                return (
                                                    <>
                                                        <TableRow>
                                                            <TableCell>{ }</TableCell>
                                                            <TableCell>{child.title}</TableCell>
                                                            <TableCell>{this.departmentStatusDisplay(child.isActive)}</TableCell>
                                                            <TableCell>{child.parentTitle}</TableCell>
                                                            <TableCell>{child.orderId}</TableCell>
                                                            <TableCell>{child.childs.length}</TableCell>
                                                            <TableCell className="text-center" style={{ verticalAlign: 'middle' }}>
                                                                <NavLink to={`/admin/category-manage?categoryId=${child.id}`} className="mr-1"><CustomButton variant="outlined" color="warning">Edit</CustomButton></NavLink>
                                                                <CustomButton className="mr-1" variant="outlined" color="error" onClick={() => this.requestToRemoveRow(child.id)}>Delete</CustomButton>
                                                                {child.childs.length > 0 && (child.isShow ? <CaretUpFill onClick={() => this.showChild(index, childIndex)} /> : <CaretDownFill onClick={() => this.showChild(index, childIndex)} />)}
                                                            </TableCell>
                                                        </TableRow>
                                                        {child.childs.map(sub_child => {
                                                            if (child.isShow)
                                                                return (
                                                                    <TableRow>
                                                                        <TableCell>{ }</TableCell>
                                                                        <TableCell>{sub_child.title}</TableCell>
                                                                        <TableCell>{this.departmentStatusDisplay(sub_child.isActive)}</TableCell>
                                                                        <TableCell>{sub_child.parentTitle}</TableCell>
                                                                        <TableCell>{sub_child.orderId}</TableCell>
                                                                        <TableCell>{sub_child.childs.length}</TableCell>
                                                                        <TableCell className="text-center" style={{ verticalAlign: 'middle' }}>
                                                                            <NavLink to={`/admin/category-manage?categoryId=${sub_child.id}`} className="mr-1"><CustomButton variant="outlined" color="warning">Edit</CustomButton></NavLink>
                                                                            <CustomButton className="mr-1" variant="outlined" onClick={() => this.requestToRemoveRow(sub_child.id)}>Delete</CustomButton>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                        })}
                                                    </>
                                                )
                                        })}
                                    </>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        )
    }
}