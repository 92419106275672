import React from "react";
import { ReactComponent as OutlinedPlusIcon } from '../../../assets/icons/content/outlined-plus.svg'
import { ReactComponent as TrashIcon } from '../../../assets/icons/content/trash.svg'
import { BsDashCircle } from "react-icons/bs";
import './CounterView.scss'

export default class CounterView extends React.Component<{ count: number, inventoryCount: number, onDelete: () => void, onChangeCount: (count: number) => void }> {
    render(): React.ReactNode {
        return <div className="counter-area">
            {
                this.props.count > 1 ? < BsDashCircle onClick={() => this.props.onChangeCount(this.props.count - 1)} />
                    : <TrashIcon className="text-danger trash" onClick={this.props.onDelete} />
            }
            <span className="font-sm">{this.props.count}</span>
            <span className={this.props.count >= this.props.inventoryCount && this.props.inventoryCount !== null && 'disable'}>
                <OutlinedPlusIcon onClick={() => this.props.onChangeCount(this.props.count + 1)} />
            </span>
        </div>
    }
}