import { useSearchParams } from "react-router-dom";
import Marketplace from "../../components/shopping/Marketplace/Marketplace";

export default function StoreRoute() {
    const [searchParams] = useSearchParams();
    let categoryId = searchParams.get("categoryId");
    let productId = searchParams.get("productId");
    let parentCategoryId = searchParams.get("parentCategoryId");
    let search = searchParams.get("search");
    let mode = searchParams.get("mode");
    if (!mode)
        mode = 'express'
    return <Marketplace parentCategoryId={parentCategoryId} categoryId={categoryId as string} productId={productId as string} search={search as string} mode={mode as "express" | "trade"} />
}