import React from "react";
import IdentityService from "../../../services/identity/IdentityService";
import NavMenu from "./navMenu/NavMenu";
import LayoutContext from "../../../contexts/LayoutContext/LayoutContext";
import { WalletOverview } from "../wallet-overview/WalletOverview";
import { ReactComponent as Logo } from "../../../assets/images/logo/logo.svg";
import './Header.scss'
import { AppBar, Box, Container, Grid, Toolbar } from "@mui/material";
import { CustomButton } from "../../../components/shared/button/ButtonComponent";
import { NavLink } from "react-router-dom";
import Alerts from "../../../components/dashboard/Alerts/Alerts";
import Profile from "../profile/Profile";
import ShopChanger from "../shop-changer/ShopChanger";
import CartModal from "../cart/CartModal";

export default class Header extends React.Component<{ showLogo: boolean, title?: string }, { isAuthentication: boolean, showDropDown: boolean, deviceType: 'mobile' | 'desktop' }> {
    identityService: IdentityService;
    walletOverview: React.RefObject<WalletOverview>;
    static contextType = LayoutContext;
    context!: React.ContextType<typeof LayoutContext>;
    cart: React.RefObject<CartModal>;
    constructor(props: any) {
        super(props);
        this.identityService = new IdentityService();
        this.state = { deviceType: window.innerWidth > 1023 ? 'desktop' : 'mobile', showDropDown: false, isAuthentication: true };
        this.walletOverview = React.createRef()
        this.cart = React.createRef()
    }

    componentDidMount(): void {
        this.context.onAddToCartSubject.subscribe(() => {
            this.walletOverview.current?.loadOverview();
        })

        if (this.cart.current)
            this.context.loadCart = this.cart.current?.openCart;
    }

    componentDidUpdate(): void {
        if (this.cart.current)
            this.context.loadCart = this.cart.current?.openCart;
    }

    mobileView = () => {
        return <AppBar position="sticky">
            <Toolbar sx={{ bgcolor: 'white', height: '64px' }} className="full-height">
                <Container>
                    <Grid container className="full-width">
                        <Grid item sm={4} xs={4}>
                            <NavMenu />
                        </Grid>
                        <Grid item sm={4} xs={4} alignItems="center" justifyContent="center" display="flex">
                            <Box sx={{ flex: 'none', fontSize: '13px' }} className="text-charcoal font-bold">Market Place</Box>
                        </Grid>
                        <Grid item sm={4} xs={4} alignItems="center" justifyContent="end" display="flex">
                            {
                                this.state.isAuthentication ?
                                    <>
                                        <div className="flex">
                                            <div>
                                                <WalletOverview />
                                            </div>
                                        </div>
                                    </> :
                                    <div className="flex">
                                        <NavLink to={'/sign-up'}>
                                            <CustomButton size="small" variant="contained" fullWidth>Register</CustomButton>
                                        </NavLink>
                                        <NavLink to={'/sign-in'} className="pl-md">
                                            <CustomButton size="small" variant="outlined" fullWidth>Login</CustomButton>
                                        </NavLink>
                                    </div>
                            }
                        </Grid>
                    </Grid>
                </Container>
            </Toolbar>
        </AppBar>;
    }

    defaultView = () => {
        return <AppBar position="sticky" sx={{ zIndex: 1 }}>
            <Toolbar className="full-height" sx={{ height: '88px' }}>
                {
                    this.props.showLogo && <div className="logo-header"><Logo /></div>
                }
                <Container>
                    <div className="flex items-center justify-between full-width">
                        <div className={"mr-xs"}>
                            <ShopChanger />
                        </div>
                        <NavMenu />
                        {
                            this.state.isAuthentication ?
                                <div className="flex">
                                    <div>
                                        <WalletOverview />
                                    </div>
                                    <div className="pl-sm">
                                        <CartModal ref={this.cart} />
                                    </div>
                                    <div className="pl-sm">
                                        <Alerts />
                                    </div>
                                    <div className="pl-sm">
                                        <Profile />
                                    </div>
                                </div> :
                                <div className="flex">
                                    <NavLink to={'/sign-up'}>
                                        <CustomButton size="small" variant="contained" fullWidth>Register</CustomButton>
                                    </NavLink>
                                    <NavLink to={'/sign-in'} className="pl-md">
                                        <CustomButton size="small" variant="outlined" fullWidth>Login</CustomButton>
                                    </NavLink>
                                </div>
                        }
                    </div>
                </Container>
            </Toolbar>
        </AppBar>;
    }

    render(): React.ReactNode {
        return this.state.deviceType == 'mobile' ? this.mobileView() : this.defaultView();
    }
}