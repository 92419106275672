import React from "react";
import { Spinner, Table } from "react-bootstrap";
import { LocalCurrencyService } from "../../../services/financials/LocalCurrencyService";
import Amount from "../../../components/utilities/Amount/Amount";
import InventoryStatisticsService from "../../../services/financials/InventoryStatisticsService";
import { ClockHistory, CurrencyDollar, Person, Stack, Wallet } from "react-bootstrap-icons";

export default class CapitalsOverview extends React.Component<{}, State> {
    localCurrencyService: LocalCurrencyService;
    inventoryStatisticsService: InventoryStatisticsService
    constructor(props: any) {
        super(props);
        this.localCurrencyService = new LocalCurrencyService();
        this.inventoryStatisticsService = new InventoryStatisticsService();
        this.state = { currencies: [], loading: true };
    }
    componentDidMount() {
        this.inventoryStatisticsService.getFinancial().then((response) => {

            this.localCurrencyService.get().then((localCurrency) => {
                this.localCurrencyService.getBalance().then((responseCurrencies) => {
                    var baseCurrency = responseCurrencies
                        .filter(currency => currency.currencyTitle === "USD")[0];
                    var currencies = responseCurrencies
                        .filter(currency => currency.currencyTitle !== "USD")
                        .map(currency => {
                            return {
                                logoSrc: '/images/tether-logo.svg',
                                localCurrencyAverage: currency.average,
                                value: currency.balance,
                                symbol: currency.currencySymbol,
                                title: currency.currencyTitle,
                                localCurrencyBalance: currency.totalBalance
                            } as Currency
                        })

                    // this.addCapital(currencies, 'Source InComming', response.totalDeficits)

                    this.setState({
                        localCurrency,
                        currencies,
                        loading: false,
                        baseCurrency: {
                            logoSrc: '/images/tether-logo.svg',
                            localCurrencyAverage: baseCurrency.average,
                            symbol: baseCurrency.currencySymbol,
                            title: baseCurrency.currencyTitle,
                            capitals: [{
                                title: 'Wallet',
                                value: baseCurrency.balance,
                                icon: <Wallet />
                            },
                            {
                                title: 'Available Inventory',
                                value: response.availableAmount,
                                icon: <Stack />
                            },
                            {
                                title: 'Source Balance',
                                value: response.sourceTotalBalance,
                                icon: <Person />
                            },
                            {
                                title: 'Source InComming',
                                value: response.totalDeficits,
                                icon: <ClockHistory />
                            }],
                        }
                    });
                })
            })
        })

    }

    render(): React.ReactNode {
        if (this.state.loading)
            return <div className="text-center p-3"><Spinner animation="border"></Spinner></div>
        const hasLocalCurrency = this.state.localCurrency && this.state.localCurrency.currencyKey !== "USD";
        return <div>
            <Table size="lg">
                <thead>
                    <tr>
                        <th>Currency</th>
                        <th>Title</th>
                        <th>Value</th>
                        {
                            hasLocalCurrency &&
                            <th>Local Value</th>
                        }
                        {
                            hasLocalCurrency &&
                            <th>Local Currency(Avg)</th>
                        }

                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.baseCurrency.capitals.map((capital, i) => <tr key={i}>
                            {
                                i === 0 &&
                                <td rowSpan={this.state.baseCurrency.capitals.length} style={{ verticalAlign: 'middle' }}>
                                    <img src='/images/usd-logo.svg' width={32} height={32} />  {this.state.baseCurrency.title}
                                </td>
                            }
                            <td><span className="mini-icon"> {capital.icon}</span>{capital.title}</td>
                            <td><Amount value={capital.value} />{this.state.baseCurrency.symbol}</td>
                            {
                                hasLocalCurrency &&
                                <td>
                                    <Amount value={capital.value * this.state.baseCurrency.localCurrencyAverage} />{this.state.localCurrency?.currencySymbol}
                                </td>
                            }
                            {
                                hasLocalCurrency && i === 0 &&
                                <td rowSpan={this.state.baseCurrency.capitals.length} style={{ verticalAlign: 'middle' }}>
                                    <Amount value={this.state.baseCurrency.localCurrencyAverage} /> {this.state.localCurrency?.currencySymbol}
                                </td>
                            }
                        </tr>)
                    }

                    {
                        this.state.currencies.filter(currency => currency.symbol !== '$').map((currency, i) => <tr key={i}>
                            <td><img src={currency.logoSrc} width={32} height={32} /> {currency.title}</td>
                            <td><span className="mini-icon"><Wallet /></span> Wallet</td>
                            <td><Amount value={currency.value} />{currency.symbol}</td>
                            {
                                hasLocalCurrency &&
                                (currency.value === 0 ? <td>0</td> :
                                    <td><Amount value={currency.localCurrencyBalance} />{this.state.localCurrency?.currencySymbol}</td>)
                            }
                            {
                                hasLocalCurrency &&
                                (currency.value === 0 ? <td>0</td> :
                                    <td><Amount value={currency.localCurrencyAverage} /> {this.state.localCurrency?.currencySymbol}</td>
                                )
                            }
                        </tr>)
                    }
                </tbody>
                <tfoot>
                    <tr style={{ background: 'rgb(187, 211, 187)' }}>
                        <td colSpan={2}>Total Assets</td>
                        <td><Amount value={this.state.currencies.reduce((a, b) => a + b.value, 0) + this.state.baseCurrency.capitals.reduce((a, b) => a + b.value, 0)} />{this.state.baseCurrency.symbol}</td>
                        {
                            hasLocalCurrency &&
                            <td colSpan={2}><Amount value={this.state.baseCurrency.capitals.reduce((a, b) => a + b.value * this.state.baseCurrency.localCurrencyAverage, 0) + this.state.currencies.reduce((a, b) => a + b.value * b.localCurrencyAverage, 0)} />{this.state.localCurrency?.currencySymbol}</td>
                        }
                    </tr>
                </tfoot>
            </Table>
        </div>
    }
}

interface State {
    currencies: Currency[],
    localCurrency?: {
        currencyKey: string
        currencySymbol: string
        currencyTitle: string
        localCurrencyId: string
    }
    baseCurrency?: BaseCurrency,
    loading: boolean
}

interface Currency {
    value: number
    localCurrencyAverage: number
    symbol: string
    title: string
    localCurrencyBalance: number,
    logoSrc: string
}

interface BaseCurrency {
    localCurrencyAverage: number
    symbol: string
    title: string
    capitals: Capital[],
    logoSrc: string
}
interface Capital {
    value: number
    title: string,
    icon: any
}