import React from "react";
import './FullScreenLoading.scss'

export default class FullScreenLoading extends React.Component<{ progressPercent: number }> {
    render(): React.ReactNode {
        return <div className="FullScreenLoading">
            <div className="cover" style={{ width: this.props.progressPercent + '%' }}></div>
            <img src="/images/loading.gif" width='64px' height='64px' className="image" />
        </div>;
    }
}