import { Badge } from "react-bootstrap";
import ChipComponent from "../../shared/Chip/Chip";
export enum InventoryShopBasePlanStatusEnum {
    Canceled,
    Pending,
    Open,
    Closed,
    Suspended,
    Rejected
}

export function inventoryShopBasePlanStatusDisplayCaption(status: number) {
    let types = ['Canceled', 'Pending', 'Open', 'Closed', 'Suspended', 'Rejected'];
    return types.length <= status ? status : types[status];
}

export function inventoryShopBasePlanStatusColor(status: number) {
    let types = ['error', 'warning', 'success', 'error', 'info', 'error'];
    return types.length <= status ? 'dark' : types[status];
}

export default function inventoryShopBasePlanStatusDisplayBadge(status: number | null) {
    return <ChipComponent label={status === null ? 'Not started' :inventoryShopBasePlanStatusDisplayCaption(status)} type={status === null ? 'secondary' : inventoryShopBasePlanStatusColor(status)} />;
}