import React from "react"
import Logo from '../../assets/images/identity/sign-in.png'
import LogoMobile from '../../assets/images/identity/sign-in-mobile.png'
import './IdentityLayout.scss'
import { Outlet } from "react-router-dom"
import { Container, Grid, Typography } from "@mui/material"
import { FaChevronLeft } from "react-icons/fa6";

export default class IdentityLayout extends React.PureComponent<{ title: string }, {}> {
    render(): React.ReactNode {
        return (<div className="identity-layout">
            <Grid container className="mobile bg-white identity-layout-header flex items-center">
                <Grid item sm={4} xs={4}><FaChevronLeft color='#444444' className="ml-md" /></Grid>
                <Grid item sm={4} xs={4} className="text-center">
                    <Typography className="element-center" color='charcoal.main' ><span className="font-bold">{this.props.title}</span></Typography>
                </Grid>
                <Grid item sm={4} xs={4}></Grid>
            </Grid>
            <Container className="flex items-center">
                <Grid container direction="row-reverse" sx={{ width: '100%' }} className="identity-layout-container">
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div>
                            <img src={Logo} className="web" width={'100%'} />
                            <img src={LogoMobile} className="mobile" width={'100%'} />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className="box-area full-height flex items-center">
                            <Outlet />
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>)
    }
}