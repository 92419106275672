import React from 'react';
import { Spinner } from 'react-bootstrap';
import './ProductCatalog.scss'
import { NotificationManager } from 'react-notifications';
import ProductCatalogService from '../../../services/catalog/ProductCatalogService';
import AlertComponent from '../../shared/alert/AlertComponent';
import { CustomButton } from '../../shared/button/ButtonComponent';
import { Box, Grid } from '@mui/material';
import ProductCatalogCardView from './CardView/ProductCatalogCardView';
import SpinnerComponent from '../../shared/spinner/SpinnerComponent';

interface Props {
    productId: string
    onClose: () => void
    onAddToCart: (count: number, productId: string, sellerShopId: string, unitPrice: number) => Promise<any>
}

export default class ProductCatalog extends React.Component<Props, { sellers: ProductCatalogSellersView[], loading: boolean, pendingRequest: number }> {
    productCatalogService: ProductCatalogService;
    constructor(props: any) {
        super(props);
        this.state = { sellers: [], loading: true, pendingRequest: 0 }
        this.productCatalogService = new ProductCatalogService();
    }
    componentDidMount(): void {
        this.productCatalogService.getPrices(this.props.productId).then((productPrices) => {
            var sellers = productPrices.sellers.map((seller) => { return { count: 0, details: { ...seller, isYourShop: false } } });
            if (productPrices.ownedSellPlan && productPrices.ownedSellPlan.status === 2)
                sellers.push({
                    count: 0, details: {
                        delayDescription: productPrices.ownedSellPlan.delayDescription,
                        inventoryCount: productPrices.ownedSellPlan.availableInventoryCount,
                        plans: productPrices.ownedSellPlan.plans,
                        sellerShopId: '',
                        title: 'Your Shop',
                        isYourShop: true,
                        totalSoldCount: '0',
                        productSoldCount: '0',
                    }
                })
            this.setState({ sellers: sellers, loading: false })
        });
    }

    changeCount = (index: number, count: number | null) => {
        count = count === null ? 0 : count;
        let sellers = [...this.state.sellers];
        sellers[index] = { ...sellers[index], count };
        this.setState({ sellers });
    }

    addToCart = () => {
        let addItems = this.state.sellers.filter(seller => seller.count > 0);
        this.setState({ pendingRequest: addItems.length })
        addItems.map((seller) => {
            if (seller.details.plans.filter(plan => plan.countFrom <= seller.count && plan.countTo > seller.count).length > 0) {
                let price = seller.details.plans.filter(plan => plan.countFrom <= seller.count && plan.countTo > seller.count)[0].price

                this.props.onAddToCart(seller.count, this.props.productId, seller.details.sellerShopId, price).finally(() => {
                    this.setState({ pendingRequest: this.state.pendingRequest - 1 })
                    this.props.onClose();
                })
            } else {
                this.setState({ pendingRequest: 0 })
                NotificationManager.error('The entered number is not in any of the ranges');
            }

        })
    }

    bodyView(sellers: ProductCatalogSellersView[], onChangeCount: (index: number, count: number) => void) {
        return <Grid container pb={4} pt={3} spacing={1}>
            {
                sellers.map((seller, index) => <Grid item md={4} lg={4} xs={12} sm={12}><ProductCatalogCardView onChangeCount={(count) => onChangeCount(index, count)}
                    productSoldCount={seller.details.productSoldCount}
                    isYourShop={seller.details.isYourShop}
                    totalSoldCount={seller.details.totalSoldCount}
                    delayDescription={seller.details.delayDescription} inventoryCount={seller.details.inventoryCount}
                    sellerShopId={seller.details.sellerShopId} title={seller.details.title} plans={seller.details.plans} /></Grid>)
            }
        </Grid>
    }

    render(): React.ReactNode {
        let selectedItemCount = this.state.sellers.reduce((a, b) => a + b.count, 0);
        if (this.state.loading)
            return <SpinnerComponent />

        if (this.state.sellers.length === 0)
            return <div className='pt-md'><AlertComponent action={{ label: "Create Offer ", link: `/marketplace?mode=trade&productId=${this.props.productId}` }} type='warning'>
                <span className="text-justify">
                    Unfortunately, at this moment, there is no seller to supply this product.<br />
                    You can submit your offer so that the auction takes place and the sellers compete for it (of course, it may take some time!)<br /><br />
                </span>
            </AlertComponent></div>

        return <div className='ProductCatalogSellers'>
            {this.bodyView(this.state.sellers, this.changeCount)}
            {this.state.sellers.filter(seller => seller.details.inventoryCount > 0 || seller.details.inventoryCount === null).length === 0 ?
                <AlertComponent type='warning' action={{ label: "Create Offer ", link: `/marketplace?mode=trade&productId=${this.props.productId}` }}>
                    <span className="text-justify">
                        None of the sellers are ready to sell right now, but you can create your own offer to show the sellers and they usually ship it quickly (sometimes it can take a while).
                    </span>
                </AlertComponent>
                : <AlertComponent action={{ label: "Create Offer ", link: `/marketplace?mode=trade&productId=${this.props.productId}` }} type='info'>
                    <span className="text-justify">
                        Also, if you have another proposed price for purchase, you can make your own offer so that the auction is held and the sellers compete for it (of course, it may take some time!)
                    </span>
                </AlertComponent>
            }
            <Box pt={5} sx={{ float: 'right' }} className="mobile-fixed-bottom">
                {
                    this.state.sellers.length !== 0 && (
                        this.state.pendingRequest > 0 ? <CustomButton className='mobile-full-width' disabled variant="contained"><Spinner size='sm' animation='border' /></CustomButton> :
                            <CustomButton className='mobile-full-width' variant="contained" disabled={selectedItemCount <= 0} onClick={this.addToCart}> Add To Cart</CustomButton>)
                }
            </Box>
        </div >
    }
}

interface ProductCatalogSellersView {
    details: {
        delayDescription: string | null
        inventoryCount: number | null
        plans: [
            {
                isApiPlan: boolean,
                countFrom: number,
                countTo: number,
                price: number,
                discount: number
            }]
        isYourShop: boolean
        sellerShopId: string
        totalSoldCount: string
        productSoldCount: string
        title: string
    }
    count: number
}
