import React from "react"
import { Button, Table } from "react-bootstrap"
import InventoryProductOverviewService, { IDeficitDTO } from "../../../../services/inventory/InventoryProductOverviewService"
import OrderProductService from "../../../../services/orders/OrderProductService"
import { ApiGiftCommonDTOsInventoryInventoryShopCollectionDeficitRowDTO } from "../../../../generated-api"

export default class InventoryDeficits extends React.PureComponent<{ productId: string, getDeficitItem?: (data: ApiGiftCommonDTOsInventoryInventoryShopCollectionDeficitRowDTO) => void }, { dailyAverageCount: number, inventoryDeficits: ApiGiftCommonDTOsInventoryInventoryShopCollectionDeficitRowDTO[] }> {
    orderProductService: OrderProductService
    inventoryProductOverviewService: InventoryProductOverviewService
    constructor(props: any) {
        super(props)
        this.orderProductService = new OrderProductService()
        this.inventoryProductOverviewService = new InventoryProductOverviewService()
        this.state = { dailyAverageCount: 0, inventoryDeficits: [] }
    }

    componentDidMount() {
        this.getStatistics()
        this.getDeficits()
    }

    getStatistics = () => {
        this.orderProductService.getStatistics(this.props.productId).then((response) => {
            this.setState({ dailyAverageCount: response.dailyAverageCount })
        })
    }

    getDeficits = () => {
        this.inventoryProductOverviewService.getDeficits(this.props.productId).then((response) => {
            this.setState({ inventoryDeficits: response.rows })
        })
    }

    render(): React.ReactNode {
        let { dailyAverageCount, inventoryDeficits } = this.state
        return <>
            <label className="text-danger">
                <b>Daily Sold Average</b>: {dailyAverageCount}
            </label>
            {
                inventoryDeficits.length > 0 && <Table className="mt-3" size="sm" hover responsive borderless bordered>
                    <thead>
                        <tr>
                            <th>Count</th>
                            <th>Order Number</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            inventoryDeficits.map((deficit, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{deficit.count}</td>
                                        <td> <a onClick={() => this.props.getDeficitItem && this.props.getDeficitItem(deficit)} className="pointer text-secondary">{deficit.orderNumber}</a></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            }
        </>
    }
}