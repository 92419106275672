import React from "react";
import authenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext";
import { ReactComponent as CartIcon } from '../../../assets/icons/content/cart.svg'
import ModalComponent from "../../../components/shared/modal/Modal";
import Cart from "../../../components/shopping/Cart/Cart";

export default class CartModal extends React.Component<{}, { showDetails: boolean }> {
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;

    constructor(props: any) {
        super(props);
        this.state = { showDetails: false }
    }

    openCart = () => this.setState({ showDetails: true })

    render(): React.ReactNode {
        return <div>
            <div className="flex items-center bg-white radius-sm py-m header-box" onClick={() => this.setState({ showDetails: true })}>
                <CartIcon />
            </div>
            {
                this.state.showDetails && <ModalComponent size={'900px'} title={`Cart`} onClose={() => this.setState({ showDetails: false })}>
                    <Cart close={() => this.setState({ showDetails: false })} />
                </ModalComponent>
            }
        </div>
    }
}