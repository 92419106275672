import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as RedirectIcon } from "../../../../assets/icons/content/redirect-icon.svg";
import { API_URL } from "../../../../constants/Constants";

export interface ExpressProductCatalogItemProps {
    mode?: 'express' | 'trade',
    title: string
    imageName?: string | null
    categoryId: string
    hasSubMenu: boolean
    onClick: (id: string) => void
}

export default class ExpressCategoryCatalogItem extends React.Component<ExpressProductCatalogItemProps, {}> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    categoryItem = (imageName: string, title: string) => {
        return (
            <div className={"category-catalog-item "}>
                <img src={imageName == null ? '/images/product-none.png' : `${API_URL}/FileManagement?folder=products&filename=${imageName}`} className="category-image" alt={title} />
                <div className="border-line"></div>
                <div className="footer flex justify-between full-width items-center pa-md">
                    <span>{title}</span>
                    <RedirectIcon />
                </div>
            </div>
        )
    }

    render(): React.ReactNode {
        let item = this.props;
        return (
            this.props.hasSubMenu ? <div onClick={() => this.props.onClick(item.categoryId)}>{this.categoryItem(item.imageName, item.title)}</div> :
                <Link to={`/marketplace/?mode=${this.props.mode}&categoryId=${item.categoryId}`}>
                    {this.categoryItem(item.imageName, item.title)}
                </Link>
        )
    }
}