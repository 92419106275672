import React from "react"
import { Nav } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import './NavLinkItem.scss'

export default class NavLinkItem extends React.PureComponent<{}, {}> {
    render(): React.ReactNode {
        return <Nav className="ms-auto text-dark">
            <div className="nav-link"><NavLink to="/contact-us">Contact us</NavLink></div>
            <div className="nav-link"><NavLink to="/sign-up">Register</NavLink></div>
            <div className="nav-link login-btn"><NavLink to="/sign-in">Sign In</NavLink></div>
        </Nav>
    }
}