import React, { RefObject, Suspense, lazy } from "react";
import { Button, Spinner } from "react-bootstrap";
import { GridAction, GridColumn, GridColumnTypes } from "../../../Grid/Grid.models";
import IState, { IProps } from "./ShopMemberInvitations.models";
import DynamicGrid from "../../../shared/DynamicGrid/DynamicGrid";
import ShopMemberInvitationService from "../../../../services/shops/ShopMemberInviteService";
import { CustomButton } from "../../../shared/button/ButtonComponent";
import SpinnerComponent from "../../../shared/spinner/SpinnerComponent";

const InviteMemberModal = lazy(() => import("../InviteMemberModal/InviteMemberModal"))

export default class ShopMemberInvitations extends React.Component<IProps, IState> {
    dynamicGrid: RefObject<DynamicGrid<{onlyPendings: boolean}>>
    shopMemberInvitationService: ShopMemberInvitationService
    constructor(props: IProps) {
        super(props);
        this.state = { showInviteModal: false }
        this.dynamicGrid = React.createRef();
        this.shopMemberInvitationService = new ShopMemberInvitationService()
    }

    render(): React.ReactNode {
        return (<>
            <div className="pt-2">
                <div className="flex justify-end">
                    <CustomButton variant="contained" onClick={() => this.setState({ showInviteModal: true })}>Invite</CustomButton>
                </div>
                {this.memberLisView()}
                <Suspense fallback={<SpinnerComponent  />}>
                    {this.state.showInviteModal && <InviteMemberModal onSubmit={this.dynamicGrid.current?.fetch} closeModal={() => this.setState({ showInviteModal: false })} />}
                </Suspense>
            </div>
        </>)
    }

    revoke = (id: string) => {
        if (window.confirm('Are you sure, you want revoke this invitation?'))
            this.shopMemberInvitationService.revoke(id).then(() => {
                this.dynamicGrid.current?.fetch();
            })
    }

    memberLisView() {
        let gridColumns: GridColumn[] = [
            { title: 'Email', key: 'email' },
            { title: 'Create Date', key: 'createDate', type: GridColumnTypes.Date },
        ];

        let gridActions: GridAction[] = [
            { caption: 'Revoke', variant: 'outline-danger', onClick: (row) => this.revoke(row.id) }
        ];

        return (
            <DynamicGrid<{onlyPendings: boolean}> ref={this.dynamicGrid}
                columns={gridColumns}
                onFetch={this.shopMemberInvitationService.get}
                hideFilterBar
                defaultFilters={{onlyPendings: true}}
                actions={gridActions}
            />
        )
    }
}