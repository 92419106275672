import axios from "axios";
import React, { RefObject } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Phone } from "react-bootstrap-icons";
import { NotificationManager } from 'react-notifications';
import TwoFactorService from "../../../../../services/identity/TwoFactorService";
import RecoveryCodeModal from "../RecoveryCode/RecoveryCode";
import IState, { IProps } from "./TOTPForm.models";
import authenticationContext from "../../../../../contexts/AuthenticationContext/AuthenticationContext";
import './TOTPForm.scss';
import ModalComponent from "../../../../shared/modal/Modal";
import { CustomButton } from "../../../../shared/button/ButtonComponent";
import { CircularProgress } from "@mui/material";

export default class TOTPForm extends React.Component<IProps, IState> {
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    input: RefObject<HTMLInputElement>
    recoveryCodeModalRef: RefObject<RecoveryCodeModal>
    twoFactorService: TwoFactorService
    constructor(props: IProps) {
        super(props);
        this.state = { totp: '', showModal: false, showRecoveryModal: false, loading: false };
        this.input = React.createRef();
        this.recoveryCodeModalRef = React.createRef();
        this.twoFactorService = new TwoFactorService()
    }

    componentDidMount() {
        this.setState({ showModal: this.props.showModal });
    }

    componentDidUpdate(prevProps: { showModal: boolean }) {
        if (prevProps.showModal !== this.props.showModal) {
            this.setState({ showModal: this.props.showModal });
        }
    }

    hide = () => {
        this.setState({ showModal: false });
        this.props.onHide();
        this.setState({ loading: false });
    }

    focusToInput = () => {
        this.input.current?.focus();
    }

    recovery = (totp: string, disableTwoFactor: boolean) => {
        if (this.props.isLoginPage) {
            this.twoFactorService.signInWithRecoveryCode({ disableTwoFactor, email: this.props.signInInformation?.userName as string, password: this.props.signInInformation?.password as string }, totp).then((response) => {
                this.setState({ showRecoveryModal: false, showModal: false })
                this.props.login && this.props.login(response.accessToken, true)
            }).finally(() => {
                this.recoveryCodeModalRef.current?.resetSpinner()
            })
        } else {
            this.twoFactorService.disableWithRecoveryCode(totp).then(() => {
                NotificationManager.success('Two-factor has been disabaled successful')
                this.setState({ showRecoveryModal: false, showModal: false })
                this.props.onHandle && this.props.onHandle()
            }).finally(() => {
                this.recoveryCodeModalRef.current?.resetSpinner()
            })
        }
    }

    submit = () => {

        this.setState({ loading: true });
        if (this.context.twoFactorAvtive) {
            if (this.state.totp === '') return NotificationManager.error("Please enter your TOTP")

            if (this.state.totp.length !== 6) return NotificationManager.error("Entered code must be 6 digits, please enter correctly")

            axios.defaults.headers.common['totp'] = this.state.totp;
        }
        this.props.onSubmit && this.props.onSubmit(this.state.totp);

        if (!this.context.twoFactorAvtive)
            this.hide();

        this.setState({ loading: false });
    }

    onKeyDown = (event: React.KeyboardEvent<any>): void => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.submit();
        }
    }

    render(): React.ReactNode {
        var hasTwofactor = this.context.twoFactorAvtive;
        return (<div style={{ zIndex: '999999' }}>
            {hasTwofactor && this.modalView()}
        </div>)
    }

    modalView() {
        return <>
            {
                this.state.showModal &&
                <ModalComponent
                    size="500px"
                    styles={{ zIndex: '999999' }}
                    onClose={() => this.hide()}
                    title={'Two Factor'}
                >
                    <div>
                        <Row style={{ textAlign: 'center' }}>
                            <Col className="p-3"><Phone size="60px" /></Col>
                            <label>Enter your verification code generated by the authenticator application</label>
                        </Row>
                        <Form>
                            <Form.Group className="mt-4" controlId="formBasicEmail">
                                <Form.Label>TOTP</Form.Label>
                                <Form.Control type="text" placeholder="Enter code..." autoComplete="off" ref={this.input} onKeyDown={(e) => this.onKeyDown(e)}
                                    value={this.state.totp} onChange={(e) => this.setState({ totp: e.target.value })} />
                            </Form.Group>
                        </Form>
                    </div>
                    <ModalComponent.Footer>
                        {
                            this.props.loading ? <CustomButton disabled variant="contained">
                                <CircularProgress color="inherit" size={20} />
                            </CustomButton> : <CustomButton type="submit" variant="contained" onClick={() => this.submit()}>Submit</CustomButton>
                        }
                        {this.props.hasRecovery && (<div> having trouble ? <a className="link" onClick={() => this.setState({ showRecoveryModal: true })}> Try Another Way </a></div>)}
                    </ModalComponent.Footer>
                </ModalComponent>
            }
            {
                (this.props.hasRecovery && this.state.showRecoveryModal) && <RecoveryCodeModal showDisableTwoFactor={this.props.isLoginPage} onSubmit={(otp, disabledTwoFactor) => { this.recovery(otp as string, disabledTwoFactor as boolean) }} closeModal={() => this.setState({ showRecoveryModal: false })} showModal={this.state.showRecoveryModal} />
            }
        </>
    }
}