import React from 'react';
import OrdersService from '../../../../services/orders/OrdersService';
import { OrderStatusEnum } from '../../OrderEnums';
import OrderItems from '../../OrderDetails/OrderItems';
import { Container, Typography } from '@mui/material';
import { CustomButton } from '../../../shared/button/ButtonComponent';
import { ReactComponent as ExportIcon } from "../../../../assets/icons/content/export.svg";

export default class OrderDeliveryModal extends React.Component<{ orderId: string }, { interval?: any, orderStatus: OrderStatusEnum }> {
    ordersService: OrdersService;
    constructor(props: any) {
        super(props)
        this.ordersService = new OrdersService();
        this.state = { orderStatus: OrderStatusEnum.Pending }
    }

    componentDidMount(): void {
        this.loadDetails()
    }

    loadDetails = () => {
        this.ordersService.getDetails(this.props.orderId).then((details: any) => {
            if (details.status == OrderStatusEnum.Done)
                this.setState({ orderStatus: details.status }, () => clearInterval(this.state.interval));
        });
    }

    export = () => { }

    render(): React.ReactNode {
        return (
            <Container className="pt-xl">
                <div className='flex justify-between items-center'>
                    <Typography sx={{ fontSize: { md: '24px', sm: '18px' } }}>
                        <span className='font-bold'>Order Delivery</span>
                    </Typography>
                    <CustomButton color='info' variant="text" onClick={this.export}>
                        <ExportIcon />
                        <span className='font-medium px-xs'>Download Excel</span>
                    </CustomButton>
                </div>
                <OrderItems hideAlert orderId={this.props.orderId} disableShowCode={false} />
            </Container>
        )
    }
}