import ChipComponent from "../../shared/Chip/Chip";

export enum OrderProductStatusEnum {
    Refund,
    Pending,
    Delivered,
    DeliveryRefund,
    Pricing,
    Payment,
    Cancel
}

export function OrderProductStatusDisplayCaption(status: number) {
    let types = ['Refund', 'Pending', 'Delivered', 'DeliveryRefund', 'Pricing', 'Payment', 'Cancel'];
    return types.length <= status ? status : types[status];
}

export function OrderProductStatusColor(status: number) {
    let types = ['info', 'warning', 'success', 'info', 'dark', 'dark', 'danger'];
    return types.length <= status ? 'dark' : types[status];
}

export default function OrderProductStatusBadge(status: number) {
    return <ChipComponent type={OrderProductStatusColor(status)} label={OrderProductStatusDisplayCaption(status)} />;
}