import React from "react";
import { Alert, Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import FinancialManagementService from "../../../../services/financials/FinancialManagementService";
import IState, { IProps } from "./FinancialAccountVerifyModal.models";
import ModalComponent from "../../../shared/modal/Modal";
import { CustomButton } from "../../../shared/button/ButtonComponent";
import { CircularProgress } from "@mui/material";
import AlertComponent from "../../../shared/alert/AlertComponent";
import SpinnerComponent from "../../../shared/spinner/SpinnerComponent";

export default class FinancialAccountVerifyModal extends React.Component<IProps, IState> {
    financialManagementService: FinancialManagementService
    constructor(props: any) {
        super(props);
        this.state = { destinationWallet: '', accountAddress: '', transactionNumber: '', loading: false, pendingRequest: false }
        this.financialManagementService = new FinancialManagementService()
    }

    componentDidMount = () => {
        this.setState({ loading: true })
        this.financialManagementService.getVerifyAmount(this.props.accountId).then(response =>
            this.setState({ amount: response.amount, expireAt: response.expireAt, accountAddress: response.cashPaymentAccountNumber })
        ).finally(() => this.setState({ loading: false }))
    }

    checkPayment = () => {
        this.setState({ pendingRequest: true })

        this.financialManagementService.checkPayment(this.state.transactionNumber, this.props.accountId).then(response => {
            this.setState({ amount: response.amount, expireAt: response.expireAt })
            this.props.onClose()
            this.props.reload()
        }).finally(() => this.setState({ pendingRequest: false }))
    }

    render(): React.ReactNode {
        let { onClose } = this.props
        let { transactionNumber, pendingRequest, accountAddress } = this.state

        return <ModalComponent title={'Verfiy External Wallet'} onClose={onClose} size="800px">
            <div>
                <Form>
                    {
                        this.state.loading ?
                            <SpinnerComponent />
                            :
                            <Row>
                                <Col md="12" sm="12">
                                    <AlertComponent type="success">
                                        <div className="font-bold"> How to verify the external wallet? </div>
                                        In order to automatically verify your wallet deposits,
                                        transfer the amount of <b> {this.state.amount} </b> currency (USDT)
                                        from wallet <code> {this.props.sourceWallet} </code> to the wallet <code> {accountAddress} </code>
                                        within the next 48 hours.
                                    </AlertComponent>
                                </Col>
                                <Col className="mt-3" md="12" sm="12">
                                    <p>If you have paid the amount, enter the transaction number and confirm.</p>
                                    <Form.Label>Transaction Number:</Form.Label>
                                    <Form.Control className="mb-3" value={transactionNumber} onChange={e => this.setState({ transactionNumber: e.target.value })} />
                                </Col>
                            </Row>
                    }
                </Form>
            </div>
            <div className="flex justify-end">
                <CustomButton variant='outlined' onClick={this.props.onClose}>Close</CustomButton>
                {
                    pendingRequest ? <CircularProgress color="inherit" size={20} /> :
                        <CustomButton sx={{ ml: 1 }} variant="contained" onClick={this.checkPayment}>Confirm</CustomButton>
                }
            </div>
        </ModalComponent>
    }
}