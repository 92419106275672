import React from "react"
import { Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap"
import IState, { IProps } from "./TokensConfigs.models"
import { ClipboardPlus, Lock, Unlock, Eye, EyeSlash, Shield, ShieldFill, ShieldLockFill } from "react-bootstrap-icons"
import ConfigurationService from "../../../../services/api/ConfigurationService"
import { NotificationManager } from 'react-notifications'
import './TokensConfigs.scss'
import TOTPForm from "../../../identity/Security/TwoFactor/TOTPForm/TOTPForm"
import AuthenticationContext from "../../../../contexts/AuthenticationContext/AuthenticationContext"
import ActivateShopConfig from "../ActivateShopConfig/ActivateShopConfig"
import Note from "../../../shared/Note/Note"

export default class TokensConfigs extends React.PureComponent<IProps, IState> {
    configurationService: ConfigurationService
    static contextType = AuthenticationContext
    context!: React.ContextType<typeof AuthenticationContext>
    constructor(props: IProps) {
        super(props)
        this.configurationService = new ConfigurationService()
        this.state = { pendingRequest: false, isSecurity: false, activeOTPMode: false, totp: '', showSecretKey: false, secretKey: null }
    }

    copyTextToClipboard = async (text: string) => {
        if ('clipboard' in navigator) {
            NotificationManager.info('Copied.', { style: { width: '200px' } })
            return await navigator.clipboard.writeText(text)
        }
    }

    generateKeyHandler = () => {
        if (this.context.twoFactorAvtive)
            this.setState({ activeOTPMode: true })
        else this.generateSecretKey()
    }

    generateSecretKey = () => {
        this.setState({ pendingRequest: true })
        this.configurationService.reGenerate().then(() => {
            this.unLock()
        }).finally(() => {
            this.setState({ pendingRequest: false })
        })
    }

    getConfirm = () => {
        if (window.confirm('Your site s API communications may be interrupted. are you sure?')) {
            this.generateKeyHandler()
        }
    }

    unLockHandler = () => {
        if (this.context.twoFactorAvtive)
            this.setState({ activeOTPMode: true })
        else this.unLock()
    }

    unLock = () => {
        this.configurationService.getFullInformation().then((response) => {
            this.setState({ isSecurity: true, secretKey: response.secretKey, activeOTPMode: false })
        })
    }

    clear = () => this.setState({ isSecurity: false, secretKey: null, showSecretKey: false })

    handleOnSubmitTotpForm = () => {
        if (this.state.isSecurity) this.generateSecretKey()
        else this.unLock()
    }

    render(): React.ReactNode {
        const { pendingRequest, isSecurity, secretKey } = this.state
        const { apiKey } = this.props

        return <div className="TokensConfigs">
            <Note>Connect the website or any web based system you have to us, so that your purchase is done automatically!</Note>
            <hr />
            {
                apiKey ?
                    <>
                        <Row className="mb-4">
                            <Col>
                                <Form.Group>
                                    <Form.Label>Access Key</Form.Label>
                                    <InputGroup>
                                        <Form.Control disabled value={apiKey} />
                                        <Button variant="outline-secondary" size="sm" className="copy-btn" onClick={() => this.copyTextToClipboard(apiKey)}>
                                            <ClipboardPlus />
                                        </Button>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col></Col>
                        </Row>

                        <Row className="mb-4">
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        Secret Key
                                        {/* {this.state.isSecurity ?
                                        < Unlock className="text-success" size="16px" onClick={() => this.clear()} /> :
                                        < Lock className="text-danger" size="16px" onClick={() => this.unLockHandler()} />
                                    } */}
                                    </Form.Label>
                                    <InputGroup>
                                        <Form.Control disabled value={this.state.showSecretKey && secretKey !== null ? secretKey : '### ### ### ### ###'} />
                                        {this.state.isSecurity ?
                                            <>
                                                <Button variant="outline-secondary" size="sm" className="copy-btn" onClick={() => this.copyTextToClipboard(secretKey ?? '')}>
                                                    <ClipboardPlus />
                                                </Button>
                                                <Button variant="outline-secondary" size="sm" className="copy-btn" onClick={() => this.setState({ showSecretKey: !this.state.showSecretKey })}>
                                                    {!this.state.showSecretKey ? <Eye /> : <EyeSlash />}
                                                </Button>
                                                <Button variant="outline-secondary" size="sm" className="copy-btn" onClick={() => this.clear()} >
                                                    <ShieldFill />
                                                </Button>
                                            </> :
                                            <>
                                                <Button variant="outline-secondary" size="sm" className="copy-btn" onClick={() => this.unLockHandler()} >
                                                    <ShieldLockFill />
                                                </Button>
                                            </>
                                        }
                                    </InputGroup>
                                </Form.Group>
                                {(secretKey && isSecurity) &&
                                    <>
                                        {pendingRequest ? <Button variant="outline-info" size="sm" disabled><Spinner animation="border" size="sm"></Spinner></Button> :
                                            <Button variant="outline-danger" size="sm" className="m-1" onClick={this.getConfirm}>Regenerate secret key</Button>}
                                    </>
                                }
                            </Col>
                            <Col></Col>
                        </Row>
                    </> :
                    <ActivateShopConfig reload={this.props.reload} />
            }
            {this.state.activeOTPMode &&
                <TOTPForm showModal={this.state.activeOTPMode} onSubmit={() => { this.handleOnSubmitTotpForm() }} onHide={() => this.setState({ activeOTPMode: false })} />
            }
        </div>
    }
}