import WalletListItem from "../../components/financials/wallets/WalletList/WalletList.models";
import WalletTransactionListItem from "../../components/financials/wallets/WalletTransactionList/WalletTransactionList.models";
import { IWalletTransactionsFilters } from "../../components/financials/wallets/WalletTransactionReport/WalletTransactionReport.models";
import { GridResult } from "../../components/Grid/Grid.models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import CommonService from "../common/CommonService";
import { mapToDataOnly } from "../common/HttpUtility";
import { SelectionView } from "../common/Models";
import { ToLocaleDate } from "../../components/utilities/HybridMoment";
import { WalletTransactionApiFactory, WalletApiFactory, CashPaymentAccountApiFactory, LocalCurrencyApiFactory, CashPaymentApiFactory, WalletTransactionReportApiFactory, FinancialsModelsWalletBuyCreditDto } from "../../generated-api/financials";

export default class WalletService {
    getOverview(): Promise<IWalletBalanceDTO> {
        return mapToDataOnly<IWalletBalanceDTO>(WalletApiFactory().apiV1WalletBalanceGet());
    }

    getWalletList(filters: IWalletsPaginationFilters, paginationQuery: PaginationQuery): Promise<GridResult<WalletListItem>> {
        return mapToDataOnly<GridResult<WalletListItem>>(WalletApiFactory().apiV1WalletGet(filters.onlyNegativeWallet, filters.onlyGlobalWallet, filters.ownerId, paginationQuery.pageskip - 1, paginationQuery.pagesize));
    }

    getWalletTransactionList(query: WalletTransactionQuery): Promise<GridResult<WalletTransactionListItem>> {
        return mapToDataOnly<GridResult<WalletTransactionListItem>>(WalletTransactionApiFactory().apiV1WalletTransactionGet(query.walletId, query.pageskip - 1, query.pagesize));
    }

    getBalanceTitleValues(): Promise<SelectionView[]> {
        return mapToDataOnly<SelectionView[]>(WalletApiFactory().apiV1WalletBalanceTitleValuesGet());
    }

    getLastAverage(currencyId: string): Promise<{ lastAverage: number }> {
        return mapToDataOnly<{ lastAverage: number }>(LocalCurrencyApiFactory().apiV1LocalCurrencyLastAverageCurrencyIdGet(currencyId));
    }

    getPaymentMethods(currencyId: string): Promise<PaymentMethodDto[]> {
        return mapToDataOnly<PaymentMethodDto[]>(CashPaymentAccountApiFactory().apiV1CashPaymentAccountSelectionsCurrencyIdGet(currencyId));
    }

    getBalance(walletId: string): Promise<IWalletBalanceDTO> {
        return mapToDataOnly<IWalletBalanceDTO>(WalletApiFactory().apiV1WalletBalanceWalletIdGet(walletId));
    }


    transferFund(dto: WalletTransferFund): Promise<WalletTransferFundRespose> {
        return mapToDataOnly<WalletTransferFundRespose>(WalletTransactionApiFactory().apiV1WalletTransactionPost(dto));
    }

    getWalletTransactions(filters: IWalletTransactionsFilters, paginationQuery: PaginationQuery): Promise<GridResult<WalletTransactionListItem>> {
        return mapToDataOnly<GridResult<WalletTransactionListItem>>(WalletTransactionReportApiFactory()
            .apiV1WalletTransactionReportGet(ToLocaleDate(filters.fromDate), ToLocaleDate(filters.toDate), filters.walletId, filters.amount, filters.status, paginationQuery.pageskip - 1, paginationQuery.pagesize));
    }

    downloadExcelWalletTransactions(filters: IWalletTransactionsFilters): Promise<any> {
        var commonService = new CommonService();

        return commonService.export(WalletTransactionReportApiFactory().apiV1WalletTransactionReportExcelGet(ToLocaleDate(filters.fromDate), ToLocaleDate(filters.toDate), filters.walletId, filters.amount, filters.status, 0, 1), 'wallet-transactions.xlsx')
    }

    buyCredit(walletId: string, dto: FinancialsModelsWalletBuyCreditDto): Promise<any> {
        return mapToDataOnly<any>(WalletApiFactory().apiV1WalletBuyCreditWalletIdPut(walletId, dto));
    }

    // getFavoriteDestinations(): Promise<{ result: FavoriteTransferDestinationRow[] }> {
    //     return mapToDataOnly<{ result: FavoriteTransferDestinationRow[] }>(WalletTransactionApiFactory()
    //         .marketPlaceWalletTransactionFavoriteTransferDestinationsGet());
    // }
}

export interface FavoriteTransferDestinationRow {
    address: string
    shopName: string
    id: string
    createAt: string
}

interface WalletTransferFund {
    amount: number,
    description: string,
    sourceWalletId: string,
    destinationWalletId: string,
    addDestinationToFavorites: boolean
}


interface WalletTransferFundRespose {
    transactionId: string,
}

export interface IWalletsPaginationFilters {
    ownerId?: string
    onlyGlobalWallet?: boolean
    onlyNegativeWallet?: boolean
}

export interface IWalletBalanceDTO {
    balance: number;
    blockedBalance: number;
    withdrawCredit: number;
    pendingBalance: number;
    credit: number;
    localCurrencyAverage: number;
}

export interface PaymentMethodDto {
    accountNumber: string
    description: string
    id: string
    title: string,
    providerKey: string
}

interface CreatePaymentDto {
    amount: number
    autoExchangeRequestWalletId: string
    cashPaymentAccountId: string
    description: string
    purchasePrice: number
    transactionAt: string
    // transactionAt: "2022-05-02T14:23:54.714+04:30"
}

export interface WalletTransactionQuery extends PaginationQuery {
    walletId: string;
}