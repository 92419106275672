import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import Articles from "../Articles/Articles";
import FAQ from "../FAQ/FAQ";
import SupportTickets from "../SupportTickets/SupportTickets";
import Note from "../../shared/Note/Note";
import authenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext";
import { Container } from "@mui/material";

export default class HelpCenter extends React.Component<{ activeTab?: string | null, categoryId?: string }, { navigateToTab?: string }> {
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps: Readonly<{ activeTab: string | null; }>, prevState: Readonly<{ navigateToTab?: string; }>, snapshot?: any): void {
        if (prevProps.activeTab !== this.props.activeTab)
            this.setState({ navigateToTab: undefined })
    }

    handleSelect = (key) => this.setState({ navigateToTab: key });

    render(): React.ReactNode {
        return <Container className="pt-xl HelpCenter">
            <Note>
                We will help you through FAQ, articles and finally direct message with us.
            </Note>
            <hr />
            {this.state.navigateToTab && this.state.navigateToTab !== this.props.activeTab ? <Navigate to={"/helpCenter?activeTab=" + this.state.navigateToTab} /> : null}
            <Tabs defaultActiveKey={this.props.activeTab ?? 'supportTickets'} onSelect={this.handleSelect} id="financials-tabs" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                <Tab eventKey="supportTickets" title="Support Tickets">
                    <SupportTickets globalTicketOnly />
                </Tab>
                <Tab eventKey="faq" title="FAQ">
                    <FAQ />
                </Tab>
                <Tab eventKey="articles" title="Articles">
                    <Articles categoryId={this.props.categoryId} />
                </Tab>
            </Tabs>
        </Container>
    }
}