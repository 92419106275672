import React from "react";
import IUserProfileState, { IUserProfileProps } from './UserProfile.models'
import { Col, Form, Row } from "react-bootstrap";
import { NotificationManager } from 'react-notifications';
import IdentityService from "../../../services/identity/IdentityService";
import { CircularProgress, Container } from "@mui/material";
import SpinnerComponent from "../../shared/spinner/SpinnerComponent";
import { CustomButton } from "../../shared/button/ButtonComponent";

export default class UserProfile extends React.PureComponent<IUserProfileProps, IUserProfileState> {
    identityService: IdentityService;
    constructor(props: IUserProfileProps) {
        super(props);
        this.identityService = new IdentityService()
        this.state = { loading: false, isLoading: false, userInformation: { id: '', lastName: '', firstName: '', email: '' } };
    }

    componentDidMount() {
        this.getInfo()
    }

    getInfo() {
        this.setState({ loading: true })
        this.identityService.getUserInformationComplete().then((response) => {
            this.setState({
                userInformation: { email: response.email, id: response.id, firstName: response.firstName, lastName: response.lastName },
                loading: false
            })
        })
    }

    submit = () => {
        const { userInformation } = this.state;

        if (userInformation.firstName.length === 0 || userInformation.lastName.length === 0) {
            NotificationManager.error('All fields are required.');
            return;
        }

        this.setState({ isLoading: true })
        this.identityService.updateUserInformation({ firstName: userInformation.firstName, lastName: userInformation.lastName }).then(() => {
            NotificationManager.success('Your information changed successfully.');
        }).finally(() => {
            this.setState({ isLoading: false });
        }).catch(() => {
            NotificationManager.error('An error has occurred.');
        })
    }

    render(): React.ReactNode {
        const { loading, userInformation, isLoading } = this.state;
        return (
            <Container className="mt-lg">
                {
                    loading ? <SpinnerComponent /> :
                        <div>
                            <div className="font-bold font-lg">Edit Profile</div>
                            <div className="font-bold">{userInformation.email}</div>
                            <Form onSubmit={this.submit} className="pt-md">
                                <Row>
                                    <Col md="4" sm="12">
                                        <Form.Group controlId="userInformation.firstName">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control type="text" value={userInformation.firstName}
                                                placeholder="Enter your name" onChange={e => { this.setState(prevState => { return { userInformation: { ...prevState.userInformation, firstName: e.target.value } } }) }} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="4" sm="12" className="pt-md">
                                        <Form.Group controlId="userInformation.lastName">
                                            <Form.Label>Last name</Form.Label>
                                            <Form.Control type="text" value={userInformation.lastName}
                                                placeholder="Enter your last name" onChange={e => { this.setState(prevState => { return { userInformation: { ...prevState.userInformation, lastName: e.target.value } } }) }} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div className="pt-md">
                                    {
                                        isLoading ? <CustomButton variant="contained" disabled>
                                            <CircularProgress color="inherit" size={20} /> </CustomButton> :
                                            <CustomButton variant='contained' className="mt-2" onClick={this.submit}>Save changes</CustomButton>
                                    }
                                </div>
                            </Form>
                        </div>
                }
            </Container>
        )
    }
}