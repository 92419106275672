/* tslint:disable */
/* eslint-disable */
/**
 * Test API V1
 * An ASP.NET Core Web API for testing versioning
 *
 * The version of the OpenAPI document: Version 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseKeyValueDTOSystemDouble
 */
export interface ApiGiftGatewayCommonDTOsBaseKeyValueDTOSystemDouble {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsBaseKeyValueDTOSystemDouble
     */
    'key'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseKeyValueDTOSystemDouble
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCashoutsCashoutRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCashoutsCashoutRowDTO {
    /**
     * 
     * @type {Array<ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCashoutsCashoutRowDTO
     */
    'rows'?: Array<ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCashoutsCashoutRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCashoutsShopCashoutRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCashoutsShopCashoutRowDTO {
    /**
     * 
     * @type {Array<ApiGiftGatewayCommonDTOsFinancialCashoutsShopCashoutRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCashoutsShopCashoutRowDTO
     */
    'rows'?: Array<ApiGiftGatewayCommonDTOsFinancialCashoutsShopCashoutRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCashoutsShopCashoutRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCreditsCreditRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCreditsCreditRowDTO {
    /**
     * 
     * @type {Array<ApiGiftGatewayCommonDTOsFinancialCreditsCreditRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCreditsCreditRowDTO
     */
    'rows'?: Array<ApiGiftGatewayCommonDTOsFinancialCreditsCreditRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCreditsCreditRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCurrenciesCurrencyRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCurrenciesCurrencyRowDTO {
    /**
     * 
     * @type {Array<ApiGiftGatewayCommonDTOsFinancialCurrenciesCurrencyRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCurrenciesCurrencyRowDTO
     */
    'rows'?: Array<ApiGiftGatewayCommonDTOsFinancialCurrenciesCurrencyRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCurrenciesCurrencyRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountRowDTO {
    /**
     * 
     * @type {Array<ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountRowDTO
     */
    'rows'?: Array<ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountRowDTO {
    /**
     * 
     * @type {Array<ApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountRowDTO
     */
    'rows'?: Array<ApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialHoldsHoldRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialHoldsHoldRowDTO {
    /**
     * 
     * @type {Array<ApiGiftGatewayCommonDTOsFinancialHoldsHoldRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialHoldsHoldRowDTO
     */
    'rows'?: Array<ApiGiftGatewayCommonDTOsFinancialHoldsHoldRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialHoldsHoldRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialPaymentsAdminCashPaymentRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialPaymentsAdminCashPaymentRowDTO {
    /**
     * 
     * @type {Array<ApiGiftGatewayCommonDTOsFinancialPaymentsAdminCashPaymentRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialPaymentsAdminCashPaymentRowDTO
     */
    'rows'?: Array<ApiGiftGatewayCommonDTOsFinancialPaymentsAdminCashPaymentRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialPaymentsAdminCashPaymentRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentRowDTO {
    /**
     * 
     * @type {Array<ApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentRowDTO
     */
    'rows'?: Array<ApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionRowDTO {
    /**
     * 
     * @type {Array<ApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionRowDTO
     */
    'rows'?: Array<ApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialWalletsWalletRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialWalletsWalletRowDTO {
    /**
     * 
     * @type {Array<ApiGiftGatewayCommonDTOsFinancialWalletsWalletRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialWalletsWalletRowDTO
     */
    'rows'?: Array<ApiGiftGatewayCommonDTOsFinancialWalletsWalletRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialWalletsWalletRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionRowDTO {
    /**
     * 
     * @type {Array<ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionRowDTO
     */
    'rows'?: Array<ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOFinancialsCommonCashierQueriesGetPagedCashierQueryResponseCashierRow
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOFinancialsCommonCashierQueriesGetPagedCashierQueryResponseCashierRow {
    /**
     * 
     * @type {Array<FinancialsCommonCashierQueriesGetPagedCashierQueryResponseCashierRow>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOFinancialsCommonCashierQueriesGetPagedCashierQueryResponseCashierRow
     */
    'rows'?: Array<FinancialsCommonCashierQueriesGetPagedCashierQueryResponseCashierRow> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOFinancialsCommonCashierQueriesGetPagedCashierQueryResponseCashierRow
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOFinancialsModelsWalletTransactionReport
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOFinancialsModelsWalletTransactionReport {
    /**
     * 
     * @type {Array<FinancialsModelsWalletTransactionReport>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOFinancialsModelsWalletTransactionReport
     */
    'rows'?: Array<FinancialsModelsWalletTransactionReport> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOFinancialsModelsWalletTransactionReport
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTitleValueDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTitleValueDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsBaseTitleValueDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsBaseTitleValueDTO
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemGuid
 */
export interface ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemGuid {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemGuid
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemGuid
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO
     */
    'financialAccountId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO
     */
    'inventorySourceId'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutDetailsDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutDetailsDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutDetailsDTO
     */
    'shopId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutDetailsDTO
     */
    'shopTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutDetailsDTO
     */
    'requestAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutDetailsDTO
     */
    'handleAt'?: string | null;
    /**
     * 
     * @type {ApiGiftGatewayCommonEnumsFinancialCashoutsCashoutStatus}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutDetailsDTO
     */
    'status'?: ApiGiftGatewayCommonEnumsFinancialCashoutsCashoutStatus;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutDetailsDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutDetailsDTO
     */
    'financialAccountType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutDetailsDTO
     */
    'financialAccount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutDetailsDTO
     */
    'handlerUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutDetailsDTO
     */
    'handlerName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutDetailsDTO
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutDetailsDTO
     */
    'transactionNumber'?: string | null;
}


/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutRowDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutRowDTO
     */
    'shopId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutRowDTO
     */
    'shopTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutRowDTO
     */
    'lastReviewAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutRowDTO
     */
    'amount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutRowDTO
     */
    'isBlockchainVerification'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutRowDTO
     */
    'requestAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutRowDTO
     */
    'handleAt'?: string | null;
    /**
     * 
     * @type {FinancialsCommonCashierEnumsWithdrawStatus}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutRowDTO
     */
    'status'?: FinancialsCommonCashierEnumsWithdrawStatus;
}


/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialCashoutsShopCashoutRowDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialCashoutsShopCashoutRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsShopCashoutRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsShopCashoutRowDTO
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsShopCashoutRowDTO
     */
    'financialAccount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsShopCashoutRowDTO
     */
    'sendAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsShopCashoutRowDTO
     */
    'requestAt'?: string;
    /**
     * 
     * @type {FinancialsCommonCashierEnumsWithdrawStatus}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsShopCashoutRowDTO
     */
    'status'?: FinancialsCommonCashierEnumsWithdrawStatus;
}


/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialCashoutsWithdrawFeeDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialCashoutsWithdrawFeeDTO {
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsWithdrawFeeDTO
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialCreditsCreditRowDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialCreditsCreditRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCreditsCreditRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCreditsCreditRowDTO
     */
    'ownerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCreditsCreditRowDTO
     */
    'ownerTitle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCreditsCreditRowDTO
     */
    'amountValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCreditsCreditRowDTO
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCreditsCreditRowDTO
     */
    'createDate'?: string;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialCurrenciesCreateCurrencyDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialCurrenciesCreateCurrencyDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCurrenciesCreateCurrencyDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCurrenciesCreateCurrencyDTO
     */
    'symbol'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialCurrenciesCurrencyRowDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialCurrenciesCurrencyRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCurrenciesCurrencyRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCurrenciesCurrencyRowDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCurrenciesCurrencyRowDTO
     */
    'symbol'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCurrenciesCurrencyRowDTO
     */
    'isMainCurrency'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO
     */
    'symbol'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialCurrencyRatesCurrencyRateDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialCurrencyRatesCurrencyRateDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCurrencyRatesCurrencyRateDTO
     */
    'currencyId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCurrencyRatesCurrencyRateDTO
     */
    'dollarRate'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCurrencyRatesCurrencyRateDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCurrencyRatesCurrencyRateDTO
     */
    'isManual'?: boolean | null;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO
     */
    'currencyId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO
     */
    'rate'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO
     */
    'isManual'?: boolean | null;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCheckVerifiedAccountDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCheckVerifiedAccountDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCheckVerifiedAccountDTO
     */
    'financialAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCheckVerifiedAccountDTO
     */
    'transactionNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO
     */
    'nickName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO
     */
    'financialAccountTypeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO
     */
    'accountIdentity'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountDetailsDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountDetailsDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountDetailsDTO
     */
    'accountIdentity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountDetailsDTO
     */
    'shopTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountDetailsDTO
     */
    'shopId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountDetailsDTO
     */
    'ownerName'?: string | null;
    /**
     * 
     * @type {ApiGiftGatewayCommonEnumsFinancialFinancialAccountsFinancialAccountStatus}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountDetailsDTO
     */
    'status'?: ApiGiftGatewayCommonEnumsFinancialFinancialAccountsFinancialAccountStatus;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountDetailsDTO
     */
    'requestAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountDetailsDTO
     */
    'handleAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountDetailsDTO
     */
    'handlerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountDetailsDTO
     */
    'handlerUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountDetailsDTO
     */
    'currencyId'?: string;
}


/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountRowDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountRowDTO
     */
    'ownerShopId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountRowDTO
     */
    'ownerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountRowDTO
     */
    'requestAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountRowDTO
     */
    'handlerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountRowDTO
     */
    'handlerUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountRowDTO
     */
    'handleAt'?: string | null;
    /**
     * 
     * @type {ApiGiftGatewayCommonEnumsFinancialFinancialAccountsFinancialAccountStatus}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountRowDTO
     */
    'status'?: ApiGiftGatewayCommonEnumsFinancialFinancialAccountsFinancialAccountStatus;
}


/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialFinancialAccountsHandleFinancialAccountDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialFinancialAccountsHandleFinancialAccountDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsHandleFinancialAccountDTO
     */
    'financialAccountId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsHandleFinancialAccountDTO
     */
    'isAccepted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsHandleFinancialAccountDTO
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountDetailsDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountDetailsDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountDetailsDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountDetailsDTO
     */
    'createAt'?: string;
    /**
     * 
     * @type {ApiGiftGatewayCommonEnumsFinancialFinancialAccountsFinancialAccountStatus}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountDetailsDTO
     */
    'status'?: ApiGiftGatewayCommonEnumsFinancialFinancialAccountsFinancialAccountStatus;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountDetailsDTO
     */
    'accountIdentity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountDetailsDTO
     */
    'nickName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountDetailsDTO
     */
    'typeTitle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountDetailsDTO
     */
    'verifyAmount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountDetailsDTO
     */
    'generateVerifyAmountAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountDetailsDTO
     */
    'verifyCashPaymentAccountId'?: string | null;
}


/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountRowDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountRowDTO
     */
    'createAt'?: string;
    /**
     * 
     * @type {ApiGiftGatewayCommonEnumsFinancialFinancialAccountsFinancialAccountStatus}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountRowDTO
     */
    'status'?: ApiGiftGatewayCommonEnumsFinancialFinancialAccountsFinancialAccountStatus;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountRowDTO
     */
    'accountIdentity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountRowDTO
     */
    'nickName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountRowDTO
     */
    'typeTitle'?: string | null;
}


/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO
     */
    'financialAccountId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO
     */
    'nickName'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialHoldsHoldRowDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialHoldsHoldRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialHoldsHoldRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialHoldsHoldRowDTO
     */
    'ownerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialHoldsHoldRowDTO
     */
    'ownerTitle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialHoldsHoldRowDTO
     */
    'amountValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialHoldsHoldRowDTO
     */
    'createDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialHoldsHoldRowDTO
     */
    'creatorUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialHoldsHoldRowDTO
     */
    'creatorUserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialHoldsHoldRowDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialHoldsHoldRowDTO
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialHoldsHoldRowDTO
     */
    'referenceLink'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialPaymentsAdminCashPaymentRowDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialPaymentsAdminCashPaymentRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsAdminCashPaymentRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsAdminCashPaymentRowDTO
     */
    'shopId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsAdminCashPaymentRowDTO
     */
    'shopTitle'?: string | null;
    /**
     * 
     * @type {ApiGiftGatewayCommonEnumsFinancialPaymentsCashPaymentStatus}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsAdminCashPaymentRowDTO
     */
    'status'?: ApiGiftGatewayCommonEnumsFinancialPaymentsCashPaymentStatus;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsAdminCashPaymentRowDTO
     */
    'invoiceId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsAdminCashPaymentRowDTO
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsAdminCashPaymentRowDTO
     */
    'transactionNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsAdminCashPaymentRowDTO
     */
    'crreateAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsAdminCashPaymentRowDTO
     */
    'cashPaymentAccountTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsAdminCashPaymentRowDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsAdminCashPaymentRowDTO
     */
    'isBlockchainVerification'?: boolean | null;
}


/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentAccountSelectionDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentAccountSelectionDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentAccountSelectionDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentAccountSelectionDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentAccountSelectionDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentAccountSelectionDTO
     */
    'accountNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentAccountSelectionDTO
     */
    'providerKey'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentRowDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {ApiGiftGatewayCommonEnumsFinancialPaymentsCashPaymentStatus}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentRowDTO
     */
    'status'?: ApiGiftGatewayCommonEnumsFinancialPaymentsCashPaymentStatus;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentRowDTO
     */
    'invoiceId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentRowDTO
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentRowDTO
     */
    'transactionNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentRowDTO
     */
    'crreateAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentRowDTO
     */
    'cashPaymentAccountTitle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentRowDTO
     */
    'purchasePrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentRowDTO
     */
    'purchaseCurrency'?: string | null;
}


/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialPaymentsPaymentGatewaysDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialPaymentsPaymentGatewaysDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsPaymentGatewaysDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsPaymentGatewaysDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsPaymentGatewaysDTO
     */
    'key'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialPaymentsTransactionVerificationInquiryDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialPaymentsTransactionVerificationInquiryDTO {
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsTransactionVerificationInquiryDTO
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsTransactionVerificationInquiryDTO
     */
    'fromWallet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsTransactionVerificationInquiryDTO
     */
    'toWallet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialPaymentsTransactionVerificationInquiryDTO
     */
    'transactionAt'?: string;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialWalletsDefaultWalletDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialWalletsDefaultWalletDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsDefaultWalletDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsDefaultWalletDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsDefaultWalletDTO
     */
    'currencyId'?: string;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO
     */
    'fromWalletId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO
     */
    'toWalletId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialWalletsGlobalWalletBalanceDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialWalletsGlobalWalletBalanceDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsGlobalWalletBalanceDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsGlobalWalletBalanceDTO
     */
    'key'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsGlobalWalletBalanceDTO
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialWalletsLockWalletHandlerDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialWalletsLockWalletHandlerDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsLockWalletHandlerDTO
     */
    'isLock'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsLockWalletHandlerDTO
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialWalletsShopCreateRequestExchangeWalletTransactionDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialWalletsShopCreateRequestExchangeWalletTransactionDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsShopCreateRequestExchangeWalletTransactionDTO
     */
    'fromWalletId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsShopCreateRequestExchangeWalletTransactionDTO
     */
    'toWalletId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsShopCreateRequestExchangeWalletTransactionDTO
     */
    'amount'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionDetailsDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionDetailsDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionDetailsDTO
     */
    'requestAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionDetailsDTO
     */
    'shopMemberUserDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionDetailsDTO
     */
    'creatorUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionDetailsDTO
     */
    'sourceCurrencyTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionDetailsDTO
     */
    'destinationCurrencyTitle'?: string | null;
    /**
     * 
     * @type {ApiGiftGatewayCommonEnumsFinancialBasicExchangeWalletTransactionStatus}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionDetailsDTO
     */
    'status'?: ApiGiftGatewayCommonEnumsFinancialBasicExchangeWalletTransactionStatus;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionDetailsDTO
     */
    'handleDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionDetailsDTO
     */
    'sourceAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionDetailsDTO
     */
    'destinationAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionDetailsDTO
     */
    'exchangeRate'?: number;
}


/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionRowDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionRowDTO
     */
    'requestAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionRowDTO
     */
    'sourceCurrencyTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionRowDTO
     */
    'destinationCurrencyTitle'?: string | null;
    /**
     * 
     * @type {ApiGiftGatewayCommonEnumsFinancialBasicExchangeWalletTransactionStatus}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionRowDTO
     */
    'status'?: ApiGiftGatewayCommonEnumsFinancialBasicExchangeWalletTransactionStatus;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionRowDTO
     */
    'sourceAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionRowDTO
     */
    'destinationAmount'?: number;
}


/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialWalletsTotalWalletMinimumPermissionDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialWalletsTotalWalletMinimumPermissionDTO {
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsTotalWalletMinimumPermissionDTO
     */
    'balance'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialWalletsWalletBalanceDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialWalletsWalletBalanceDTO {
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletBalanceDTO
     */
    'blockedBalance'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletBalanceDTO
     */
    'balance'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletBalanceDTO
     */
    'withdrawCredit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletBalanceDTO
     */
    'credit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletBalanceDTO
     */
    'pendingBalance'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletBalanceDTO
     */
    'localCurrencyAverage'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialWalletsWalletChargeStatusDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialWalletsWalletChargeStatusDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletChargeStatusDTO
     */
    'walletOwnerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletChargeStatusDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletChargeStatusDTO
     */
    'balance'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletChargeStatusDTO
     */
    'walletCurrencyTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletChargeStatusDTO
     */
    'walletCurrencyId'?: string;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialWalletsWalletInformationDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialWalletsWalletInformationDTO {
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletInformationDTO
     */
    'lastAverage'?: number | null;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialWalletsWalletRowDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialWalletsWalletRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletRowDTO
     */
    'ownerShopId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletRowDTO
     */
    'ownerTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletRowDTO
     */
    'currencyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletRowDTO
     */
    'currencyTitle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletRowDTO
     */
    'balance'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletRowDTO
     */
    'pending'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletRowDTO
     */
    'credit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletRowDTO
     */
    'hold'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletRowDTO
     */
    'isLock'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletRowDTO
     */
    'lockDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletRowDTO
     */
    'globalKey'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionDTO {
    /**
     * 
     * @type {ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionDTO
     */
    'status'?: ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionDTO
     */
    'fromWalletDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionDTO
     */
    'toWalletDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionDTO
     */
    'acceptorUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionDTO
     */
    'acceptDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionDTO
     */
    'memberUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionDTO
     */
    'createAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionDTO
     */
    'toWalletId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionDTO
     */
    'fromWalletId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionDTO
     */
    'toShopId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionDTO
     */
    'sourceName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionDTO
     */
    'destinationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionDTO
     */
    'fromShopId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionDTO
     */
    'sourceAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionDTO
     */
    'destinationAmount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionDTO
     */
    'isRefund'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionDTO
     */
    'fromShopTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionDTO
     */
    'toShopTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionDTO
     */
    'acceptorUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionDTO
     */
    'memberName'?: string | null;
}


/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionRowDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionRowDTO {
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionRowDTO
     */
    'amount'?: number;
    /**
     * 
     * @type {ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionRowDTO
     */
    'status'?: ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionRowDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionRowDTO
     */
    'memberUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionRowDTO
     */
    'memberName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionRowDTO
     */
    'createAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionRowDTO
     */
    'isOutTransaction'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionRowDTO
     */
    'id'?: string;
}


/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto
 */
export interface ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto {
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto
     */
    'sourceWalletId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto
     */
    'destinationWalletId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto
     */
    'addDestinationToFavorites'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundResponseDto
 */
export interface ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundResponseDto
     */
    'transactionId'?: string;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO
     */
    'transactionNumber'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ApiGiftGatewayCommonEnumsFinancialBasicExchangeWalletTransactionStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type ApiGiftGatewayCommonEnumsFinancialBasicExchangeWalletTransactionStatus = typeof ApiGiftGatewayCommonEnumsFinancialBasicExchangeWalletTransactionStatus[keyof typeof ApiGiftGatewayCommonEnumsFinancialBasicExchangeWalletTransactionStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const ApiGiftGatewayCommonEnumsFinancialCashoutsCashoutStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type ApiGiftGatewayCommonEnumsFinancialCashoutsCashoutStatus = typeof ApiGiftGatewayCommonEnumsFinancialCashoutsCashoutStatus[keyof typeof ApiGiftGatewayCommonEnumsFinancialCashoutsCashoutStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const ApiGiftGatewayCommonEnumsFinancialFinancialAccountsFinancialAccountStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type ApiGiftGatewayCommonEnumsFinancialFinancialAccountsFinancialAccountStatus = typeof ApiGiftGatewayCommonEnumsFinancialFinancialAccountsFinancialAccountStatus[keyof typeof ApiGiftGatewayCommonEnumsFinancialFinancialAccountsFinancialAccountStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const ApiGiftGatewayCommonEnumsFinancialPaymentsCashPaymentStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type ApiGiftGatewayCommonEnumsFinancialPaymentsCashPaymentStatus = typeof ApiGiftGatewayCommonEnumsFinancialPaymentsCashPaymentStatus[keyof typeof ApiGiftGatewayCommonEnumsFinancialPaymentsCashPaymentStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus = typeof ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus[keyof typeof ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus];


/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO
 */
export interface ApiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO {
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO
     */
    'amount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO
     */
    'walletId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface FinancialsCommonCashierCommandsDepositsVerifyDepositCommandResponse
 */
export interface FinancialsCommonCashierCommandsDepositsVerifyDepositCommandResponse {
    /**
     * 
     * @type {number}
     * @memberof FinancialsCommonCashierCommandsDepositsVerifyDepositCommandResponse
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof FinancialsCommonCashierCommandsDepositsVerifyDepositCommandResponse
     */
    'message'?: string | null;
    /**
     * 
     * @type {FinancialsCommonCashierCommandsDepositsVerifyDepositCommandResponseStatuses}
     * @memberof FinancialsCommonCashierCommandsDepositsVerifyDepositCommandResponse
     */
    'status'?: FinancialsCommonCashierCommandsDepositsVerifyDepositCommandResponseStatuses;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const FinancialsCommonCashierCommandsDepositsVerifyDepositCommandResponseStatuses = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_3: 3
} as const;

export type FinancialsCommonCashierCommandsDepositsVerifyDepositCommandResponseStatuses = typeof FinancialsCommonCashierCommandsDepositsVerifyDepositCommandResponseStatuses[keyof typeof FinancialsCommonCashierCommandsDepositsVerifyDepositCommandResponseStatuses];


/**
 * 
 * @export
 * @enum {string}
 */

export const FinancialsCommonCashierEnumsWithdrawStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type FinancialsCommonCashierEnumsWithdrawStatus = typeof FinancialsCommonCashierEnumsWithdrawStatus[keyof typeof FinancialsCommonCashierEnumsWithdrawStatus];


/**
 * 
 * @export
 * @interface FinancialsCommonCashierQueriesGetPagedCashierQueryResponseCashierRow
 */
export interface FinancialsCommonCashierQueriesGetPagedCashierQueryResponseCashierRow {
    /**
     * 
     * @type {string}
     * @memberof FinancialsCommonCashierQueriesGetPagedCashierQueryResponseCashierRow
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialsCommonCashierQueriesGetPagedCashierQueryResponseCashierRow
     */
    'walletId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialsCommonCashierQueriesGetPagedCashierQueryResponseCashierRow
     */
    'accountNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FinancialsCommonCashierQueriesGetPagedCashierQueryResponseCashierRow
     */
    'balance'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FinancialsCommonCashierQueriesGetPagedCashierQueryResponseCashierRow
     */
    'realBalance'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FinancialsCommonCashierQueriesGetPagedCashierQueryResponseCashierRow
     */
    'currencySymbol'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FinancialsCommonCashierQueriesGetPagedCashierQueryResponseCashierRow
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FinancialsCommonCashierQueriesGetPagedCashierQueryResponseCashierRow
     */
    'key'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FinancialsCommonCashierQueriesGetPagedCashierQueryResponseCashierRow
     */
    'order'?: number | null;
}
/**
 * 
 * @export
 * @interface FinancialsCommonCommandsFinancialAccountsGenerateVerifyAmountFinancialAccountCommandsGenerateVerifyAmountFinancialAccountCommandResponse
 */
export interface FinancialsCommonCommandsFinancialAccountsGenerateVerifyAmountFinancialAccountCommandsGenerateVerifyAmountFinancialAccountCommandResponse {
    /**
     * 
     * @type {number}
     * @memberof FinancialsCommonCommandsFinancialAccountsGenerateVerifyAmountFinancialAccountCommandsGenerateVerifyAmountFinancialAccountCommandResponse
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof FinancialsCommonCommandsFinancialAccountsGenerateVerifyAmountFinancialAccountCommandsGenerateVerifyAmountFinancialAccountCommandResponse
     */
    'expireAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialsCommonCommandsFinancialAccountsGenerateVerifyAmountFinancialAccountCommandsGenerateVerifyAmountFinancialAccountCommandResponse
     */
    'cashPaymentAccountNumber'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FinancialsCommonEnumsInvoicePaymentMethods = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type FinancialsCommonEnumsInvoicePaymentMethods = typeof FinancialsCommonEnumsInvoicePaymentMethods[keyof typeof FinancialsCommonEnumsInvoicePaymentMethods];


/**
 * 
 * @export
 * @enum {string}
 */

export const FinancialsCommonEnumsInvoiceTypes = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type FinancialsCommonEnumsInvoiceTypes = typeof FinancialsCommonEnumsInvoiceTypes[keyof typeof FinancialsCommonEnumsInvoiceTypes];


/**
 * 
 * @export
 * @interface FinancialsCommonQueriesTronScanTransactionInquiryQueriesGetTronScanTransactionInquiryQueryResponseTronScanTransactionResultView
 */
export interface FinancialsCommonQueriesTronScanTransactionInquiryQueriesGetTronScanTransactionInquiryQueryResponseTronScanTransactionResultView {
    /**
     * 
     * @type {number}
     * @memberof FinancialsCommonQueriesTronScanTransactionInquiryQueriesGetTronScanTransactionInquiryQueryResponseTronScanTransactionResultView
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof FinancialsCommonQueriesTronScanTransactionInquiryQueriesGetTronScanTransactionInquiryQueryResponseTronScanTransactionResultView
     */
    'token'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FinancialsCommonQueriesTronScanTransactionInquiryQueriesGetTronScanTransactionInquiryQueryResponseTronScanTransactionResultView
     */
    'toWalletAddress'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FinancialsCommonQueriesTronScanTransactionInquiryQueriesGetTronScanTransactionInquiryQueryResponseTronScanTransactionResultView
     */
    'isConfirm'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FinancialsCommonQueriesTronScanTransactionInquiryQueriesGetTronScanTransactionInquiryQueryResponseTronScanTransactionResultView
     */
    'fromWalletAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FinancialsCommonQueriesTronScanTransactionInquiryQueriesGetTronScanTransactionInquiryQueryResponseTronScanTransactionResultView
     */
    'transactionDate'?: string;
}
/**
 * 
 * @export
 * @interface FinancialsCommonQueriesWalletsGetAverageWalletQueryResponse
 */
export interface FinancialsCommonQueriesWalletsGetAverageWalletQueryResponse {
    /**
     * 
     * @type {number}
     * @memberof FinancialsCommonQueriesWalletsGetAverageWalletQueryResponse
     */
    'negativeAverage'?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialsCommonQueriesWalletsGetAverageWalletQueryResponse
     */
    'positiveAverage'?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialsCommonQueriesWalletsGetAverageWalletQueryResponse
     */
    'average'?: number;
}
/**
 * 
 * @export
 * @interface FinancialsControllersOwnerInvoiceControllerInvoiceDto
 */
export interface FinancialsControllersOwnerInvoiceControllerInvoiceDto {
    /**
     * 
     * @type {number}
     * @memberof FinancialsControllersOwnerInvoiceControllerInvoiceDto
     */
    'payableAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialsControllersOwnerInvoiceControllerInvoiceDto
     */
    'feeAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof FinancialsControllersOwnerInvoiceControllerInvoiceDto
     */
    'ownerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialsControllersOwnerInvoiceControllerInvoiceDto
     */
    'currencyId'?: string;
    /**
     * 
     * @type {FinancialsCommonEnumsInvoicePaymentMethods}
     * @memberof FinancialsControllersOwnerInvoiceControllerInvoiceDto
     */
    'paymentMethod'?: FinancialsCommonEnumsInvoicePaymentMethods;
    /**
     * 
     * @type {FinancialsCommonEnumsInvoiceTypes}
     * @memberof FinancialsControllersOwnerInvoiceControllerInvoiceDto
     */
    'type'?: FinancialsCommonEnumsInvoiceTypes;
}


/**
 * 
 * @export
 * @interface FinancialsControllersOwnerPaymentControllerCreatePaymentDto
 */
export interface FinancialsControllersOwnerPaymentControllerCreatePaymentDto {
    /**
     * 
     * @type {string}
     * @memberof FinancialsControllersOwnerPaymentControllerCreatePaymentDto
     */
    'paymentGatewayId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialsControllersOwnerPaymentControllerCreatePaymentDto
     */
    'invoiceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialsControllersOwnerPaymentControllerCreatePaymentDto
     */
    'callBackUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface FinancialsModelsCreateGlobalWalletDTO
 */
export interface FinancialsModelsCreateGlobalWalletDTO {
    /**
     * 
     * @type {string}
     * @memberof FinancialsModelsCreateGlobalWalletDTO
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FinancialsModelsCreateGlobalWalletDTO
     */
    'currencyId'?: string;
}
/**
 * 
 * @export
 * @interface FinancialsModelsDepositInquiryDTOFinancialsCommonQueriesTronScanTransactionInquiryQueriesGetTronScanTransactionInquiryQueryResponseTronScanTransactionResultView
 */
export interface FinancialsModelsDepositInquiryDTOFinancialsCommonQueriesTronScanTransactionInquiryQueriesGetTronScanTransactionInquiryQueryResponseTronScanTransactionResultView {
    /**
     * 
     * @type {boolean}
     * @memberof FinancialsModelsDepositInquiryDTOFinancialsCommonQueriesTronScanTransactionInquiryQueriesGetTronScanTransactionInquiryQueryResponseTronScanTransactionResultView
     */
    'isExisting'?: boolean;
    /**
     * 
     * @type {FinancialsCommonQueriesTronScanTransactionInquiryQueriesGetTronScanTransactionInquiryQueryResponseTronScanTransactionResultView}
     * @memberof FinancialsModelsDepositInquiryDTOFinancialsCommonQueriesTronScanTransactionInquiryQueriesGetTronScanTransactionInquiryQueryResponseTronScanTransactionResultView
     */
    'details'?: FinancialsCommonQueriesTronScanTransactionInquiryQueriesGetTronScanTransactionInquiryQueryResponseTronScanTransactionResultView;
}
/**
 * 
 * @export
 * @interface FinancialsModelsLocalShopCurrencyDTO
 */
export interface FinancialsModelsLocalShopCurrencyDTO {
    /**
     * 
     * @type {string}
     * @memberof FinancialsModelsLocalShopCurrencyDTO
     */
    'localCurrencyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialsModelsLocalShopCurrencyDTO
     */
    'currencySymbol'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FinancialsModelsLocalShopCurrencyDTO
     */
    'currencyTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FinancialsModelsLocalShopCurrencyDTO
     */
    'currencyKey'?: string | null;
}
/**
 * 
 * @export
 * @interface FinancialsModelsWalletBuyCreditDto
 */
export interface FinancialsModelsWalletBuyCreditDto {
    /**
     * 
     * @type {number}
     * @memberof FinancialsModelsWalletBuyCreditDto
     */
    'amount'?: number;
}
/**
 * 
 * @export
 * @interface FinancialsModelsWalletLocalCurrencyDTO
 */
export interface FinancialsModelsWalletLocalCurrencyDTO {
    /**
     * 
     * @type {string}
     * @memberof FinancialsModelsWalletLocalCurrencyDTO
     */
    'walletId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialsModelsWalletLocalCurrencyDTO
     */
    'currencyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialsModelsWalletLocalCurrencyDTO
     */
    'currencyTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FinancialsModelsWalletLocalCurrencyDTO
     */
    'currencySymbol'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FinancialsModelsWalletLocalCurrencyDTO
     */
    'balance'?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialsModelsWalletLocalCurrencyDTO
     */
    'totalBalance'?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialsModelsWalletLocalCurrencyDTO
     */
    'average'?: number | null;
}
/**
 * 
 * @export
 * @interface FinancialsModelsWalletTransactionReport
 */
export interface FinancialsModelsWalletTransactionReport {
    /**
     * 
     * @type {string}
     * @memberof FinancialsModelsWalletTransactionReport
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof FinancialsModelsWalletTransactionReport
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialsModelsWalletTransactionReport
     */
    'balance'?: number | null;
    /**
     * 
     * @type {ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus}
     * @memberof FinancialsModelsWalletTransactionReport
     */
    'status'?: ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus;
    /**
     * 
     * @type {string}
     * @memberof FinancialsModelsWalletTransactionReport
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FinancialsModelsWalletTransactionReport
     */
    'memberUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FinancialsModelsWalletTransactionReport
     */
    'createAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FinancialsModelsWalletTransactionReport
     */
    'isOutTransaction'?: boolean;
}



/**
 * AdminFinancialAccountApi - axios parameter creator
 * @export
 */
export const AdminFinancialAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} financialAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAdminFinancialAccountFinancialAccountIdDelete: async (financialAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'financialAccountId' is not null or undefined
            assertParamExists('apiV1AdminAdminFinancialAccountFinancialAccountIdDelete', 'financialAccountId', financialAccountId)
            const localVarPath = `/api/v1/Admin/AdminFinancialAccount/{financialAccountId}`
                .replace(`{${"financialAccountId"}}`, encodeURIComponent(String(financialAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAdminFinancialAccountGet: async (pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/AdminFinancialAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAdminFinancialAccountIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminAdminFinancialAccountIdGet', 'id', id)
            const localVarPath = `/api/v1/Admin/AdminFinancialAccount/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO} [apiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAdminFinancialAccountPost: async (apiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO?: ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/AdminFinancialAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO} [apiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAdminFinancialAccountPut: async (apiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO?: ApiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/AdminFinancialAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminFinancialAccountApi - functional programming interface
 * @export
 */
export const AdminFinancialAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminFinancialAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} financialAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminAdminFinancialAccountFinancialAccountIdDelete(financialAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminAdminFinancialAccountFinancialAccountIdDelete(financialAccountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminFinancialAccountApi.apiV1AdminAdminFinancialAccountFinancialAccountIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminAdminFinancialAccountGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminAdminFinancialAccountGet(pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminFinancialAccountApi.apiV1AdminAdminFinancialAccountGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminAdminFinancialAccountIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountDetailsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminAdminFinancialAccountIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminFinancialAccountApi.apiV1AdminAdminFinancialAccountIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO} [apiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminAdminFinancialAccountPost(apiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO?: ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminAdminFinancialAccountPost(apiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminFinancialAccountApi.apiV1AdminAdminFinancialAccountPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO} [apiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminAdminFinancialAccountPut(apiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO?: ApiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminAdminFinancialAccountPut(apiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminFinancialAccountApi.apiV1AdminAdminFinancialAccountPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminFinancialAccountApi - factory interface
 * @export
 */
export const AdminFinancialAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminFinancialAccountApiFp(configuration)
    return {
        /**
         * 
         * @param {string} financialAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAdminFinancialAccountFinancialAccountIdDelete(financialAccountId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminAdminFinancialAccountFinancialAccountIdDelete(financialAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAdminFinancialAccountGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountRowDTO> {
            return localVarFp.apiV1AdminAdminFinancialAccountGet(pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAdminFinancialAccountIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountDetailsDTO> {
            return localVarFp.apiV1AdminAdminFinancialAccountIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO} [apiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAdminFinancialAccountPost(apiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO?: ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.apiV1AdminAdminFinancialAccountPost(apiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO} [apiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminAdminFinancialAccountPut(apiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO?: ApiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminAdminFinancialAccountPut(apiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminFinancialAccountApi - object-oriented interface
 * @export
 * @class AdminFinancialAccountApi
 * @extends {BaseAPI}
 */
export class AdminFinancialAccountApi extends BaseAPI {
    /**
     * 
     * @param {string} financialAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFinancialAccountApi
     */
    public apiV1AdminAdminFinancialAccountFinancialAccountIdDelete(financialAccountId: string, options?: AxiosRequestConfig) {
        return AdminFinancialAccountApiFp(this.configuration).apiV1AdminAdminFinancialAccountFinancialAccountIdDelete(financialAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFinancialAccountApi
     */
    public apiV1AdminAdminFinancialAccountGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return AdminFinancialAccountApiFp(this.configuration).apiV1AdminAdminFinancialAccountGet(pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFinancialAccountApi
     */
    public apiV1AdminAdminFinancialAccountIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminFinancialAccountApiFp(this.configuration).apiV1AdminAdminFinancialAccountIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO} [apiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFinancialAccountApi
     */
    public apiV1AdminAdminFinancialAccountPost(apiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO?: ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO, options?: AxiosRequestConfig) {
        return AdminFinancialAccountApiFp(this.configuration).apiV1AdminAdminFinancialAccountPost(apiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO} [apiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFinancialAccountApi
     */
    public apiV1AdminAdminFinancialAccountPut(apiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO?: ApiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO, options?: AxiosRequestConfig) {
        return AdminFinancialAccountApiFp(this.configuration).apiV1AdminAdminFinancialAccountPut(apiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CashPaymentApi - axios parameter creator
 * @export
 */
export const CashPaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [shopTitle] 
         * @param {number} [status] 
         * @param {boolean} [unreviewedOnly] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCashPaymentGet: async (shopTitle?: string, status?: number, unreviewedOnly?: boolean, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/CashPayment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (shopTitle !== undefined) {
                localVarQueryParameter['ShopTitle'] = shopTitle;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (unreviewedOnly !== undefined) {
                localVarQueryParameter['UnreviewedOnly'] = unreviewedOnly;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} depositId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCashPaymentReviewDepositIdPut: async (depositId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'depositId' is not null or undefined
            assertParamExists('apiV1AdminCashPaymentReviewDepositIdPut', 'depositId', depositId)
            const localVarPath = `/api/v1/Admin/CashPayment/Review/{depositId}`
                .replace(`{${"depositId"}}`, encodeURIComponent(String(depositId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CashPaymentGet: async (accountId?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/CashPayment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId !== undefined) {
                localVarQueryParameter['AccountId'] = accountId;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CashPaymentIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1CashPaymentIdDelete', 'id', id)
            const localVarPath = `/api/v1/CashPayment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CashPaymentInquiryHashGet: async (hash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('apiV1CashPaymentInquiryHashGet', 'hash', hash)
            const localVarPath = `/api/v1/CashPayment/Inquiry/{hash}`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [transactionNumber] 
         * @param {string} [cashPaymentAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CashPaymentVerificationDetailsGet: async (transactionNumber?: string, cashPaymentAccountId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/CashPayment/VerificationDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (transactionNumber !== undefined) {
                localVarQueryParameter['TransactionNumber'] = transactionNumber;
            }

            if (cashPaymentAccountId !== undefined) {
                localVarQueryParameter['CashPaymentAccountId'] = cashPaymentAccountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CashPaymentApi - functional programming interface
 * @export
 */
export const CashPaymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CashPaymentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [shopTitle] 
         * @param {number} [status] 
         * @param {boolean} [unreviewedOnly] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminCashPaymentGet(shopTitle?: string, status?: number, unreviewedOnly?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialPaymentsAdminCashPaymentRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminCashPaymentGet(shopTitle, status, unreviewedOnly, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CashPaymentApi.apiV1AdminCashPaymentGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} depositId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminCashPaymentReviewDepositIdPut(depositId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminCashPaymentReviewDepositIdPut(depositId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CashPaymentApi.apiV1AdminCashPaymentReviewDepositIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CashPaymentGet(accountId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CashPaymentGet(accountId, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CashPaymentApi.apiV1CashPaymentGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CashPaymentIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CashPaymentIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CashPaymentApi.apiV1CashPaymentIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CashPaymentInquiryHashGet(hash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinancialsModelsDepositInquiryDTOFinancialsCommonQueriesTronScanTransactionInquiryQueriesGetTronScanTransactionInquiryQueryResponseTronScanTransactionResultView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CashPaymentInquiryHashGet(hash, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CashPaymentApi.apiV1CashPaymentInquiryHashGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [transactionNumber] 
         * @param {string} [cashPaymentAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CashPaymentVerificationDetailsGet(transactionNumber?: string, cashPaymentAccountId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsFinancialPaymentsTransactionVerificationInquiryDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CashPaymentVerificationDetailsGet(transactionNumber, cashPaymentAccountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CashPaymentApi.apiV1CashPaymentVerificationDetailsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CashPaymentApi - factory interface
 * @export
 */
export const CashPaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CashPaymentApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [shopTitle] 
         * @param {number} [status] 
         * @param {boolean} [unreviewedOnly] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCashPaymentGet(shopTitle?: string, status?: number, unreviewedOnly?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialPaymentsAdminCashPaymentRowDTO> {
            return localVarFp.apiV1AdminCashPaymentGet(shopTitle, status, unreviewedOnly, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} depositId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCashPaymentReviewDepositIdPut(depositId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminCashPaymentReviewDepositIdPut(depositId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [accountId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CashPaymentGet(accountId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentRowDTO> {
            return localVarFp.apiV1CashPaymentGet(accountId, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CashPaymentIdDelete(id: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1CashPaymentIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CashPaymentInquiryHashGet(hash: string, options?: AxiosRequestConfig): AxiosPromise<FinancialsModelsDepositInquiryDTOFinancialsCommonQueriesTronScanTransactionInquiryQueriesGetTronScanTransactionInquiryQueryResponseTronScanTransactionResultView> {
            return localVarFp.apiV1CashPaymentInquiryHashGet(hash, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [transactionNumber] 
         * @param {string} [cashPaymentAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CashPaymentVerificationDetailsGet(transactionNumber?: string, cashPaymentAccountId?: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsFinancialPaymentsTransactionVerificationInquiryDTO> {
            return localVarFp.apiV1CashPaymentVerificationDetailsGet(transactionNumber, cashPaymentAccountId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CashPaymentApi - object-oriented interface
 * @export
 * @class CashPaymentApi
 * @extends {BaseAPI}
 */
export class CashPaymentApi extends BaseAPI {
    /**
     * 
     * @param {string} [shopTitle] 
     * @param {number} [status] 
     * @param {boolean} [unreviewedOnly] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashPaymentApi
     */
    public apiV1AdminCashPaymentGet(shopTitle?: string, status?: number, unreviewedOnly?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return CashPaymentApiFp(this.configuration).apiV1AdminCashPaymentGet(shopTitle, status, unreviewedOnly, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} depositId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashPaymentApi
     */
    public apiV1AdminCashPaymentReviewDepositIdPut(depositId: string, options?: AxiosRequestConfig) {
        return CashPaymentApiFp(this.configuration).apiV1AdminCashPaymentReviewDepositIdPut(depositId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [accountId] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashPaymentApi
     */
    public apiV1CashPaymentGet(accountId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return CashPaymentApiFp(this.configuration).apiV1CashPaymentGet(accountId, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashPaymentApi
     */
    public apiV1CashPaymentIdDelete(id: string, options?: AxiosRequestConfig) {
        return CashPaymentApiFp(this.configuration).apiV1CashPaymentIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} hash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashPaymentApi
     */
    public apiV1CashPaymentInquiryHashGet(hash: string, options?: AxiosRequestConfig) {
        return CashPaymentApiFp(this.configuration).apiV1CashPaymentInquiryHashGet(hash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [transactionNumber] 
     * @param {string} [cashPaymentAccountId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashPaymentApi
     */
    public apiV1CashPaymentVerificationDetailsGet(transactionNumber?: string, cashPaymentAccountId?: string, options?: AxiosRequestConfig) {
        return CashPaymentApiFp(this.configuration).apiV1CashPaymentVerificationDetailsGet(transactionNumber, cashPaymentAccountId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CashPaymentAccountApi - axios parameter creator
 * @export
 */
export const CashPaymentAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} currencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CashPaymentAccountSelectionsCurrencyIdGet: async (currencyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'currencyId' is not null or undefined
            assertParamExists('apiV1CashPaymentAccountSelectionsCurrencyIdGet', 'currencyId', currencyId)
            const localVarPath = `/api/v1/CashPaymentAccount/Selections/{currencyId}`
                .replace(`{${"currencyId"}}`, encodeURIComponent(String(currencyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CashPaymentAccountApi - functional programming interface
 * @export
 */
export const CashPaymentAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CashPaymentAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} currencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CashPaymentAccountSelectionsCurrencyIdGet(currencyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentAccountSelectionDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CashPaymentAccountSelectionsCurrencyIdGet(currencyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CashPaymentAccountApi.apiV1CashPaymentAccountSelectionsCurrencyIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CashPaymentAccountApi - factory interface
 * @export
 */
export const CashPaymentAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CashPaymentAccountApiFp(configuration)
    return {
        /**
         * 
         * @param {string} currencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CashPaymentAccountSelectionsCurrencyIdGet(currencyId: string, options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsFinancialPaymentsCashPaymentAccountSelectionDTO>> {
            return localVarFp.apiV1CashPaymentAccountSelectionsCurrencyIdGet(currencyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CashPaymentAccountApi - object-oriented interface
 * @export
 * @class CashPaymentAccountApi
 * @extends {BaseAPI}
 */
export class CashPaymentAccountApi extends BaseAPI {
    /**
     * 
     * @param {string} currencyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashPaymentAccountApi
     */
    public apiV1CashPaymentAccountSelectionsCurrencyIdGet(currencyId: string, options?: AxiosRequestConfig) {
        return CashPaymentAccountApiFp(this.configuration).apiV1CashPaymentAccountSelectionsCurrencyIdGet(currencyId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CashierApi - axios parameter creator
 * @export
 */
export const CashierApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCashierGet: async (pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/Cashier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CashierApi - functional programming interface
 * @export
 */
export const CashierApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CashierApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminCashierGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOFinancialsCommonCashierQueriesGetPagedCashierQueryResponseCashierRow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminCashierGet(pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CashierApi.apiV1AdminCashierGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CashierApi - factory interface
 * @export
 */
export const CashierApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CashierApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCashierGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOFinancialsCommonCashierQueriesGetPagedCashierQueryResponseCashierRow> {
            return localVarFp.apiV1AdminCashierGet(pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CashierApi - object-oriented interface
 * @export
 * @class CashierApi
 * @extends {BaseAPI}
 */
export class CashierApi extends BaseAPI {
    /**
     * 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashierApi
     */
    public apiV1AdminCashierGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return CashierApiFp(this.configuration).apiV1AdminCashierGet(pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CashoutApi - axios parameter creator
 * @export
 */
export const CashoutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [status] 
         * @param {string} [transactionNumber] 
         * @param {string} [shopId] 
         * @param {boolean} [unreviewedOnly] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCashoutGet: async (status?: number, transactionNumber?: string, shopId?: string, unreviewedOnly?: boolean, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/Cashout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (transactionNumber !== undefined) {
                localVarQueryParameter['TransactionNumber'] = transactionNumber;
            }

            if (shopId !== undefined) {
                localVarQueryParameter['ShopId'] = shopId;
            }

            if (unreviewedOnly !== undefined) {
                localVarQueryParameter['UnreviewedOnly'] = unreviewedOnly;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCashoutIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminCashoutIdGet', 'id', id)
            const localVarPath = `/api/v1/Admin/Cashout/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO} [apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCashoutPost: async (apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO?: ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/Cashout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FinancialsCommonCashierEnumsWithdrawStatus} [status] 
         * @param {string} [transactionNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CashoutGet: async (status?: FinancialsCommonCashierEnumsWithdrawStatus, transactionNumber?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Cashout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (transactionNumber !== undefined) {
                localVarQueryParameter['TransactionNumber'] = transactionNumber;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CashoutApi - functional programming interface
 * @export
 */
export const CashoutApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CashoutApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [status] 
         * @param {string} [transactionNumber] 
         * @param {string} [shopId] 
         * @param {boolean} [unreviewedOnly] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminCashoutGet(status?: number, transactionNumber?: string, shopId?: string, unreviewedOnly?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCashoutsCashoutRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminCashoutGet(status, transactionNumber, shopId, unreviewedOnly, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CashoutApi.apiV1AdminCashoutGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminCashoutIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutDetailsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminCashoutIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CashoutApi.apiV1AdminCashoutIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO} [apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminCashoutPost(apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO?: ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminCashoutPost(apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CashoutApi.apiV1AdminCashoutPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FinancialsCommonCashierEnumsWithdrawStatus} [status] 
         * @param {string} [transactionNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CashoutGet(status?: FinancialsCommonCashierEnumsWithdrawStatus, transactionNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCashoutsShopCashoutRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CashoutGet(status, transactionNumber, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CashoutApi.apiV1CashoutGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CashoutApi - factory interface
 * @export
 */
export const CashoutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CashoutApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [status] 
         * @param {string} [transactionNumber] 
         * @param {string} [shopId] 
         * @param {boolean} [unreviewedOnly] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCashoutGet(status?: number, transactionNumber?: string, shopId?: string, unreviewedOnly?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCashoutsCashoutRowDTO> {
            return localVarFp.apiV1AdminCashoutGet(status, transactionNumber, shopId, unreviewedOnly, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCashoutIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsFinancialCashoutsCashoutDetailsDTO> {
            return localVarFp.apiV1AdminCashoutIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO} [apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCashoutPost(apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO?: ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminCashoutPost(apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FinancialsCommonCashierEnumsWithdrawStatus} [status] 
         * @param {string} [transactionNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CashoutGet(status?: FinancialsCommonCashierEnumsWithdrawStatus, transactionNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCashoutsShopCashoutRowDTO> {
            return localVarFp.apiV1CashoutGet(status, transactionNumber, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CashoutApi - object-oriented interface
 * @export
 * @class CashoutApi
 * @extends {BaseAPI}
 */
export class CashoutApi extends BaseAPI {
    /**
     * 
     * @param {number} [status] 
     * @param {string} [transactionNumber] 
     * @param {string} [shopId] 
     * @param {boolean} [unreviewedOnly] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashoutApi
     */
    public apiV1AdminCashoutGet(status?: number, transactionNumber?: string, shopId?: string, unreviewedOnly?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return CashoutApiFp(this.configuration).apiV1AdminCashoutGet(status, transactionNumber, shopId, unreviewedOnly, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashoutApi
     */
    public apiV1AdminCashoutIdGet(id: string, options?: AxiosRequestConfig) {
        return CashoutApiFp(this.configuration).apiV1AdminCashoutIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO} [apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashoutApi
     */
    public apiV1AdminCashoutPost(apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO?: ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO, options?: AxiosRequestConfig) {
        return CashoutApiFp(this.configuration).apiV1AdminCashoutPost(apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FinancialsCommonCashierEnumsWithdrawStatus} [status] 
     * @param {string} [transactionNumber] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashoutApi
     */
    public apiV1CashoutGet(status?: FinancialsCommonCashierEnumsWithdrawStatus, transactionNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return CashoutApiFp(this.configuration).apiV1CashoutGet(status, transactionNumber, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CreditApi - axios parameter creator
 * @export
 */
export const CreditApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} creditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCreditArchiveCreditIdPut: async (creditId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditId' is not null or undefined
            assertParamExists('apiV1AdminCreditArchiveCreditIdPut', 'creditId', creditId)
            const localVarPath = `/api/v1/Admin/Credit/Archive/{creditId}`
                .replace(`{${"creditId"}}`, encodeURIComponent(String(creditId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [shopTitle] 
         * @param {boolean} [isActive] 
         * @param {boolean} [negativeCreditsOnly] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCreditGet: async (shopTitle?: string, isActive?: boolean, negativeCreditsOnly?: boolean, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/Credit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (shopTitle !== undefined) {
                localVarQueryParameter['ShopTitle'] = shopTitle;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['IsActive'] = isActive;
            }

            if (negativeCreditsOnly !== undefined) {
                localVarQueryParameter['NegativeCreditsOnly'] = negativeCreditsOnly;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO} [apiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCreditPost: async (apiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO?: ApiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/Credit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CreditApi - functional programming interface
 * @export
 */
export const CreditApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CreditApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} creditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminCreditArchiveCreditIdPut(creditId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminCreditArchiveCreditIdPut(creditId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CreditApi.apiV1AdminCreditArchiveCreditIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [shopTitle] 
         * @param {boolean} [isActive] 
         * @param {boolean} [negativeCreditsOnly] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminCreditGet(shopTitle?: string, isActive?: boolean, negativeCreditsOnly?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCreditsCreditRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminCreditGet(shopTitle, isActive, negativeCreditsOnly, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CreditApi.apiV1AdminCreditGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO} [apiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminCreditPost(apiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO?: ApiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminCreditPost(apiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CreditApi.apiV1AdminCreditPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CreditApi - factory interface
 * @export
 */
export const CreditApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CreditApiFp(configuration)
    return {
        /**
         * 
         * @param {string} creditId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCreditArchiveCreditIdPut(creditId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminCreditArchiveCreditIdPut(creditId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [shopTitle] 
         * @param {boolean} [isActive] 
         * @param {boolean} [negativeCreditsOnly] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCreditGet(shopTitle?: string, isActive?: boolean, negativeCreditsOnly?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCreditsCreditRowDTO> {
            return localVarFp.apiV1AdminCreditGet(shopTitle, isActive, negativeCreditsOnly, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO} [apiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCreditPost(apiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO?: ApiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminCreditPost(apiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CreditApi - object-oriented interface
 * @export
 * @class CreditApi
 * @extends {BaseAPI}
 */
export class CreditApi extends BaseAPI {
    /**
     * 
     * @param {string} creditId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditApi
     */
    public apiV1AdminCreditArchiveCreditIdPut(creditId: string, options?: AxiosRequestConfig) {
        return CreditApiFp(this.configuration).apiV1AdminCreditArchiveCreditIdPut(creditId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [shopTitle] 
     * @param {boolean} [isActive] 
     * @param {boolean} [negativeCreditsOnly] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditApi
     */
    public apiV1AdminCreditGet(shopTitle?: string, isActive?: boolean, negativeCreditsOnly?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return CreditApiFp(this.configuration).apiV1AdminCreditGet(shopTitle, isActive, negativeCreditsOnly, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO} [apiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditApi
     */
    public apiV1AdminCreditPost(apiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO?: ApiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO, options?: AxiosRequestConfig) {
        return CreditApiFp(this.configuration).apiV1AdminCreditPost(apiGiftGatewayCommonQueryStringsFinancialWalletsCreditAddCreditDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CurrencyApi - axios parameter creator
 * @export
 */
export const CurrencyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCurrencyGet: async (pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/Currency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCurrencyIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminCurrencyIdDelete', 'id', id)
            const localVarPath = `/api/v1/Admin/Currency/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCurrencyIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminCurrencyIdGet', 'id', id)
            const localVarPath = `/api/v1/Admin/Currency/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialCurrenciesCreateCurrencyDTO} [apiGiftGatewayCommonDTOsFinancialCurrenciesCreateCurrencyDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCurrencyPost: async (apiGiftGatewayCommonDTOsFinancialCurrenciesCreateCurrencyDTO?: ApiGiftGatewayCommonDTOsFinancialCurrenciesCreateCurrencyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/Currency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsFinancialCurrenciesCreateCurrencyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO} [apiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCurrencyPut: async (apiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO?: ApiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/Currency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCurrencyTitleValueGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/Currency/TitleValue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CurrencyTitleValueGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Currency/TitleValue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CurrencyApi - functional programming interface
 * @export
 */
export const CurrencyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CurrencyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminCurrencyGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCurrenciesCurrencyRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminCurrencyGet(pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrencyApi.apiV1AdminCurrencyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminCurrencyIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminCurrencyIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrencyApi.apiV1AdminCurrencyIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminCurrencyIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminCurrencyIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrencyApi.apiV1AdminCurrencyIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialCurrenciesCreateCurrencyDTO} [apiGiftGatewayCommonDTOsFinancialCurrenciesCreateCurrencyDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminCurrencyPost(apiGiftGatewayCommonDTOsFinancialCurrenciesCreateCurrencyDTO?: ApiGiftGatewayCommonDTOsFinancialCurrenciesCreateCurrencyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminCurrencyPost(apiGiftGatewayCommonDTOsFinancialCurrenciesCreateCurrencyDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrencyApi.apiV1AdminCurrencyPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO} [apiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminCurrencyPut(apiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO?: ApiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminCurrencyPut(apiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrencyApi.apiV1AdminCurrencyPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminCurrencyTitleValueGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminCurrencyTitleValueGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrencyApi.apiV1AdminCurrencyTitleValueGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CurrencyTitleValueGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CurrencyTitleValueGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrencyApi.apiV1CurrencyTitleValueGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CurrencyApi - factory interface
 * @export
 */
export const CurrencyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CurrencyApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCurrencyGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCurrenciesCurrencyRowDTO> {
            return localVarFp.apiV1AdminCurrencyGet(pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCurrencyIdDelete(id: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminCurrencyIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCurrencyIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO> {
            return localVarFp.apiV1AdminCurrencyIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialCurrenciesCreateCurrencyDTO} [apiGiftGatewayCommonDTOsFinancialCurrenciesCreateCurrencyDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCurrencyPost(apiGiftGatewayCommonDTOsFinancialCurrenciesCreateCurrencyDTO?: ApiGiftGatewayCommonDTOsFinancialCurrenciesCreateCurrencyDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminCurrencyPost(apiGiftGatewayCommonDTOsFinancialCurrenciesCreateCurrencyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO} [apiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCurrencyPut(apiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO?: ApiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminCurrencyPut(apiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCurrencyTitleValueGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>> {
            return localVarFp.apiV1AdminCurrencyTitleValueGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CurrencyTitleValueGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>> {
            return localVarFp.apiV1CurrencyTitleValueGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CurrencyApi - object-oriented interface
 * @export
 * @class CurrencyApi
 * @extends {BaseAPI}
 */
export class CurrencyApi extends BaseAPI {
    /**
     * 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrencyApi
     */
    public apiV1AdminCurrencyGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return CurrencyApiFp(this.configuration).apiV1AdminCurrencyGet(pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrencyApi
     */
    public apiV1AdminCurrencyIdDelete(id: string, options?: AxiosRequestConfig) {
        return CurrencyApiFp(this.configuration).apiV1AdminCurrencyIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrencyApi
     */
    public apiV1AdminCurrencyIdGet(id: string, options?: AxiosRequestConfig) {
        return CurrencyApiFp(this.configuration).apiV1AdminCurrencyIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftGatewayCommonDTOsFinancialCurrenciesCreateCurrencyDTO} [apiGiftGatewayCommonDTOsFinancialCurrenciesCreateCurrencyDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrencyApi
     */
    public apiV1AdminCurrencyPost(apiGiftGatewayCommonDTOsFinancialCurrenciesCreateCurrencyDTO?: ApiGiftGatewayCommonDTOsFinancialCurrenciesCreateCurrencyDTO, options?: AxiosRequestConfig) {
        return CurrencyApiFp(this.configuration).apiV1AdminCurrencyPost(apiGiftGatewayCommonDTOsFinancialCurrenciesCreateCurrencyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO} [apiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrencyApi
     */
    public apiV1AdminCurrencyPut(apiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO?: ApiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO, options?: AxiosRequestConfig) {
        return CurrencyApiFp(this.configuration).apiV1AdminCurrencyPut(apiGiftGatewayCommonDTOsFinancialCurrenciesUpdateCurrencyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrencyApi
     */
    public apiV1AdminCurrencyTitleValueGet(options?: AxiosRequestConfig) {
        return CurrencyApiFp(this.configuration).apiV1AdminCurrencyTitleValueGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrencyApi
     */
    public apiV1CurrencyTitleValueGet(options?: AxiosRequestConfig) {
        return CurrencyApiFp(this.configuration).apiV1CurrencyTitleValueGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CurrencyRateApi - axios parameter creator
 * @export
 */
export const CurrencyRateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} currencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCurrencyRateCurrencyIdGet: async (currencyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'currencyId' is not null or undefined
            assertParamExists('apiV1AdminCurrencyRateCurrencyIdGet', 'currencyId', currencyId)
            const localVarPath = `/api/v1/Admin/CurrencyRate/{currencyId}`
                .replace(`{${"currencyId"}}`, encodeURIComponent(String(currencyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCurrencyRateGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/CurrencyRate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<ApiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO>} [apiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCurrencyRatePut: async (apiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO?: Array<ApiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/CurrencyRate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO} [apiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCurrencyRateUpdateByCurrencyIdPut: async (apiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO?: ApiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/CurrencyRate/UpdateByCurrencyId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} currencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CurrencyRateCurrencyIdGet: async (currencyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'currencyId' is not null or undefined
            assertParamExists('apiV1CurrencyRateCurrencyIdGet', 'currencyId', currencyId)
            const localVarPath = `/api/v1/CurrencyRate/{currencyId}`
                .replace(`{${"currencyId"}}`, encodeURIComponent(String(currencyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CurrencyRateApi - functional programming interface
 * @export
 */
export const CurrencyRateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CurrencyRateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} currencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminCurrencyRateCurrencyIdGet(currencyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsFinancialCurrencyRatesCurrencyRateDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminCurrencyRateCurrencyIdGet(currencyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrencyRateApi.apiV1AdminCurrencyRateCurrencyIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminCurrencyRateGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsFinancialCurrencyRatesCurrencyRateDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminCurrencyRateGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrencyRateApi.apiV1AdminCurrencyRateGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<ApiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO>} [apiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminCurrencyRatePut(apiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO?: Array<ApiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminCurrencyRatePut(apiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrencyRateApi.apiV1AdminCurrencyRatePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO} [apiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminCurrencyRateUpdateByCurrencyIdPut(apiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO?: ApiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminCurrencyRateUpdateByCurrencyIdPut(apiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrencyRateApi.apiV1AdminCurrencyRateUpdateByCurrencyIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} currencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CurrencyRateCurrencyIdGet(currencyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsFinancialCurrencyRatesCurrencyRateDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CurrencyRateCurrencyIdGet(currencyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CurrencyRateApi.apiV1CurrencyRateCurrencyIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CurrencyRateApi - factory interface
 * @export
 */
export const CurrencyRateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CurrencyRateApiFp(configuration)
    return {
        /**
         * 
         * @param {string} currencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCurrencyRateCurrencyIdGet(currencyId: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsFinancialCurrencyRatesCurrencyRateDTO> {
            return localVarFp.apiV1AdminCurrencyRateCurrencyIdGet(currencyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCurrencyRateGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsFinancialCurrencyRatesCurrencyRateDTO>> {
            return localVarFp.apiV1AdminCurrencyRateGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<ApiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO>} [apiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCurrencyRatePut(apiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO?: Array<ApiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO>, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminCurrencyRatePut(apiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO} [apiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminCurrencyRateUpdateByCurrencyIdPut(apiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO?: ApiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminCurrencyRateUpdateByCurrencyIdPut(apiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} currencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CurrencyRateCurrencyIdGet(currencyId: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsFinancialCurrencyRatesCurrencyRateDTO> {
            return localVarFp.apiV1CurrencyRateCurrencyIdGet(currencyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CurrencyRateApi - object-oriented interface
 * @export
 * @class CurrencyRateApi
 * @extends {BaseAPI}
 */
export class CurrencyRateApi extends BaseAPI {
    /**
     * 
     * @param {string} currencyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrencyRateApi
     */
    public apiV1AdminCurrencyRateCurrencyIdGet(currencyId: string, options?: AxiosRequestConfig) {
        return CurrencyRateApiFp(this.configuration).apiV1AdminCurrencyRateCurrencyIdGet(currencyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrencyRateApi
     */
    public apiV1AdminCurrencyRateGet(options?: AxiosRequestConfig) {
        return CurrencyRateApiFp(this.configuration).apiV1AdminCurrencyRateGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<ApiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO>} [apiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrencyRateApi
     */
    public apiV1AdminCurrencyRatePut(apiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO?: Array<ApiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO>, options?: AxiosRequestConfig) {
        return CurrencyRateApiFp(this.configuration).apiV1AdminCurrencyRatePut(apiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO} [apiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrencyRateApi
     */
    public apiV1AdminCurrencyRateUpdateByCurrencyIdPut(apiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO?: ApiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO, options?: AxiosRequestConfig) {
        return CurrencyRateApiFp(this.configuration).apiV1AdminCurrencyRateUpdateByCurrencyIdPut(apiGiftGatewayCommonDTOsFinancialCurrencyRatesSetCurrencyRateDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} currencyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrencyRateApi
     */
    public apiV1CurrencyRateCurrencyIdGet(currencyId: string, options?: AxiosRequestConfig) {
        return CurrencyRateApiFp(this.configuration).apiV1CurrencyRateCurrencyIdGet(currencyId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DepositApi - axios parameter creator
 * @export
 */
export const DepositApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} depositId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepositRejectDepositIdPut: async (depositId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'depositId' is not null or undefined
            assertParamExists('apiV1DepositRejectDepositIdPut', 'depositId', depositId)
            const localVarPath = `/api/v1/Deposit/Reject/{depositId}`
                .replace(`{${"depositId"}}`, encodeURIComponent(String(depositId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepositApi - functional programming interface
 * @export
 */
export const DepositApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepositApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} depositId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DepositRejectDepositIdPut(depositId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DepositRejectDepositIdPut(depositId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepositApi.apiV1DepositRejectDepositIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DepositApi - factory interface
 * @export
 */
export const DepositApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepositApiFp(configuration)
    return {
        /**
         * 
         * @param {string} depositId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepositRejectDepositIdPut(depositId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1DepositRejectDepositIdPut(depositId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepositApi - object-oriented interface
 * @export
 * @class DepositApi
 * @extends {BaseAPI}
 */
export class DepositApi extends BaseAPI {
    /**
     * 
     * @param {string} depositId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositApi
     */
    public apiV1DepositRejectDepositIdPut(depositId: string, options?: AxiosRequestConfig) {
        return DepositApiFp(this.configuration).apiV1DepositRejectDepositIdPut(depositId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ExchangeTransactionApi - axios parameter creator
 * @export
 */
export const ExchangeTransactionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO} [apiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminExchangeTransactionPost: async (apiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO?: ApiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/ExchangeTransaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} exchangeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ExchangeTransactionExchangeIdGet: async (exchangeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exchangeId' is not null or undefined
            assertParamExists('apiV1ExchangeTransactionExchangeIdGet', 'exchangeId', exchangeId)
            const localVarPath = `/api/v1/ExchangeTransaction/{exchangeId}`
                .replace(`{${"exchangeId"}}`, encodeURIComponent(String(exchangeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ExchangeTransactionGet: async (pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ExchangeTransaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialWalletsShopCreateRequestExchangeWalletTransactionDTO} [apiGiftGatewayCommonDTOsFinancialWalletsShopCreateRequestExchangeWalletTransactionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ExchangeTransactionPost: async (apiGiftGatewayCommonDTOsFinancialWalletsShopCreateRequestExchangeWalletTransactionDTO?: ApiGiftGatewayCommonDTOsFinancialWalletsShopCreateRequestExchangeWalletTransactionDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ExchangeTransaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsFinancialWalletsShopCreateRequestExchangeWalletTransactionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExchangeTransactionApi - functional programming interface
 * @export
 */
export const ExchangeTransactionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExchangeTransactionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO} [apiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminExchangeTransactionPost(apiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO?: ApiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminExchangeTransactionPost(apiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExchangeTransactionApi.apiV1AdminExchangeTransactionPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} exchangeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ExchangeTransactionExchangeIdGet(exchangeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionDetailsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ExchangeTransactionExchangeIdGet(exchangeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExchangeTransactionApi.apiV1ExchangeTransactionExchangeIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ExchangeTransactionGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ExchangeTransactionGet(pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExchangeTransactionApi.apiV1ExchangeTransactionGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialWalletsShopCreateRequestExchangeWalletTransactionDTO} [apiGiftGatewayCommonDTOsFinancialWalletsShopCreateRequestExchangeWalletTransactionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ExchangeTransactionPost(apiGiftGatewayCommonDTOsFinancialWalletsShopCreateRequestExchangeWalletTransactionDTO?: ApiGiftGatewayCommonDTOsFinancialWalletsShopCreateRequestExchangeWalletTransactionDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ExchangeTransactionPost(apiGiftGatewayCommonDTOsFinancialWalletsShopCreateRequestExchangeWalletTransactionDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExchangeTransactionApi.apiV1ExchangeTransactionPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ExchangeTransactionApi - factory interface
 * @export
 */
export const ExchangeTransactionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExchangeTransactionApiFp(configuration)
    return {
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO} [apiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminExchangeTransactionPost(apiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO?: ApiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminExchangeTransactionPost(apiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} exchangeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ExchangeTransactionExchangeIdGet(exchangeId: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionDetailsDTO> {
            return localVarFp.apiV1ExchangeTransactionExchangeIdGet(exchangeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ExchangeTransactionGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialWalletsShopExchangeWalletTransactionRowDTO> {
            return localVarFp.apiV1ExchangeTransactionGet(pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialWalletsShopCreateRequestExchangeWalletTransactionDTO} [apiGiftGatewayCommonDTOsFinancialWalletsShopCreateRequestExchangeWalletTransactionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ExchangeTransactionPost(apiGiftGatewayCommonDTOsFinancialWalletsShopCreateRequestExchangeWalletTransactionDTO?: ApiGiftGatewayCommonDTOsFinancialWalletsShopCreateRequestExchangeWalletTransactionDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1ExchangeTransactionPost(apiGiftGatewayCommonDTOsFinancialWalletsShopCreateRequestExchangeWalletTransactionDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExchangeTransactionApi - object-oriented interface
 * @export
 * @class ExchangeTransactionApi
 * @extends {BaseAPI}
 */
export class ExchangeTransactionApi extends BaseAPI {
    /**
     * 
     * @param {ApiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO} [apiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeTransactionApi
     */
    public apiV1AdminExchangeTransactionPost(apiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO?: ApiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO, options?: AxiosRequestConfig) {
        return ExchangeTransactionApiFp(this.configuration).apiV1AdminExchangeTransactionPost(apiGiftGatewayCommonDTOsFinancialWalletsExchangeWalletTransactionDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} exchangeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeTransactionApi
     */
    public apiV1ExchangeTransactionExchangeIdGet(exchangeId: string, options?: AxiosRequestConfig) {
        return ExchangeTransactionApiFp(this.configuration).apiV1ExchangeTransactionExchangeIdGet(exchangeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeTransactionApi
     */
    public apiV1ExchangeTransactionGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return ExchangeTransactionApiFp(this.configuration).apiV1ExchangeTransactionGet(pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftGatewayCommonDTOsFinancialWalletsShopCreateRequestExchangeWalletTransactionDTO} [apiGiftGatewayCommonDTOsFinancialWalletsShopCreateRequestExchangeWalletTransactionDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeTransactionApi
     */
    public apiV1ExchangeTransactionPost(apiGiftGatewayCommonDTOsFinancialWalletsShopCreateRequestExchangeWalletTransactionDTO?: ApiGiftGatewayCommonDTOsFinancialWalletsShopCreateRequestExchangeWalletTransactionDTO, options?: AxiosRequestConfig) {
        return ExchangeTransactionApiFp(this.configuration).apiV1ExchangeTransactionPost(apiGiftGatewayCommonDTOsFinancialWalletsShopCreateRequestExchangeWalletTransactionDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FinancialAccountApi - axios parameter creator
 * @export
 */
export const FinancialAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminFinancialAccountGet: async (pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/FinancialAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialFinancialAccountsHandleFinancialAccountDTO} [apiGiftGatewayCommonDTOsFinancialFinancialAccountsHandleFinancialAccountDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminFinancialAccountHandlePut: async (apiGiftGatewayCommonDTOsFinancialFinancialAccountsHandleFinancialAccountDTO?: ApiGiftGatewayCommonDTOsFinancialFinancialAccountsHandleFinancialAccountDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/FinancialAccount/Handle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsFinancialFinancialAccountsHandleFinancialAccountDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminFinancialAccountIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminFinancialAccountIdGet', 'id', id)
            const localVarPath = `/api/v1/Admin/FinancialAccount/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCheckVerifiedAccountDTO} [apiGiftGatewayCommonDTOsFinancialFinancialAccountsCheckVerifiedAccountDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1FinancialAccountCheckVerifiedPut: async (apiGiftGatewayCommonDTOsFinancialFinancialAccountsCheckVerifiedAccountDTO?: ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCheckVerifiedAccountDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/FinancialAccount/CheckVerified`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsFinancialFinancialAccountsCheckVerifiedAccountDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} financialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FinancialAccountFinancialIdDelete: async (financialId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'financialId' is not null or undefined
            assertParamExists('apiV1FinancialAccountFinancialIdDelete', 'financialId', financialId)
            const localVarPath = `/api/v1/FinancialAccount/{financialId}`
                .replace(`{${"financialId"}}`, encodeURIComponent(String(financialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} financialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FinancialAccountFinancialIdGet: async (financialId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'financialId' is not null or undefined
            assertParamExists('apiV1FinancialAccountFinancialIdGet', 'financialId', financialId)
            const localVarPath = `/api/v1/FinancialAccount/{financialId}`
                .replace(`{${"financialId"}}`, encodeURIComponent(String(financialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} financialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FinancialAccountGenerateVerifyAmountFinancialIdGet: async (financialId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'financialId' is not null or undefined
            assertParamExists('apiV1FinancialAccountGenerateVerifyAmountFinancialIdGet', 'financialId', financialId)
            const localVarPath = `/api/v1/FinancialAccount/GenerateVerifyAmount/{financialId}`
                .replace(`{${"financialId"}}`, encodeURIComponent(String(financialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FinancialAccountGet: async (pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/FinancialAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO} [apiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FinancialAccountIdPut: async (id: string, apiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO?: ApiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1FinancialAccountIdPut', 'id', id)
            const localVarPath = `/api/v1/FinancialAccount/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO} [apiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FinancialAccountPost: async (apiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO?: ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/FinancialAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FinancialAccountTitleValueByWalletWalletIdGet: async (walletId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            assertParamExists('apiV1FinancialAccountTitleValueByWalletWalletIdGet', 'walletId', walletId)
            const localVarPath = `/api/v1/FinancialAccount/TitleValueByWallet/{walletId}`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FinancialAccountApi - functional programming interface
 * @export
 */
export const FinancialAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FinancialAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminFinancialAccountGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminFinancialAccountGet(pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FinancialAccountApi.apiV1AdminFinancialAccountGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialFinancialAccountsHandleFinancialAccountDTO} [apiGiftGatewayCommonDTOsFinancialFinancialAccountsHandleFinancialAccountDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminFinancialAccountHandlePut(apiGiftGatewayCommonDTOsFinancialFinancialAccountsHandleFinancialAccountDTO?: ApiGiftGatewayCommonDTOsFinancialFinancialAccountsHandleFinancialAccountDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminFinancialAccountHandlePut(apiGiftGatewayCommonDTOsFinancialFinancialAccountsHandleFinancialAccountDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FinancialAccountApi.apiV1AdminFinancialAccountHandlePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminFinancialAccountIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountDetailsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminFinancialAccountIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FinancialAccountApi.apiV1AdminFinancialAccountIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCheckVerifiedAccountDTO} [apiGiftGatewayCommonDTOsFinancialFinancialAccountsCheckVerifiedAccountDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async apiV1FinancialAccountCheckVerifiedPut(apiGiftGatewayCommonDTOsFinancialFinancialAccountsCheckVerifiedAccountDTO?: ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCheckVerifiedAccountDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonEnumsFinancialFinancialAccountsFinancialAccountStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FinancialAccountCheckVerifiedPut(apiGiftGatewayCommonDTOsFinancialFinancialAccountsCheckVerifiedAccountDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FinancialAccountApi.apiV1FinancialAccountCheckVerifiedPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} financialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FinancialAccountFinancialIdDelete(financialId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FinancialAccountFinancialIdDelete(financialId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FinancialAccountApi.apiV1FinancialAccountFinancialIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} financialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FinancialAccountFinancialIdGet(financialId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountDetailsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FinancialAccountFinancialIdGet(financialId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FinancialAccountApi.apiV1FinancialAccountFinancialIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} financialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FinancialAccountGenerateVerifyAmountFinancialIdGet(financialId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinancialsCommonCommandsFinancialAccountsGenerateVerifyAmountFinancialAccountCommandsGenerateVerifyAmountFinancialAccountCommandResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FinancialAccountGenerateVerifyAmountFinancialIdGet(financialId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FinancialAccountApi.apiV1FinancialAccountGenerateVerifyAmountFinancialIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FinancialAccountGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FinancialAccountGet(pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FinancialAccountApi.apiV1FinancialAccountGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO} [apiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FinancialAccountIdPut(id: string, apiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO?: ApiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FinancialAccountIdPut(id, apiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FinancialAccountApi.apiV1FinancialAccountIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO} [apiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FinancialAccountPost(apiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO?: ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FinancialAccountPost(apiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FinancialAccountApi.apiV1FinancialAccountPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FinancialAccountTitleValueByWalletWalletIdGet(walletId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FinancialAccountTitleValueByWalletWalletIdGet(walletId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FinancialAccountApi.apiV1FinancialAccountTitleValueByWalletWalletIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FinancialAccountApi - factory interface
 * @export
 */
export const FinancialAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FinancialAccountApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminFinancialAccountGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountRowDTO> {
            return localVarFp.apiV1AdminFinancialAccountGet(pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialFinancialAccountsHandleFinancialAccountDTO} [apiGiftGatewayCommonDTOsFinancialFinancialAccountsHandleFinancialAccountDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminFinancialAccountHandlePut(apiGiftGatewayCommonDTOsFinancialFinancialAccountsHandleFinancialAccountDTO?: ApiGiftGatewayCommonDTOsFinancialFinancialAccountsHandleFinancialAccountDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminFinancialAccountHandlePut(apiGiftGatewayCommonDTOsFinancialFinancialAccountsHandleFinancialAccountDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminFinancialAccountIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsFinancialFinancialAccountsFinancialAccountDetailsDTO> {
            return localVarFp.apiV1AdminFinancialAccountIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCheckVerifiedAccountDTO} [apiGiftGatewayCommonDTOsFinancialFinancialAccountsCheckVerifiedAccountDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1FinancialAccountCheckVerifiedPut(apiGiftGatewayCommonDTOsFinancialFinancialAccountsCheckVerifiedAccountDTO?: ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCheckVerifiedAccountDTO, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonEnumsFinancialFinancialAccountsFinancialAccountStatus> {
            return localVarFp.apiV1FinancialAccountCheckVerifiedPut(apiGiftGatewayCommonDTOsFinancialFinancialAccountsCheckVerifiedAccountDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} financialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FinancialAccountFinancialIdDelete(financialId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1FinancialAccountFinancialIdDelete(financialId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} financialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FinancialAccountFinancialIdGet(financialId: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountDetailsDTO> {
            return localVarFp.apiV1FinancialAccountFinancialIdGet(financialId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} financialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FinancialAccountGenerateVerifyAmountFinancialIdGet(financialId: string, options?: AxiosRequestConfig): AxiosPromise<FinancialsCommonCommandsFinancialAccountsGenerateVerifyAmountFinancialAccountCommandsGenerateVerifyAmountFinancialAccountCommandResponse> {
            return localVarFp.apiV1FinancialAccountGenerateVerifyAmountFinancialIdGet(financialId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FinancialAccountGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialFinancialAccountsShopFinancialAccountRowDTO> {
            return localVarFp.apiV1FinancialAccountGet(pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO} [apiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FinancialAccountIdPut(id: string, apiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO?: ApiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1FinancialAccountIdPut(id, apiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO} [apiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FinancialAccountPost(apiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO?: ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1FinancialAccountPost(apiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FinancialAccountTitleValueByWalletWalletIdGet(walletId: string, options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>> {
            return localVarFp.apiV1FinancialAccountTitleValueByWalletWalletIdGet(walletId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FinancialAccountApi - object-oriented interface
 * @export
 * @class FinancialAccountApi
 * @extends {BaseAPI}
 */
export class FinancialAccountApi extends BaseAPI {
    /**
     * 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialAccountApi
     */
    public apiV1AdminFinancialAccountGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return FinancialAccountApiFp(this.configuration).apiV1AdminFinancialAccountGet(pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftGatewayCommonDTOsFinancialFinancialAccountsHandleFinancialAccountDTO} [apiGiftGatewayCommonDTOsFinancialFinancialAccountsHandleFinancialAccountDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialAccountApi
     */
    public apiV1AdminFinancialAccountHandlePut(apiGiftGatewayCommonDTOsFinancialFinancialAccountsHandleFinancialAccountDTO?: ApiGiftGatewayCommonDTOsFinancialFinancialAccountsHandleFinancialAccountDTO, options?: AxiosRequestConfig) {
        return FinancialAccountApiFp(this.configuration).apiV1AdminFinancialAccountHandlePut(apiGiftGatewayCommonDTOsFinancialFinancialAccountsHandleFinancialAccountDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialAccountApi
     */
    public apiV1AdminFinancialAccountIdGet(id: string, options?: AxiosRequestConfig) {
        return FinancialAccountApiFp(this.configuration).apiV1AdminFinancialAccountIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCheckVerifiedAccountDTO} [apiGiftGatewayCommonDTOsFinancialFinancialAccountsCheckVerifiedAccountDTO] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FinancialAccountApi
     */
    public apiV1FinancialAccountCheckVerifiedPut(apiGiftGatewayCommonDTOsFinancialFinancialAccountsCheckVerifiedAccountDTO?: ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCheckVerifiedAccountDTO, options?: AxiosRequestConfig) {
        return FinancialAccountApiFp(this.configuration).apiV1FinancialAccountCheckVerifiedPut(apiGiftGatewayCommonDTOsFinancialFinancialAccountsCheckVerifiedAccountDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} financialId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialAccountApi
     */
    public apiV1FinancialAccountFinancialIdDelete(financialId: string, options?: AxiosRequestConfig) {
        return FinancialAccountApiFp(this.configuration).apiV1FinancialAccountFinancialIdDelete(financialId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} financialId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialAccountApi
     */
    public apiV1FinancialAccountFinancialIdGet(financialId: string, options?: AxiosRequestConfig) {
        return FinancialAccountApiFp(this.configuration).apiV1FinancialAccountFinancialIdGet(financialId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} financialId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialAccountApi
     */
    public apiV1FinancialAccountGenerateVerifyAmountFinancialIdGet(financialId: string, options?: AxiosRequestConfig) {
        return FinancialAccountApiFp(this.configuration).apiV1FinancialAccountGenerateVerifyAmountFinancialIdGet(financialId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialAccountApi
     */
    public apiV1FinancialAccountGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return FinancialAccountApiFp(this.configuration).apiV1FinancialAccountGet(pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ApiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO} [apiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialAccountApi
     */
    public apiV1FinancialAccountIdPut(id: string, apiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO?: ApiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO, options?: AxiosRequestConfig) {
        return FinancialAccountApiFp(this.configuration).apiV1FinancialAccountIdPut(id, apiGiftGatewayCommonDTOsFinancialFinancialAccountsUpdateFinancialAccountDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO} [apiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialAccountApi
     */
    public apiV1FinancialAccountPost(apiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO?: ApiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO, options?: AxiosRequestConfig) {
        return FinancialAccountApiFp(this.configuration).apiV1FinancialAccountPost(apiGiftGatewayCommonDTOsFinancialFinancialAccountsCreateFinancialAccountDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialAccountApi
     */
    public apiV1FinancialAccountTitleValueByWalletWalletIdGet(walletId: string, options?: AxiosRequestConfig) {
        return FinancialAccountApiFp(this.configuration).apiV1FinancialAccountTitleValueByWalletWalletIdGet(walletId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FinancialAccountTypeApi - axios parameter creator
 * @export
 */
export const FinancialAccountTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminFinancialAccountTypeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/FinancialAccountType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FinancialAccountTypeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/FinancialAccountType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FinancialAccountTypeApi - functional programming interface
 * @export
 */
export const FinancialAccountTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FinancialAccountTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminFinancialAccountTypeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemGuid>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminFinancialAccountTypeGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FinancialAccountTypeApi.apiV1AdminFinancialAccountTypeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FinancialAccountTypeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemGuid>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FinancialAccountTypeGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FinancialAccountTypeApi.apiV1FinancialAccountTypeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FinancialAccountTypeApi - factory interface
 * @export
 */
export const FinancialAccountTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FinancialAccountTypeApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminFinancialAccountTypeGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemGuid>> {
            return localVarFp.apiV1AdminFinancialAccountTypeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FinancialAccountTypeGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemGuid>> {
            return localVarFp.apiV1FinancialAccountTypeGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FinancialAccountTypeApi - object-oriented interface
 * @export
 * @class FinancialAccountTypeApi
 * @extends {BaseAPI}
 */
export class FinancialAccountTypeApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialAccountTypeApi
     */
    public apiV1AdminFinancialAccountTypeGet(options?: AxiosRequestConfig) {
        return FinancialAccountTypeApiFp(this.configuration).apiV1AdminFinancialAccountTypeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialAccountTypeApi
     */
    public apiV1FinancialAccountTypeGet(options?: AxiosRequestConfig) {
        return FinancialAccountTypeApiFp(this.configuration).apiV1FinancialAccountTypeGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FinancialsApi - axios parameter creator
 * @export
 */
export const FinancialsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FinancialsApi - functional programming interface
 * @export
 */
export const FinancialsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FinancialsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FinancialsApi.rootGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FinancialsApi - factory interface
 * @export
 */
export const FinancialsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FinancialsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet(options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.rootGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FinancialsApi - object-oriented interface
 * @export
 * @class FinancialsApi
 * @extends {BaseAPI}
 */
export class FinancialsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialsApi
     */
    public rootGet(options?: AxiosRequestConfig) {
        return FinancialsApiFp(this.configuration).rootGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GatewayPaymentApi - axios parameter creator
 * @export
 */
export const GatewayPaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GatewayPaymentGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/GatewayPayment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayPaymentApi - functional programming interface
 * @export
 */
export const GatewayPaymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GatewayPaymentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GatewayPaymentGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsFinancialPaymentsPaymentGatewaysDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GatewayPaymentGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GatewayPaymentApi.apiV1GatewayPaymentGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GatewayPaymentApi - factory interface
 * @export
 */
export const GatewayPaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GatewayPaymentApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GatewayPaymentGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsFinancialPaymentsPaymentGatewaysDTO>> {
            return localVarFp.apiV1GatewayPaymentGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GatewayPaymentApi - object-oriented interface
 * @export
 * @class GatewayPaymentApi
 * @extends {BaseAPI}
 */
export class GatewayPaymentApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayPaymentApi
     */
    public apiV1GatewayPaymentGet(options?: AxiosRequestConfig) {
        return GatewayPaymentApiFp(this.configuration).apiV1GatewayPaymentGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HoldApi - axios parameter creator
 * @export
 */
export const HoldApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [walletId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HoldGet: async (walletId?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Hold`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (walletId !== undefined) {
                localVarQueryParameter['WalletId'] = walletId;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HoldApi - functional programming interface
 * @export
 */
export const HoldApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HoldApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [walletId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1HoldGet(walletId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialHoldsHoldRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1HoldGet(walletId, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoldApi.apiV1HoldGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HoldApi - factory interface
 * @export
 */
export const HoldApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HoldApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [walletId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HoldGet(walletId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialHoldsHoldRowDTO> {
            return localVarFp.apiV1HoldGet(walletId, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HoldApi - object-oriented interface
 * @export
 * @class HoldApi
 * @extends {BaseAPI}
 */
export class HoldApi extends BaseAPI {
    /**
     * 
     * @param {string} [walletId] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoldApi
     */
    public apiV1HoldGet(walletId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return HoldApiFp(this.configuration).apiV1HoldGet(walletId, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InvoiceApi - axios parameter creator
 * @export
 */
export const InvoiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvoiceIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1InvoiceIdGet', 'id', id)
            const localVarPath = `/api/v1/Invoice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceApi - functional programming interface
 * @export
 */
export const InvoiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InvoiceIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinancialsControllersOwnerInvoiceControllerInvoiceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InvoiceIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoiceApi.apiV1InvoiceIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InvoiceApi - factory interface
 * @export
 */
export const InvoiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvoiceIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<FinancialsControllersOwnerInvoiceControllerInvoiceDto> {
            return localVarFp.apiV1InvoiceIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceApi - object-oriented interface
 * @export
 * @class InvoiceApi
 * @extends {BaseAPI}
 */
export class InvoiceApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiV1InvoiceIdGet(id: string, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).apiV1InvoiceIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LocalCurrencyApi - axios parameter creator
 * @export
 */
export const LocalCurrencyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} currencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocalCurrencyCurrencyIdPut: async (currencyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'currencyId' is not null or undefined
            assertParamExists('apiV1LocalCurrencyCurrencyIdPut', 'currencyId', currencyId)
            const localVarPath = `/api/v1/LocalCurrency/{currencyId}`
                .replace(`{${"currencyId"}}`, encodeURIComponent(String(currencyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocalCurrencyGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/LocalCurrency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} currencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocalCurrencyLastAverageCurrencyIdGet: async (currencyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'currencyId' is not null or undefined
            assertParamExists('apiV1LocalCurrencyLastAverageCurrencyIdGet', 'currencyId', currencyId)
            const localVarPath = `/api/v1/LocalCurrency/LastAverage/{currencyId}`
                .replace(`{${"currencyId"}}`, encodeURIComponent(String(currencyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocalCurrencyWalletsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/LocalCurrency/Wallets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocalCurrencyApi - functional programming interface
 * @export
 */
export const LocalCurrencyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocalCurrencyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} currencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LocalCurrencyCurrencyIdPut(currencyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LocalCurrencyCurrencyIdPut(currencyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocalCurrencyApi.apiV1LocalCurrencyCurrencyIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LocalCurrencyGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinancialsModelsLocalShopCurrencyDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LocalCurrencyGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocalCurrencyApi.apiV1LocalCurrencyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} currencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LocalCurrencyLastAverageCurrencyIdGet(currencyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsFinancialWalletsWalletInformationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LocalCurrencyLastAverageCurrencyIdGet(currencyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocalCurrencyApi.apiV1LocalCurrencyLastAverageCurrencyIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LocalCurrencyWalletsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FinancialsModelsWalletLocalCurrencyDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LocalCurrencyWalletsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocalCurrencyApi.apiV1LocalCurrencyWalletsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LocalCurrencyApi - factory interface
 * @export
 */
export const LocalCurrencyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocalCurrencyApiFp(configuration)
    return {
        /**
         * 
         * @param {string} currencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocalCurrencyCurrencyIdPut(currencyId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1LocalCurrencyCurrencyIdPut(currencyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocalCurrencyGet(options?: AxiosRequestConfig): AxiosPromise<FinancialsModelsLocalShopCurrencyDTO> {
            return localVarFp.apiV1LocalCurrencyGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} currencyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocalCurrencyLastAverageCurrencyIdGet(currencyId: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsFinancialWalletsWalletInformationDTO> {
            return localVarFp.apiV1LocalCurrencyLastAverageCurrencyIdGet(currencyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocalCurrencyWalletsGet(options?: AxiosRequestConfig): AxiosPromise<Array<FinancialsModelsWalletLocalCurrencyDTO>> {
            return localVarFp.apiV1LocalCurrencyWalletsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocalCurrencyApi - object-oriented interface
 * @export
 * @class LocalCurrencyApi
 * @extends {BaseAPI}
 */
export class LocalCurrencyApi extends BaseAPI {
    /**
     * 
     * @param {string} currencyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalCurrencyApi
     */
    public apiV1LocalCurrencyCurrencyIdPut(currencyId: string, options?: AxiosRequestConfig) {
        return LocalCurrencyApiFp(this.configuration).apiV1LocalCurrencyCurrencyIdPut(currencyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalCurrencyApi
     */
    public apiV1LocalCurrencyGet(options?: AxiosRequestConfig) {
        return LocalCurrencyApiFp(this.configuration).apiV1LocalCurrencyGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} currencyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalCurrencyApi
     */
    public apiV1LocalCurrencyLastAverageCurrencyIdGet(currencyId: string, options?: AxiosRequestConfig) {
        return LocalCurrencyApiFp(this.configuration).apiV1LocalCurrencyLastAverageCurrencyIdGet(currencyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalCurrencyApi
     */
    public apiV1LocalCurrencyWalletsGet(options?: AxiosRequestConfig) {
        return LocalCurrencyApiFp(this.configuration).apiV1LocalCurrencyWalletsGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PaymentIdPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PaymentIdPut', 'id', id)
            const localVarPath = `/api/v1/Payment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FinancialsControllersOwnerPaymentControllerCreatePaymentDto} [financialsControllersOwnerPaymentControllerCreatePaymentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PaymentPost: async (financialsControllersOwnerPaymentControllerCreatePaymentDto?: FinancialsControllersOwnerPaymentControllerCreatePaymentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(financialsControllersOwnerPaymentControllerCreatePaymentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PaymentIdPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinancialsCommonCashierCommandsDepositsVerifyDepositCommandResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PaymentIdPut(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentApi.apiV1PaymentIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FinancialsControllersOwnerPaymentControllerCreatePaymentDto} [financialsControllersOwnerPaymentControllerCreatePaymentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PaymentPost(financialsControllersOwnerPaymentControllerCreatePaymentDto?: FinancialsControllersOwnerPaymentControllerCreatePaymentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PaymentPost(financialsControllersOwnerPaymentControllerCreatePaymentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentApi.apiV1PaymentPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PaymentIdPut(id: string, options?: AxiosRequestConfig): AxiosPromise<FinancialsCommonCashierCommandsDepositsVerifyDepositCommandResponse> {
            return localVarFp.apiV1PaymentIdPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FinancialsControllersOwnerPaymentControllerCreatePaymentDto} [financialsControllersOwnerPaymentControllerCreatePaymentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PaymentPost(financialsControllersOwnerPaymentControllerCreatePaymentDto?: FinancialsControllersOwnerPaymentControllerCreatePaymentDto, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.apiV1PaymentPost(financialsControllersOwnerPaymentControllerCreatePaymentDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public apiV1PaymentIdPut(id: string, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).apiV1PaymentIdPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FinancialsControllersOwnerPaymentControllerCreatePaymentDto} [financialsControllersOwnerPaymentControllerCreatePaymentDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public apiV1PaymentPost(financialsControllersOwnerPaymentControllerCreatePaymentDto?: FinancialsControllersOwnerPaymentControllerCreatePaymentDto, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).apiV1PaymentPost(financialsControllersOwnerPaymentControllerCreatePaymentDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WalletApi - axios parameter creator
 * @export
 */
export const WalletApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} [onlyNegativeWallet] 
         * @param {boolean} [onlyGlobalWallet] 
         * @param {string} [ownerId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletGet: async (onlyNegativeWallet?: boolean, onlyGlobalWallet?: boolean, ownerId?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/Wallet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (onlyNegativeWallet !== undefined) {
                localVarQueryParameter['OnlyNegativeWallet'] = onlyNegativeWallet;
            }

            if (onlyGlobalWallet !== undefined) {
                localVarQueryParameter['OnlyGlobalWallet'] = onlyGlobalWallet;
            }

            if (ownerId !== undefined) {
                localVarQueryParameter['OwnerId'] = ownerId;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FinancialsModelsCreateGlobalWalletDTO} [financialsModelsCreateGlobalWalletDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletGlobalPost: async (financialsModelsCreateGlobalWalletDTO?: FinancialsModelsCreateGlobalWalletDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/Wallet/Global`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(financialsModelsCreateGlobalWalletDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletGlobalWalletBalancesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/Wallet/GlobalWalletBalances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiGiftGatewayCommonDTOsFinancialWalletsLockWalletHandlerDTO} [apiGiftGatewayCommonDTOsFinancialWalletsLockWalletHandlerDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletLockWalletHandlerIdPut: async (id: string, apiGiftGatewayCommonDTOsFinancialWalletsLockWalletHandlerDTO?: ApiGiftGatewayCommonDTOsFinancialWalletsLockWalletHandlerDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminWalletLockWalletHandlerIdPut', 'id', id)
            const localVarPath = `/api/v1/Admin/Wallet/LockWalletHandler/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsFinancialWalletsLockWalletHandlerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletOverviewStatisticGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/Wallet/OverviewStatistic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletTitleValuesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/Wallet/TitleValues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletTitleValuesShopIdGet: async (shopId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shopId' is not null or undefined
            assertParamExists('apiV1AdminWalletTitleValuesShopIdGet', 'shopId', shopId)
            const localVarPath = `/api/v1/Admin/Wallet/TitleValues/{shopId}`
                .replace(`{${"shopId"}}`, encodeURIComponent(String(shopId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletUpdateBalancesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/Wallet/UpdateBalances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletWalletIdGet: async (walletId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            assertParamExists('apiV1AdminWalletWalletIdGet', 'walletId', walletId)
            const localVarPath = `/api/v1/Admin/Wallet/{walletId}`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletBalanceGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Wallet/Balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletBalanceTitleValuesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Wallet/BalanceTitleValues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletBalanceWalletIdGet: async (walletId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            assertParamExists('apiV1WalletBalanceWalletIdGet', 'walletId', walletId)
            const localVarPath = `/api/v1/Wallet/Balance/{walletId}`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {FinancialsModelsWalletBuyCreditDto} [financialsModelsWalletBuyCreditDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletBuyCreditWalletIdPut: async (walletId: string, financialsModelsWalletBuyCreditDto?: FinancialsModelsWalletBuyCreditDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            assertParamExists('apiV1WalletBuyCreditWalletIdPut', 'walletId', walletId)
            const localVarPath = `/api/v1/Wallet/BuyCredit/{walletId}`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(financialsModelsWalletBuyCreditDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletDefaultGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Wallet/Default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [onlyNegativeWallet] 
         * @param {boolean} [onlyGlobalWallet] 
         * @param {string} [ownerId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletGet: async (onlyNegativeWallet?: boolean, onlyGlobalWallet?: boolean, ownerId?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Wallet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (onlyNegativeWallet !== undefined) {
                localVarQueryParameter['OnlyNegativeWallet'] = onlyNegativeWallet;
            }

            if (onlyGlobalWallet !== undefined) {
                localVarQueryParameter['OnlyGlobalWallet'] = onlyGlobalWallet;
            }

            if (ownerId !== undefined) {
                localVarQueryParameter['OwnerId'] = ownerId;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletLastAverageWalletIdGet: async (walletId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            assertParamExists('apiV1WalletLastAverageWalletIdGet', 'walletId', walletId)
            const localVarPath = `/api/v1/Wallet/LastAverage/{walletId}`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletMinimumPermissionGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Wallet/MinimumPermission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sourceWalletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletSourceWalletIdFavoritesDestinationGet: async (sourceWalletId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceWalletId' is not null or undefined
            assertParamExists('apiV1WalletSourceWalletIdFavoritesDestinationGet', 'sourceWalletId', sourceWalletId)
            const localVarPath = `/api/v1/Wallet/{sourceWalletId}/FavoritesDestination`
                .replace(`{${"sourceWalletId"}}`, encodeURIComponent(String(sourceWalletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletWalletIdTransferOverviewGet: async (walletId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            assertParamExists('apiV1WalletWalletIdTransferOverviewGet', 'walletId', walletId)
            const localVarPath = `/api/v1/Wallet/{walletId}/TransferOverview`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WalletApi - functional programming interface
 * @export
 */
export const WalletApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WalletApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {boolean} [onlyNegativeWallet] 
         * @param {boolean} [onlyGlobalWallet] 
         * @param {string} [ownerId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminWalletGet(onlyNegativeWallet?: boolean, onlyGlobalWallet?: boolean, ownerId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialWalletsWalletRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminWalletGet(onlyNegativeWallet, onlyGlobalWallet, ownerId, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.apiV1AdminWalletGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FinancialsModelsCreateGlobalWalletDTO} [financialsModelsCreateGlobalWalletDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminWalletGlobalPost(financialsModelsCreateGlobalWalletDTO?: FinancialsModelsCreateGlobalWalletDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminWalletGlobalPost(financialsModelsCreateGlobalWalletDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.apiV1AdminWalletGlobalPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminWalletGlobalWalletBalancesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsFinancialWalletsGlobalWalletBalanceDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminWalletGlobalWalletBalancesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.apiV1AdminWalletGlobalWalletBalancesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiGiftGatewayCommonDTOsFinancialWalletsLockWalletHandlerDTO} [apiGiftGatewayCommonDTOsFinancialWalletsLockWalletHandlerDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminWalletLockWalletHandlerIdPut(id: string, apiGiftGatewayCommonDTOsFinancialWalletsLockWalletHandlerDTO?: ApiGiftGatewayCommonDTOsFinancialWalletsLockWalletHandlerDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminWalletLockWalletHandlerIdPut(id, apiGiftGatewayCommonDTOsFinancialWalletsLockWalletHandlerDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.apiV1AdminWalletLockWalletHandlerIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminWalletOverviewStatisticGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinancialsCommonQueriesWalletsGetAverageWalletQueryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminWalletOverviewStatisticGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.apiV1AdminWalletOverviewStatisticGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminWalletTitleValuesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminWalletTitleValuesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.apiV1AdminWalletTitleValuesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminWalletTitleValuesShopIdGet(shopId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminWalletTitleValuesShopIdGet(shopId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.apiV1AdminWalletTitleValuesShopIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminWalletUpdateBalancesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminWalletUpdateBalancesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.apiV1AdminWalletUpdateBalancesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminWalletWalletIdGet(walletId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsFinancialWalletsWalletChargeStatusDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminWalletWalletIdGet(walletId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.apiV1AdminWalletWalletIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WalletBalanceGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsFinancialWalletsWalletBalanceDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WalletBalanceGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.apiV1WalletBalanceGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WalletBalanceTitleValuesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WalletBalanceTitleValuesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.apiV1WalletBalanceTitleValuesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WalletBalanceWalletIdGet(walletId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsFinancialWalletsWalletBalanceDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WalletBalanceWalletIdGet(walletId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.apiV1WalletBalanceWalletIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} walletId 
         * @param {FinancialsModelsWalletBuyCreditDto} [financialsModelsWalletBuyCreditDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WalletBuyCreditWalletIdPut(walletId: string, financialsModelsWalletBuyCreditDto?: FinancialsModelsWalletBuyCreditDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WalletBuyCreditWalletIdPut(walletId, financialsModelsWalletBuyCreditDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.apiV1WalletBuyCreditWalletIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WalletDefaultGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsFinancialWalletsDefaultWalletDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WalletDefaultGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.apiV1WalletDefaultGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [onlyNegativeWallet] 
         * @param {boolean} [onlyGlobalWallet] 
         * @param {string} [ownerId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WalletGet(onlyNegativeWallet?: boolean, onlyGlobalWallet?: boolean, ownerId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialWalletsWalletRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WalletGet(onlyNegativeWallet, onlyGlobalWallet, ownerId, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.apiV1WalletGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WalletLastAverageWalletIdGet(walletId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsFinancialWalletsWalletInformationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WalletLastAverageWalletIdGet(walletId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.apiV1WalletLastAverageWalletIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WalletMinimumPermissionGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsFinancialWalletsTotalWalletMinimumPermissionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WalletMinimumPermissionGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.apiV1WalletMinimumPermissionGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} sourceWalletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WalletSourceWalletIdFavoritesDestinationGet(sourceWalletId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WalletSourceWalletIdFavoritesDestinationGet(sourceWalletId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.apiV1WalletSourceWalletIdFavoritesDestinationGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WalletWalletIdTransferOverviewGet(walletId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WalletWalletIdTransferOverviewGet(walletId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.apiV1WalletWalletIdTransferOverviewGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WalletApi - factory interface
 * @export
 */
export const WalletApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WalletApiFp(configuration)
    return {
        /**
         * 
         * @param {boolean} [onlyNegativeWallet] 
         * @param {boolean} [onlyGlobalWallet] 
         * @param {string} [ownerId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletGet(onlyNegativeWallet?: boolean, onlyGlobalWallet?: boolean, ownerId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialWalletsWalletRowDTO> {
            return localVarFp.apiV1AdminWalletGet(onlyNegativeWallet, onlyGlobalWallet, ownerId, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FinancialsModelsCreateGlobalWalletDTO} [financialsModelsCreateGlobalWalletDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletGlobalPost(financialsModelsCreateGlobalWalletDTO?: FinancialsModelsCreateGlobalWalletDTO, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.apiV1AdminWalletGlobalPost(financialsModelsCreateGlobalWalletDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletGlobalWalletBalancesGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsFinancialWalletsGlobalWalletBalanceDTO>> {
            return localVarFp.apiV1AdminWalletGlobalWalletBalancesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiGiftGatewayCommonDTOsFinancialWalletsLockWalletHandlerDTO} [apiGiftGatewayCommonDTOsFinancialWalletsLockWalletHandlerDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletLockWalletHandlerIdPut(id: string, apiGiftGatewayCommonDTOsFinancialWalletsLockWalletHandlerDTO?: ApiGiftGatewayCommonDTOsFinancialWalletsLockWalletHandlerDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminWalletLockWalletHandlerIdPut(id, apiGiftGatewayCommonDTOsFinancialWalletsLockWalletHandlerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletOverviewStatisticGet(options?: AxiosRequestConfig): AxiosPromise<FinancialsCommonQueriesWalletsGetAverageWalletQueryResponse> {
            return localVarFp.apiV1AdminWalletOverviewStatisticGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletTitleValuesGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>> {
            return localVarFp.apiV1AdminWalletTitleValuesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletTitleValuesShopIdGet(shopId: string, options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>> {
            return localVarFp.apiV1AdminWalletTitleValuesShopIdGet(shopId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletUpdateBalancesGet(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminWalletUpdateBalancesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletWalletIdGet(walletId: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsFinancialWalletsWalletChargeStatusDTO> {
            return localVarFp.apiV1AdminWalletWalletIdGet(walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletBalanceGet(options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsFinancialWalletsWalletBalanceDTO> {
            return localVarFp.apiV1WalletBalanceGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletBalanceTitleValuesGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>> {
            return localVarFp.apiV1WalletBalanceTitleValuesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletBalanceWalletIdGet(walletId: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsFinancialWalletsWalletBalanceDTO> {
            return localVarFp.apiV1WalletBalanceWalletIdGet(walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {FinancialsModelsWalletBuyCreditDto} [financialsModelsWalletBuyCreditDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletBuyCreditWalletIdPut(walletId: string, financialsModelsWalletBuyCreditDto?: FinancialsModelsWalletBuyCreditDto, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1WalletBuyCreditWalletIdPut(walletId, financialsModelsWalletBuyCreditDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletDefaultGet(options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsFinancialWalletsDefaultWalletDTO> {
            return localVarFp.apiV1WalletDefaultGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [onlyNegativeWallet] 
         * @param {boolean} [onlyGlobalWallet] 
         * @param {string} [ownerId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletGet(onlyNegativeWallet?: boolean, onlyGlobalWallet?: boolean, ownerId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialWalletsWalletRowDTO> {
            return localVarFp.apiV1WalletGet(onlyNegativeWallet, onlyGlobalWallet, ownerId, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletLastAverageWalletIdGet(walletId: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsFinancialWalletsWalletInformationDTO> {
            return localVarFp.apiV1WalletLastAverageWalletIdGet(walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletMinimumPermissionGet(options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsFinancialWalletsTotalWalletMinimumPermissionDTO> {
            return localVarFp.apiV1WalletMinimumPermissionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sourceWalletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletSourceWalletIdFavoritesDestinationGet(sourceWalletId: string, options?: AxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.apiV1WalletSourceWalletIdFavoritesDestinationGet(sourceWalletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletWalletIdTransferOverviewGet(walletId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1WalletWalletIdTransferOverviewGet(walletId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WalletApi - object-oriented interface
 * @export
 * @class WalletApi
 * @extends {BaseAPI}
 */
export class WalletApi extends BaseAPI {
    /**
     * 
     * @param {boolean} [onlyNegativeWallet] 
     * @param {boolean} [onlyGlobalWallet] 
     * @param {string} [ownerId] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public apiV1AdminWalletGet(onlyNegativeWallet?: boolean, onlyGlobalWallet?: boolean, ownerId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).apiV1AdminWalletGet(onlyNegativeWallet, onlyGlobalWallet, ownerId, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FinancialsModelsCreateGlobalWalletDTO} [financialsModelsCreateGlobalWalletDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public apiV1AdminWalletGlobalPost(financialsModelsCreateGlobalWalletDTO?: FinancialsModelsCreateGlobalWalletDTO, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).apiV1AdminWalletGlobalPost(financialsModelsCreateGlobalWalletDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public apiV1AdminWalletGlobalWalletBalancesGet(options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).apiV1AdminWalletGlobalWalletBalancesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ApiGiftGatewayCommonDTOsFinancialWalletsLockWalletHandlerDTO} [apiGiftGatewayCommonDTOsFinancialWalletsLockWalletHandlerDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public apiV1AdminWalletLockWalletHandlerIdPut(id: string, apiGiftGatewayCommonDTOsFinancialWalletsLockWalletHandlerDTO?: ApiGiftGatewayCommonDTOsFinancialWalletsLockWalletHandlerDTO, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).apiV1AdminWalletLockWalletHandlerIdPut(id, apiGiftGatewayCommonDTOsFinancialWalletsLockWalletHandlerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public apiV1AdminWalletOverviewStatisticGet(options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).apiV1AdminWalletOverviewStatisticGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public apiV1AdminWalletTitleValuesGet(options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).apiV1AdminWalletTitleValuesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} shopId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public apiV1AdminWalletTitleValuesShopIdGet(shopId: string, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).apiV1AdminWalletTitleValuesShopIdGet(shopId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public apiV1AdminWalletUpdateBalancesGet(options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).apiV1AdminWalletUpdateBalancesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public apiV1AdminWalletWalletIdGet(walletId: string, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).apiV1AdminWalletWalletIdGet(walletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public apiV1WalletBalanceGet(options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).apiV1WalletBalanceGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public apiV1WalletBalanceTitleValuesGet(options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).apiV1WalletBalanceTitleValuesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public apiV1WalletBalanceWalletIdGet(walletId: string, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).apiV1WalletBalanceWalletIdGet(walletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {FinancialsModelsWalletBuyCreditDto} [financialsModelsWalletBuyCreditDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public apiV1WalletBuyCreditWalletIdPut(walletId: string, financialsModelsWalletBuyCreditDto?: FinancialsModelsWalletBuyCreditDto, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).apiV1WalletBuyCreditWalletIdPut(walletId, financialsModelsWalletBuyCreditDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public apiV1WalletDefaultGet(options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).apiV1WalletDefaultGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [onlyNegativeWallet] 
     * @param {boolean} [onlyGlobalWallet] 
     * @param {string} [ownerId] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public apiV1WalletGet(onlyNegativeWallet?: boolean, onlyGlobalWallet?: boolean, ownerId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).apiV1WalletGet(onlyNegativeWallet, onlyGlobalWallet, ownerId, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public apiV1WalletLastAverageWalletIdGet(walletId: string, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).apiV1WalletLastAverageWalletIdGet(walletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public apiV1WalletMinimumPermissionGet(options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).apiV1WalletMinimumPermissionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sourceWalletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public apiV1WalletSourceWalletIdFavoritesDestinationGet(sourceWalletId: string, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).apiV1WalletSourceWalletIdFavoritesDestinationGet(sourceWalletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public apiV1WalletWalletIdTransferOverviewGet(walletId: string, options?: AxiosRequestConfig) {
        return WalletApiFp(this.configuration).apiV1WalletWalletIdTransferOverviewGet(walletId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WalletTransactionApi - axios parameter creator
 * @export
 */
export const WalletTransactionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} walletId 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletTransactionGet: async (walletId: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            assertParamExists('apiV1AdminWalletTransactionGet', 'walletId', walletId)
            const localVarPath = `/api/v1/Admin/WalletTransaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (walletId !== undefined) {
                localVarQueryParameter['WalletId'] = walletId;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletTransactionIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AdminWalletTransactionIdGet', 'id', id)
            const localVarPath = `/api/v1/Admin/WalletTransaction/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletTransactionInquiryTransferWalletWalletIdShopIdGet: async (walletId: string, shopId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            assertParamExists('apiV1AdminWalletTransactionInquiryTransferWalletWalletIdShopIdGet', 'walletId', walletId)
            // verify required parameter 'shopId' is not null or undefined
            assertParamExists('apiV1AdminWalletTransactionInquiryTransferWalletWalletIdShopIdGet', 'shopId', shopId)
            const localVarPath = `/api/v1/Admin/WalletTransaction/InquiryTransferWallet/{walletId}/{shopId}`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)))
                .replace(`{${"shopId"}}`, encodeURIComponent(String(shopId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto} [apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletTransactionTransferPost: async (apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto?: ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/WalletTransaction/Transfer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletTransactionGet: async (walletId: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            assertParamExists('apiV1WalletTransactionGet', 'walletId', walletId)
            const localVarPath = `/api/v1/WalletTransaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (walletId !== undefined) {
                localVarQueryParameter['WalletId'] = walletId;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletTransactionInquiryHashGet: async (hash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('apiV1WalletTransactionInquiryHashGet', 'hash', hash)
            const localVarPath = `/api/v1/WalletTransaction/Inquiry/{hash}`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto} [apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletTransactionPost: async (apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto?: ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/WalletTransaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WalletTransactionApi - functional programming interface
 * @export
 */
export const WalletTransactionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WalletTransactionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} walletId 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminWalletTransactionGet(walletId: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminWalletTransactionGet(walletId, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletTransactionApi.apiV1AdminWalletTransactionGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminWalletTransactionIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminWalletTransactionIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletTransactionApi.apiV1AdminWalletTransactionIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} walletId 
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminWalletTransactionInquiryTransferWalletWalletIdShopIdGet(walletId: string, shopId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseKeyValueDTOSystemDouble>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminWalletTransactionInquiryTransferWalletWalletIdShopIdGet(walletId, shopId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletTransactionApi.apiV1AdminWalletTransactionInquiryTransferWalletWalletIdShopIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto} [apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminWalletTransactionTransferPost(apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto?: ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminWalletTransactionTransferPost(apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletTransactionApi.apiV1AdminWalletTransactionTransferPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} walletId 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WalletTransactionGet(walletId: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WalletTransactionGet(walletId, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletTransactionApi.apiV1WalletTransactionGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WalletTransactionInquiryHashGet(hash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinancialsCommonQueriesTronScanTransactionInquiryQueriesGetTronScanTransactionInquiryQueryResponseTronScanTransactionResultView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WalletTransactionInquiryHashGet(hash, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletTransactionApi.apiV1WalletTransactionInquiryHashGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto} [apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WalletTransactionPost(apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto?: ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WalletTransactionPost(apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletTransactionApi.apiV1WalletTransactionPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WalletTransactionApi - factory interface
 * @export
 */
export const WalletTransactionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WalletTransactionApiFp(configuration)
    return {
        /**
         * 
         * @param {string} walletId 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletTransactionGet(walletId: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionRowDTO> {
            return localVarFp.apiV1AdminWalletTransactionGet(walletId, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletTransactionIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionDTO> {
            return localVarFp.apiV1AdminWalletTransactionIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletTransactionInquiryTransferWalletWalletIdShopIdGet(walletId: string, shopId: string, options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseKeyValueDTOSystemDouble>> {
            return localVarFp.apiV1AdminWalletTransactionInquiryTransferWalletWalletIdShopIdGet(walletId, shopId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto} [apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWalletTransactionTransferPost(apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto?: ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundResponseDto> {
            return localVarFp.apiV1AdminWalletTransactionTransferPost(apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletTransactionGet(walletId: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialWalletsWalletTransactionRowDTO> {
            return localVarFp.apiV1WalletTransactionGet(walletId, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletTransactionInquiryHashGet(hash: string, options?: AxiosRequestConfig): AxiosPromise<FinancialsCommonQueriesTronScanTransactionInquiryQueriesGetTronScanTransactionInquiryQueryResponseTronScanTransactionResultView> {
            return localVarFp.apiV1WalletTransactionInquiryHashGet(hash, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto} [apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletTransactionPost(apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto?: ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundResponseDto> {
            return localVarFp.apiV1WalletTransactionPost(apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WalletTransactionApi - object-oriented interface
 * @export
 * @class WalletTransactionApi
 * @extends {BaseAPI}
 */
export class WalletTransactionApi extends BaseAPI {
    /**
     * 
     * @param {string} walletId 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletTransactionApi
     */
    public apiV1AdminWalletTransactionGet(walletId: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return WalletTransactionApiFp(this.configuration).apiV1AdminWalletTransactionGet(walletId, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletTransactionApi
     */
    public apiV1AdminWalletTransactionIdGet(id: string, options?: AxiosRequestConfig) {
        return WalletTransactionApiFp(this.configuration).apiV1AdminWalletTransactionIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {string} shopId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletTransactionApi
     */
    public apiV1AdminWalletTransactionInquiryTransferWalletWalletIdShopIdGet(walletId: string, shopId: string, options?: AxiosRequestConfig) {
        return WalletTransactionApiFp(this.configuration).apiV1AdminWalletTransactionInquiryTransferWalletWalletIdShopIdGet(walletId, shopId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto} [apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletTransactionApi
     */
    public apiV1AdminWalletTransactionTransferPost(apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto?: ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto, options?: AxiosRequestConfig) {
        return WalletTransactionApiFp(this.configuration).apiV1AdminWalletTransactionTransferPost(apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletTransactionApi
     */
    public apiV1WalletTransactionGet(walletId: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return WalletTransactionApiFp(this.configuration).apiV1WalletTransactionGet(walletId, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} hash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletTransactionApi
     */
    public apiV1WalletTransactionInquiryHashGet(hash: string, options?: AxiosRequestConfig) {
        return WalletTransactionApiFp(this.configuration).apiV1WalletTransactionInquiryHashGet(hash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto} [apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletTransactionApi
     */
    public apiV1WalletTransactionPost(apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto?: ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto, options?: AxiosRequestConfig) {
        return WalletTransactionApiFp(this.configuration).apiV1WalletTransactionPost(apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WalletTransactionReportApi - axios parameter creator
 * @export
 */
export const WalletTransactionReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [walletId] 
         * @param {number} [amount] 
         * @param {ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletTransactionReportExcelGet: async (fromDate?: string, toDate?: string, walletId?: string, amount?: number, status?: ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/WalletTransactionReport/Excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (walletId !== undefined) {
                localVarQueryParameter['WalletId'] = walletId;
            }

            if (amount !== undefined) {
                localVarQueryParameter['Amount'] = amount;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [walletId] 
         * @param {number} [amount] 
         * @param {ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletTransactionReportGet: async (fromDate?: string, toDate?: string, walletId?: string, amount?: number, status?: ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/WalletTransactionReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (walletId !== undefined) {
                localVarQueryParameter['WalletId'] = walletId;
            }

            if (amount !== undefined) {
                localVarQueryParameter['Amount'] = amount;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WalletTransactionReportApi - functional programming interface
 * @export
 */
export const WalletTransactionReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WalletTransactionReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [walletId] 
         * @param {number} [amount] 
         * @param {ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WalletTransactionReportExcelGet(fromDate?: string, toDate?: string, walletId?: string, amount?: number, status?: ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WalletTransactionReportExcelGet(fromDate, toDate, walletId, amount, status, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletTransactionReportApi.apiV1WalletTransactionReportExcelGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [walletId] 
         * @param {number} [amount] 
         * @param {ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WalletTransactionReportGet(fromDate?: string, toDate?: string, walletId?: string, amount?: number, status?: ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOFinancialsModelsWalletTransactionReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WalletTransactionReportGet(fromDate, toDate, walletId, amount, status, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletTransactionReportApi.apiV1WalletTransactionReportGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WalletTransactionReportApi - factory interface
 * @export
 */
export const WalletTransactionReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WalletTransactionReportApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [walletId] 
         * @param {number} [amount] 
         * @param {ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletTransactionReportExcelGet(fromDate?: string, toDate?: string, walletId?: string, amount?: number, status?: ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1WalletTransactionReportExcelGet(fromDate, toDate, walletId, amount, status, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [walletId] 
         * @param {number} [amount] 
         * @param {ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WalletTransactionReportGet(fromDate?: string, toDate?: string, walletId?: string, amount?: number, status?: ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOFinancialsModelsWalletTransactionReport> {
            return localVarFp.apiV1WalletTransactionReportGet(fromDate, toDate, walletId, amount, status, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WalletTransactionReportApi - object-oriented interface
 * @export
 * @class WalletTransactionReportApi
 * @extends {BaseAPI}
 */
export class WalletTransactionReportApi extends BaseAPI {
    /**
     * 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [walletId] 
     * @param {number} [amount] 
     * @param {ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus} [status] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletTransactionReportApi
     */
    public apiV1WalletTransactionReportExcelGet(fromDate?: string, toDate?: string, walletId?: string, amount?: number, status?: ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return WalletTransactionReportApiFp(this.configuration).apiV1WalletTransactionReportExcelGet(fromDate, toDate, walletId, amount, status, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [walletId] 
     * @param {number} [amount] 
     * @param {ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus} [status] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletTransactionReportApi
     */
    public apiV1WalletTransactionReportGet(fromDate?: string, toDate?: string, walletId?: string, amount?: number, status?: ApiGiftGatewayCommonEnumsFinancialWalletsWalletTransactionStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return WalletTransactionReportApiFp(this.configuration).apiV1WalletTransactionReportGet(fromDate, toDate, walletId, amount, status, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WithdrawApi - axios parameter creator
 * @export
 */
export const WithdrawApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} withdrawId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWithdrawAcceptWithdrawIdPut: async (withdrawId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'withdrawId' is not null or undefined
            assertParamExists('apiV1AdminWithdrawAcceptWithdrawIdPut', 'withdrawId', withdrawId)
            const localVarPath = `/api/v1/Admin/Withdraw/Accept/{withdrawId}`
                .replace(`{${"withdrawId"}}`, encodeURIComponent(String(withdrawId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} withdrawId 
         * @param {ApiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO} [apiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWithdrawDoneWithdrawIdPut: async (withdrawId: string, apiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO?: ApiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'withdrawId' is not null or undefined
            assertParamExists('apiV1AdminWithdrawDoneWithdrawIdPut', 'withdrawId', withdrawId)
            const localVarPath = `/api/v1/Admin/Withdraw/Done/{withdrawId}`
                .replace(`{${"withdrawId"}}`, encodeURIComponent(String(withdrawId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [status] 
         * @param {string} [transactionNumber] 
         * @param {string} [shopId] 
         * @param {boolean} [unreviewedOnly] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWithdrawGet: async (status?: number, transactionNumber?: string, shopId?: string, unreviewedOnly?: boolean, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Admin/Withdraw`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (transactionNumber !== undefined) {
                localVarQueryParameter['TransactionNumber'] = transactionNumber;
            }

            if (shopId !== undefined) {
                localVarQueryParameter['ShopId'] = shopId;
            }

            if (unreviewedOnly !== undefined) {
                localVarQueryParameter['UnreviewedOnly'] = unreviewedOnly;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} withdrawId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWithdrawRejectWithdrawIdPut: async (withdrawId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'withdrawId' is not null or undefined
            assertParamExists('apiV1AdminWithdrawRejectWithdrawIdPut', 'withdrawId', withdrawId)
            const localVarPath = `/api/v1/Admin/Withdraw/Reject/{withdrawId}`
                .replace(`{${"withdrawId"}}`, encodeURIComponent(String(withdrawId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} withdrawId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWithdrawReviewWithdrawIdPut: async (withdrawId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'withdrawId' is not null or undefined
            assertParamExists('apiV1AdminWithdrawReviewWithdrawIdPut', 'withdrawId', withdrawId)
            const localVarPath = `/api/v1/Admin/Withdraw/Review/{withdrawId}`
                .replace(`{${"withdrawId"}}`, encodeURIComponent(String(withdrawId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} withdrawId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WithdrawCancelWithdrawIdPut: async (withdrawId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'withdrawId' is not null or undefined
            assertParamExists('apiV1WithdrawCancelWithdrawIdPut', 'withdrawId', withdrawId)
            const localVarPath = `/api/v1/Withdraw/Cancel/{withdrawId}`
                .replace(`{${"withdrawId"}}`, encodeURIComponent(String(withdrawId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WithdrawFeeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Withdraw/Fee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FinancialsCommonCashierEnumsWithdrawStatus} [status] 
         * @param {string} [transactionNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WithdrawGet: async (status?: FinancialsCommonCashierEnumsWithdrawStatus, transactionNumber?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Withdraw`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (transactionNumber !== undefined) {
                localVarQueryParameter['TransactionNumber'] = transactionNumber;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO} [apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WithdrawPost: async (apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO?: ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Withdraw`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OwnerId required
            await setApiKeyToObject(localVarHeaderParameter, "OwnerId", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WithdrawApi - functional programming interface
 * @export
 */
export const WithdrawApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WithdrawApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} withdrawId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminWithdrawAcceptWithdrawIdPut(withdrawId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminWithdrawAcceptWithdrawIdPut(withdrawId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WithdrawApi.apiV1AdminWithdrawAcceptWithdrawIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} withdrawId 
         * @param {ApiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO} [apiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminWithdrawDoneWithdrawIdPut(withdrawId: string, apiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO?: ApiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminWithdrawDoneWithdrawIdPut(withdrawId, apiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WithdrawApi.apiV1AdminWithdrawDoneWithdrawIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [status] 
         * @param {string} [transactionNumber] 
         * @param {string} [shopId] 
         * @param {boolean} [unreviewedOnly] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminWithdrawGet(status?: number, transactionNumber?: string, shopId?: string, unreviewedOnly?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCashoutsCashoutRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminWithdrawGet(status, transactionNumber, shopId, unreviewedOnly, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WithdrawApi.apiV1AdminWithdrawGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} withdrawId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminWithdrawRejectWithdrawIdPut(withdrawId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminWithdrawRejectWithdrawIdPut(withdrawId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WithdrawApi.apiV1AdminWithdrawRejectWithdrawIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} withdrawId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AdminWithdrawReviewWithdrawIdPut(withdrawId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AdminWithdrawReviewWithdrawIdPut(withdrawId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WithdrawApi.apiV1AdminWithdrawReviewWithdrawIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} withdrawId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WithdrawCancelWithdrawIdPut(withdrawId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WithdrawCancelWithdrawIdPut(withdrawId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WithdrawApi.apiV1WithdrawCancelWithdrawIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WithdrawFeeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsFinancialCashoutsWithdrawFeeDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WithdrawFeeGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WithdrawApi.apiV1WithdrawFeeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FinancialsCommonCashierEnumsWithdrawStatus} [status] 
         * @param {string} [transactionNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WithdrawGet(status?: FinancialsCommonCashierEnumsWithdrawStatus, transactionNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCashoutsShopCashoutRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WithdrawGet(status, transactionNumber, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WithdrawApi.apiV1WithdrawGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO} [apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WithdrawPost(apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO?: ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WithdrawPost(apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WithdrawApi.apiV1WithdrawPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WithdrawApi - factory interface
 * @export
 */
export const WithdrawApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WithdrawApiFp(configuration)
    return {
        /**
         * 
         * @param {string} withdrawId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWithdrawAcceptWithdrawIdPut(withdrawId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminWithdrawAcceptWithdrawIdPut(withdrawId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} withdrawId 
         * @param {ApiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO} [apiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWithdrawDoneWithdrawIdPut(withdrawId: string, apiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO?: ApiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminWithdrawDoneWithdrawIdPut(withdrawId, apiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [status] 
         * @param {string} [transactionNumber] 
         * @param {string} [shopId] 
         * @param {boolean} [unreviewedOnly] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWithdrawGet(status?: number, transactionNumber?: string, shopId?: string, unreviewedOnly?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCashoutsCashoutRowDTO> {
            return localVarFp.apiV1AdminWithdrawGet(status, transactionNumber, shopId, unreviewedOnly, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} withdrawId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWithdrawRejectWithdrawIdPut(withdrawId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminWithdrawRejectWithdrawIdPut(withdrawId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} withdrawId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AdminWithdrawReviewWithdrawIdPut(withdrawId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1AdminWithdrawReviewWithdrawIdPut(withdrawId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} withdrawId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WithdrawCancelWithdrawIdPut(withdrawId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1WithdrawCancelWithdrawIdPut(withdrawId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WithdrawFeeGet(options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsFinancialCashoutsWithdrawFeeDTO> {
            return localVarFp.apiV1WithdrawFeeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FinancialsCommonCashierEnumsWithdrawStatus} [status] 
         * @param {string} [transactionNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WithdrawGet(status?: FinancialsCommonCashierEnumsWithdrawStatus, transactionNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsFinancialCashoutsShopCashoutRowDTO> {
            return localVarFp.apiV1WithdrawGet(status, transactionNumber, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO} [apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WithdrawPost(apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO?: ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV1WithdrawPost(apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WithdrawApi - object-oriented interface
 * @export
 * @class WithdrawApi
 * @extends {BaseAPI}
 */
export class WithdrawApi extends BaseAPI {
    /**
     * 
     * @param {string} withdrawId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApi
     */
    public apiV1AdminWithdrawAcceptWithdrawIdPut(withdrawId: string, options?: AxiosRequestConfig) {
        return WithdrawApiFp(this.configuration).apiV1AdminWithdrawAcceptWithdrawIdPut(withdrawId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} withdrawId 
     * @param {ApiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO} [apiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApi
     */
    public apiV1AdminWithdrawDoneWithdrawIdPut(withdrawId: string, apiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO?: ApiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO, options?: AxiosRequestConfig) {
        return WithdrawApiFp(this.configuration).apiV1AdminWithdrawDoneWithdrawIdPut(withdrawId, apiGiftGatewayCommonDTOsFinancialWithdrawDoneWithdrawDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [status] 
     * @param {string} [transactionNumber] 
     * @param {string} [shopId] 
     * @param {boolean} [unreviewedOnly] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApi
     */
    public apiV1AdminWithdrawGet(status?: number, transactionNumber?: string, shopId?: string, unreviewedOnly?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return WithdrawApiFp(this.configuration).apiV1AdminWithdrawGet(status, transactionNumber, shopId, unreviewedOnly, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} withdrawId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApi
     */
    public apiV1AdminWithdrawRejectWithdrawIdPut(withdrawId: string, options?: AxiosRequestConfig) {
        return WithdrawApiFp(this.configuration).apiV1AdminWithdrawRejectWithdrawIdPut(withdrawId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} withdrawId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApi
     */
    public apiV1AdminWithdrawReviewWithdrawIdPut(withdrawId: string, options?: AxiosRequestConfig) {
        return WithdrawApiFp(this.configuration).apiV1AdminWithdrawReviewWithdrawIdPut(withdrawId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} withdrawId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApi
     */
    public apiV1WithdrawCancelWithdrawIdPut(withdrawId: string, options?: AxiosRequestConfig) {
        return WithdrawApiFp(this.configuration).apiV1WithdrawCancelWithdrawIdPut(withdrawId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApi
     */
    public apiV1WithdrawFeeGet(options?: AxiosRequestConfig) {
        return WithdrawApiFp(this.configuration).apiV1WithdrawFeeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FinancialsCommonCashierEnumsWithdrawStatus} [status] 
     * @param {string} [transactionNumber] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApi
     */
    public apiV1WithdrawGet(status?: FinancialsCommonCashierEnumsWithdrawStatus, transactionNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return WithdrawApiFp(this.configuration).apiV1WithdrawGet(status, transactionNumber, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO} [apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApi
     */
    public apiV1WithdrawPost(apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO?: ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO, options?: AxiosRequestConfig) {
        return WithdrawApiFp(this.configuration).apiV1WithdrawPost(apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



