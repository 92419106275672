import React from "react"
import { Tab, Tabs } from "react-bootstrap"
import AdminShopsList from "./AdminShops/AdminShopsList"
import IState from "./AdminShops/AdminShopsList.models"
import { ShopStatusEnum } from "../../shops/ShopStatusEnum"
import { Container } from "@mui/material"

export default class AdminShops extends React.Component<{}, IState> {
    constructor(props: any) {
        super(props)
        this.state = { isLoading: false }
    }

    render(): React.ReactNode {
        return (
            <Container className="pt-xl">
                <Tabs id="shops-tabs" defaultActiveKey="active" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                    <Tab eventKey="active" title="Active">
                        <AdminShopsList status={ShopStatusEnum.Accepted} />
                    </Tab>
                    <Tab eventKey="pending" title="Pending">
                        <AdminShopsList status={ShopStatusEnum.Pending} />
                    </Tab>
                </Tabs >
            </Container>
        )
    }
}
