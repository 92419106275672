import React from 'react';
import { Alert, Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import IState, { IProps } from './AdminMakeDecisionModal.models';
import FormatMoney from '../../../utilities/FormatMoney';
import ValidAmount from '../../../utilities/Amount/ValidAmount';
import OrdersService from '../../../../services/orders/OrdersService';
import MediationService, { CorrectnessResults } from '../../../../services/admin/orders/AdminMediationService';
import { NotificationManager } from 'react-notifications';
import AdminReviewRequestService from '../../../../services/admin/review-request/AdminReviewRequestService';
import AdminWalletsServices from '../../../../services/admin/financials/AdminWalletsServices';
import ModalComponent from '../../../shared/modal/Modal';
import SpinnerComponent from '../../../shared/spinner/SpinnerComponent';
import { CircularProgress } from '@mui/material';
import { CustomButton } from '../../../shared/button/ButtonComponent';

export default class AdminMakeDecisionModal extends React.Component<IProps, IState> {
    ordersService: OrdersService
    mediationService: MediationService
    reviewRequestService: AdminReviewRequestService
    adminWalletsServices: AdminWalletsServices
    constructor(props: any) {
        super(props)
        this.ordersService = new OrdersService();
        this.mediationService = new MediationService();
        this.reviewRequestService = new AdminReviewRequestService();
        this.adminWalletsServices = new AdminWalletsServices();

        this.state = {
            buyerLossAmount: '0', sellerLossAmount: '0', loading: false,
            sellerWallets: [], buyerWallets: [], correctnessResult: CorrectnessResults.Seller
        }
    }

    componentDidMount(): void {
        this.adminWalletsServices.getPaged({ ownerId: this.props.sellerId }, { pagesize: 10, pageskip: 1 }).then((response) => {
            this.setState({ sellerWallets: response.rows })
        })
        this.adminWalletsServices.getPaged({ ownerId: this.props.buyerId }, { pagesize: 10, pageskip: 1 }).then((response) => {
            this.setState({ buyerWallets: response.rows })
        })
    }

    submit = () => {
        let { buyerLossAmount, sellerLossAmount } = this.state
        if (this.state.correctnessResult == CorrectnessResults.Both && (Number(buyerLossAmount) + Number(sellerLossAmount)) != this.props.sellPrice) return NotificationManager.error('Enter the loss amount correctly.');
        if (window.confirm('Are you sure you want to make this decision?')) {
            this.setState({ loading: true })


            this.mediationService.create({
                buyerLossAmount: Number(buyerLossAmount), sellerLossAmount: Number(sellerLossAmount),
                orderProductId: this.props.orderProductId, ticketId: this.props.ticketId, correctnessResult: this.state.correctnessResult
            }).then(() => {
                this.props.onSubmit && this.props.onSubmit()
                this.props.onClose()

            }).finally(() => this.setState({ loading: false }))
        }
    }

    render(): React.ReactNode {
        const { buyerLossAmount, sellerLossAmount, loading } = this.state
        const { sellPrice } = this.props
        const sellerWalletBalance = this.state.sellerWallets.filter(wallet => wallet.currencyTitle == 'USD')[0];

        var validation = true;

        if (this.state.correctnessResult == CorrectnessResults.Both)
            validation = (Number(buyerLossAmount) + Number(sellerLossAmount)) == sellPrice;

        return <>
            <ModalComponent title={"Make Decision"} size="800px"
                onClose={this.props.onClose}
            >
                <Form>
                    <div>
                        {
                            loading || !sellerWalletBalance ? <div className="text-center text-muted p-2"><SpinnerComponent /></div> :
                                <>
                                    {
                                        this.props.alreadyDecided ?
                                            <Alert variant="warning">It has already been decided</Alert> :
                                            <>
                                                <Row>
                                                    <Col md="12" sm="12">
                                                        <div> Sell Price: <b>{sellPrice && FormatMoney(sellPrice)}</b></div>
                                                    </Col>
                                                </Row>
                                                <div className='mt-4'>

                                                    Choose the culprit from the seller and the buyer of this order:
                                                    <Row>
                                                        <Col md="12" sm="12">
                                                            <Form.Check name="CorrectnessResults" inline checked={this.state.correctnessResult == CorrectnessResults.Seller}
                                                                onChange={(e) => this.setState({ correctnessResult: CorrectnessResults.Seller })}
                                                                style={this.state.correctnessResult == CorrectnessResults.Seller ? { color: 'red' } : {}}
                                                                type="radio" value={CorrectnessResults.Seller} label="Seller" />

                                                            <Form.Check name="CorrectnessResults" inline checked={this.state.correctnessResult == CorrectnessResults.Buyer}
                                                                onChange={(e) => this.setState({ correctnessResult: CorrectnessResults.Buyer })}
                                                                style={this.state.correctnessResult == CorrectnessResults.Buyer ? { color: 'red' } : {}}
                                                                type="radio" value={CorrectnessResults.Buyer} label="Buyer" />

                                                            <Form.Check name="CorrectnessResults" inline checked={this.state.correctnessResult == CorrectnessResults.Both}
                                                                style={this.state.correctnessResult == CorrectnessResults.Both ? { color: 'red' } : {}}
                                                                onChange={(e) => this.setState({ correctnessResult: CorrectnessResults.Both })}
                                                                type="radio" value={CorrectnessResults.Both} label="Both" />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                {
                                                    this.state.correctnessResult == CorrectnessResults.Both && <div>
                                                        <Row className='mt-4'>
                                                            <Col md="6" sm="12" >
                                                                <Form.Label> Refund To Buyer: </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    inputMode='decimal'
                                                                    value={sellerLossAmount}
                                                                    onChange={(e) => (ValidAmount(e.target.value) && Number(e.target.value) <= sellPrice) && this.setState({ sellerLossAmount: e.target.value, buyerLossAmount: String(sellPrice - Number(e.target.value)) })}
                                                                />
                                                            </Col>
                                                            <Col md="6" sm="12">
                                                                <Form.Label> Seller: </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    inputMode='decimal'
                                                                    value={buyerLossAmount}
                                                                    onChange={(e) => (ValidAmount(e.target.value) && Number(e.target.value) <= sellPrice) && this.setState({ buyerLossAmount: e.target.value, sellerLossAmount: String(sellPrice - Number(e.target.value)) })}
                                                                />

                                                            </Col>
                                                        </Row>
                                                        <div style={{
                                                            background: '#eee', padding: '5px', borderRadius: '5px',
                                                            display: 'inline-block', color: '#666666', cursor: 'pointer'
                                                        }} onClick={() => this.setState({ sellerLossAmount: String(sellPrice / 2), buyerLossAmount: String(sellPrice / 2) })}>50%-50%</div>
                                                    </div>
                                                }
                                                {
                                                    validation && <div className='pt-2 font-bold'>
                                                        {
                                                            this.state.correctnessResult == CorrectnessResults.Buyer && <ul>
                                                                <li>There will be no transaction.</li>
                                                                <li>Seller and Buyer Hold money will be release now.</li>
                                                                <li>Mediation ended.</li>
                                                            </ul>
                                                        }
                                                        {
                                                            this.state.correctnessResult == CorrectnessResults.Seller && <ul>
                                                                {<li>The <span className='text-danger'>full amount</span> will be transferred from the seller's wallet to the buyer's wallet.</li>}
                                                                {<li>Buyer Hold money will be release now.</li>}
                                                                {<li>Seller Hold money will be release after refund money to buyer (auto).</li>}
                                                                <li>Mediation ended.</li>
                                                            </ul>
                                                        }
                                                        {
                                                            this.state.correctnessResult == CorrectnessResults.Both && <ul>
                                                                {<li><span className='text-danger'>{FormatMoney(Number(sellerLossAmount))}</span> is transferred from the seller's wallet to the buyer's wallet.</li>}
                                                                <li>Buyer Hold money will be release now.</li>
                                                                <li>Seller Hold money will be release after refund money to buyer (auto).</li>
                                                                <li>Mediation ended.</li>
                                                            </ul>
                                                        }
                                                    </div>
                                                }
                                                {
                                                    sellerWalletBalance.balance < Number(this.state.sellerLossAmount) && <div style={{
                                                        background: 'rgb(247 213 213)',
                                                        color: '#bb2c2c',
                                                        padding: '5px 10px',
                                                        border: '1px solid #ffb9b9',
                                                        borderRadius: '3px',
                                                        marginTop: '5px'
                                                    }}>
                                                        The balance of the seller's wallet is not enough to return the amount! (balance: {sellerWalletBalance.balance})<br />
                                                        Making any amount transfer depends on the balance of the wallet, as soon as the balance in the desired wallet is available, the transaction will be done. (This process will be automatic and no further follow-up is required!)
                                                    </div>
                                                }

                                            </>
                                    }
                                </>
                        }
                    </div>
                    {
                        !this.props.alreadyDecided &&
                        <ModalComponent.Footer>
                            {loading ? <CustomButton variant='contained' disabled><CircularProgress color='inherit' size={20} /></CustomButton> :
                                <CustomButton variant='contained' disabled={!validation} onClick={this.submit}>Make</CustomButton>}
                        </ModalComponent.Footer>
                    }
                </Form>
            </ModalComponent>
        </>
    }
}