import React, { RefObject } from "react"
import { GridAction, GridColumn, GridColumnTypes } from "../../../Grid/Grid.models"
import DynamicGrid from "../../../shared/DynamicGrid/DynamicGrid"
import IState, { IAdminCreditsFilters, IProps } from "./AdminCredits.models"
import AdminCreditsService from "../../../../services/admin/financials/AdminCreditsService"
import { Button, Tab, Tabs } from "react-bootstrap"
import AddCreditModal from "./Add/AddCredit"
import { CustomButton } from "../../../shared/button/ButtonComponent"

export default class AdminCredits extends React.PureComponent<IProps, IState> {
    adminCreditsService: AdminCreditsService
    dynamicGrid: RefObject<DynamicGrid<IAdminCreditsFilters>>;
    constructor(props: any) {
        super(props);
        this.dynamicGrid = React.createRef()
        this.adminCreditsService = new AdminCreditsService()
        this.state = { pendingRequest: false, showAddCreditModal: false }
    }

    takeCredit = (id) => {
        if (window.confirm('This credit is taken from the wallet. are you sure?'))
            this.adminCreditsService.archive(id).then((response) => {
                this.dynamicGrid.current.fetch()
            }).finally(() => { this.dynamicGrid.current.resetSpinner() })
    }

    reload = () => this.dynamicGrid.current.fetch()

    render(): React.ReactNode {
        let { showAddCreditModal } = this.state
        let { isNegativeCredit } = this.props

        let gridColumns: GridColumn[] = [
            { title: 'Owner', key: 'ownerTitle' },
            { title: 'Amount', key: 'amountValue', type: GridColumnTypes.Amount },
            { title: 'Create At', key: 'createDate', type: GridColumnTypes.Date },
        ]

        var gridActions: GridAction[] = [{ caption: 'Take', variant: 'outline-danger', onClick: (row) => this.takeCredit(row.id) }]

        return (<>
            {showAddCreditModal && <AddCreditModal reload={this.reload} isNegativeCredit={this.props.isNegativeCredit} onClose={() => this.setState({ showAddCreditModal: false })} />}
            <div style={{ textAlign: 'end' }}>
                <CustomButton onClick={() => this.setState({ showAddCreditModal: true })} variant="contained">{isNegativeCredit ? 'Add Negative Credit' : 'Add New Credit'} </CustomButton>
            </div>
            <Tabs defaultActiveKey={'active'} id="orders-tabs" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                <Tab eventKey="active" title="Active">
                    <DynamicGrid<IAdminCreditsFilters> ref={this.dynamicGrid}
                        columns={gridColumns}
                        onFetch={this.adminCreditsService.getPaged}
                        hideFilterBar
                        defaultFilters={{ isActive: true, negativeCreditsOnly: this.props.isNegativeCredit }}
                        actions={gridActions}
                    />
                </Tab>
                <Tab eventKey="archive" title="Archive">
                    <DynamicGrid<IAdminCreditsFilters> ref={this.dynamicGrid}
                        columns={gridColumns}
                        onFetch={this.adminCreditsService.getPaged}
                        hideFilterBar
                        defaultFilters={{ isActive: false, negativeCreditsOnly: this.props.isNegativeCredit }}
                        actions={gridActions}
                    />
                </Tab>
            </Tabs>
        </>)
    }
}