import { Box, Modal, Typography } from '@mui/material';
import Close from '../../../assets/icons/content/close.svg';

export default function ModalComponent(props: { borderLess?: boolean, size: string, title?: any, onClose: () => void, children?: any, styles?: any }) {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#F6F6F6',
        boxShadow: 0,
        border: '0 solid',
        borderRadius: '32px',
        maxWidth: '100%',
        maxHeight: '90vh',
        pt: 2,
        pb: 3,
        '@media (max-width: 900px)': {
            top: 'unset',
            bottom: 0,
            left: 0,
            right: 0,
            transform: 'unset',
            borderRadius: '32px 32px 0 0',
            height: 'auto',
            paddingBottom: '5vh'
        },
    };

    return (<Modal
        open
        disableEnforceFocus
        disableAutoFocus
        disableRestoreFocus
        disablePortal
        onClose={props.onClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
    >
        <Box sx={{ ...style, ...props.styles, width: props.size }}>
            <Box sx={{ borderBottom: props.borderLess ? 'unset' : '1px solid #E3E3E3' }} className='px-md pb-md flex justify-between items-center'>
                {props.title && <Typography sx={{ fontSize: { md: '20px', sm: '16px' } }}><span className='font-bold text-black'>{props.title}</span></Typography>}
                <div onClick={props.onClose} className='pointer'><img src={Close} /></div>
            </Box>
            <Box className="px-md pt-md" sx={{
                maxHeight: '70vh', overflowY: 'auto', paddingBottom: '1vh',
                '@media (max-width: 900px)': {
                    paddingBottom: '15vh'
                },
            }}>
                {props.children}
            </Box>
        </Box>
    </Modal>)
}

function Header(props: { children?: any }) {
    return (
        <div className="py-md">
            {props.children}
        </div>
    )
}

function Footer(props: { children?: any }) {
    return (
        <div className='flex justify-end pt-md'>
            {props.children}
        </div>
    )
}

ModalComponent.Header = Header
ModalComponent.Footer = Footer