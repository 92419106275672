import React from 'react';
import { Button, Form, Spinner, Row, Col } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import { LocalCurrencyService } from '../../../../services/financials/LocalCurrencyService';
import Note from '../../../shared/Note/Note';
import IState, { IProps } from './SetLocalCurrencyModal.models';
import { CircularProgress, Grid } from '@mui/material';
import { CustomButton } from '../../../shared/button/ButtonComponent';

export default class SetLocalCurrencyModal extends React.Component<IProps, IState> {
    localCurrencyService: LocalCurrencyService;
    constructor(props: any) {
        super(props)
        this.state = { pendingRequest: false, currencies: [], currencyId: '' }
        this.localCurrencyService = new LocalCurrencyService()
    }

    componentDidMount() {
        this.getCurrencyTitleValues();
    }

    getCurrencyTitleValues = () => {
        this.localCurrencyService.getTitleValues().then((response) => {
            this.setState({ currencies: response, currencyId: this.props.currentCurrencyId ?? response[0].value })
        })
    }

    submit = () => {
        this.setState({ pendingRequest: true })
        this.localCurrencyService.setLocalCurrency(this.props.shopId, this.state.currencyId).then(() => {
            NotificationManager.success('Your shop Local currency updated successfully.')
            this.props.onSubmit && this.props.onSubmit()
        }).finally(() => {
            this.setState({ pendingRequest: false })
        }).catch(() => {
            NotificationManager.error('An error has occurred.')
        })
    }

    render(): React.ReactNode {
        const { pendingRequest, currencyId, currencies } = this.state

        return <div>
            <div className='font-bold font-md'>{this.props.currentCurrencyId ? 'Edit Local Currency' : 'Set Local Currency'}</div>
            <Note>Choose your local currency and make your financial calculations more accurate.</Note>
            <div className='bg-white radius-lg pa-md mt-md'>
                <Grid spacing={1} container>
                    <Grid md={6} sm={6} item>
                        <Form.Select aria-label=">Select Local currency" id="currencyId" value={currencyId}
                            onChange={(e) => this.setState({ currencyId: e.target.value })}>
                            {
                                currencies.map((item) => <option value={item.value} key={item.value}>{item.title}</option>)
                            }
                        </Form.Select>
                    </Grid>
                    <Grid md={12} sm={12} item mt={2}>
                        <Note>If your local currency is USD or you don't want to use local currency now then set your local currency on USD</Note>
                    </Grid>
                    <Grid md={12} sm={12} item>
                        <div className='flex justify-end'>
                            {pendingRequest ? <CustomButton variant={'contained'} className="mt-4" disabled><CircularProgress color='inherit' size={20} /></CustomButton> :
                                <CustomButton variant={'contained'} onClick={this.submit} className="mt-4">{this.props.currentCurrencyId ? 'Edit' : 'Set Local Currency'}</CustomButton>}
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    }
}