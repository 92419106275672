import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { NotificationPermissionRowDto, UpdateNotificationPermission } from "../../../../services/notifications/NotificationPermissionService";
import { NotificationManager } from 'react-notifications';
import AdminNotificationPermissionService from "../../../../services/admin/notifications/AdminNotificationPermissionService";
import NotificationPermissionsList from "../../../notifications/permissions/PermissionsList/NotificationPermissionsList";
import SpinnerComponent from "../../../shared/spinner/SpinnerComponent";

interface INotificationPermissaionState {
    pendingRequest: boolean
    loading: boolean
    isActiveAll: boolean
    permissions: NotificationPermissionRowDto[]
}

export default class AdminNotificationPermissions extends React.PureComponent<{}, INotificationPermissaionState> {
    notificationPermissionService: AdminNotificationPermissionService
    constructor(props: any) {
        super(props);
        this.state = { pendingRequest: false, permissions: [], loading: false, isActiveAll: false };
        this.notificationPermissionService = new AdminNotificationPermissionService();
    }

    componentDidMount(): void {
        this.setState({ loading: true })

        this.notificationPermissionService.get()
            .then((response) => { this.setState({ permissions: response }) })
            .finally(() => { this.setState({ loading: false }) })
    }

    submit = (newPermissions: NotificationPermissionRowDto[]) => {
        this.setState({ pendingRequest: true });

        let dto: UpdateNotificationPermission[] = newPermissions.map((permission) => {
            return {
                isActive: permission.isActive,
                permissionId: permission.id
            }
        })

        this.notificationPermissionService.update(dto).then(() => {
            NotificationManager.success('Permissions updates successfully.')
        }).finally(() => {
            this.setState({ pendingRequest: false })
        })
    }

    render(): React.ReactNode {
        return (
            <div className="pb-md mt-md">
                <div className="font-bold font-md">Email Notifications Settings</div>
                <Row>
                    <Col md="12" sm="12">
                        <div className="sub-title">Manage and customize email notification for different features.</div>
                    </Col>
                    {this.state.loading ? <SpinnerComponent /> : <NotificationPermissionsList pendingRequest={this.state.pendingRequest} onSubmit={this.submit} permissions={this.state.permissions} />}
                </Row>
            </div>
        )
    }
}