import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import CreateShop from "../CreateShop/CreateShop";
import ShopMemberList from "../ShopMember/ShopMemberList/ShopMemberList";
import ShopMemberInvitations from "../ShopMember/ShopMemberInvitationList/ShopMemberInvitations";
import { Container } from "@mui/material";

export default class ShopManagement extends React.Component<{ defaultActiveKey?: string | null }, { shopId: string }> {
    /**
     *
     */
    constructor(props: any) {
        super(props);
        this.state = { shopId: localStorage.getItem('activeshop') }
    }
    render(): React.ReactNode {
        let defaultKey = this.props.defaultActiveKey
        return <Container className="pt-xl">
            <Tabs defaultActiveKey={!defaultKey ? 'members' : defaultKey} id="management-tabs" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                <Tab eventKey="members" title="Members">
                    <Tabs defaultActiveKey="pending" id="pending-member-tabs" mountOnEnter={true} unmountOnExit={true}>
                        <Tab eventKey="pending" title="Pending">
                            <ShopMemberInvitations />
                        </Tab>
                        <Tab eventKey="accepted" title="Accepted">
                            <ShopMemberList />
                        </Tab>
                    </Tabs>
                </Tab>
                <Tab eventKey="shopDetails" title="Shop Details">
                    <CreateShop shopeId={this.state.shopId} />
                </Tab>
            </Tabs>
        </Container>
    }
}