import './App.scss';
import './assets/css/typography.scss';
import './assets/css/common.scss';
import AuthenticationContext, { IAuthenticationContext } from './contexts/AuthenticationContext/AuthenticationContext';
import axios from 'axios';
import Routing from './routes/Routing';
import { AxiosConfig } from './components/utilities/AxiosConfig';
import { API_URL, GOOGLE_ANALYTICS_ID } from './constants/Constants';
import ReactGA from "react-ga4";
import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import getPalette from './composables/configs/ThemConfig';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';

if (GOOGLE_ANALYTICS_ID)
  ReactGA.initialize(GOOGLE_ANALYTICS_ID);

document.addEventListener("wheel", function (event) {
  if ((document.activeElement as any).type === "number") {
    (document.activeElement as any).blur();
  }
});

function App() {
  const authenticationContext: IAuthenticationContext = {
    twoFactorAvtive: false,
    privileges: [],
    policies: [],
    roles: [],
    role: localStorage.getItem('role') || 'buyer',
    user: { displayName: '', userName: '' },
    isAuthentication: () => localStorage.getItem('token') !== undefined && localStorage.getItem('token') !== null && localStorage.getItem('token') !== '',
    login: (token: string) => {
      localStorage.setItem('token', token);
      axios.defaults.headers.common['authorization'] = `Bearer ${token}`;
    },
    logout: () => {
      localStorage.removeItem('token');
      localStorage.removeItem('activeshop');
      delete axios.defaults.headers.common['authorization'];
    }
  };

  AxiosConfig(API_URL);

  const theme = React.useMemo(() => createTheme(getPalette()), [])

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AuthenticationContext.Provider value={authenticationContext}>
          <NotificationContainer />
          <Routing />
        </AuthenticationContext.Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;
