import React from "react";
import './OwnedOffersBoard.scss'
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import OffersService, { OwnedOfferDto } from "../../../../services/orders/OffersService";
import { NotificationManager } from 'react-notifications';
import { Link } from "react-router-dom";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { CustomButton } from "../../../shared/button/ButtonComponent";

interface IProps {
    categoryId?: string
}

export default class OwnedOffersBoard extends React.Component<IProps, { offers: OwnedOfferDto[] }> {
    offersService: OffersService;
    constructor(props: any) {
        super(props);
        this.offersService = new OffersService();
        this.state = { offers: [] }
    }

    componentDidMount(): void {
        this.loadBoard();
    }

    loadBoard = () => {
        this.offersService.getActiveOffers(this.props.categoryId).then((response) => {
            this.setState({ offers: response.rows })
        });
    }


    componentDidUpdate(prevProps: IProps) {
        if ((prevProps.categoryId !== this.props.categoryId))
            this.loadBoard();
    }

    cancelOffer = (id: string) => {
        if (window.confirm('Are you sure your offer will be cancelled?'))
            this.offersService.cancel(id).then((response) => {
                NotificationManager.success('Your offer canceled')
                this.loadBoard();
            });
    }
    render(): React.ReactNode {

        return <div className="OwnedOffersBoard">
            <div className="font-bold font-lg">Your Active Offers</div>
            <span className="text-charcoal">Your offers pending and show for all sellers. also you can see all your offers <Link to="/orders/buy/?tab=offers">here</Link></span>

            <div className="mt-lg mb-lg">
                <TableContainer className="grid">
                    <Table sx={{ minWidth: 320 }} aria-label="table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                Product Title
                                            </Tooltip>
                                        }
                                    >
                                        <span>Product</span>
                                    </OverlayTrigger>
                                </TableCell>
                                <TableCell>
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                Quantity item in offers
                                            </Tooltip>
                                        }>
                                        <span>
                                            Quantity
                                        </span>
                                    </OverlayTrigger>
                                </TableCell>
                                <TableCell>
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                Accepted Count item in offers
                                            </Tooltip>
                                        }>
                                        <span>
                                            Accepted
                                        </span>
                                    </OverlayTrigger>
                                </TableCell>
                                <TableCell>
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                Your maximum price in this offer (don't share with seller)
                                            </Tooltip>
                                        }>
                                        <span>
                                            Max Price
                                        </span>
                                    </OverlayTrigger>
                                </TableCell>
                                <TableCell>
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                Offer exipre at this time
                                            </Tooltip>
                                        }>
                                        <span>
                                            Expire At
                                        </span>
                                    </OverlayTrigger>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.state.offers.length == 0 ? <TableRow>
                                    <TableCell colSpan={5} style={{ textAlign: 'center' }}>offer not registered</TableCell>
                                </TableRow> :
                                    this.state.offers.map(offer => <TableRow>
                                        <TableCell style={{ color: '#198754' }}>

                                            {offer.productTitle}
                                        </TableCell>
                                        <TableCell>
                                            {offer.quantity}
                                        </TableCell>
                                        <TableCell>
                                            {offer.acceptedCount}
                                        </TableCell>
                                        <TableCell>
                                            {offer.price}
                                        </TableCell>
                                        <TableCell>
                                            {offer.expireAt ?? '-'}
                                        </TableCell>
                                        <TableCell>
                                            <CustomButton variant="text" color="error" size="small" onClick={() => this.cancelOffer(offer.id)}>Cancel</CustomButton>
                                        </TableCell>
                                    </TableRow>)
                            }

                        </TableBody>
                    </Table >
                </TableContainer>
            </div>
        </div>
    }
}