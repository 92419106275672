import { AxiosError } from "axios";
import React from "react";
import { NavLink } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import authenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext";
import IdentityService from "../../../services/identity/IdentityService";
import '../IdentityLayout.scss'
import ShopService from "../../../services/shops/ShopService";
import { CircularProgress, Grid, TextField } from "@mui/material";
import { CustomButton } from "../../shared/button/ButtonComponent";
import { Eye, EyeSlash } from "react-bootstrap-icons";

export default class SignUp extends React.Component<{}, State> {
    identityService: IdentityService;
    shopService: ShopService;
    hostUrl: string;
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;

    constructor(props: any) {
        super(props);
        this.state = { showPassword: false, showRepeatPassword: false, email: '', pendingRequest: false, password: '', repeatPassword: '', firstname: '', lastname: '', isRegistred: false, loading: false, logged: false }
        this.identityService = new IdentityService();
        this.shopService = new ShopService();
        this.hostUrl = window.location.protocol + "//" + window.location.host;
    }

    register = () => {
        if (this.state.firstname.length === 0 || this.state.lastname.length === 0 || this.state.password.length === 0 || this.state.email.length === 0) {
            NotificationManager.error('All fields are required.');
            return;
        }

        if (this.state.password.length === 0) {
            NotificationManager.error('Password is required');
            return;
        }
        if (this.state.password !== this.state.repeatPassword) {
            NotificationManager.error('Your password is not equal to repeating it');
            return;
        }

        if (this.state.password.length < 8) {
            NotificationManager.error('Passowrd should have minimum 8 characters');
            return;
        }

        this.setState({ loading: true });
        this.identityService.register({
            email: this.state.email,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            password: this.state.password
        }, `${this.hostUrl}/registerConfirmed`).then(() => {
            NotificationManager.success('Your account has been created successfully.');
            this.identityService.login(this.state.email, this.state.password).then((response) => {

                this.context.login(response.data.accessToken);
                this.shopService.create({ title: this.state.firstname + this.state.lastname, email: this.state.email }).then(() => {
                    this.setState({ logged: true });
                }).finally(() => {
                    this.setState({ loading: false });
                })
            });
        }).catch((data: AxiosError) => {
            var responseErrors = data.response.data.errors;
            if (responseErrors) {
                var errors = [];
                for (var error of Object.keys(responseErrors).map((key) => responseErrors[key]))
                    errors.push(error);
                NotificationManager.error(errors[0][0]);
            }
            else
                NotificationManager.error('This email has already been registered.');
            this.setState({ loading: false });

        })
    }

    render(): React.ReactNode {
        let { email, firstname, pendingRequest, showPassword, showRepeatPassword, isRegistred, lastname, loading, logged, password, repeatPassword } = this.state

        return <div className="full-width">
            <div className="font-lg font-bold text-charcoal text-center web">Register</div>
            <div className="mini-border-line web"></div>
            <form onSubmit={this.register}>
                <Grid container mt={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} mb={3} sx={{ pr: { xs: 0, md: 1 } }}>
                        <label className="pb-sm font-sm text-charcoal-dark">First Name</label>
                        <TextField
                            fullWidth
                            id="firstname"
                            className="text-feild-filled"
                            placeholder="Enter your first name"
                            variant="outlined"
                            value={firstname}
                            error={firstname === ""}
                            onChange={(e) => this.setState({ firstname: e.target.value })}
                        />

                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} mb={3} sx={{ pl: { xs: 0, md: 1 } }}>
                        <label className="pb-sm font-sm text-charcoal-dark">Last Name</label>
                        <TextField
                            fullWidth
                            id="email"
                            className="text-feild-filled"
                            placeholder="Enter your last name"
                            variant="outlined"
                            value={lastname}
                            error={lastname === ""}
                            onChange={(e) => this.setState({ lastname: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} mb={3}>
                        <label className="pb-sm font-sm text-charcoal-dark">Email address</label>
                        <TextField
                            fullWidth
                            id="email"
                            className="text-feild-filled"
                            placeholder="Enter email address"
                            variant="outlined"
                            value={email}
                            error={email === ""}
                            onChange={(e) => this.setState({ email: e.target.value })}
                        />

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} mb={4}>
                        <div className="flex justify-between">
                            <label className="pb-sm font-sm text-charcoal-dark">Password</label>
                            <div>{showPassword ? <EyeSlash /> : <Eye />}</div>
                        </div>
                        <TextField
                            fullWidth
                            id="password"
                            className="text-feild-filled"
                            placeholder="Enter password"
                            type={showPassword ? "text" : "password"}
                            value={password}
                            error={password === ""}
                            onChange={(e) => this.setState({ password: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} mb={4}>
                        <div className="flex justify-between">
                            <label className="pb-sm font-sm text-charcoal-dark">Repeat Password</label>
                            <div>{showPassword ? <EyeSlash /> : <Eye />}</div>
                        </div>
                        <TextField
                            fullWidth
                            id="password"
                            className="text-feild-filled"
                            placeholder="Enter password"
                            type={showRepeatPassword ? "text" : "password"}
                            value={repeatPassword}
                            error={repeatPassword === ""}
                            onChange={(e) => this.setState({ repeatPassword: e.target.value })}
                        />
                    </Grid>
                </Grid>
                {
                    pendingRequest ?
                        <CustomButton sx={{ borderRadius: "12px", backgroundColor: '#0E3FC4'}} variant="contained" disabled fullWidth>
                            <CircularProgress color="inherit" size={20} />
                        </CustomButton> :
                        <CustomButton sx={{ borderRadius: "12px" }} variant="contained" fullWidth onClick={() => this.register()}>Sign Up</CustomButton>
                }
                <div className="pt-md">
                    <NavLink to={'/sign-in'} className="font-sm">Have you registered?</NavLink>
                </div>
            </form>
        </div>
    }
}

interface State {
    firstname: string,
    lastname: string,
    email: string,
    password: string,
    repeatPassword: string,
    isRegistred: boolean,
    loading: boolean,
    logged: boolean,
    pendingRequest: boolean
    showPassword: boolean
    showRepeatPassword: boolean
}