import React from "react";
import {
    Button
} from "react-bootstrap";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";
import { NavLink } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import AdminArticleCategoryService from "../../../../services/admin/articles/AdminArticleCategoryService";
import IState, { IArticleCategory } from "./AdminArticleCategoty.models";
import { CustomButton } from "../../../shared/button/ButtonComponent";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

export default class AdminArticleCategory extends React.Component<{}, IState> {
    adminArticleCategoryService: AdminArticleCategoryService
    constructor(props: any) {
        super(props);
        this.state = { articleCategories: [], loading: false };
        this.adminArticleCategoryService = new AdminArticleCategoryService()
    }

    componentDidMount() {
        this.getColumns()
    }

    getColumns = () => {
        this.setState({ loading: true })
        this.adminArticleCategoryService.getPaged().then((response) => {

            let data: IArticleCategory[] = response.filter((articleCategory: IArticleCategory) => articleCategory.parentId == null).map(category => {
                return {
                    ...category,
                    isShow: false,
                    childs: response.filter(child => child.parentId == category.id).map(child => {
                        return {
                            ...child,
                            isShow: false,
                            childs: response.filter(item => item.parentId == child.id).map(item => {
                                return {
                                    ...item,
                                    isShow: false,
                                    childs: []
                                }
                            })
                        }
                    })
                }
            })
            this.setState({ articleCategories: data, loading: false })
        })
    }

    showChild = (index: number, childIndex?: number) => {
        let temp = this.state.articleCategories;

        if (childIndex != undefined)
            temp[index].childs[childIndex].isShow = !temp[index].childs[childIndex].isShow
        else
            temp[index].isShow = !temp[index].isShow

        this.setState({ articleCategories: temp })
        this.forceUpdate()
    }

    requestToRemoveRow = (id: string) => {
        if (window.confirm('Are you sure? This Article category will be deleted permanently')) {
            this.adminArticleCategoryService.delete(id).then(() => {
                NotificationManager.success("the article category deleted successfully")
                this.getColumns()
            })
        }
    }

    departmentStatusDisplay = (status: boolean) => {
        return <span>{status ? 'Active' : 'Inactive'}</span>
    }

    render(): React.ReactNode {
        return <>
            <NavLink to={'/admin/create-articleCategory'}><CustomButton variant="contained" className="mt-4 mb-4">Add</CustomButton></NavLink>
            <TableContainer className="grid">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Is Active</TableCell>
                            <TableCell>Parent Title</TableCell>
                            <TableCell>Order</TableCell>
                            <TableCell>Childs</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.articleCategories.map((articleCategory, index) => {
                            return (
                                <>
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{articleCategory.title}</TableCell>
                                        <TableCell>{this.departmentStatusDisplay(articleCategory.isActive)}</TableCell>
                                        <TableCell>{articleCategory.parentTitle}</TableCell>
                                        <TableCell>{articleCategory.orderId}</TableCell>
                                        <TableCell>{articleCategory.childs.length}</TableCell>
                                        <TableCell className="text-center" style={{ verticalAlign: 'middle' }}>
                                            <NavLink to={`/admin/create-articleCategory?articleCategoryId=${articleCategory.id}`}><Button className="mr-1" size="sm" variant="outline-warning">Edit</Button></NavLink>
                                            <Button className="mr-1" size="sm" variant="outline-danger" onClick={() => this.requestToRemoveRow(articleCategory.id)}>Delete</Button>
                                            {articleCategory.childs.length > 0 && (articleCategory.isShow ? <CaretUpFill onClick={() => this.showChild(index)} /> : <CaretDownFill onClick={() => this.showChild(index)} />)}
                                        </TableCell>
                                    </TableRow>
                                    {articleCategory.childs.map((child, childIndex) => {
                                        if (articleCategory.isShow)
                                            return (
                                                <>
                                                    <TableRow>
                                                        <TableCell>{ }</TableCell>
                                                        <TableCell>{child.title}</TableCell>
                                                        <TableCell>{this.departmentStatusDisplay(child.isActive)}</TableCell>
                                                        <TableCell>{child.parentTitle}</TableCell>
                                                        <TableCell>{child.orderId}</TableCell>
                                                        <TableCell>{child.childs.length}</TableCell>
                                                        <TableCell className="text-center" style={{ verticalAlign: 'middle' }}>
                                                            <NavLink className="mr-1" to={`/admin/category-manage?categoryId=${child.id}`}><CustomButton className="mr-1" variant="outlined" color="warning">Edit</CustomButton></NavLink>
                                                            <CustomButton variant="outlined" color="error" onClick={() => this.requestToRemoveRow(child.id)}>Delete</CustomButton>
                                                            {child.childs.length > 0 && (child.isShow ? <CaretUpFill onClick={() => this.showChild(index, childIndex)} /> : <CaretDownFill onClick={() => this.showChild(index, childIndex)} />)}
                                                        </TableCell>
                                                    </TableRow>
                                                    {child.childs.map(sub_child => {
                                                        if (child.isShow)
                                                            return (
                                                                <TableRow>
                                                                    <TableCell>{ }</TableCell>
                                                                    <TableCell>{sub_child.title}</TableCell>
                                                                    <TableCell>{this.departmentStatusDisplay(sub_child.isActive)}</TableCell>
                                                                    <TableCell>{sub_child.parentTitle}</TableCell>
                                                                    <TableCell>{sub_child.orderId}</TableCell>
                                                                    <TableCell>{sub_child.childs.length}</TableCell>
                                                                    <TableCell className="text-center" style={{ verticalAlign: 'middle' }}>
                                                                        <NavLink to={`/admin/category-manage?categoryId=${sub_child.id}`}><CustomButton className="mr-1" variant="outlined" color="warning">Edit</CustomButton></NavLink>
                                                                        <CustomButton className="mr-1" color="error" variant="outlined" onClick={() => this.requestToRemoveRow(sub_child.id)}>Delete</CustomButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                    })}
                                                </>
                                            )
                                    })}
                                </>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {this.state.articleCategories.length == 0 && <div className="d-flex justify-content-center">not exist any data.</div>}
        </>
    }
}
