import React from "react";
import { Spinner } from "react-bootstrap";
import { Box, Container, Grid } from "@mui/material";
import { CustomButton } from "../../shared/button/ButtonComponent";
import WalletService from "../../../services/financials/WalletService";
import CartService from "../../../services/shopping/CartService";
import { DepositApiFactory, PaymentApiFactory } from "../../../generated-api/financials";
import { CheckCircleFill } from "react-bootstrap-icons";

export default class PaymentResult extends React.Component<{ depositId }, { verified?: boolean, amount: number }> {
    walletService: WalletService;
    cartService: CartService;
    USDTCurrencyId: string = 'b8b8eaaa-c421-4783-b481-ae4fea6024de';
    cryptoAddress: string = 'TRQJm7PcwTSkcfjEdUjsx6TxU6xZ49QnFD';
    constructor(props: any) {
        super(props);
        this.state = { amount: 0 }
        this.walletService = new WalletService()
        this.cartService = new CartService()
    }

    componentDidMount() {
        PaymentApiFactory().apiV1PaymentIdPut(this.props.depositId).then(response => {
            this.setState({ verified: response.data.status === 3, amount: response.data.amount })
        })
    }

    render(): React.ReactNode {

        if (this.state.verified === undefined)
            return <Spinner animation="grow" />

        return <Container className="pt-xl">
            {this.state.verified ? <div style={{ textAlign: "center" }}>
                <CheckCircleFill style={{ color: 'green' }}/>
                <span className="font-lg font-bold d-block" style={{ color: 'green' }}>Payment Successful</span>
                We're sorry, but your payment could not be completed.<br />
                Please try again or contact our support team if the issue persists.
            </div> :
                <div style={{ textAlign: "center" }}>
                    <img src="/images/timer-reload-flat-icon.webp" alt="failed-request" className="product-image" style={{width:'64px',height:'64px'}} />
                    <span className="font-lg font-bold d-block" style={{ color: '#5fc6cb' }}>Waiting...</span>
                    Awaiting confirmation by the payment gateway.<br />
                </div>
            }
        </Container>
    }

    invoiceView = (orderTotal: number) => {
        return <>
            {/* <div className="pl-sm">
                <SwitchComponent handleChange={(event) => this.setState({ isPayFromWalletBalance: event.target.checked })}
                    checked={this.state.isPayFromWalletBalance} label="Wallet balance" />
            </div> */}
            <Box bgcolor="#FAFAFA" sx={{ boxShadow: '0px 2px 5px 0px #AAAAAA40', borderRadius: '12px' }} mt={2} p={1}>
                {
                    this.state.amount > 0 && <>
                        <div className="pt-md pb-sm font-medium text-grey-dark flex justify-between items-center">
                            <span>Base Amount</span>
                            <span className="font-bold">{this.state.amount} $</span>
                        </div>
                        {/* <div className="pb-md pt-sm font-medium text-grey-dark flex justify-between items-center">
                    <span>Wallet Balance</span>
                    <span className={walletBalance <= 0 && "text-grey-ligth"}>{walletBalance} $</span>
                </div> */}

                        <div className="pb-md font-medium text-grey-dark flex justify-between items-center">
                            <span>Api Gift Fee</span>
                            <span>+ {this.state.amount} $</span>
                        </div>
                    </>
                }

                <div className="pt-md pb-sm font-medium text-grey-dark flex justify-between items-center">
                    <span>Payable Amount</span>
                    <span className="font-bold">{this.state.amount} $</span>
                </div>
            </Box>
        </>
    }
}