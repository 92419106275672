import React from "react";
import { Form } from "react-bootstrap";
import { NotificationManager } from 'react-notifications';
import InventoryShopCollectionService from "../../../../services/inventory/InventoryShopCollectionService";
import IState, { IProps } from "./EditDeficitModal.models";
import ModalComponent from "../../../shared/modal/Modal";
import { CustomButton } from "../../../shared/button/ButtonComponent";
import { CircularProgress } from "@mui/material";

export class EditDeficitModal extends React.PureComponent<IProps, IState> {
    inventoryShopCollectionService: InventoryShopCollectionService
    constructor(props: IProps) {
        super(props)
        this.state = { count: 0, isLoading: false }
        this.inventoryShopCollectionService = new InventoryShopCollectionService()
    }

    componentDidMount() {
        this.setState({ count: this.props.count })
    }

    update = () => {
        this.setState({ isLoading: true })
        this.inventoryShopCollectionService.updateDeficitCount({ deficitCount: this.state.count, shopCollectionId: this.props.collectionId }).then(() => {
            NotificationManager.success('Collection deficit has been updated successfully')
            this.setState({ isLoading: false })
            this.props.reload && this.props.reload()
            this.props.closeModal()
        })
    }

    render(): React.ReactNode {
        return (
            <ModalComponent title={'Update Deficit'} size="500px"
                onClose={this.props.closeModal}
            >
                <div>
                    <Form.Group controlId="sourceAmountValue">
                        <Form.Label>New Deficit Count:</Form.Label>
                        <Form.Control type="number" value={this.state.count} onChange={e => this.setState({ count: Number(e.target.value) })} />
                    </Form.Group>
                </div>
                <ModalComponent.Footer>
                    {
                        this.state.isLoading ? <CustomButton variant="contained" disabled><CircularProgress color="inherit" size={20} /></CustomButton> :
                            <CustomButton variant="contained" type="submit" onClick={this.update}>Update</CustomButton>
                    }
                </ModalComponent.Footer>
            </ModalComponent>
        )
    }
}