import { Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ChipComponent: React.FC<{ label: any, type: string, size?: 'small' | 'medium' }> = ({ label, type, size }) => {
    const theme = useTheme();

    const getChipStyle = (type: any) => {
        switch (type) {
            case 'error':
                return {
                    backgroundColor: theme.palette.error.light,
                    color: theme.palette.error.main,
                    border: `1px solid ${theme.palette.error.main}`,
                };
            case 'success':
                return {
                    backgroundColor: theme.palette.success.light,
                    color: theme.palette.success.main,
                    border: `1px solid ${theme.palette.success.main}`,
                };
            case 'warning':
                return {
                    backgroundColor: theme.palette.warning.light,
                    color: theme.palette.warning.main,
                    border: `1px solid ${theme.palette.warning.main}`,
                };
            default:
                return {
                    backgroundColor: '#607D8B',
                    color: '#fff',
                };
        }
    };

    return (<Chip
        size={size}
        className='font-bold'
        sx={{
            fontSize: '13px',
            padding: '1px 6px',
            height: 'auto',
            '& .MuiChip-label': {
                display: 'block',
                whiteSpace: 'normal',
                fontFamily: 'SFPro-Medium'
            },
        }}
        style={getChipStyle(type)}
        label={label}
    />);
}

export default ChipComponent;