import React from "react";
import './Payment.scss'
import { Box, Checkbox, CircularProgress, Container, Grid, TextField } from "@mui/material";
import { TbSquareRoundedCheck } from "react-icons/tb";
import { PiCheckCircleFill } from "react-icons/pi";
import { TbCircleFilled } from "react-icons/tb";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { CustomButton } from "../../shared/button/ButtonComponent";
import AlertComponent from "../../shared/alert/AlertComponent";
import SwitchComponent from "../../shared/switch/SwitchComponent";
import WalletService, { PaymentMethodDto } from "../../../services/financials/WalletService";
import IPaymentState, { IPaymentProps } from "./Payment.models";
import CartService from "../../../services/shopping/CartService";
import CartView from "./cart-view/CartView";
import Amount from "../../utilities/Amount/Amount";
import SpinnerComponent from "../../shared/spinner/SpinnerComponent";
import { Navigate } from "react-router-dom";
import { InvoiceApiFactory, PaymentApiFactory } from "../../../generated-api/financials";

export default class Payment extends React.Component<IPaymentProps, IPaymentState> {
    walletService: WalletService;
    cartService: CartService;
    USDTCurrencyId: string = 'b8b8eaaa-c421-4783-b481-ae4fea6024de';

    constructor(props: IPaymentProps) {
        super(props);
        this.state = {
            feePercent: 0.5, paymentMethods: [], walletCreditToOrder: 0, products: [], payUSDTRedirectLink: '', loading: false, isPayFromWalletBalance: true, pendingRequest: false, redirectToPaymentWaiting: false,
            bill: { feeAmount: 0, baseAmount: 0, payableAmount: 0, totalAmount: 0 }
        }
        this.walletService = new WalletService()
        this.cartService = new CartService()
    }

    componentDidMount() {
        this.setData()
        this.loadWalletBalance()
        this.setWalletId()

        this.setState({ loading: true })

        InvoiceApiFactory().apiV1InvoiceIdGet(this.props.invoiceId).then((invoice) => {
            this.setState({
                bill: {
                    totalAmount: 0,
                    baseAmount: invoice.data.payableAmount - invoice.data.feeAmount,
                    feeAmount: invoice.data.feeAmount,
                    payableAmount: invoice.data.payableAmount
                }
            });
            this.walletService.getPaymentMethods(invoice.data.currencyId).then((paymentMethods) => {
                this.setState({
                    paymentMethods: paymentMethods,
                    selectedPaymentMethod: paymentMethods.length > 0 ? paymentMethods[0] : undefined
                });

                if (paymentMethods.length == 0)
                    this.setState({ payUSDTRedirectLink: `/payment/?currencyId=${this.USDTCurrencyId}&type=${this.props.type}` })
            }).finally(() => this.setState({ loading: false }));
        })
    }

    setWalletId = () => {
        this.walletService.getWalletList({}, { pagesize: 100, pageskip: 1 }).then(response => {
            let wallet = response.rows.filter(wallet => wallet.currencyId !== this.USDTCurrencyId)[0]

            this.setState({ walletId: wallet.id });
        })
    }

    loadWalletBalance = () => {
        this.walletService.getOverview().then((response) => {
            let walletCreditToOrder = response.balance - response.blockedBalance;
            walletCreditToOrder = walletCreditToOrder > 0 ? walletCreditToOrder : 0;
            walletCreditToOrder += response.credit;
            this.setState({ walletCreditToOrder });
        }).finally(() => { if (this.props.type == 'purchase') this.loadCart() })
    }

    loadCart = () => {
        this.cartService.get().then((cartItems) => {
            this.setState({
                products: cartItems.map((item) => {
                    return {
                        id: item.id, productId: item.productId, sellerShopId: item.sellerShopId, productInventoryAvailableCount: item.productInventoryAvailableCount,
                        count: item.count, imageSrc: '', title: item.productTitle, unitPrice: item.unitPrice
                    }
                }),
            }, () => this.billCalculation(cartItems.length > 0 && cartItems.map(item => item.unitPrice * item.count).reduce(function (a, b) { return a + b }, 0)));
        })
    }

    billCalculation = (amount: number) => {
        let { feePercent } = this.state
        let totalAmount = amount
        let feeAmount = totalAmount / 100 * feePercent;
        totalAmount += feeAmount
        let payableAmount = totalAmount

        this.setState({
            bill: {
                totalAmount,
                baseAmount: amount,
                feeAmount,
                payableAmount
            }
        });
    }

    setData = () => {


    }

    selectMethod = (paymentMethod: PaymentMethodDto) => {
        this.setState({ selectedPaymentMethod: paymentMethod });
    }

    getDomainWithPort = () => {
        const { protocol, hostname, port } = window.location;
        return port ? `${protocol}//${hostname}:${port}` : `${protocol}//${hostname}`;
    };

    submit = () => {
        this.setState({ pendingRequest: true })
        PaymentApiFactory().apiV1PaymentPost({ callBackUrl: this.getDomainWithPort()+'/paymentResult', invoiceId: this.props.invoiceId, paymentGatewayId: this.state.selectedPaymentMethod.id })
            .then((response) => {
                window.location.replace(response.data)
            })
    }

    render(): React.ReactNode {
        return <Container className="pt-xl">
            <div className="payment">
                <span className="font-lg font-bold">Payment</span>
                {this.mainView()}
            </div>
        </Container>
    }

    alreadyPaidView = () => {
        return <p>This bill already paid</p>
    }

    notFoundMethod = () => {
        return <Box pt={2}>
            <AlertComponent type="warning" action={{ link: this.state.payUSDTRedirectLink, label: 'Click to Pay with USDT' }}>
                This currency don't have any payment method and you can only <b>exchange</b> from another currencies to this currency,
                For example you can charge your USDT wallet and exchange it to USA Dollar
            </AlertComponent>
        </Box>
    }

    mainView = () => {
        let { paymentMethods, products, pendingRequest } = this.state

        if (this.state.loading || !this.state.bill)
            return <SpinnerComponent />

        if (paymentMethods.length === 0)
            return this.notFoundMethod()

        return <>
            <Grid container className="mb-lg mt-lg">
                <Grid item md={8} pr={2}>
                    <div className="payment-box bg-white radius-lg">
                        {this.paymentMethodView()}
                        <div className="mt-3 body-footer flex items-center justify-end">
                            <div className="pr-md">
                                <span className="font-md font-medium">Total:</span>
                                <span className="pl-xs font-md font-bold"><Amount value={this.state.bill.payableAmount} />$</span>
                            </div>
                            {
                                pendingRequest ?
                                    <CustomButton style={{ padding: '8px 34px' }} variant='contained' disabled><CircularProgress size={20} /></CustomButton> :
                                    <CustomButton disabled={this.state.bill.payableAmount <= 0} style={{ padding: '8px 34px' }} variant="contained" onClick={this.submit}>Pay</CustomButton>
                            }
                        </div>
                    </div>
                </Grid>
                <Grid item md={4} pl={1}>
                    <div className="payment-box bg-white radius-lg">
                        {this.invoiceView()}
                        {this.props.type == 'purchase' && <div className="mt-lg">
                            <CartView products={products} />
                        </div>}
                    </div>
                </Grid>
            </Grid>
        </>
    }

    invoiceView = () => {
        let { walletCreditToOrder } = this.state

        return <>
            {
                this.props.type === 'purchase' && <div className="pl-sm pb-sm">
                    <SwitchComponent handleChange={(event) => this.setState({ isPayFromWalletBalance: event.target.checked })}
                        disabled={walletCreditToOrder < this.state.bill.payableAmount}
                        checked={this.state.isPayFromWalletBalance} label="Pay with wallet balance" />
                </div>
            }
            <Box bgcolor="#FAFAFA" sx={{ boxShadow: '0px 2px 5px 0px #AAAAAA40', borderRadius: '12px', color: '#333333' }} p={2}>
                {
                    this.state.bill.feeAmount > 0 && <>
                        <div className="pb-sm pt-sm font-medium text-grey-dark flex justify-between items-center">
                            <span>Base Amount</span>
                            <span className="font-bold"><Amount value={this.state.bill.baseAmount} /> $</span>
                        </div>
                    </>
                }
                <div className="pb-sm pt-sm font-medium text-grey-dark flex justify-between items-center">
                    {/* <IoIosInformationCircleOutline size={16} /> */}
                    <span>Api Gift Fee </span>
                    <span>+ <Amount value={this.state.bill.feeAmount} /> $</span>
                </div>

                {
                    this.props.type === 'purchase' && <div className="pb-md pt-sm font-medium text-grey-dark flex justify-between items-center">
                        <span>Total Amount</span>
                        <span className="font-bold"><Amount value={this.state.bill.totalAmount} />  $</span>
                    </div>
                }


                {<div>
                    {/* (!this.state.isPayFromWalletBalance && " text-grey-ligth") */}
                    {
                        this.props.type === 'purchase' && <div className={'pt-md pb-sm font-medium text-grey-dark flex justify-between items-center'}>
                            <span>Wallet available balance</span>
                            <span className="text-grey-ligth"><Amount value={walletCreditToOrder} /> $</span>
                        </div>
                    }

                    {
                        this.props.type === 'purchase' && <div className="pb-md pt-sm font-medium text-grey-dark flex justify-between items-center">
                            <span>Online payment</span>
                            <span className="font-bold text-grey-ligth"><Amount value={this.state.bill.payableAmount} /> $</span>
                        </div>
                    }

                    <div style={{ border: '1px solid #EAEAEA', borderStyle: 'dashed' }}></div>
                    <div className="pt-md pb-sm font-medium text-grey-dark flex justify-between items-center">
                        <span>Online Payment Amount</span>
                        <span className="font-bold"> <Amount value={this.state.bill.payableAmount} />  $</span>
                    </div>
                </div>}
            </Box>
        </>
    }

    paymentMethodView = () => {
        const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

        return <>
            <div className="font-bold font-md flex items-center"><TbSquareRoundedCheck /> <span className="pl-xs">Choose Payment Method</span></div>
            <div className="font-medium font-sm pt-md">In order to charge or top up your wallet, you must first choose a payment method. Find and select it from the list below:</div>
            <ul>
                {
                    this.state.paymentMethods.map((method) => {
                        let selected = this.state.selectedPaymentMethod?.id === method.id;
                        return <li className={selected && ' selected '} style={{ marginTop: '10px' }}
                            key={method.id}
                            onClick={() => this.selectMethod(method)}><div>
                                <Checkbox
                                    checked={selected}
                                    {...label}
                                    icon={<TbCircleFilled size={24} color="#DEDEDE" />}
                                    checkedIcon={<PiCheckCircleFill size={24} color="#2196F3" />}
                                />
                                <span className="font-main font-bold">{method.title}</span>
                            </div>
                            <div className="font-xs pt-sm font-medium pl-md">
                                {method.description}
                            </div>
                        </li>
                    })
                }
            </ul>
        </>
    }
}