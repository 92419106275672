import { Alert, Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import './AlertComponent.scss'
import { ReactComponent as Info } from '../../../assets/icons/content/info.svg'
import { ReactComponent as Warning } from '../../../assets/icons/content/warning.svg'
import { ReactComponent as Arrow } from '../../../assets/icons/content/arrow.svg'
import { MdOutlineErrorOutline } from "react-icons/md";
import { NavLink } from 'react-router-dom';
import { CustomButton } from '../button/ButtonComponent';

export default function AlertComponent(props: { action?: { onClick?: () => void, link?: string, label?: string }, title?: string, type: 'success' | 'info' | 'warning' | 'error', children?: any }) {

    const renderIcon = () => {
        switch (props.type) {
            case 'info':
                return <Info />
            case 'warning':
                return <Warning />
            case 'error':
                return <MdOutlineErrorOutline />
        }
    }

    return (
        <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert icon={renderIcon()} className='alert' severity={props.type}>
                <Grid container className='font-medium'>
                    <Grid item md={props.action ? 9 : 12} lg={props.action ? 9 : 12} sm={12} xs={12} pr={2} className='alert-body'>
                        {props.children}
                    </Grid>
                    {
                        props.action &&
                        <Grid item md={3} lg={3} sm={12} xs={12} className='flex items-end justify-end'>
                            <div>
                                {props.action.link && <NavLink className="flex items-center" replace to={props.action.link}><span className='px-sm'>{props.action.label}</span><Arrow /></NavLink>}
                                {props.action.onClick && <CustomButton variant='text' className="flex items-center" onClick={() => props.action.onClick()}><span className='px-sm'>{props.action.label}</span><Arrow /></CustomButton>}
                            </div>
                        </Grid>
                    }
                </Grid>
            </Alert>
        </Stack>
    );
}