import React from "react";
import './BottomNavigation.scss'
import LayoutContext from "../../../../contexts/LayoutContext/LayoutContext";
import { ReactComponent as HomeIcon } from "../../../../assets/icons/content/home.svg";
import { ReactComponent as NoteIcon } from "../../../../assets/icons/content/note.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/content/search.svg";
import { ReactComponent as ProfileIcon } from "../../../../assets/icons/content/profile-mobile.svg";
import { ReactComponent as CartIcon } from "../../../../assets/icons/content/cart-mobile.svg";
import { Toolbar } from "@mui/material";
import { NavLink } from "react-router-dom";

export default class BottomNavigationComponent extends React.Component<{}, {}> {
    static contextType = LayoutContext;
    context!: React.ContextType<typeof LayoutContext>;

    constructor(props: any) {
        super(props);
    }

    componentDidMount(): void {
    }

    componentDidUpdate(): void {
    }

    render(): React.ReactNode {
        return <footer className="bottom-navigation">
            <Toolbar className="bottom-navigation-container flex justify-between pa-md">
                <div className="pb-sm pl-sm"><HomeIcon /></div>
                <div className="pb-sm pr-md">
                    <NavLink to={'/cart'}><CartIcon /></NavLink>
                </div>
                <div className="search-style"><SearchIcon /></div>
                <div className="pb-sm pl-md"><NoteIcon /></div>
                <div className="pb-sm pr-sm"><ProfileIcon /></div>
            </Toolbar>
        </footer>
    }
}