import React from "react";
import { Placeholder } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import WalletService from "../../../services/financials/WalletService";
import authenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext";
import Amount from "../../../components/utilities/Amount/Amount";
import './WalletOverview.scss'
import { FINANCIAL_MENU, FULL_ACCESS } from "../../../constants/ConstantsPermissions";
import { ReactComponent as WalletIcon } from '../../../assets/icons/content/wallet.svg'

export class WalletOverview extends React.PureComponent<{}, { balance: number, pendingBalance: number, loading: boolean }> {
    walletService: WalletService;
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;

    constructor(props: any) {
        super(props);
        this.walletService = new WalletService();
        this.state = { balance: 0, pendingBalance: 0, loading: false };
    }

    componentDidMount() {
        this.loadOverview();
    }

    loadOverview() {
        this.setState({ loading: true });
        this.walletService.getOverview().then((overview) => {
            this.setState({ balance: overview.balance, pendingBalance: overview.pendingBalance });
        }).finally(() => {
            this.setState({ loading: false });
        });
    }

    render(): React.ReactNode {
        return (
            <>
                <NavLink to={'/financials/?defaultActiveKey=wallets'} className={this.context.privileges.includes(FULL_ACCESS) || this.context.privileges.includes(FINANCIAL_MENU) ? '' : 'disabled'}>
                    <div className="text-charcoal flex items-center bg-white radius-sm py-m wallet-overview">
                        <WalletIcon />
                        <span className="pl-sm text-charcoal web"> | </span>
                        <div className="pl-sm font-bold">
                            {
                                this.state.loading ? <Placeholder animation="glow">
                                    <Placeholder style={{ height: '2vh', width: '100%' }} />
                                </Placeholder> : <div className="amount"><Amount value={this.state.balance} /> $</div>
                            }
                        </div>
                    </div>
                </NavLink>
            </>
        );
    }
}