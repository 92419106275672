import React from 'react';
import { Form, Spinner } from 'react-bootstrap'
import { NotificationManager } from 'react-notifications';
import OrdersService from '../../../services/orders/OrdersService';
import { OrderProductStatusEnum } from '../Enums/OrderProductStatusEnum';
import IReportState, { IReportProps } from './OrderItemReportModal.models'
import ModalComponent from '../../shared/modal/Modal';
import { CustomButton } from '../../shared/button/ButtonComponent';
import AlertComponent from '../../shared/alert/AlertComponent';
import { TextField } from '@mui/material';


export default class OrderItemReportModal extends React.Component<IReportProps, IReportState> {
    ordersService: OrdersService
    constructor(props: IReportProps) {
        super(props)
        this.state = { pendingRequest: false, description: '', requestType: undefined }
        this.ordersService = new OrdersService()
    }

    submit = () => {
        var autoDescription = this.getAutoDescription();
        if (this.state.description === '' && autoDescription === undefined) return NotificationManager.error('Description is required')
        this.setState({ pendingRequest: true })
        var requestDescription = autoDescription === undefined ? this.state.description : autoDescription;
        this.ordersService.sendReport({ description: requestDescription, orderProductId: this.props.productId }).then(() => {
            NotificationManager.success('Your report has been created successfully , you can follow it up in your tickets page');
            this.props.onReported();
        }).finally(() => {
            this.setState({ description: '', pendingRequest: false })
        })
    }

    getAutoDescription() {
        switch (this.state.requestType) {
            case 'when':
                return 'When will the order be delivered?';
            case 'cancel':
                return 'I want to cancel the order';
        }
        return undefined;
    }

    render(): React.ReactNode {
        const { pendingRequest } = this.state
        const { closeModal } = this.props

        return (
            <ModalComponent
                title={"Send Review Request"}
                onClose={closeModal}
                size={'1000px'}
            >
                <div className='pt-md text-justify'>
                    <span className="font-medium font-sm">From this section, you can ask the seller to check order item.
                        this request will be sent to him and the seller is obliged to respond within 48 hours,
                        your request can include your problems with the product received.</span>
                    <div className='pt-xl'>
                        <AlertComponent type='warning'>
                            <span>From this section, you can ask the seller to check order item. this request will be sent to him and the seller is obliged to respond within 48 hours, your request can include your problems with the product received.</span>
                        </AlertComponent>
                    </div>
                    <div>
                        {
                            this.props.status == OrderProductStatusEnum.Pending ? <>
                                <div>
                                    <span style={{ display: "block", marginBottom: "10px" }}> This product has not yet been delivered. you can send one of the following two requests for this product.</span>
                                    <Form.Check
                                        inline
                                        onChange={(e) => this.setState({ requestType: e.target.value })}
                                        checked={this.state.requestType === 'when'}
                                        label="When will the order be delivered?"
                                        value={"when"}
                                        name="review-request-type"
                                        type="radio"
                                        id={`review-request-when`}
                                    /><br />
                                    <Form.Check
                                        inline
                                        checked={this.state.requestType === 'cancel'}
                                        value={"cancel"}
                                        onChange={(e) => this.setState({ requestType: e.target.value })}
                                        label="I want to cancel the order"
                                        name="review-request-type"
                                        type="radio"
                                        id={`cancel`}
                                    />
                                    {
                                        this.state.requestType === 'cancel' ? <span>The acceptance of the cancellation order item request or its delivery will be determined by the seller.</span>
                                            : null
                                    }
                                </div>
                            </> : <div className='py-lg'>
                                <TextField
                                    className="text-feild-filled full-width"
                                    placeholder="Enter description"
                                    multiline
                                    value={this.state.description}
                                    rows={10}
                                    onChange={(e) => this.setState({ description: e.target.value })}
                                    variant="outlined"
                                />
                            </div>
                        }

                    </div>
                </div>
                <ModalComponent.Footer>
                    <div className="flex justify-end mobile-fixed-bottom">
                        {pendingRequest ? <CustomButton variant='contained' className='mobile-full-width' disabled><Spinner animation='border' /></CustomButton> :
                            <CustomButton variant='contained' className='mobile-full-width' onClick={this.submit}>Send Request</CustomButton>}
                    </div>
                </ModalComponent.Footer>
            </ModalComponent>
        )
    }
}