import React from 'react';
import './ProductCatalogCardView.scss'
import { DashCircleFill, PlusCircleFill, QuestionCircleFill } from "react-bootstrap-icons";
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props {
    delayDescription: string | null
    isYourShop: boolean
    inventoryCount: number | null
    plans: [
        {
            countFrom: number,
            countTo: number,
            price: number,
            discount: number
        }]
    sellerShopId: string
    title: string
    totalSoldCount: string
    productSoldCount: string
    onChangeCount: (count: number) => void
}

export default class ProductCatalogCardView extends React.Component<Props, { count: number }> {
    constructor(props: any) {
        super(props);

        this.state = { count: 0 };
    }

    plusCount = () => {
        if (this.props.inventoryCount != null && this.state.count >= this.props.inventoryCount)
            return;
        var count = this.state.count + 1;
        this.setState({ count })
        this.props.onChangeCount(count);
    }

    minusCount = () => {
        if (this.state.count === 0)
            return;
        var count = this.state.count - 1;
        this.setState({ count })
        this.props.onChangeCount(count);
    }

    changeCount = (count: number) => {
        if (count < 0)
            count = 0;
        if (this.props.inventoryCount != null && count >= this.props.inventoryCount)
            count = this.props.inventoryCount;
        this.setState({ count })
        this.props.onChangeCount(count);
    }

    deliveryTimeView(delayDescription: string) {
        return <>
            <OverlayTrigger
                key="top"
                placement="top"
                overlay={
                    <Tooltip id="tooltip-top">
                        This seller can make the product available up to the maximum time specified.
                        Create a offer to buy!
                    </Tooltip>
                }
            >
                <span>
                    <Badge bg='warning'>Offer <QuestionCircleFill /></Badge>
                    <span className='ml-1'> {delayDescription}</span>
                </span>
            </OverlayTrigger>
        </>
    }

    soldCountView() {
        if (this.props.isYourShop)
            return null
        return <div style={{ fontSize: '11px' }}>Sold Count <div>All Product: {this.props.totalSoldCount}</div> <div>This Product: {this.props.productSoldCount}</div></div>
    }

    render(): React.ReactNode {

        const isViewerShop = this.props.isYourShop;
        const seller = this.props;
        const plans = seller.plans;

        var emptyPlan = [];
        for (var i = 0; i < (isViewerShop || (seller.inventoryCount === 0 && seller.inventoryCount != null) ? 6 : 4) - (plans ? plans.length : 0); i++)
            emptyPlan.push({});

        var inventoryStatus = <span>Variable</span>;
        if (seller.inventoryCount === 0)
            inventoryStatus = <span>{this.deliveryTimeView(seller.delayDescription)}</span>;
        else if (seller.inventoryCount !== null)
            inventoryStatus = <span>{seller.inventoryCount}</span>;

        return <div className='product-catalog-card'>
            <div className='flex justify-between product-catalog-card-title'>
                <span className='font-main'>{seller.title}</span>
                <span className='font-md font-bold'>{
                    plans && plans.map((plan) => <td className={this.state.count >= plan.countFrom && this.state.count <= plan.countTo && 'selected-plan'}>
                        {plan.discount > 0 ? <span>{plan.price + plan.discount}$</span> : <span>{plan.price}$</span>}
                    </td>)
                }</span>
            </div>
            <div className='product-catalog-card-body'>
                <div className='flex justify-between pb-md'>
                    <span className='font-sm'>Sell Plan Count</span>
                    <span className='font-sm'>{
                        plans && plans.map((plan) => <span className={this.state.count >= plan.countFrom && this.state.count <= plan.countTo && 'selected-plan'}>{plan.countFrom} - {plan.countTo}</span>)
                    }</span>
                </div>
                <div className='flex justify-between pb-md'>
                    <span className='font-sm'>Count</span>
                    <span className='font-sm'>{inventoryStatus}</span>
                </div>
                <div className='flex justify-between pb-md'>
                    <span className='font-sm'>All Product</span>
                    <span className='font-sm'>{this.props.totalSoldCount}</span>
                </div>
                <div className='flex justify-between'>
                    <span className='font-sm font-bold'></span>
                    <div>
                        {
                            !isViewerShop && (seller.inventoryCount > 0 || seller.inventoryCount === null) && <div className="action-area flex items-center">
                                <DashCircleFill size={22} onClick={() => this.minusCount()} className={`red ${this.state.count == 0 && 'disable'} `} />
                                <div>{this.state.count}</div>
                                <PlusCircleFill size={22} onClick={() => this.plusCount()} className={this.state.count >= this.props.inventoryCount && this.props.inventoryCount !== null && 'disable'} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    }
}