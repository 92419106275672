import React from "react";
import { BiHide, BiShow } from "react-icons/bi";
import OrderProductStatusBadge, { OrderProductStatusEnum } from "../../Enums/OrderProductStatusEnum";
import HybridMoment from "../../../utilities/HybridMoment";
import OrderReviewRequest from "../../OrderReviewRequest/OrderReviewRequest";
import TruncateString from "../../../utilities/truncate-string/TruncateString";

interface OrderItemView {
    deliveredAt: string
    reviewRequestSupportTicketId: string
    id: string
    informations: string
    isSold: boolean
    orderType: number
    status: number
    title: string
}

interface OrderItemsState { }

interface OrderItemsProps {
    items: OrderItemView[];
    isSafeMode: boolean
    changeSafeMode: () => void
    loadTable: () => void
}

export default class OrderItemsListCardView extends React.Component<OrderItemsProps, OrderItemsState> {
    render(): React.ReactNode {
        return (<div>
            {
                this.props.items.map((item, index) =>
                    <>
                        <div
                            className="font-main  bg-white radius-sm box-shadow mt-lg mb-lg" key={index}
                        >
                            <div className="flex justify-between items-center pa-md">
                                <div className="font-medium"><TruncateString text={item.title} maxLength={18} /></div>
                                <div className="font-sm">{OrderProductStatusBadge(item.status)}</div>
                            </div>
                            <div className="seperator px-md"></div>
                            <div className="flex justify-between items-center pa-md">
                                <div className="font-bold">Delivery Time</div>
                                <div className="font-medium">{item.deliveredAt && HybridMoment(item.deliveredAt)}</div>
                            </div>
                            <div className="flex justify-between items-center pa-md">
                                <div className="font-bold">Information</div>
                                <div className="flex font-medium">
                                    <div>
                                        {item.informations && this.informationNormalize(item.informations)}
                                    </div>
                                    {(this.props.isSafeMode ?
                                        <BiHide size={22} className="pointer text-grey" onClick={this.props.changeSafeMode} />
                                        : <BiShow size={22} className="pointer text-grey" onClick={this.props.changeSafeMode} />
                                    )}
                                </div>
                            </div>
                            <div className="pa-md">
                                <OrderReviewRequest orderItemId={item.id} reviewRequestSupportTicketId={item.reviewRequestSupportTicketId}
                                    status={item.status as OrderProductStatusEnum}
                                    onReported={() => { this.props.loadTable() }} />
                            </div>
                        </div>
                    </>
                )}
        </div>);
    }

    informationNormalize(information: string) {
        information = information.replace('["', '').replace('"]', '').replace('","', ' , ');
        return information.indexOf(',') >= 0 ? information.split(',').map((info) => <span>{info}<br /></span>) : information;
    }
}