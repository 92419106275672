import React from "react";
import { NavLink } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import IdentityService from "../../../services/identity/IdentityService";
import '../IdentityLayout.scss'
import { CircularProgress, Grid, TextField } from "@mui/material";
import { CustomButton } from "../../shared/button/ButtonComponent";

export default class ForgotPassword extends React.Component<{}, State> {
    returnUrl: string;
    identityService: IdentityService;
    constructor(props: any) {
        super(props);
        this.state = { email: '', loading: false, done: false };
        this.identityService = new IdentityService();
        this.returnUrl = `${window.location.protocol}//${window.location.host}/reset-password`;
    }

    submit = () => {
        this.setState({ loading: true });
        this.identityService.forgotPassword(this.state.email, this.returnUrl).then(() => {
            NotificationManager.success('Password recovery email was sent to you.');
            this.setState({ done: true });
        }).finally(() => {
            this.setState({ loading: false });
        })
    }

    render(): React.ReactNode {
        let { email, loading } = this.state

        return <div className="full-width">
            <div className="font-lg font-bold text-charcoal text-center web">Forgot Password</div>
            <div className="mini-border-line web"></div>

            {
                this.state.done ?
                    <div className="center-text">
                        Check your inbox to next step.
                    </div> :
                    <form onSubmit={this.submit}>
                        <Grid container mt={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} mb={3}>
                                <label className="pb-sm font-sm text-charcoal-dark">Email address</label>
                                <TextField
                                    fullWidth
                                    id="email"
                                    className="text-feild-filled"
                                    placeholder="Enter email address"
                                    variant="outlined"
                                    value={email}
                                    error={email === ""}
                                    onChange={(e) => this.setState({ email: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                        {
                            loading ?
                                <CustomButton sx={{ borderRadius: "12px" }} variant="contained" disabled fullWidth>
                                    <CircularProgress color="inherit" size={20} />
                                </CustomButton> :
                                <CustomButton sx={{ borderRadius: "12px" }} variant="contained" fullWidth onClick={() => this.submit()}>Next</CustomButton>
                        }
                        <div className="pt-md">
                            <NavLink to={'/sign-in'} className="font-sm">Sign in</NavLink>
                        </div>
                    </form>
            }
        </div>
    }
}

interface State {
    email: string,
    loading: boolean,
    done: boolean
}