import React from "react"
import { Alert, Button, Card } from "react-bootstrap"
import { NotificationManager } from 'react-notifications'
import InventoryProductOverviewService from "../../../services/inventory/InventoryProductOverviewService"
import { InventoryShopBasePlanStatusEnum } from "../Enums/InventoryShopBasePlanStatusEnum"
import IState, { IProps } from "./InventoryPlans.models"
import InventoryProductPlansConfig from "./InventoryProductConfig/InventoryProductPlansConfig"
import SpinnerComponent from "../../shared/spinner/SpinnerComponent"

export default class InventoryPlans extends React.PureComponent<IProps, IState> {
    inventoryProductOverviewService: InventoryProductOverviewService
    constructor(props: any) {
        super(props)
        this.inventoryProductOverviewService = new InventoryProductOverviewService()
        this.state = {
            pendingRequest: false,
            isLoading: false,
            orderingPlan: {
                deliveryConfig: { enableParticipateOffers: false },
                inventoryConfig: {
                    isActiveMinimumAlert: false, isActiveMinimumCalculated: false, minimumAlertCustomCount: 0,
                    averagePrice: 0, averagePriceWithCurrencyRate: 0
                },
                isPro: false, pricingConfig: { isAutoBasePrice: false, baseCustomPrice: '0', lastAutoBasePriceAmount: 0, lastAveragePrice: 0, minimumBasePrice: '0' },
                systemDetails: { productId: '', productTitle: '', shopId: '', status: null, isMainCurrency: false, sharePercent: 0 },
            }
        }
    }

    componentDidMount() {
        this.getConfig()
    }

    getConfig() {
        this.setState({ isLoading: true })
        this.inventoryProductOverviewService.getOrderingPlan(this.props.productId).then((response) => {
            this.setState({ orderingPlan: response, isLoading: false })
        })
    }

    changeStatus = (status: InventoryShopBasePlanStatusEnum) => {
        this.setState({ pendingRequest: true })
        this.inventoryProductOverviewService.updateInventoryConfigStatus(this.props.productId, { status }).then(() => {
            if (status === InventoryShopBasePlanStatusEnum.Closed) NotificationManager.success("Your sell plan closed successfully");
            else if (status === InventoryShopBasePlanStatusEnum.Canceled) NotificationManager.success("Your sell plan request canceled successfully");
            this.getConfig()
        }).finally(() => {
            this.setState({ pendingRequest: false })
        })
    }

    render(): React.ReactNode {
        const { orderingPlan, isLoading } = this.state

        return isLoading ? <SpinnerComponent /> : <>
            {
                orderingPlan.systemDetails.status === InventoryShopBasePlanStatusEnum.Open ?
                    <InventoryProductPlansConfig changeStatus={this.changeStatus} onUpdated={this.props.onUpdated}
                        productId={this.props.productId} orderingPlan={orderingPlan} /> :
                    <Card>
                        <Card.Header>Plans</Card.Header>
                        <Card.Body>
                            {this.renderBodyView()}
                        </Card.Body>
                    </Card>
            }
        </>
    }

    renderBodyView() {
        const { orderingPlan } = this.state

        switch (orderingPlan.systemDetails.status) {
            case null:
                return this.openSalesView()
            case (InventoryShopBasePlanStatusEnum.Closed):
                return this.openSalesView()
            case (InventoryShopBasePlanStatusEnum.Canceled):
                return this.openSalesView()
            case InventoryShopBasePlanStatusEnum.Pending:
                return this.waitCheckingView()
            case InventoryShopBasePlanStatusEnum.Suspended:
                return this.suspendedRequestView()
        }
    }

    openSalesView() {
        return <>
            You can open sales for this product and set the price config
            <Button variant="success" size="sm" className="m-2" onClick={() => this.setState(prevState => { return { orderingPlan: { ...prevState.orderingPlan, systemDetails: { ...prevState.orderingPlan.systemDetails, status: InventoryShopBasePlanStatusEnum.Open } } } })}>Open sales</Button>
        </>
    }

    waitCheckingView() {
        return <>
            Sales request for this product already sent, please wait for check
            <Button variant="danger" size="sm" className="m-2" onClick={() => this.changeStatus(InventoryShopBasePlanStatusEnum.Canceled)}>Cancel</Button>
        </>
    }

    suspendedRequestView() {
        return <>
            <Alert variant="warning">Sales  this product is suspended for your shop</Alert>
        </>
    }
}