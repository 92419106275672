import { useSearchParams } from "react-router-dom";
import NotFoundRoute from "../shared/NotFoundRoute";
import OrderDelivery from "../../components/orders/Orders/OrderDelivery/OrderDelivery";

export default function OrderDeliveryRoute() {
    const [searchParams] = useSearchParams();
    let orderId = searchParams.get("orderId");

    return orderId === null ? <NotFoundRoute /> : <OrderDelivery orderId={orderId} />
}
