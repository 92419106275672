import React from "react";
import { Container } from "@mui/material";
import * as signalR from '@microsoft/signalr';
import axios from "axios";
import PaymentStatus from "../status/PaymentStatus";
import { FINANCIAL_API_URL } from "../../../../constants/Constants";

export default class Payment extends React.Component<{}, {}> {
    billHubConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${FINANCIAL_API_URL}/Hubs/Bill?access_token=${axios.defaults.headers.common['authorization'].toString().replace("Bearer ", "") || ""}`, {
        })
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();

    constructor(props: any) {
        super(props);
        this.state = {}
    }

    componentDidMount(): void {
        this.billHubConnection.start();
        this.billHubConnection.on("InvoiceReadyToPaymentEvent", (dto: InvoiceReadyToPaymentDtos) => console.log(dto.invoiceId))
    }

    render(): React.ReactNode {
        return <Container className="pt-xl">
            <PaymentStatus status="waiting" />
        </Container>
    }
}
export interface InvoiceReadyToPaymentDtos {
    currencyId: string,
    feeAmount: number,
    invoiceId: string,
    ownerId: string
    payableAmount: number,
    payerDescription: string
    paymentMethod: number
    receiverDescription: string
    systemDescription: string
    type: number
    userId: string
}