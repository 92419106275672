import React, { FormEvent, RefObject } from "react"
import { Alert, Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap"
import DatePicker from "react-datepicker"
import { NotificationManager } from 'react-notifications'
import { PRO } from "../../../constants/ConstantsPolicies"
import InventoryProductOverviewService, { IDeficitDTO } from "../../../services/inventory/InventoryProductOverviewService"
import InventoryService, { IAddShopInventoryDTO } from "../../../services/inventory/InventoryService"
import InventorySourceService from "../../../services/inventory/InventorySourceService"
import ReviewRequestWarningModal from "../../helpCenter/review-request-warning-modal/ReviewRequestWarningModal"
import PageTitle from "../../shared/PageTitle/PageTitle"
import ShopPolicies from "../../shared/policy/ShopPolicies/ShopPolicies"
import Amount from "../../utilities/Amount/Amount"
import IState, { IProps } from "./CreateInventory.models"
import authenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext";
import InventoriesInformation from './InventoriesInformation/InventoriesInformation'
import InventoryDeficits from './InventoryDeficits/InventoryDeficits'
import { ClipboardPlus } from "react-bootstrap-icons"
import Note from "../../shared/Note/Note"
import { NavLink } from "react-router-dom"
import ReviewRequestService from "../../../services/orders/ReviewRequestService"
import AlertComponent from "../../shared/alert/AlertComponent"
import { CustomButton } from "../../shared/button/ButtonComponent"
import { Box, CircularProgress } from "@mui/material"

export default class CreateInventory extends React.PureComponent<IProps, IState> {
    inventoryService: InventoryService
    inventorySourceService: InventorySourceService
    inventoryProductOverviewService: InventoryProductOverviewService
    reviewRequestService: ReviewRequestService
    inventoriesInformationRef: RefObject<InventoriesInformation>
    inventoryDeficits: RefObject<InventoryDeficits>
    refShopPolicies: RefObject<ShopPolicies>
    static contextType = authenticationContext;
    context!: React.ContextType<typeof authenticationContext>;
    constructor(props: IProps) {
        super(props)
        this.inventoryService = new InventoryService()
        this.inventorySourceService = new InventorySourceService()
        this.inventoryProductOverviewService = new InventoryProductOverviewService()
        this.reviewRequestService = new ReviewRequestService();
        this.inventoriesInformationRef = React.createRef()
        this.inventoryDeficits = React.createRef()
        this.refShopPolicies = React.createRef()
        this.state = {
            initializedRequestCount: 0,
            added: false,
            orderNumberIsLoading: false,
            config: { title: '', realPrice: 0, inventoryAveragePrice: 0, minimumBasePrice: 0, status: null }, errors: [], isLoading: false,
            isAlreadyExist: false, lastOrderNumber: "-----------------", ignoreConflictWithInventoryAveragePrice: false, inventory: { price: null, date: new Date(), deficitCount: 0, description: '', isDebt: false, isSelectAll: false, orderNumber: '', sourceId: '' }, sources: [],
            reportCounts: 0, ignoreInventoryDuplication: false
        }
    }

    componentDidMount() {
        this.getSources();
        this.setConfig();
        this.getReportPendings();
        this.setState({ inventory: { ...this.state.inventory, deficitCount: 0, price: this.hasPolicy() ? "0" : null } })

    }

    getReportPendings = () => {
        this.reviewRequestService.getCount().then(response => {
            this.setState({ reportCounts: response.count, initializedRequestCount: this.state.initializedRequestCount + 1 })
        });
    }

    setOrderNumber = () => {
        if (this.props.inComming)
            this.setState({ inventory: { ...this.state.inventory, sourceId: this.props.inComming.sourceId } },
                () => {
                    this.getOrder(this.props.inComming.orderNumber)
                })
    }

    setConfig = () => {
        this.inventoryProductOverviewService.getOrderingPlan(this.props.productId).then((response) => {
            this.setState({
                config: {
                    title: response.systemDetails.productTitle, realPrice: 0,
                    status: response.systemDetails.status,
                    inventoryAveragePrice: response.inventoryConfig.averagePrice, minimumBasePrice: Number(response.pricingConfig.minimumBasePrice)
                }, initializedRequestCount: this.state.initializedRequestCount + 1
            })
        })

    }

    getSources = () => {
        this.inventorySourceService.getTitleValues().then((response) => {
            this.setState({ sources: response, initializedRequestCount: this.state.initializedRequestCount + 1 }, this.setOrderNumber)
        })
    }

    submit = (e?: FormEvent<HTMLFormElement>) => {
        e?.preventDefault()
        e?.stopPropagation()

        const { inventory, reportCounts } = this.state


        let data: IAddShopInventoryDTO
        let emptyRowCount = this.inventoriesInformationRef.current?.state.inventories.map(item => item.Informations.map(information => information.value === null || information.value === '')).map((row) => { return row.includes(true) }).filter(test => test === true)
        if (emptyRowCount && emptyRowCount?.length > 0) return NotificationManager.error('One or more product input is empty')

        if (!this.priceValidation()) return NotificationManager.error('Your price have a problem')

        var columnKeys = this.inventoriesInformationRef.current?.state.columns.map(column => column.value) || [];
        var inventoryItems = this.inventoriesInformationRef.current?.state.inventories.map(inventory => columnKeys.map(columnKey => inventory.Informations.filter(info => info.key == columnKey)[0].value));

        if (this.hasPolicy()) {
            data = {
                deficitCount: inventory.deficitCount,
                description: inventory.description,
                isDebt: inventory.isDebt,
                orderNumber: inventory.orderNumber,
                sourceId: inventory.sourceId,
                price: Number(inventory.price),
                buyDate: inventory.date,
                inventoryItems: inventoryItems,
                productId: this.props.productId,
                withoutConflictValidation: this.state.ignoreInventoryDuplication
            }
        } else {
            data = {
                inventoryItems: inventoryItems,
                withoutConflictValidation: this.state.ignoreInventoryDuplication,
                productId: this.props.productId
            }
        }



        this.setState({ isLoading: true })
        this.inventoryService.create(data).then((response) => {
            if (response.errors.length > 0) {
                NotificationManager.error('One or more inventory has conflict')
                this.setState({ errors: response.errors })
            }
            else {
                if (this.props.onAdded) {
                    this.props.onAdded();
                    NotificationManager.success('Products added to inventory successfully')

                } else
                    this.setState({ added: true })

                this.inventoryDeficits.current?.getDeficits()
                this.clear()
            }
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }

    clear = () => {
        this.setState({
            errors: [], isAlreadyExist: false, lastOrderNumber: "-----------------", ignoreConflictWithInventoryAveragePrice: false,
            inventory: { price: null, date: new Date(), deficitCount: 0, description: '', isDebt: false, isSelectAll: false, orderNumber: '', sourceId: '' }
        })
    }

    formIsValid = () => {
        const { inventory, reportCounts } = this.state

        if (!this.hasPolicy())
            return false
        else if (!inventory.date || !inventory.orderNumber || !inventory.sourceId || !inventory.price)
            return true

        return false
    }

    priceValidation = () => {
        const { ignoreConflictWithInventoryAveragePrice, inventory } = this.state
        const { config } = this.state

        try {

            if (!ignoreConflictWithInventoryAveragePrice && inventory.price) {
                var basePrice = config.inventoryAveragePrice > 0 ? config.inventoryAveragePrice : config.minimumBasePrice
                var max = basePrice + (basePrice / 10)
                var min = basePrice - (basePrice / 10)
                if (max < Number(inventory.price) || min > Number(inventory.price))
                    return false
            }
            return true
        }
        catch {
            return false;
        }

    }

    isDisabledCollectionFields = () => {
        if (this.state.isLoading)
            return true;
        if (this.state.orderNumberIsLoading)
            return true;
        if (this.state.inventory.orderNumber == null || String(this.state.inventory.orderNumber) === '' || this.state.lastOrderNumber !== this.state.inventory.orderNumber || this.state.isAlreadyExist) return true
        return false
    }

    getOrder = (orderNumber: string) => {
        this.setState(prevState => { return { orderNumberIsLoading: true, lastOrderNumber: orderNumber, inventory: { ...prevState.inventory, orderNumber: orderNumber, date: new Date(), price: null }, isAlreadyExist: false } })

        this.inventoryProductOverviewService.getUniqueBuyOrderNumber({ orderNumber: orderNumber, productId: this.props.productId, sourceId: this.state.inventory.sourceId as string }).then((response) => {
            if (response.sourceId) {
                this.setState(prevState => {
                    return {
                        orderNumberIsLoading: false,
                        inventory: {
                            ...prevState.inventory,
                            price: response.buyPrice?.toString(),
                            date: response.orderDate ? new Date(response.orderDate) : new Date(),
                            sourceId: response.sourceId
                        }, isAlreadyExist: response.buyPrice ? true : false,
                    }
                })
            }
            // else
            //     this.setState(prevState => {
            //         return {
            //             inventory: {
            //                 ...prevState.inventory,
            //                 sourceId: response.sourceId,
            //                 price: response.buyPrice?.toString(),
            //                 date: response.orderDate ? new Date(response.orderDate) : new Date()
            //             },
            //             isAlreadyExist: response.buyPrice ? true : false
            //         }
            //     })
        }).finally(() => {
            this.setState({ orderNumberIsLoading: false })
        })
    }

    setDeficit = (deficit: IDeficitDTO) => {
        this.setState({ inventory: { ...this.state.inventory, sourceId: deficit.sourceId } },
            () => {
                this.getOrder(deficit.orderNumber)
            })

    }

    hasPolicy = () => this.context.policies?.includes(PRO)

    render(): React.ReactNode {
        const { inventory, sources, lastOrderNumber, isAlreadyExist, config } = this.state
        const orderFormLock = this.props.inComming !== undefined;
        if (this.state.initializedRequestCount < 3)
            return <Spinner animation="border" />

        if (this.state.config.status === null)
            return <Alert variant="warning">Before entering your inventory, you must first plan your sales from the Plan tab.</Alert>

        if (this.state.added)
            return <>
                <Alert variant="success" style={{ textAlign: 'center' }}>
                    <img src='/images/tick.svg' className="product-image" width={64} height={64} /><br /><br />
                    Your inventory added successfully and ready to sell!<br />
                    also you can see your inventories in <b>Inventories</b> section
                </Alert>
            </>
        else if (this.hasPolicy() && sources.length === 0)
            return <>
                <Note>
                    You are in <b>professional mode</b>, so before add anything you need create a source.<br /><br />
                    <NavLink to={`/inventory/?activeTab=stockIn&defaultActiveSubKey=sources`} >
                        <Button variant="success" size="sm">Create New Source</Button>
                    </NavLink>
                </Note>
            </>
        return <>


            <Form onSubmit={this.submit}>
                {this.hasPolicy() && <>
                    <Row>
                        <Col md={orderFormLock ? "12" : "8"} sm="12">
                            <Row className="mt-3">
                                <Col md="4" sm="12">
                                    <Form.Group controlId="fromWalletId">
                                        <Form.Label style={{ marginBottom: '13px' }}>Source:</Form.Label>
                                        <Form.Select value={inventory.sourceId || ''}
                                            disabled={orderFormLock}
                                            onChange={(e) => this.setState((prevState) => { return { inventory: { ...prevState.inventory, sourceId: e.target.value, orderNumber: '' } } })}>
                                            <option value="">Select Your Source</option>
                                            {
                                                sources.map((source) => <option value={source.value} key={source.value}>{source.title}</option>)
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md="4" sm="12">
                                    <Form.Group>
                                        <Form.Label>Order Number:</Form.Label>
                                        <InputGroup>
                                            <Form.Control disabled={!inventory.sourceId || orderFormLock} placeholder="Enter order number"
                                                value={inventory.orderNumber}
                                                onChange={(e) => this.getOrder(e.target.value)} />
                                            {
                                                this.state.orderNumberIsLoading && <Button disabled variant="outline-secondary" size="sm" className="input-fix-btn">
                                                    <Spinner animation="border" size="sm" />
                                                </Button>
                                            }

                                        </InputGroup>
                                    </Form.Group>
                                    {/* <Form.Group>
                                            <Form.Label>Order Number:</Form.Label>
                                            <Form.Control disabled={!inventory.sourceId} placeholder="Enter order number"
                                                value={inventory.orderNumber}
                                                onChange={(e) => this.getOrder(e.target.value)} />
                                        </Form.Group> */}
                                </Col>
                                <Col md="3" sm="12">
                                    {
                                        (!this.props.inComming || this.props.inComming.deficitCount <= 0) &&
                                        <Form.Group>
                                            <Form.Label>Deficit Count:</Form.Label>
                                            <Form.Control type="number" placeholder="Enter deficit count"
                                                disabled={orderFormLock || this.hasPolicy() && (!inventory.sourceId || this.isDisabledCollectionFields())}
                                                value={inventory.deficitCount}
                                                onChange={(e) => this.setState(prevState => { return { inventory: { ...prevState.inventory, deficitCount: Number(e.target.value) } } })} />
                                        </Form.Group>
                                    }
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md="4" sm="12">
                                    <Form.Group className="mb-2" controlId="formBasicAmount">
                                        <Form.Label>Your purchase price(USD):</Form.Label>
                                        <Form.Control type="text" placeholder="Enter your purchase price"
                                            value={inventory.price ?? 0}
                                            disabled={orderFormLock || this.hasPolicy() && (!inventory.sourceId || this.isDisabledCollectionFields())}
                                            onChange={(e) => this.setState(prevState => { return { inventory: { ...prevState.inventory, price: e.target.value } } })} />
                                    </Form.Group>
                                </Col>
                                <Col md="4" sm="12">
                                    {this.hasPolicy() &&
                                        <Form.Group controlId="formBasicDate">
                                            <Form.Label>Order Date:</Form.Label>
                                            <DatePicker selected={inventory.date}
                                                disabled={orderFormLock || !inventory.sourceId || this.isDisabledCollectionFields()}
                                                onChange={(date: Date) => this.setState(prevState => { return { inventory: { ...prevState.inventory, date } } })}
                                                className="form-control"
                                            />
                                        </Form.Group>
                                    }
                                </Col>
                                {(!this.hasPolicy() || (lastOrderNumber === inventory.orderNumber)) &&
                                    <Col md="4" sm="4">
                                        <Form.Group controlId="formBasicDescription">
                                            <Form.Label>Description:</Form.Label>
                                            <Form.Control as="textarea" rows={3} placeholder="Enter description(optional)"
                                                disabled={orderFormLock || this.state.orderNumberIsLoading || this.state.isLoading}
                                                value={inventory.description}
                                                onChange={(e) => this.setState(prevState => { return { inventory: { ...prevState.inventory, description: e.target.value } } })} />
                                        </Form.Group>
                                    </Col>
                                }
                            </Row>
                            <Row>
                                <Col md={12} sm={12} className="pt-md">
                                    {!this.priceValidation() && <AlertComponent type="error" action={{ label: 'No-Problem', onClick: () => this.setState({ ignoreConflictWithInventoryAveragePrice: true }) }}>
                                        <span>
                                            Your inventory average price: <Amount value={config.inventoryAveragePrice <= 0 ? config.minimumBasePrice : config.inventoryAveragePrice} />
                                        </span>
                                    </AlertComponent>}
                                </Col>
                            </Row>
                        </Col>
                        {
                            !orderFormLock && <Col md="4" sm="12">
                                <InventoryDeficits ref={this.inventoryDeficits} productId={this.props.productId}
                                    getDeficitItem={this.setDeficit} />
                            </Col>
                        }

                    </Row> <hr />
                </>
                }
                {
                    this.props.inComming && this.props.inComming.deficitCount > 0 && <span>
                        Deficit Count: {this.props.inComming.deficitCount}
                    </span>
                }

                {(((lastOrderNumber === inventory.orderNumber) || !this.hasPolicy())) && <InventoriesInformation ref={this.inventoriesInformationRef} onIgnoreConflict={() => this.setState({ ignoreInventoryDuplication: true })} errors={this.state.errors} productId={this.props.productId} />}
                <Box sx={{ float: 'right' }}>
                    {
                        this.state.isLoading ? <CustomButton variant="contained" disabled>
                            <CircularProgress color="inherit" size={20} />
                        </CustomButton> : <CustomButton onClick={() => this.submit()} variant="contained" disabled={this.formIsValid()}>Add Collection</CustomButton>
                    }
                </Box>
            </Form >
            <ShopPolicies ref={this.refShopPolicies} policy={PRO} />
        </>
    }
}