import React, { Ref, RefObject } from "react";
import { Button } from "react-bootstrap";
import SupportTicketAddReply from "../../SupportTickets/Details/AddReplay/SupportTicketAddReply";
import { ISupportTicketDetailsCommentDTO } from "../../Reports/Details/ReportDetails.models";
import TicketCardView from "../TicketCardView/TicketCardView";
import './TicketView.scss';

interface IProps {
    onHandleReply: any,
    title?: string,
    comments: ISupportTicketDetailsCommentDTO[],
    hasEnableAddReply?: boolean,
    actionChildren?: any,
    draftText?: boolean,
    draftId?: string
    shopId?: string
}
export default class TicketView extends React.PureComponent<IProps, { showAddComment: boolean }> {
    supportTicketAddReply: RefObject<SupportTicketAddReply>
    constructor(props: any) {
        super(props)
        this.state = { showAddComment: false }
        this.supportTicketAddReply = React.createRef();
    }

    addReply = () => {
        this.supportTicketAddReply.current.reply(true);
    }


    render(): React.ReactNode {
        let isMobile = window.innerWidth > 960 ? false : true
        const { comments, draftText, shopId } = this.props

        return <div className="ticket-view">
            <TicketCardView headerTitle={this.props.title || 'Comments'} actionChildren={this.props.actionChildren} footerChildren={this.props.hasEnableAddReply && <SupportTicketAddReply hideUploader={this.props.title == 'Comment'} draftId={this.props.draftId} ref={this.supportTicketAddReply} draftText={draftText ?? false} onAddReply={this.props.onHandleReply} />}>
                {comments !== null && comments.length > 0 ?
                    comments.map((comment, index) => {
                        let textDocument;
                        let floatType = shopId === null || shopId === undefined ? null : comment.shopId === shopId;

                        if (comment.isSystemic === true) {

                            textDocument = <>
                                <div className="text-center systemic-text mt-2">{comment.description}</div>
                                <div style={{ clear: "both" }}></div>
                            </>;
                        }
                        else textDocument = <TicketCardView attachmentPaths={comment.attachmentPaths} width={(floatType != null && !isMobile ) ? "45%" : "100%"} float={floatType} description={comment.description} createAt={comment.createAt} headerTitle={comment.userDisplayName} />;

                        return (

                            <span key={index}>
                                {textDocument}
                            </span>
                        )
                    }) : <span>No comments yet.</span>
                }
            </TicketCardView>
        </div>
    }
}
