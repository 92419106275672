import React from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import AdminWalletsServices from "../../../../../services/admin/financials/AdminWalletsServices";
import { NotificationManager } from 'react-notifications';
import ModalComponent from "../../../../shared/modal/Modal";
import { CustomButton } from "../../../../shared/button/ButtonComponent";
import { CircularProgress } from "@mui/material";
import AlertComponent from "../../../../shared/alert/AlertComponent";

export default class LockWalletModal extends React.Component<{ reload: () => void, onClose: () => void, walletId: string, isLock: boolean }, { description: string, pendingRequest: boolean }> {
    adminWalletsServices: AdminWalletsServices
    constructor(props: any) {
        super(props);
        this.state = { pendingRequest: false, description: '' }
        this.adminWalletsServices = new AdminWalletsServices()
    }

    submit = () => {
        this.setState({ pendingRequest: true })

        let body = {
            description: this.state.description,
            isLock: !this.props.isLock
        }

        this.adminWalletsServices.lockWallet(body, this.props.walletId).then(response => {
            NotificationManager.success(this.props.isLock ? "The wallet unlocked successfully" : "The wallet  locked successfully")
            this.props.onClose()
            this.props.reload()
        }).finally(() => this.setState({ pendingRequest: false }))
    }

    render(): React.ReactNode {
        let { onClose } = this.props
        let { description, pendingRequest } = this.state

        return <ModalComponent size="500px" title={this.props.isLock ? 'Unlock Wallet' : 'Lock Wallet'} onClose={onClose}>
            <div>
                <Form>
                    <Row>
                        {
                            !this.props.isLock &&
                            <Col md="12" sm="12">
                                <AlertComponent type="warning">The outgoing transactions of this wallet will be blocked, the incoming transactions will still be active</AlertComponent>
                            </Col>
                        }
                        <Col className="mt-3" md="12" sm="12">
                            <Form.Label>Description:</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter description"
                                value={description}
                                onChange={e => this.setState({ description: e.target.value })} />
                        </Col>
                    </Row>
                </Form>
            </div>
            <ModalComponent.Footer>
                {pendingRequest ? <CustomButton variant='contained' disabled><CircularProgress size={20} color="inherit" /></CustomButton> :
                    <CustomButton variant='contained' onClick={this.submit}>{this.props.isLock ? 'Unlock' : 'Lock'}</CustomButton>}
            </ModalComponent.Footer>
        </ModalComponent>
    }
}