import React from "react";
import IChangePasswordState, { IChangePasswordProps } from './ChangePassword.models'
import { Badge, Col, Form, Row } from "react-bootstrap";
import { NotificationManager } from 'react-notifications';
import IdentityService from "../../../../services/identity/IdentityService";
import CardView from "../../../shared/Card/CardView";
import { CustomButton } from "../../../shared/button/ButtonComponent";
import { CircularProgress } from "@mui/material";

export default class ChangePassword extends React.PureComponent<IChangePasswordProps, IChangePasswordState> {
    identityService: IdentityService;
    constructor(props: IChangePasswordProps) {
        super(props);
        this.identityService = new IdentityService()
        this.state = { isLoading: false, password: { new: '', current: '', repeat: '' } };
    }

    submit = () => {
        const { password } = this.state;

        if (password.current.length === 0 || password.current.length === 0 || password.repeat.length === 0) {
            NotificationManager.error('Password is required');
            return;
        }

        if (password.new !== password.repeat) {
            NotificationManager.error('Your password is not equal to repeating it');
            return;
        }

        this.setState({ isLoading: true })
        this.identityService.changePassword({ currentPassword: password.current, newPassword: password.new }).then(() => {
            NotificationManager.success('Your Password changed successfully.');
            this.setState({ password: { current: '', new: '', repeat: '' } })
        }).finally(() => {
            this.setState({ isLoading: false })
        }).catch(() => {
            NotificationManager.error('An error has occurred.');
        })
    }


    render(): React.ReactNode {
        const { password, isLoading } = this.state;
        return (
            <div className="pt-md">
                <div className="font-bold font-lg">Change Password</div>
                <Form onSubmit={this.submit}>
                    <Row>
                        <Col md="4" sm="12">
                            <Form.Group className="mb-3" controlId="password.current">
                                <Form.Label>Current Password</Form.Label>
                                <Form.Control type="password" placeholder="Enter current password" value={password.current} onChange={e => this.setState((prevState) => { return { password: { ...prevState.password, current: e.target.value } } })} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4" sm="12">
                            <Form.Group className="mb-3" controlId="password.new">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control type="password" placeholder="Enter new password" value={password.new} onChange={e => this.setState((prevState) => { return { password: { ...prevState.password, new: e.target.value } } })} />
                            </Form.Group>
                            {
                                password.new.length === 0 || /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/.test(password.new) ? null : <Badge bg={'danger'}>Your password is not strong enough</Badge>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4" sm="12">
                            <Form.Group controlId="password.repeat">
                                <Form.Label>Repeat Password</Form.Label>
                                <Form.Control type="password" placeholder="Enter new password again" value={password.repeat} onChange={e => this.setState((prevState) => { return { password: { ...prevState.password, repeat: e.target.value } } })} />
                                {password.repeat && password.repeat !== password.new ? <Badge bg={'danger'}>Your password is not equal to repeating it.</Badge> : null}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md="12" className="mb-3 mt-5">
                            <CardView small card={{ bg: 'light', body: 'Passowrd should have at least one lower case, upper case, number, special character and minimum 8 characters' }} />
                        </Col>
                    </Row>
                    {
                        isLoading ? <CustomButton variant="contained" disabled>
                            <CircularProgress color="inherit" size={20} /> </CustomButton> :
                            <CustomButton variant='contained' className="mt-2" onClick={this.submit}>Change</CustomButton>
                    }
                </Form>
            </div>
        )
    }
}