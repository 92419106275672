import React, { RefObject } from "react"
import { GridAction, GridColumn } from "../../../Grid/Grid.models"
import DynamicGrid from "../../../shared/DynamicGrid/DynamicGrid"
import IState, { IProps } from "./AdminCurrencies.models"
import AdminCurrenciesService from "../../../../services/admin/financials/AdminCurrenciesService"
import { NotificationManager } from 'react-notifications'
import AdminCreateCurrencyModal from "./Create/AdminCreateCurrencyModal"
import AdminChangeRateModal from "./Rates/AdminChangeRateModal"
import { CustomButton } from "../../../shared/button/ButtonComponent"

export default class AdminCurrencies extends React.PureComponent<IProps, IState> {
    adminCurrenciesService: AdminCurrenciesService
    dynamicGrid: RefObject<DynamicGrid<{}>>;
    constructor(props: any) {
        super(props);
        this.dynamicGrid = React.createRef()
        this.adminCurrenciesService = new AdminCurrenciesService()
        this.state = { currencyId: '', showCreateModal: false }
    }

    reload = () => this.dynamicGrid.current.fetch()

    mainCurrencyView = (isMain: boolean) => {
        return <> {isMain && <span>Main Currency</span>} </>
    }

    requestToRemoveRow = (id: string) => {
        if (window.confirm('Are you sure? This Currency will be deleted permanently')) {
            this.adminCurrenciesService.delete(id).then(() => {
                NotificationManager.success("the currency deleted successfully")
                this.dynamicGrid.current.fetch();
            }).finally(() => this.dynamicGrid.current.resetSpinner())
        }
    }

    render(): React.ReactNode {
        let gridColumns: GridColumn[] = [
            { title: 'Title', key: 'title' },
            { title: 'Symbol', key: 'symbol' },
            { title: 'Main Currency', key: 'isMainCurrency' },
        ]

        var gridActions: GridAction[] = [
            { caption: 'Change Rate', variant: 'outline-info', onClick: (row) => this.setState({ changeRateCurrencyId: row.id }), skipSpinner: true },
            { caption: 'Edit', variant: 'outline-warning', onClick: (row) => this.setState({ currencyId: row.id, showCreateModal: true }), skipSpinner: true },
            { caption: 'Delete', variant: 'outline-danger', onClick: (row) => this.requestToRemoveRow(row.id) },
        ]

        return (<>
            <div style={{ textAlign: 'end' }}>
                <CustomButton onClick={() => this.setState({ showCreateModal: true })} variant="contained">Add New Currency</CustomButton>
            </div>
            <DynamicGrid<{}> ref={this.dynamicGrid}
                columns={gridColumns}
                onFetch={this.adminCurrenciesService.getPaged}
                hideFilterBar
                defaultFilters={{}}
                refactorRow={(row) => {
                    return { ...row, isMainCurrency: this.mainCurrencyView(row.isMainCurrency) }
                }}
                actions={gridActions}
            />
            {this.state.changeRateCurrencyId && <AdminChangeRateModal currencyId={this.state.changeRateCurrencyId} reload={this.reload} onClose={() => this.setState({ changeRateCurrencyId: undefined })} />}
            {
                this.state.showCreateModal &&
                <AdminCreateCurrencyModal reload={this.reload} currencyId={this.state.currencyId} onClose={() => this.setState({ currencyId: undefined, showCreateModal: false })} />
            }
        </>)
    }
}