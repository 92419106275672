import React from "react"
import { Dropdown } from "react-bootstrap"
import { Cash, CashCoin, PersonCircle } from "react-bootstrap-icons"
import { useNavigate, NavLink } from "react-router-dom"
import './CapitalsOverview.scss'
import authenticationContext from "../../../contexts/AuthenticationContext/AuthenticationContext"
import { WalletOverview } from "../wallet-overview/WalletOverview"
import ShopAuthenticated from "../../../components/shared/ShopAuthenticated/ShopAuthenticated"
import { CURRENCIES_MENU } from "../../../constants/ConstantsPermissions"
import CapitalsOverview from "./CapitalsOverview"
import { FaChartArea, FaChartPie } from "react-icons/fa"

const CustomToggle = React.forwardRef((props: any, ref) => (
    <div className="dropdown-custom" onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
    }}>{props.children}</div>
))

const CustomMenu = React.forwardRef((props: any, ref: any) => {
    return (
        <div ref={ref} aria-labelledby={props.labeledBy} style={props.style} className={props.className}>
            {props.children}
        </div>
    )
});

export interface IMenuItems {
    styles?: string, label: string, link?: string
}

export default function CapitalsOverviewToggle() {
    const context = React.useContext(authenticationContext)
    let navigate = useNavigate()

    let items: IMenuItems[] = [
        { label: 'Edit Profile', link: '/user-profile' },
        { label: 'New Shop', link: '/new-shops' },
        { label: 'Security', link: '/security' },
    ]

    const logout = () => {
        context.logout();
        navigate("/sign-in")
    }

    return (<div className="CapitalsOverview">

        <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                <div className="icon" style={{ cursor: 'pointer', color: 'green' }}>
                    <FaChartPie />
                    General statistics
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="profile-dropdown" as={CustomMenu}>
                <h5>Warehouse Statistics</h5>
                <hr />
                <div className="clear"></div>
                <ShopAuthenticated showAccessDenied permission={CURRENCIES_MENU}>
                    <CapitalsOverview />
                </ShopAuthenticated>
            </Dropdown.Menu>
        </Dropdown>
    </div >
    )
}