import React from "react";
import AdminArticleService from "../../../../../services/admin/articles/AdminArticlesService";
import ModalComponent from "../../../../shared/modal/Modal";
import SpinnerComponent from "../../../../shared/spinner/SpinnerComponent";

export default class AdminArticleDetailsModal extends React.Component<{ articleId?: string, onClose: () => void }, { loading: boolean, details: string, title: string, imageSrc?: string }> {
    adminArticleService: AdminArticleService
    constructor(props: any) {
        super(props);
        this.state = { loading: false, details: '', title: '' }
        this.adminArticleService = new AdminArticleService()
    }

    componentDidMount(): void {
        this.props.articleId && this.getDetails()
    }

    getDetails = () => {
        this.setState({ loading: true })
        this.adminArticleService.getById(this.props.articleId)
            .then(response => this.setState({ details: response.details, title: response.title, loading: false, imageSrc: response.imageSrc }))
    }

    render(): React.ReactNode {
        let { loading, details, title, imageSrc } = this.state

        return <ModalComponent title={title} size="1000px" onClose={this.props.onClose}>
            <div>
                <div className="article-body">
                    {
                        !loading ?
                            <>
                                {
                                    imageSrc &&
                                    <div className="d-flex align-items-center justify-content-center mb-3">
                                        <img src={imageSrc} style={{ maxWidth: '300px', maxHeight: '300px' }} />
                                    </div>
                                }
                                <div dangerouslySetInnerHTML={{ __html: details }}></div>
                            </>
                            : <SpinnerComponent />
                    }
                </div>
            </div>
        </ModalComponent>
    }
}