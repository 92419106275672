import React from "react";
import './AdminHeader.scss';
import IdentityService from "../../../services/identity/IdentityService";
import { WalletOverview } from "../../main-layout/wallet-overview/WalletOverview";
import LayoutContext from "../../../contexts/LayoutContext/LayoutContext";
import ShopChanger from "../../main-layout/shop-changer/ShopChanger";
import Profile from "../../main-layout/profile/Profile";
import Alerts from "../../../components/dashboard/Alerts/Alerts";
import AdminNavMenu from "./NavMenu/AdminNavMenu";
import { AppBar, Box, Container, Grid, Toolbar } from "@mui/material";

export default class AdminHeader extends React.Component<{ onToggleSidebar(): void, hideMenu: boolean, isMobile: boolean }, { showDropDown: boolean }> {
    identityService: IdentityService;
    walletOverview: React.RefObject<WalletOverview>;
    static contextType = LayoutContext;
    context!: React.ContextType<typeof LayoutContext>;

    constructor(props: any) {
        super(props);
        this.identityService = new IdentityService();
        this.state = { showDropDown: false };
        this.walletOverview = React.createRef()
    }

    componentDidMount(): void {
        this.context.onAddToCartSubject.subscribe(() => {
            this.walletOverview.current?.loadOverview();
        })
    }

    mobileView = () => {
        return <AppBar position="sticky">
            <Toolbar sx={{ bgcolor: 'white', height: '64px' }} className="full-height">
                <Container>
                    <Grid container className="full-width">
                        <Grid item sm={4} xs={4}>
                            <AdminNavMenu />
                        </Grid>
                        <Grid item sm={4} xs={4} alignItems="center" justifyContent="center" display="flex">
                            <Box sx={{ flex: 'none', fontSize: '13px' }} className="text-charcoal font-bold">Admin</Box>
                        </Grid>
                        <Grid item sm={4} xs={4} alignItems="center" justifyContent="end" display="flex">
                            <>
                                <div className="flex">
                                    <div>
                                        <Profile />
                                    </div>
                                </div>
                            </>
                        </Grid>
                    </Grid>
                </Container>
            </Toolbar>
        </AppBar>;
    }

    defaultView = () => {
        return <AppBar position="sticky" sx={{zIndex: 1}}>
            <Toolbar className="full-height" sx={{ height: '88px' }}>
                <Container>
                    <div className="flex items-center justify-between full-width">
                        <div className={"mr-xs"}>
                            <ShopChanger />
                        </div>
                        <AdminNavMenu />
                        <div className="flex">
                            <div className="pl-sm">
                                <Alerts />
                            </div>
                            <div className="pl-sm">
                                <Profile />
                            </div>
                        </div>
                    </div>
                </Container>
            </Toolbar>
        </AppBar>;
    }

    render(): React.ReactNode {
        return this.props.isMobile ? this.mobileView() : this.defaultView();
    }
}