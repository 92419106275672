import React from "react";
import { Alert, Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { NotificationManager } from 'react-notifications';
import IState from "./CurrencyAverage.models";
import { SelectionView } from "../../../../services/common/Models";
import { LocalCurrencyService } from "../../../../services/financials/LocalCurrencyService";
import Amount from "../../../utilities/Amount/Amount";
import Note from "../../../shared/Note/Note";
import ValidAmount from "../../../utilities/Amount/ValidAmount";

export default class CurrencyAverage extends React.PureComponent<{}, IState> {
    localCurrencyService: LocalCurrencyService
    constructor(props: any) {
        super(props)
        this.localCurrencyService = new LocalCurrencyService()
        this.state = { currencyId: '', loading: false, currencies: [], average: '0', pendingRequest: false, balances: [], currentCurrencyAverage: 0 }
    }

    componentDidMount() {
        this.getPaged()
    }

    getPaged = (currencyId?: string) => {
        this.setState({ loading: true })
        this.localCurrencyService.getBalance().then((balances) => {
            let currencies: SelectionView[] = balances.map((balance) => {
                return {
                    title: balance.currencyTitle,
                    value: balance.currencyId
                } as SelectionView
            })
            this.setState({ balances, currencies, loading: false })
            this.updateCurrency(currencyId !== undefined ? currencyId : currencies[0].value)
        })
    }

    submit = () => {
    }

    updateCurrency = (id: string) => {
        let balance = this.state.balances.filter(balance => balance.currencyId === id)
        this.setState({ currencyId: id, currentCurrencyAverage: balance[0].average, average: balance[0].average !== null ? String(balance[0].average) : '0' })
    }

    render(): React.ReactNode {
        const { currencyId, currencies, average, loading, pendingRequest, currentCurrencyAverage } = this.state

        return loading ? <Spinner animation="grow" /> :
            <>
                <h5>Change Currency Average</h5>
                <Note>If you entered the wrong local currency at the time of charging your wallet, or if you want to change the final average for any reason, you can do so from this section.</Note>
                <hr />

                <Row>
                    <Col md="4" sm="12">
                        <Form.Label>Select Currency: </Form.Label>
                        <Form.Select aria-label=">Select Local currency" id="currencyId" value={currencyId}
                            onChange={(e) => this.updateCurrency(e.target.value)}>
                            {
                                currencies.map((item) => <option value={item.value} key={item.value}>{item.title}</option>)
                            }
                        </Form.Select>
                    </Col>
                </Row>
                {currencyId &&
                    <>
                        <Row className="mt-3">
                            <Col md="4" sm="12">
                                <Alert variant="secondary">
                                    <span>Current Average: </span><span className="ml-1"><Amount value={currentCurrencyAverage} /></span>
                                </Alert>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md="4" sm="12">
                                <Form.Label>New Average:</Form.Label>
                                <Form.Control 
                                    type="text"
                                    inputMode='decimal' aria-label="Enter new average"
                                    value={average}
                                    onChange={(e) => ValidAmount(e.target.value) && this.setState({ average: e.target.value })} />
                            </Col>
                        </Row>
                        {
                            pendingRequest ? <Button variant="success" className="mt-2" disabled ><Spinner size="sm" animation="border" /></Button> :
                                <Button onClick={() => this.submit()} variant="success" className="mt-2">Update</Button>
                        }
                    </>
                }
            </>
    }
}