import React from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { NotificationManager } from 'react-notifications';
import SupportTicketsService from "../../../services/helpCenter/SupportTicketService";
import IRequestCreateCategoryState, { IRequestCreateCategoryProps } from './RequestCreateCategoryModal.models'

export default class RequestCreateCategoryModal extends React.PureComponent<IRequestCreateCategoryProps, IRequestCreateCategoryState> {
    supportTicketsService: SupportTicketsService
    constructor(props: IRequestCreateCategoryProps) {
        super(props)
        this.supportTicketsService = new SupportTicketsService()
        this.state = { pendingRequest: false, description: '', departmentId: '' }
    }

    componentDidMount() {
        this.setDepartment()
    }

    setDepartment = () => {
        this.supportTicketsService.getDepartments().then((response) => {
            this.setState({ departmentId: response[0].value })
        })
    }

    submit = () => {
        this.setState({ pendingRequest: true })
        this.supportTicketsService.create("Open Category Request", this.state.description, this.state.departmentId).then(() => {
            NotificationManager.success('Category requested successfully, you can follow it up in your ticket list')
            this.setState({ pendingRequest: true, description: '' })
            this.props.closeModal()
        })
    }

    render(): React.ReactNode {
        return <Modal size="sm"
            show={true}
            onHide={this.props.closeModal}>
            <Modal.Header>
                <Modal.Title>Request Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Label>Requested Category Description:</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Enter description"
                    value={this.state.description}
                    onChange={e => this.setState({ description: e.target.value })} />
            </Modal.Body>
            <Modal.Footer>
                {this.state.pendingRequest ? <Button variant='success' disabled><Spinner animation='border' size="sm" /></Button> :
                    <Button variant='success' onClick={this.submit}>Create</Button>}
            </Modal.Footer>
        </Modal>
    }
}