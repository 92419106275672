import { Route } from "react-router-dom";
import StoreRoute from "../shopping/StoreRoute";
import MainLayout from "../../layouts/main-layout/MainLayout";
import CartRoute from "../shopping/CartRoute";

export function StoreRoutes() {
    return (
        <>
            <Route element={<MainLayout showBottomNavigation showLogo />}>
                <Route path="/marketplace" element={<StoreRoute />} />
            </Route>

            <Route element={<MainLayout showLogo/>}>
                <Route path="/cart" element={<CartRoute />} />
            </Route>
        </>
    )
}