import React from "react";
import MainBanner from "../../../../assets/images/banners/main-mobile.png";
import SecondBanner from "../../../../assets/images/banners/second-mobile.png";
import ThirdBanner from "../../../../assets/images/banners/third-mobile.png";
import { Box } from "@mui/material";

export default class MobileBanners extends React.Component<{}, { loaded: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = { loaded: false };
    }
    render(): React.ReactNode {
        return (<div>
            <Box pt={1}>
                <img src={MainBanner}
                    className="full-width"
                    style={{ display: this.state.loaded ? 'block' : 'none' }}
                    onLoad={() => this.setState({ loaded: true })}
                />
            </Box>
            <Box pt={1.5}>
                <img src={SecondBanner}
                    className="full-width"
                    style={{ display: this.state.loaded ? 'block' : 'none' }}
                    onLoad={() => this.setState({ loaded: true })}
                />
            </Box>
            <Box pt={1.5}>
                <img src={ThirdBanner}
                    className="full-width"
                    style={{ display: this.state.loaded ? 'block' : 'none' }}
                    onLoad={() => this.setState({ loaded: true })}
                />
            </Box>
        </div>);
    }
}