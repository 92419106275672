import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import NotificationPermissionService, { NotificationPermissionRowDto, UpdateNotificationPermission } from "../../../services/notifications/NotificationPermissionService";
import { NotificationManager } from 'react-notifications';
import NotificationPermissionsList from "./PermissionsList/NotificationPermissionsList";
import ShopService from "../../../services/shops/ShopService";
import { Grid } from "@mui/material";
import SpinnerComponent from "../../shared/spinner/SpinnerComponent";

interface INotificationPermissaionState {
    pendingRequest: boolean
    loading: boolean
    shopTitle?: string
    permissions: NotificationPermissionRowDto[]
}

export default class NotificationPermissaion extends React.PureComponent<{}, INotificationPermissaionState> {
    notificationPermissionService: NotificationPermissionService
    shopService: ShopService
    constructor(props: any) {
        super(props);
        this.state = { pendingRequest: false, permissions: [], loading: false };
        this.notificationPermissionService = new NotificationPermissionService();
        this.shopService = new ShopService();
    }

    componentDidMount(): void {
        this.setState({ loading: true })

        this.notificationPermissionService.get()
            .then((response) => {
                this.setState({ permissions: response })
            })
            .finally(() => { this.setState({ loading: false }) })

        this.setShopInformation()
    }

    setShopInformation = () => {
        this.shopService.getCurrent().then((response) => {
            this.setState({ shopTitle: response.title })
        })
    }

    submit = (newPermissions: NotificationPermissionRowDto[]) => {
        this.setState({ pendingRequest: true });

        let dto: UpdateNotificationPermission[] = newPermissions.map((permission) => {
            return {
                isActive: permission.isActive,
                permissionId: permission.id
            }
        })

        this.notificationPermissionService.update(dto).then(() => {
            NotificationManager.success('Permissions updates successfully.')
        }).finally(() => {
            this.setState({ pendingRequest: false })
        })
    }

    render(): React.ReactNode {
        return (
            <div className="mt-3">
                <div className="font-bold font-lg">Email Notifications Settings {this.state.shopTitle && <span className="text-success">({this.state.shopTitle})</span>}</div>
                <Grid container>
                    <Grid md={12} sm={12} item>
                        <div className="sub-title">Manage and customize email notification for different features of your shop.</div>
                    </Grid>
                    {this.state.loading ? <SpinnerComponent /> : <NotificationPermissionsList pendingRequest={this.state.pendingRequest} onSubmit={this.submit} permissions={this.state.permissions} />}
                </Grid>
            </div>
        )
    }
}