import React, { RefObject } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import Deficits from "../dashboard/Deficits/Deficits";
import InventoryList from "./InventoriesList/InventoryList";
import InventoryShopCollectionList from "./InventoryShopCollection/InventoryShopCollectionList";
import InventorySourceList from "./InventorySource/InventorySourceList/InventorySourceList";
import TakeoutInventory from "./TakeoutInventory/TakeoutInventory";
import { ShopInventoryStatusEnum, shopInventoryStatusDisplayCaption } from "./Enums/ShopInventoryStatusEnum";
import Note from "../shared/Note/Note";
import { Container } from "@mui/material";
import CapitalsOverviewToggle from "../../layouts/main-layout/capitals-overview/CapitalsOverviewToggle";

export default class Inventory extends React.Component<{ defaultActiveSubKey?: string | null, defaultActiveKey: string | null, productId: string | null, shopCollectionId: string | null },
    {
        key: string
    }> {
    constructor(props: any) {
        super(props)
        this.state = { key: this.props.defaultActiveKey ?? 'inventory' }
    }

    setKey = (newKey: string) => {
        this.setState({ key: newKey })
    }

    shopInventoryStatusDisplayNote(status: number) {
        let types = ['Inventories that are available and ready for sale are included in this list.', 'Inventories that have been out (sold) are included in this list.', '', 'Inventories that have been hold manually are included in this list.', '', '', '', 'Inventories that are locked due to duplicate code or validation are included in this list.'];
        return types.length <= status ? status : types[status];
    }

    render(): React.ReactNode {
        return <Container className="pt-xl">
            {this.state.key && this.state.key !== this.props.defaultActiveKey ? <Navigate to={"/inventory/?activeTab=" + this.state.key} /> : null}
            <Tabs
                activeKey={this.state.key}
                onSelect={(key) => this.setKey(key)}
                defaultActiveKey={this.props.defaultActiveKey !== null ? this.props.defaultActiveKey : 'inventory'} className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                <Tab eventKey="inventory" title="Warehouse">
                    <CapitalsOverviewToggle />
                    <Tabs defaultActiveKey={"Available"}>
                        {Object.keys(ShopInventoryStatusEnum).map(key => typeof ShopInventoryStatusEnum[key as any] !== 'number' && (
                            ![ShopInventoryStatusEnum.Preparing, ShopInventoryStatusEnum.Validation, ShopInventoryStatusEnum.ManualSold, ShopInventoryStatusEnum.Reserved].includes(Number(key)) &&
                            <Tab eventKey={ShopInventoryStatusEnum[key as any]} title={shopInventoryStatusDisplayCaption(Number(key))}>
                                <div className="pt-2">
                                    <Note>{this.shopInventoryStatusDisplayNote(Number(key))}</Note>
                                    <InventoryList defaultFilter={{ status: Number(key), productId: this.props.productId, shopCollectionId: this.props.shopCollectionId }} hideDontShowOnlyinLockRow />
                                </div>
                            </Tab>
                        ))}
                        <Tab eventKey="All" title="All">
                            <div className="pt-2">
                                <InventoryList defaultFilter={{ productId: this.props.productId, shopCollectionId: this.props.shopCollectionId }} hideDontShowOnlyinLockRow />
                            </div>
                        </Tab>
                    </Tabs>
                </Tab>
                <Tab eventKey="stockIn" title="Stock In">
                    <Tabs defaultActiveKey={this.props.defaultActiveSubKey ?? "sources"}>
                        <Tab eventKey="sources" title="Sources" >
                            <InventorySourceList />
                        </Tab>
                        <Tab eventKey="collections" title="Collections">
                            <InventoryShopCollectionList changeTab={(key) => this.setKey(key)} />
                        </Tab>
                        <Tab eventKey="inComming" title="In Comming">
                            <Deficits cardView />
                        </Tab>
                    </Tabs>
                </Tab>
                <Tab eventKey="stockOut" title="Stock Out">
                    <TakeoutInventory />
                </Tab>
            </Tabs>
        </Container>
    }
}